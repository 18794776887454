<template>
  <div class="header-global" v-if="user.data">
    <base-nav
      class="navbar-main d-lg-block d-none"
      type="default"
      effect="dark"
      ref="baseNavBar"
      expand
    >
      <router-link
        slot="brand"
        class="navbar-brand mr-lg-5 d-lg-none d-md-block"
        to="/reflectnow"
      >
        <img src="/img/brand/white.svg" alt="My Reflections" />
      </router-link>

      <ul class="navbar-nav align-items-lg-center d-lg-block d-none">
        <a slot="title" href="#" class="nav-link" data-toggle="dropdown" role="button">
          <router-link slot="brand" class="navbar-brand mr-lg-5" to="/reflectnow">
            <img src="/img/brand/white.svg" alt="My Reflections" />
          </router-link>
        </a>
      </ul>

      <ul class="navbar-nav navbar-nav-hover ml-0 d-lg-flex d-none">
        <li class="nav-item">

          <a
            role="button"
            @click="openReflections"
            class="pointer fa-pulser nav-link nav-link-icon"
            title="Reflect Now!"
          >
            <icon
              name="fal fa-plus text-white"
              size="sm"
              type="default"
              shadow
              rounded
            ></icon>
          </a>

        </li>

        <li class="nav-item">
          <router-link class="nav-link nav-link-icon" to="/reflectnow">
            <i class="fad fa-head-side-brain"></i>
            <span class="nav-link-inner--text">Reflections</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link nav-link-icon" to="/deep-dives">
            <i class="fad fa-lightbulb-on"></i>
            <span class="nav-link-inner--text">Deep Dives</span>
          </router-link>
        </li>

        <!-- IF WE HAVE MULTIPLE GROUPS LIST THEM -->
        <li class="nav-item dropdown" v-if="groups.length > 1">
          <router-link
            to="/mygroups"
            class="nav-link nav-link-icon pointer"
            data-toggle="dropdown"
            role="button"
            v-if="groups.length > 0"
          >
            <i class="fad fa-users-class"></i>
            <span class="nav-link-inner--text">My Groups</span>
          </router-link>

          <div class="dropdown-menu">
            <router-link
              :to="'/mygroups/' + group.id"
              class="dropdown-item"
              v-for="(group, g) in groups"
              v-bind:key="g"
            >
              {{ group.groupName }}
            </router-link>
          </div>
        </li>

        <li class="nav-item" v-if="groups.length === 1">
          <router-link class="nav-link nav-link-icon" :to="'/mygroups/' + groups[0].id">
            <i class="fad fa-users-class"></i>
            <span class="nav-link-inner--text">My Group</span>
          </router-link>
        </li>
      </ul>

      <ul class="navbar-nav navbar-nav-hover align-items-lg-center ml-lg-auto">
        <!-- MENU FOR LARGE DEVICES -->
        <li class="nav-item dropdown d-lg-block d-none ml-lg-4">
          <div class="media align-items-center my-3" data-toggle="dropdown" slot="title">

            <span
              class="avatar rounded-circle border border-white-50 border-2 pointer"
              v-if="user.data"
            >
              <img
                :alt="user.data.fullName"
                :src="user.data.photoURL"
                v-if="user.data.photoURL"
              />
              <img
                alt="Defaultt Profile"
                src="/img/my-reflections-default-profile.png"
                v-else
              />
            </span>
          </div>

          <div class="dropdown-menu dropdown-menu-right">

            <router-link to="/profile" class="dropdown-item">
              <span class="nav-link-inner--text">
                <i class="fad fa-user"></i> Profile
              </span>
            </router-link>

            <router-link to="/admin" class="dropdown-item" v-if="user.administrativeUser">
              <span class="nav-link-inner--text">
                <i class="fad fa-user-cowboy"></i> Administration
              </span>
            </router-link>

            <a href="javascript:void(Tawk_API.toggle())" class="dropdown-item">
                <span class="nav-link-inner--text">
                    <i class="fad fa-user-headset"></i> Get Help
                </span>
            </a>

            <a href="#" class="dropdown-item" @click="signout">
              <span class="nav-link-inner--text">
                <i class="fad fa-sign-out"></i> Logout
              </span>
            </a>
          </div>
        </li>
      </ul>
    </base-nav>

    <mobile-nav
      class="navbar-expand d-lg-none d-md-block shadow"
      type="default"
      effect="dark"
      ref="baseNavBar"
    >
      <router-link class="navbar-brand" to="/reflectnow">
        <img src="/img/brand/white.svg" alt="my reflections" class="mt-1 mb-1" />
      </router-link>

      <ul class="navbar-nav navbar-nav-hover align-items-lg-center ml-lg-auto">
        <!-- MENU FOR LARGE DEVICES -->
        <li class="nav-item">
          <router-link to="/profile">
            <div class="media align-items-center">
              <span
                class="avatar avatar-sm rounded-circle border border-white-50 border-2 pointer"
                v-if="user.data"
              >
                <img
                  :alt="user.data.fullName"
                  :src="user.data.photoURL"
                  v-if="user.data.photoURL"
                />
                <img
                  alt="Default Profile"
                  src="/img/my-reflections-default-profile.png"
                  v-else
                />
              </span>
            </div>
          </router-link>
        </li>
      </ul>
    </mobile-nav>

    <mobile-nav
      class="fixed-bottom navbar-expand d-lg-none d-md-block shadow"
      type="default"
      effect="dark"
      ref="baseNavBar"
    >
      <ul class="navbar-nav navbar-nav-hover nav-fill align-items-center w-100">
        <!-- MENU FOR SMALL DEVICES -->
        <li class="nav-item mt-1 mb-1 nav-button">
          <router-link to="/reflectnow">
            <i class="fad fa-head-side-brain fa-2x"></i>
          </router-link>
        </li>

        <li class="nav-item mt-1 mb-1 nav-button">
          <router-link to="/deep-dives" style="position: relative">
            <i class="fad fa-lightbulb-on fa-2x"></i>
<!-- 
            <div
              class="rounded-circle bg-danger text-white notice-pill-mobile text-center pointer"
              v-if="pendingReflections && pendingReflections.length > 0"
            >
              <i class="fas fa-bell fa-pulser" title="Deep Dive Due"></i>
            </div> -->
          </router-link>
        </li>

        <li class="nav-item mt-1 mb-1 nav-button">
          <a
            role="button"
            id="help-popover"
            @click="openReflections"
            class="pointer"
            title="Reflect Now!"
            v-if="!modals.reflection"
          >
            <icon name="fal fa-plus" type="default" shadow rounded></icon>
          </a>
          <a
            role="button"
            @click="modals.reflection = false"
            class="pointer"
            style="z-index: 9999"
            title="Cancel"
            v-else
          >
            <icon name="fal fa-times" type="danger" shadow rounded></icon>
          </a>
        </li>

        <li class="nav-item mt-1 mb-1 nav-button">
          <router-link to="/mygroups" v-if="groups.length > 1">
            <i class="fad fa-users-class fa-2x"></i>
          </router-link>

          <router-link :to="'/mygroups/' + groups[0].id" v-else-if="groups.length === 1">
            <i class="fad fa-users-class fa-2x"></i>
          </router-link>

          <router-link :to="{ path: '/profile', query: { tab: 'groups' }}" v-else>
            <i class="fad fa-users-medical fa-2x"></i>
          </router-link>
        </li>

        <li class="nav-item mt-1 mb-1 nav-button">
          <router-link to="/profile">
            <i class="fad fa-user fa-2x"></i>
          </router-link>
        </li>
      </ul>
    </mobile-nav>

    <modal :show.sync="modals.reflection" modal-classes="modal-dialog-centered modal-sm p-1" v-on:close="reflectionModalClosed">
      <reflection
        ref="reflectPanel"
        name="reflection"
        :current-user="user.data"
      ></reflection>
    </modal>

    <!-- EMAIL VERIFICATION START -->
    <div
      v-if="
        !user.data.emailVerified &&
        this.reflections.length > 10 &&
        this.reflections.length < 20
      "
    >
      <div class="bg-primary p-3">
        <div class="container text-white text-center" v-if="!emailVerificationSent">
          <i class="fad fa-engine-warning"></i>
          <small>
            You have not yet verified your email address and will only be able to log
            another
            {{ 20 - this.reflections.length }} reflections before you
            will be locked out
            <base-button
              type="secondary"
              size="sm"
              shadow
              @click="confirmEmail()"
              class="ml-2"
            >
              confirm now
            </base-button>
          </small>
        </div>
        <div class="container text-white text-center" v-else>
          <small>
            An email has been sent to
            <span class="font-weight-bold">{{ user.data.email }}</span> please check now!
            Once verified
            <base-button
              type="secondary"
              size="sm"
              shadow
              @click="reloadPage()"
              class="ml-2"
            >
              reload page
            </base-button>
          </small>
        </div>
      </div>
    </div>
    <div
      v-if="!user.data.emailVerified && this.reflections.length >= 20"
    >
      <div class="bg-danger p-3">
        <div class="container text-white text-center" v-if="!emailVerificationSent">
          <i class="fad fa-engine-warning"></i>
          <small>
            You have not yet verified your email address and have reached the reflections
            limit for unverified accounts!
            <base-button
              type="secondary"
              size="sm"
              shadow
              @click="confirmEmail()"
              class="ml-2"
            >
              confirm now
            </base-button>
          </small>
        </div>
        <div class="container text-white text-center" v-else>
          <small>
            An email has been sent to
            <span class="font-weight-bold">{{ user.data.email }}</span> please check now!
            Once verified
            <base-button
              type="secondary"
              size="sm"
              shadow
              @click="reloadPage()"
              class="ml-2"
            >
              reload page
            </base-button>
          </small>
        </div>
      </div>
    </div>
    <!-- EMAIL VERIFICATION END -->

    <!-- <tips-checker :user="user" /> -->
  </div>
</template>

<script>
import firebase from "firebase";
import { db } from "@/firebase";

import store from "@/store.js";
import { mapGetters } from "vuex";
import { mapMutations } from "vuex";
// import { mapMutations } from 'vuex'
import BaseNav from "@/components/BaseNav";
import MobileNav from "@/components/MobileNav";
import Modal from "@/components/Modal.vue";
import BaseDropdown from "@/components/BaseDropdown";
import CloseButton from "@/components/CloseButton";
import md5 from "js-md5";


// Alerts / Tips
// import TipsChecker from "@/components/tips/TipsChecker";
import Reflection from "@/components/reflections/Reflection";

export default {

  name: "app-header",
  props: ["reflections"],

  components: {
    // TipsChecker,
    BaseNav,
    MobileNav,
    BaseDropdown,
    CloseButton,
    Modal,
    Reflection,
  },

  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      // map `this.groups` to `this.$store.getters.groups`
      user: "user",
      groups: "groups",
      reflectionActions: "reflectionActions",
      reflectionQueue: "reflectionQueue"
    }),

    // pendingReflections() {
    //   if (!this.user.data) {
    //     return;
    //   }

    //   if (this.user.data.reflectionsWeekly) {
    //     var tmpUserWeeks = this.user.data.reflectionsWeekly;
    //     var firstpass = [];

    //     tmpUserWeeks.forEach((filter, fi) => {
    //       if (filter.status === null && filter.endDate < this.today) {
    //         firstpass.push(filter);
    //       }
    //     });

    //     return firstpass;
    //   } else {
    //     return [];
    //   }
    // },

    currentRoutePath() {
      return this.$route.path;
    },
  },

  data() {
    return {
      reflectionWeeks: [],
      timer: 0,
      today: null,
      emailVerificationSent: false,
      modals: {
        reflection: false,
      }
    };
  },

  mounted() {
    this.checkNotifiables();

    this.$watch("$refs.reflectPanel.hideModal", (new_value, old_value) => {

      if (new_value === true) {
        this.modals.reflection = false;
      }
    });
  },

  watch: {
    user: {
      handler: function () {
        // console.log('watch')
        this.checkNotifiables();
        this.checkFCM();
        this.reflectionWeeks = [];
        // this.generateWeeks();
      },
      deep: true,
    },

    // WIP: CONVERT TO STORE
    reflectionQueue: {
        deep: true,
        handler() {
            setTimeout(this.processReflectionQueue,500);
        },
    },

    $route(to, from) {
      this.modals.reflection = false;
    },
  },

  methods: {

    processReflectionQueue(){
        if(this.reflectionQueue.length > 0){
            this.openReflections();
        }
    },

    reflectionModalClosed(){

      if(this.$refs.reflectPanel){
        // this.$refs.reflectPanel.form.action = 'resetreflection'
        this.$refs.reflectPanel.clearReflection();
      }

      
      
    },

    openReflections() {
      this.modals.reflection = true;
    },

    closeBaseNavMenu() {
      //this.$emit('closeMenu')
      this.$refs.baseNavBar.closeMenu();
    },

    reloadPage() {
      location.reload();
    },

    checkFCM() {
      if (!this.user.data) {
        return;
      }

      let messaging = null;
      if (firebase.messaging.isSupported()) {
        messaging = firebase.messaging();
      }

      if (!messaging) {
        return;
      }

      // Get registration token. Initially this makes a network call, once retrieved
      // subsequent calls to getToken will return from cache.
      messaging
        .getToken({
          vapidKey:
            "BAEquBBbfIebGuxNZHqIDgDZn2aPoYPAVkzhwPGVylkkyBSkW82y36gnkdmqr5o-A3zRk1WgrlkjuLOvvN2XO-Y",
        })
        .then((currentToken) => {
          if (currentToken) {
            // console.log(currentToken)

            this.sendTokenToServer(currentToken, this.user.data.uid);
            // firebase.messaging().subscribeToTopic(currentToken, 'my-reflections-global')
            //   .then((response) => {
            //     // See the MessagingTopicManagementResponse reference documentation
            //     // for the contents of response.
            //     console.log('Successfully subscribed to topic:', response);
            //   })
            //   .catch((error) => {
            //     console.log('Error subscribing to topic:', error);
            //   });
            
          } else {
            // Show permission request.
            // console.log('No registration token available. Request permission to generate one.');
            // Show permission UI.
            // updateUIForPushPermissionRequired();
            // setTokenSentToServer(false);
          }
        })
        .catch((err) => {
          //console.log('An error occurred while retrieving token. ', err);
          // showToken('Error retrieving registration token. ', err);
          // setTokenSentToServer(false);
        });

      messaging.onMessage((payload) => {

        // console.log('Received forreground message ', payload);

        // let link = 'https://www.myreflections.app'
        // if(payload.data.route){
        //   link = payload.data.route
        // }
        
        // Customize notification here
        // const notificationTitle = payload.notification.title;
        // const notificationOptions = {
        //   body: payload.notification.body,
        //   icon: payload.notification.image,
        //   click_action: link,
        // };

        // self.registration.showNotification(notificationTitle,
        //   notificationOptions);
      });

    },

    sendTokenToServer(token, uid) {
      var userRef = db.collection("users").doc(uid);
      userRef.update({
        cloudMessagingTokens: firebase.firestore.FieldValue.arrayUnion(token),
      });
    },

    checkNotifiables() {
      if (!this.user.data) {
        return;
      }

      this.today = this.$dayjs().format("YYYY-MM-DD");

      if (!this.user.data.photoURL) {
        this.getProfileImage();
      }

      if (!this.user.data.emailVerified) {
        this.checkEmailVerification();
      }
    },

    checkEmailVerification() {
      let fbuser = firebase.auth().currentUser;

      if (fbuser) {
        if (fbuser.emailVerified) {
          let newUser = JSON.parse(JSON.stringify(this.user.data));
          newUser.emailVerified = true;
          this.$store.dispatch("updateUser", newUser);
        }
      }
    },

    confirmEmail() {
      const vm = this;

      var thisUser = firebase.auth().currentUser;
      thisUser
        .sendEmailVerification()
        .then(function () {
          vm.emailVerificationSent = true;
        })
        .catch(function (error) {
          // console.log(error)
        });
    },

    getProfileImage() {
      let emailHash = md5(this.user.data.email);
      let profileUrl = "https://www.gravatar.com/avatar/" + emailHash + "?s=200&d=retro";

      let newUser = JSON.parse(JSON.stringify(this.user.data));
      newUser.photoURL = profileUrl;

      this.$store.dispatch("updateUser", newUser);
    },

    updateFirebase(profileUrl) {
      db.collection("users").doc(this.user.data.uid).update({
        photoURL: profileUrl,
      });
    },

    signout: function () {
      const vm = this;
      firebase
        .auth()
        .signOut()
        .then(
          function () {
            vm.$store.commit("SET_LOGGED_IN", null);
            vm.$store.commit("SET_USER", null);
            vm.$router.replace("/landing");
          },
          function (error) {
            // console.error('Sign Out Error', error);
          }
        );
    },

  },
};
</script>

<style scoped>
/* .menu-label {
    position: absolute;
    bottom: 0px;
    text-align: center;
    line-height: 0px;
    width: 100px;
} */

.router-link {
  color: #553069;
}

.router-link-active {
  color: #ffffff;
}

.notice-pill {
  width: 21px;
  height: 21px;
  position: absolute;
  top: 50px;
  left: -5px;
  padding-top: 1px;
  padding-right: 1px;
  font-size: 0.8em;
}

.notice-pill-mobile {
  width: 21px;
  height: 21px;
  position: absolute;
  top: -14px;
  right: -9px;
  padding-top: 1px;
  padding-right: 1px;
  font-size: 0.8em;
}

/* @media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) {

    .notice-pill-safari { 

        top: 50px;
        left: -5px;

    }
}} */

/* Icon pulse */
.fa-pulser {
  display: inline-block;
  -moz-animation: pulser 1s infinite linear;
  -o-animation: pulser 1s infinite linear;
  -webkit-animation: pulser 1s infinite linear;
  animation: pulser 1s infinite linear;
}

@-webkit-keyframes pulser {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes pulser {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes pulser {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes pulser {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@keyframes pulser {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}
</style>
