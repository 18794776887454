<template>
    <div class="p-1">

        <a :href="attachment.src" target="_blank">

          <div class="card shadow" v-if="attachment.pageTitle">
            <div class="d-flex flex-row align-items-center">
              <div class="p-2 align-lg-left align-center d-md-flex d-none">
                <img class="card-img-top" :src="attachment.pageImage" :alt="attachment.pageTitle" />
              </div>
              <div class="p-2 text-lg-left text-center">
                <h6 class="text-default font-weight-bold">{{ attachment.pageTitle }}</h6>
                <p class="card-text">{{ attachment.pageDescription }}<br /></p>
                <a v-bind:href="attachment.src" class="btn btn-sm btn-primary">Visit</a>
              </div>
            </div>
          </div>
          <div class="card shadow p-2" v-else>
            Preview is not available but you can still link to this website<br />
            <a v-bind:href="attachment.src" class="btn btn-block btn-primary mt-2">Open website</a>
          </div>

          <!-- <div class="row" v-if="attachment.pageTitle">

            <div class="col-12 col-lg-5 p-2 align-lg-left align-center">
              <img class="card-img-top" :src="attachment.pageImage" :alt="attachment.pageTitle" />
            </div>
            <div class="col p-2 text-lg-left text-center">
                <h5 class="mt-lg-5 text-default font-weight-bold">{{attachment.pageTitle}}</h5>
                <p class="card-text">{{attachment.pageDescription}}</p>
                <a v-bind:href="attachment.src" class="btn btn-sm btn-primary">More</a>
            </div>
            
          </div>
          <div v-else>
            <a v-bind:href="attachment.src" class="btn btn-block btn-primary m-2">Open website</a>
          </div> -->
          
        </a>

    </div>
</template>
<script>

import firebase from 'firebase/app'
import { db } from '@/firebase';

import store from '@/store.js'
import { mapGetters } from "vuex";

const chunk = (arr, size) => arr
  .reduce((acc, _, i) =>
    (i % size) ?
    acc :
    [...acc, arr.slice(i, i + size)], []);


export default {
    name: 'website-embed',
    props: ['attachment'],
    // components: ['CoolLightBox'],
    mounted() {
      // this.user = this.currentUser;
    },

    data () {
        return {
            // data here
            expanded: false,
            largeImage: null,
            index: null
        }
    },
    computed: {

        ...mapGetters({
            // map `this.user` to `this.$store.getters.user`
            // map `this.groups` to `this.$store.getters.groups`
            user: "user",
            groups: "groups"
        }),

        imageClassObject: function () {
            return {
                'thumb-upto3-width': this.attachment.images.length <= 3,
                'thumb-morethan3-width': this.attachment.images.length > 3
            }
        },

        rows() {
            // const withBanners = chunk(this.attachment.images, 5).map((arr) => [...arr, {src: 'banner', type: 'Banner'}]).reduce((a, b) => a.concat(b), []);
            return chunk(this.attachment.images, 4);
        }

    },

    methods: {



    }
};
</script>

<style scoped>

    .thumb-single-width {
        max-width: 350px;
    }

    .thumb-upto3-width {
        /* max-width: 250px; */
        max-width: 90px;
    }

    .thumb-morethan3-width {
        max-width: 90px;
    }

.gallery-row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

/* Create four equal columns that sits next to each other */
.gallery-column {
  flex: 25%;
  max-width: 25%;
  padding: 0 4px;
}

.gallery-column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .gallery-column {
    flex: 50%;
    max-width: 50%;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .gallery-column {
    flex: 100%;
    max-width: 100%;
  }
}


.card-columns {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
  -webkit-column-gap: 1rem;
  -moz-column-gap: 1rem;
  column-gap: 1rem;
}

@media screen and (max-width: 600px) {
.card-columns {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  -webkit-column-gap: 1rem;
  -moz-column-gap: 1rem;
  column-gap: 1rem;
}
}

.card {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  display: inline-block;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 2px;
  margin-bottom: 5px;
  width: 100%;
}
.card img {
  width: 100%; height: auto;
  margin: 0;
}


</style>