<template>
  <div v-if="featureEnabled('heartbeat') !== 'disabled'">

    <div class="card shadow p-0 bg-white rounded">
      <div class="mt-2 mb-0">
        <div class="float-left ml-2 mr-1 p-2">
          <img
            alt="Image placeholder"
            class="avatar avatar-sm bg-white border-avatar p-1"
            src="/img/images/trends.svg"
          />
        </div>

        <p class="feed-title font-weight-bold text-default mt-2 mb-0">
          Your Reflection Heart Beat
        </p>
        <p class="feed-date text-muted" style="margin-top: -7px" v-if="reflections.length > 0">
          Last reflection: {{ $dayjs(lastReflection()).fromNow() }}
        </p>
        <p class="feed-date text-muted" style="margin-top: -7px" v-else>
          Add reflections to see <b>yours</b>!
        </p>
        <hr class="mt-0 mb-0" />
      </div>

      <div class="trend p-2" v-if="reflections.length > 0">
        <trend
          :data="sparkData"
          gradientDirection="top"
          :gradient="['#8D6C9F', '#AED768']"
          :padding="8"
          :radius="8"
          :stroke-width="sparkStroke"
          stroke-linecap="round"
          auto-draw
          style="vertical-align: middle"
        >
        </trend>
      </div>
      <div class="trend p-2" v-else>
        <trend
          :data="randomData"
          gradientDirection="top"
          :gradient="['#eee', '#e1e1e1']"
          :padding="8"
          :radius="8"
          :stroke-width="sparkStroke"
          stroke-linecap="round"
          auto-draw
          style="vertical-align: middle"
        >
        </trend>
      </div>
      <div>
        <hr class="mt-0 mb-0" />
        <p class="text-muted m-2 small text-center">
          <i class="fal fa-info-circle"></i> This spark line displays your most recent reflections
        </p>
        <!-- <div class="m-0 alert alert-secondary border-1 border-default">
        </div> -->
      </div>
    </div>

    <hr style="margin-left: 35%; margin-right: 35%; " />

  </div>

</template>
<script>

import { mapGetters } from "vuex";

export default {
  name: "reporting-heartbeat",
  props: ["reflections"],
  computed: {

    ...mapGetters({
        // map `this.user` to `this.$store.getters.user`
        // map `this.groups` to `this.$store.getters.groups`
        user: "user",
        groups: "groups",
        reflectionActions: "reflectionActions"
    }),

    sparkStroke() {
      if (this.$isDesktop()) {
        return 1;
      } else {
        return 2.5;
      }
    },

    randomData(){

      let values = Array();
      for (let i = 0; i < 10; i++){
        let value = Math.floor(Math.random() * 10)+1;
        values.push(value)
      }
      return values;

    },

    sparkData() {
      if (!this.reflections) {
        return [0];
      }

      let values = Array();

      var tmpReflections = this.reflections;
      tmpReflections.forEach(function (r, i) {
        if(r.rating > 0){
          values.unshift(Number(r.rating));
        }
        
      });

      return values;
    },

  },

  methods: {
    lastReflection() {
      return new Date(this.reflections[0].creationTime.seconds * 1000);
    },

    featureEnabled(feature){

        if(!this.user.data.features){
            return 'enabled'
        }

        if(!(feature in this.user.data.features)){
            return 'enabled'
        }

        if(this.user.data.features[feature] === 'disabled'){
            return 'disabled'
        }

        if(this.user.data.features[feature] === 'enabled'){
            return 'enabled'
        }

    },

  },
};
</script>
<style scoped>
.feed-title {
  font-size: 0.9em;
}

.feed-date {
  font-size: 0.8em;
}

.border-avatar {
  border: 2px solid #8d6c9f;
}

.trend {
  width: 100%;
  position: relative;
  top: 0;
  /* min-height: 120px; */
  vertical-align: middle;
}

.trend-icon {
  z-index: 9999;
  width: 90px;
  position: absolute;
  height: 100%;
  vertical-align: middle;
}

.img-hb {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  min-width: 60px;
  min-height: 60px;
}
</style>
