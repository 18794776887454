<template>
    <div>
        
        <modal :show.sync="modals.delete" v-if="entry" @close="closeModal"
            gradient="danger"
            modal-classes="modal-danger modal-dialog-centered">

            <div class="py-3 text-center">
                <i class="fad fa-bell fa-4x"></i>
                <h2 class="display-2 text-white mt-4">Are you sure?</h2>
                <p>Deleted threads cannot be restored!</p>
            </div>

            <template slot="footer">
                <base-button type="white" @click="deleteGroupPost">Yes</base-button>
                <base-button type="link"
                            text-color="white"
                            class="ml-auto"
                            @click="closeModal">
                    Close
                </base-button>
            </template>

        </modal>

        <!-- <modal :show.sync="modals.deletemember" v-if="entry"
            gradient="danger"
            modal-classes="modal-danger modal-dialog-centered" @close="closeModal">

            <div class="py-3 text-center">
                <i class="fad fa-bell fa-4x"></i>
                <h2 class="display-2 text-white mt-4">Are you sure?</h2>
                <p>You are about to remove this member from your group!</p>
            </div>

            <template slot="footer">
                <base-button type="white" @click="deleteMember">Yes</base-button>
                <base-button type="link"
                            text-color="white"
                            class="ml-auto"
                            @click="closeModal">
                    Close
                </base-button>
            </template>

        </modal> -->

<!-- 
        <modal :show.sync="modals.edit" modal-classes="modal-dialog-centered modal-lg" v-if="entry" @close="closeModal">

            <div class="row justify-content-center">

                <h3 class="modal-title text-center text-default mb-3" id="modal-title-default">Edit your Group</h3>
                
                <div class="col-10 pt-1 pb-4 text-center">

                    <div class="form-group text-left">
                        <label class="text-muted text-left">Group Name</label><br>
                        <base-input alternative
                                    v-model="form.groupName"
                                    class="mb-3"
                                    placeholder="Group Name"
                                    >
                        </base-input>
                    </div>

                    <div class="form-group text-left">
                        <label class="text-muted">Mission</label><br>
                        <textarea class="form-control mb-3" v-model="form.groupMission" id="exampleFormControlTextarea1" rows="3" placeholder="What's the purpose/mission for this group?"></textarea>
                    </div>

                    <div class="form-group text-left">
                        <label class="text-muted">Group Type</label><br>
                        <div class="alert alert-default text-center">
                            <b>Open / Shared</b> groups allow all team members to see each others reflections<br>
                            <b>Hierarchical</b> groups only allow the owner or leaders to see members reflections
                        </div>

                        <div class="btn-group btn-block btn-lg m-0" role="group" aria-label="Basic example">
                            <button type="button" class="btn btn-lg" @click="form.groupType = 'open'" :class="[form.groupType === 'open' ? 'btn-default' : 'btn-secondary']" >
                                <i class="fas fa-users"></i> Open / Shared
                            </button>
                            <button type="button" class="btn btn-lg" @click="form.groupType = 'hierarchical'" :class="[form.groupType === 'hierarchical' ? 'btn-default' : 'btn-secondary']">
                                <i class="fas fa-chart-network"></i> Hierarchical
                            </button>
                        </div>
                    </div>


                    <div class="col-12">

                        <div class="form-row mb-4">
                            <div class="col-sm-5 my-1">
                                <input type="text" placeholder="Add a new tag" v-slugify v-model="tempTag" class="form-control">
                            </div>
                            <div class="col-sm-5 my-1">
                                <select class="form-control" v-model="tempType">
                                    <option selected :value="null">all reflection types</option>
                                    <option v-for="(type,t) in reflectionTypes" v-bind:key="t">{{ type.name }}</option>
                                </select>
                            </div>
                            <div class="col-sm-2 my-1">
                                <button class="btn btn-default btn-block" type="button" @click="commitTag" v-if="tempTag"><i class="fal fa-check"></i> Save</button>
                                <button class="btn btn-default btn-block" disabled type="button" v-else><i class="fal fa-plus"></i> Add</button>
                            </div>
                        </div>

                        <div class="badge badge-lg badge-default pointer" v-for="(tag,index) in form.tags" v-bind:key="index" @click="deleteTag(index)">
                            {{ tag.name }} <i class="fad fa-trash"></i>
                        </div>

                        <div v-if="form.tags.length === 0" class="badge badge-lg badge-secondary">
                            You currently do not have any tags, your members will not be able to associate their reflections to the group without a tag to select!
                        </div>

                    </div>

                </div>

            </div>
            
            <template slot="footer">
                <base-button type="secondary" class="ml-auto" @click="closeModal">Close
                </base-button>
                <base-button type="primary" @click="updateGroup">Update</base-button>
            </template>
        </modal> -->

    </div>
</template>
<script>

import Modal from "@/components/Modal.vue";
import reflectionTypes from "@/models/reflectionTypes";
import firebase from 'firebase/app'
import { db } from '@/firebase';
import { mapGetters } from "vuex";

export default {
    name: 'group-modals',
    components: {
        Modal
    },
    props: ['entry','action'],

    mounted() {
      this.setModal()
    },

    data () {
        return {
            // data here
            modals: {
                delete: false,
                // deletemember: false,
                // edit: false
            }
        }
    },
    computed: {

        ...mapGetters({
            // map `this.user` to `this.$store.getters.user`
            // map `this.groups` to `this.$store.getters.groups`
            user: "user",
            groups: "groups"
        }),

        // filteredTags(){

        //     if(this.user.data.tags){

        //       return this.user.data.tags.filter(tag => {
        //           return tag.status === 'active' && (!tag.type || tag.type === this.form.GroupType)
        //       })

        //     }

        //     return []

        // },
    },
    methods: {

        setModal(){

            this.modals[this.action] = true
            // if(this.action === 'edit'){

            //     this.form = {
            //         groupName: this.entry.groupName,
            //         groupMission: this.entry.groupName,
            //         groupType: this.entry.groupType,
            //         tags: this.entry.tags
            //     }

            // }
        
        },

        closeModal(){
            
            this.modals[this.action] = false
            // this.form = {
            //     groupName: null,
            //     groupMission: null,
            //     groupType: 'open',
            //     tags: []
            // }
            setTimeout(this.sendClose, 200)

        },

        // closeMemberModal(){
            
        //     this.modals[this.action] = false
        //     setTimeout(this.sendClose, 200)

        // },

        sendClose(){
            this.$emit('modalClosed')
        },


        deleteGroupPost(){

            let docId = this.entry.id

            db.collection("groupPosts").doc(docId).delete().then(function() {
                // console.log("Document successfully deleted!");
            }).catch(function(error) {
                // console.error("Error removing document: ", error);
            });

            this.closeModal()

            this.$toast.open({
                message: 'Thread deleted!',
                type: 'warning',
                // all of other options may go here
            });

        },

        // updateGroup(){

        //     let docId = this.entry.id

        //     let groupMap = JSON.parse(JSON.stringify(this.entry))
        //     groupMap.groupName = this.form.groupName,
        //     groupMap.groupMission = this.form.groupMission,
        //     groupMap.groupType = this.form.groupType,
        //     groupMap.tags = this.form.tags

        //     db.collection("groups").doc(docId).update(groupMap);

        //     this.closeModal()

        //     this.$toast.open({
        //         message: 'Group updated!',
        //         type: 'success',
        //         // all of other options may go here
        //     });

        // },

        // deleteMember(){

        //     let docId = this.entry.id

        //     let groupMap = JSON.parse(JSON.stringify(this.entry))
        //     let mymembers = groupMap.members

        //     mymembers.forEach((m,i) => {

        //         if(this.member.uid === m.uid){
        //             groupMap.members.splice(i, 1);
        //         }

        //     })

        //     db.collection("groups").doc(docId).update(groupMap);

        //     this.closeMemberModal()

        //     this.$toast.open({
        //         message: 'Member removed!',
        //         type: 'success',
        //         // all of other options may go here
        //     });

        // }

    }
};
</script>

<style scoped>

    .card-ellipse {
        position: absolute;
        text-align: right;
        top: 0;
        right: 0;
    }

    .img-rt {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 60px; 
        height: 60px;
    }

    .img-rating-report {
        position: absolute;
        /* top: 12px; */
        margin: 45px -45px;
        width: 30px; 
        height: 30px;
    }



    /* rating */

    input[type=range] {
        -webkit-appearance: none;
        width: 100%;
        margin: 15.7px 0;
    }
    input[type=range]:focus {
        outline: none;
    }
    input[type=range]::-webkit-slider-runnable-track {
        width: 100%;
        height: 4.6px;
        cursor: pointer;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
        background: #e6dfea;
        border-radius: 2.4px;
        border: 0px solid rgba(1, 1, 1, 0);
    }
    input[type=range]::-webkit-slider-thumb {
      box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
      border: 1px solid rgba(0, 0, 0, 0);
      height: 36px;
      width: 36px;
      border-radius: 47px;
      background: #8d6c9f;
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -15.7px;
    }
    input[type=range]:focus::-webkit-slider-runnable-track {
      background: #f2eef4;
    }
    input[type=range]::-moz-range-track {
      width: 100%;
      height: 4.6px;
      cursor: pointer;
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
      background: #e6dfea;
      border-radius: 2.4px;
      border: 0px solid rgba(1, 1, 1, 0);
    }
    input[type=range]::-moz-range-thumb {
      box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
      border: 1px solid rgba(0, 0, 0, 0);
      height: 36px;
      width: 36px;
      border-radius: 47px;
      background: #8d6c9f;
      cursor: pointer;
    }
    input[type=range]::-ms-track {
      width: 100%;
      height: 4.6px;
      cursor: pointer;
      background: transparent;
      border-color: transparent;
      color: transparent;
    }
    input[type=range]::-ms-fill-lower {
      background: #dad0e0;
      border: 0px solid rgba(1, 1, 1, 0);
      border-radius: 4.8px;
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
    }
    input[type=range]::-ms-fill-upper {
      background: #e6dfea;
      border: 0px solid rgba(1, 1, 1, 0);
      border-radius: 4.8px;
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
    }
    input[type=range]::-ms-thumb {
      box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
      border: 1px solid rgba(0, 0, 0, 0);
      height: 36px;
      width: 36px;
      border-radius: 47px;
      background: #8d6c9f;
      cursor: pointer;
      height: 4.6px;
    }
    input[type=range]:focus::-ms-fill-lower {
      background: #e6dfea;
    }
    input[type=range]:focus::-ms-fill-upper {
      background: #f2eef4;
    }

</style>