// lineChart.js
import { Line } from 'vue-chartjs'

export default {
  extends: Line,
  props: ['chartDataRef','chartDataAvg','chartDataRefPrev','chartDataAvgPrev','chartLabels'],
  mounted () {
    // Overwriting base render method with actual data.
    this.renderChart(
      // Data
      {
          labels: this.chartLabels,
          datasets: [
              {
                label: "Reflections",
                borderColor: "#8D6C9F",
                // backgroundColor: "#BFACC9",
                // backgroundColor: [
                //   "#8D6C9F","#C0B5D1","#7A909B","#38867F","#866058","#9CA5B9","#7A909B","#AD9163","#8A6B55","#624B41"
                // ],
                data: this.chartDataRef,
                fill: false,
                borderWidth: 3
              },
              {
                label: "Average Rating",
                borderColor: "#866058",
                // backgroundColor: "#B7A09C",
                // backgroundColor: [
                //   "#8D6C9F","#C0B5D1","#7A909B","#38867F","#866058","#9CA5B9","#7A909B","#AD9163","#8A6B55","#624B41"
                // ],
                data: this.chartDataAvg,
                fill: false,
                borderWidth: 3
              }
              // {
              //   label: "Prev. Weeks Reflections",
              //   borderColor: "#DAD0E0",
              //   // backgroundColor: "#BFACC9",
              //   // backgroundColor: [
              //   //   "#8D6C9F","#C0B5D1","#7A909B","#38867F","#866058","#9CA5B9","#7A909B","#AD9163","#8A6B55","#624B41"
              //   // ],
              //   data: this.chartDataRefPrev,
              //   fill: false,
              //   borderWidth: 3
              // },
              // {
              //   label: "Prev. Weeks Average Rating",
              //   borderColor: "#D8CCC9",
              //   // backgroundColor: "#B7A09C",
              //   // backgroundColor: [
              //   //   "#8D6C9F","#C0B5D1","#7A909B","#38867F","#866058","#9CA5B9","#7A909B","#AD9163","#8A6B55","#624B41"
              //   // ],
              //   data: this.chartDataAvgPrev,
              //   fill: false,
              //   borderWidth: 3
              // }
          ]
          
      },
      // Options
      {
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              boxWidth: 15,
            }
          },
          maintainAspectRatio: false
      }
    )
  }
}
