<template>
    <div>

        <div class="row justify-content-center pl-3 pr-3 mb-2">

            <div class="col-12" v-show="attachment.title || attachment.summary">
                <p class="card-title mb-1 text-default font-weight-bold" v-show="attachment.title">{{ attachment.title }}<br /></p>
                <p v-show="attachment.summary">{{ attachment.summary }}</p>
            </div>

            <div class="col-lg-6 col-12">

                <div class="mt-4 mb-4">

                    <b-table striped hover small responsive :items="attachment.data" :fields="attachment.headings"></b-table>
                </div>

                <p>{{ attachment.summary }}</p>

            </div>

        </div>
    </div>
</template>
<script>

import _ from "lodash";

export default {
    name: 'embed-table',
    props: ['attachment','id','index','readOnly'],
    components: {
        // Pie,
        // PieChart
    },
    mounted() {

    },

    data () {
        return {
            localData: _.cloneDeep(this.attachment),
            newData: false,
        }
    },
    computed: {

    },

    watch: {

        attachment: {
            handler: function () {
                setTimeout(this.resetNewData,200);
            },
            deep: true,
        }

    },

    methods: {

        resetNewData(){
            this.newData = false;
        },

    }
};
</script>

<style scoped>

</style>