<template>
  <div class="row justify-content-center">
    <div class="col-12 text-center align-middle mb-4 mt-2 p-0">

        <h4 class="text-default font-weight-bold mt-3" v-if="!hideTitle">
          Sentiment analysis
        </h4>
        <h5 class="text-default font-weight-bold mt-0 mb-2">
          <small> by 
            <span class="badge badge-default">
              <i class="text-primary fas fa-robot"></i> Reflection Bot <i class="fad fa-question-circle pointer" @click="showExplainer = !showExplainer"></i>
            </span>
          </small>
        </h5>

        <div class="row justify-content-center mt-3 mb-3" v-if="showExplainer">
          <div class="col-12"> 
            <p class="text-primary text-center p-4">
                <i class="text-primary fas fa-robot"></i> Reflection Bot is an artificial intellengence tool that will scan
                your reflection &amp; deep dive responses and provide you with sentiment insights
            </p>
          </div>
        </div>

        <div v-if="status === 'PENDING'">

          <div class="m-3">
            <h2 class="text-primary font-weight-bold">
              <i class="fad fa-robot fa-beat fa-4x mb-2"></i>
            </h2>
          </div>

          <div>
            <div class="m-3">
              <h2 class="text-primary font-weight-bold">
                <small>analysing...</small>
              </h2>
            </div>
          </div>

        </div>
        <div v-else-if="status === 'SUCCESS'">

            <div class="row justify-content-center mt-3 mb-3">
              <div class="col-12"> 
                <img :src="'/img/moods/' + analysis.overall.score_tag + '.svg'" class="analysis-mood" /><br>
                <p class="text-lowercase badge badge-default">
                  {{ analysis.overall.score_label }}
                </p>
              </div>

              <!-- <div class="col-lg-2 col-4">
                <img
                  :src="'/img/moods/' + analysis.overall.subjectivity + '.svg'"
                  class="analysis-mood"
                /><br>
                <p class="text-lowercase badge badge-default">
                  {{ analysis.overall.subjectivity }}
                </p>
              </div> -->
            </div>

            <div class="row justify-content-center">

              <div class="col-lg-6 col-12">

                  <sentiment-progress-bar :values="analysis.overall.intensity"></sentiment-progress-bar>

              </div>

            </div>

            <div
              class="col-lg-12 col-12 text-center align-middle mb-2 mt-2"
              v-if="!showSentimentDetails"
            >
              <base-button
                type="secondary"
                size="sm"
                @click="showSentimentDetails = !showSentimentDetails"
              >
                <i class="fad fa-info-circle"></i> view details
              </base-button>

            </div>
            <div class="col-lg-12 col-12 text-center align-middle mb-4 mt-2" v-else>
              <base-button
                type="secondary"
                size="sm"
                @click="showSentimentDetails = !showSentimentDetails"
              >
                <i class="fad fa-info-circle"></i> hide details
              </base-button>

            </div>
            
            <div class="col-12" v-if="showSentimentDetails">

                <ul class="list-group text-left">
                  <li class="list-group-item shadow mb-2 bg-default text-white" v-if="skippedValues > 0">
                    A total of {{ skippedValues }} details or reflections were skipped because
                    they were either not populated or they had short details that can not be
                    processed effectively. To get more value out of our Reflection Bot ensure
                    that your answers, details, highlights and lowlights are more than five
                    words.
                  </li>
                  <li
                    class="list-group-item shadow mb-2 text-default"
                    v-for="(phrase, p) in analysis.phrases"
                    v-bind:key="p"
                  >
                    <div>
                      <div class="badge badge-default">
                        <img
                          :src="'/img/moods/' + phrase.score_tag + '.svg'"
                          class="analysis-sentence-mood mr-1"
                        />
                        <span>{{ phrase.score_label }}</span>
                      </div>
                      {{ phrase.phrase }}
                    </div>
                    <div class="mt-2 mb-0">
                      <sentiment-progress-bar :values="phrase.intensity"></sentiment-progress-bar>
                    </div>
                    
                  </li>
                </ul>

            </div>

        </div>
        <div v-else-if="status === 'NO TEXT'">

            <div class="m-3">
              <p class="text-primary text-center">
                  <i class="text-primary fas fa-robot"></i> <i>Reflection Bot</i> was
                  unable to find any text to analyse.
                  To get more value out of our Reflection Bot ensure
                  that details such as highlights, lowlights and adjustments are more than five
                  words.
              </p>
            </div>

        </div>

    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import { db } from "@/firebase";
import SentimentProgressBar from "@/components/SentimentProgressBar";

const vader = require('vader-sentiment');

export default {
  props: ["document", "currentUser","hideTitle"],
  components: { SentimentProgressBar },
  data() {
    return {
      loading: false,
      showExplainer: false,
      analysis: null,
      source: null,
      status: "PENDING",
      showSentimentDetails: false,
      skippedValues: 0,
      skipTolerance: 1,
      sentimentSource: []
    };
  },

  watch: {
    document: {
      handler: function () {
        this.buildSentimentSource()
      },
      deep: true,
    },
  },

  mounted() {
    // this.preloadAnalysis()
    this.buildSentimentSource()
  },

  methods: {

    // preloadAnalysis(){

    //     if(this.document.analysis){

    //         this.analysis = this.document.analysis
    //         this.status = this.document.analysis.status
    //         this.skippedValues = this.document.analysis.skippedValues

    //     }else{

    //         this.buildSentimentSource()

    //     }

    // },

    rateReflection(rating){

      if(rating >= 1 && rating < 3){
        return 'Very Bad'
      }else if(rating >= 3 && rating < 5){
        return 'Bad'
      }else if(rating === 5){
        return 'Okay'
      }else if(rating > 5 && rating < 7){
        return 'Good'
      }else if(rating >= 7){
        return 'Very Good'
      }

    },

    analyseReflection(reflection){

        if(reflection.rating){

          this.sentimentSource.unshift({
            source: 'reflection-ratingStatement',
            // ref: reflection.id,
            value: 'I rate this ' + reflection.activityName + ' as ' + this.rateReflection(reflection.rating) + ' (' + reflection.rating + ' out of 10)'
          });
          
        }

        if (reflection.activityDetails) {

          this.sentimentSource.unshift(
            {
              source: 'reflection-activityDetails',
              //ref: reflection.id,
              value: reflection.activityDetails
            }
          );
        }

        if (reflection.activityName !== reflection.reflectionType) {
          if (reflection.activityName.split(" ").length >= this.skipTolerance) {
            this.sentimentSource.unshift(
              {
                source: 'reflection-activityName',
                //ref: reflection.id,
                value: reflection.activityName
              }
            );
          } else {
            skippedValues++;
          }
        }

        if (reflection.reflectionType === 'daily checkin') {

          let questions = reflection.dailyCheckinQuestions
          questions.forEach(q => {
              if(q.type === 'range'){

                  this.sentimentSource.unshift({
                    source: 'reflection-dailyCheckinAnswer',
                    //ref: reflection.id,
                    value: 'I rate this ' + q.question + ' as ' + this.rateReflection(q.value) + ' (' + q.value + ' out of 10)'
                  });

              }else{

                  this.sentimentSource.unshift({
                    source: 'reflection-dailyCheckinAnswer',
                    //ref: reflection.id,
                    value: q.value
                  });

              }
          });


        }

        if (reflection.activityDetails) {

          this.sentimentSource.unshift(
            {
              source: 'reflection-activityDetails',
              //ref: reflection.id,
              value: reflection.activityDetails
            }
          );
        }

    },

    buildSentimentSource() {
      
      this.sentimentSource = [];
      let skippedValues = 0;
      this.loading = true;
      const vm = this; 
        
      // console.log('Building sentiment source')

      // Deep Dive Label
      if (this.document.label) {

        this.sentimentSource.unshift(
          {
            source: 'deepdive-label',
            //ref: this.document.id,
            value: this.document.label
          }
        );
      }

      // Deep Dive topThree
      if (this.document.answers.topThree) {

        this.sentimentSource.unshift(
          {
            source: 'deepdive-topThree',
            //ref: this.document.id,
            value: this.document.answers.topThree
          }
        );
      }

      // Deep Dive bottomThree
      if (this.document.answers.bottomThree) {

        this.sentimentSource.unshift(
          {
            source: 'deepdive-bottomThree',
            //ref: this.document.id,
            value: this.document.answers.bottomThree
          }
        );
      }

      // Deep Dive reflectionSplit
      if (this.document.answers.reflectionSplit) {

        this.sentimentSource.unshift(
          {
            source: 'deepdive-reflectionSplit',
            //ref: this.document.id,
            value: this.document.answers.reflectionSplit
          }
        );
      }

      // Deep Dive reflectionTagSplit
      if (this.document.answers.reflectionTagSplit) {

        this.sentimentSource.unshift(
          {
            source: 'deepdive-reflectionTagSplit',
            //ref: this.document.id,
            value: this.document.answers.reflectionTagSplit
          }
        );
      }

      // Deep Dive Highlights
      var tmpHighlights = this.document.deepDiveRetrospective.highlights;
      tmpHighlights.forEach((highlight, hi) => {
        if (highlight.split(" ").length > this.skipTolerance) {
          this.sentimentSource.unshift(
            {
              source: 'deepdive-highlight',
              //ref: this.document.id,
              value: '(good) ' + highlight
            }
          );
        } else {
          skippedValues++;
        }
      });

      // Deep Dive Lowlights
      var tmpLowlights = this.document.deepDiveRetrospective.lowlights;
      tmpLowlights.forEach((lowlight, li) => {
        if (lowlight.split(" ").length > this.skipTolerance) {
          this.sentimentSource.unshift(
            {
              source: 'deepdive-lowlight',
              //ref: this.document.id,
              value: '(bad) ' + lowlight
            }
          );
        } else {
          skippedValues++;
        }
      });

      // Deep Dive Adjustments
      var tmpAdjustments = this.document.deepDiveRetrospective.adjustments;
      tmpAdjustments.forEach((adjustment, li) => {
        if (adjustment.split(" ").length > this.skipTolerance) {
          this.sentimentSource.unshift(
            {
              source: 'deepdive-adjustment',
              //ref: this.document.id,
              value: '(adjustment) ' + adjustment
            }
          );
        } else {
          skippedValues++;
        }
      });

      // Deep Dive Lowlights
      var tmpReflections = this.document.reflections;
      tmpReflections.forEach((reflection, r) => {
          vm.analyseReflection(reflection);
      });

      this.skippedValues = skippedValues;
      this.source = this.sentimentSource;
      this.showAnalysis = true;

      this.runAnalysis();

    },

    runAnalysis() {

      const vm = this;
      let sentimentSource = this.source;

      if (!sentimentSource) {
        vm.status = "NO TEXT";
        vm.loading = false;
        vm.analysis = { message:'There was no text to analysis' }
        return;
      }

      let analysis = {
        overall : null,
        phrases : []
      };

      let entirePhrase = []
      sentimentSource.forEach(phrase => {
        
          let tmpPhrase = {}
          tmpPhrase.intensity = vader.SentimentIntensityAnalyzer.polarity_scores(phrase.value);

          if(tmpPhrase.intensity.compound >= 0.075){
            tmpPhrase.score_label = 'Very Positive'
            tmpPhrase.score_tag = 'P+'
          }else if(tmpPhrase.intensity.compound >= 0.05 && tmpPhrase.intensity.compound < 0.075){
            tmpPhrase.score_label = 'Positive'
            tmpPhrase.score_tag = 'P'
          }else if(tmpPhrase.intensity.compound >= -0.05 && tmpPhrase.intensity.compound < 0.05){
            tmpPhrase.score_label = 'Neutral'
            tmpPhrase.score_tag = 'NEU'
          }else if(tmpPhrase.intensity.compound >= -0.075 && tmpPhrase.intensity.compound < -0.05){
            tmpPhrase.score_label = 'Negative'
            tmpPhrase.score_tag = 'N'
          }else if(tmpPhrase.intensity.compound < -0.075){
            tmpPhrase.score_label = 'Very Negative'
            tmpPhrase.score_tag = 'N+'
          }else{
            tmpPhrase.score_label = 'Without Sentiment'
            tmpPhrase.score_tag = 'NONE'
          }

          entirePhrase.push(phrase.value)

          tmpPhrase.phrase = phrase.value
          //tmpPhrase.ref = phrase.ref
          tmpPhrase.source = phrase.source

          analysis.phrases.push(tmpPhrase);

      });

      let newphrase = entirePhrase.join(". ");
      let tmpEntirePhrase = {}
      tmpEntirePhrase.intensity = vader.SentimentIntensityAnalyzer.polarity_scores(newphrase);

      if(tmpEntirePhrase.intensity.compound >= 0.075){
        tmpEntirePhrase.score_label = 'Very Positive'
        tmpEntirePhrase.score_tag = 'P+'
      }else if(tmpEntirePhrase.intensity.compound >= 0.05 && tmpEntirePhrase.intensity.compound < 0.075){
        tmpEntirePhrase.score_label = 'Positive'
        tmpEntirePhrase.score_tag = 'P'
      }else if(tmpEntirePhrase.intensity.compound >= -0.05 && tmpEntirePhrase.intensity.compound < 0.05){
        tmpEntirePhrase.score_label = 'Neutral'
        tmpEntirePhrase.score_tag = 'NEU'
      }else if(tmpEntirePhrase.intensity.compound >= -0.075 && tmpEntirePhrase.intensity.compound < -0.05){
        tmpEntirePhrase.score_label = 'Negative'
        tmpEntirePhrase.score_tag = 'N'
      }else if(tmpEntirePhrase.intensity.compound < -0.075){
        tmpEntirePhrase.score_label = 'Very Negative'
        tmpEntirePhrase.score_tag = 'N+'
      }else{
        tmpEntirePhrase.score_label = 'Without Sentiment'
        tmpEntirePhrase.score_tag = 'NONE'
      }

      analysis.overall = tmpEntirePhrase
      analysis.phrase = newphrase
      analysis.status = 'SUCCESS'
      analysis.skippedValues = this.skippedValues

      this.analysis = analysis;

      vm.$analytics.logEvent("sentiment_checker", {
        sentimentSource: sentimentSource,
        uid: vm.currentUser.uid,
        fullName: vm.currentUser.fullName,
        startDate: vm.startDate,
        endDate: vm.endDate,
        analysis: analysis
      });

      // Store our analysis
      // var analysisDoc = db.collection("sentimentAnalysis").doc();
      // let docData = {
      //   analysis_id: analysisDoc.id,
      //   analysis_data: newphrase,
      //   user_id: this.currentUser.uid,
      //   analysis: analysis
      // };

      // db.collection("sentimentAnalysis").doc(analysisDoc.id).set(docData);

      this.loading = false;
      this.status = "SUCCESS";

    },
  },
};
</script>

<style scoped>
.ill-analysis {
  width: 60%;
  max-width: 350px;
}

.analysis-mood {
  width: 120%;
  max-width: 70px;
}

.analysis-sentence-mood {
  width: 24px;
}

.fa-beat {
  animation:fa-beat 5s ease infinite;
}
@keyframes fa-beat {
  0% {
    transform:scale(1);
  }
  5% {
    transform:scale(1.05);
  }
  20% {
    transform:scale(1);
  }
  30% {
    transform:scale(1);
  }
  35% {
    transform:scale(1.05);
  }
  50% {
    transform:scale(1);
  }
  55% {
    transform:scale(1.05);
  }
  70% {
    transform:scale(1);
  }
}

</style>
