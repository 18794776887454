<template>
    <div>
        
        <card shadow class="p-4" no-body v-if="user">

            <div class="row justify-content-center mb-2">
                <div class="col-12 text-center">
                    <div class="float-right">
                        <a class="btn btn-sm btn-default text-white" role="button" @click="createSponsor = true">
                            <i class="fad fa-hands-helping"></i> New
                        </a>
                    </div>
                    <h4>
                        Sponsored Users
                    </h4>
                </div>
            </div>

            <div v-show="createSponsor">

                <div class="row justify-content-center">

                    <div class="col-12 pt-1">
                        <p class="text-default mb-3 font-weight-bold">
                            Sponsor a user
                        </p>
                    </div>

                </div>

                <div class="row justify-content-center" v-if="sponsorship && !sponsorship.uid">

                    <div class="col-12">

                        <div class="input-group">

                            <input type="text" class="form-control" v-model="sponsorship.email" placeholder="Search for an email" aria-label="Search for an email" aria-describedby="basic-addon2">
                            <div class="input-group-append">
                                <button class="btn btn-default text-white" type="button" v-if="sponsorship.email" @click="getUserFromEmail">Search</button>
                                <button class="btn btn-outline-default text-primary" type="button" disabled v-else>Search</button>
                            </div>

                        </div>

                    </div>

                </div>

                <div class="row justify-content-center" v-if="sponsorship && sponsorship.uid">

                    <div class="col-6">
                        
                        <label>Full Name</label>
                        <base-input
                            type="text"
                            placeholder="Add title"
                            v-model="sponsorship.fullName"
                        >
                        </base-input>
                        
                    </div>

                    <div class="col-6">

                        <label>Email</label>
                        <base-input
                            type="text"
                            placeholder="Email"
                            readonly
                            v-model="sponsorship.email"
                        >
                        </base-input>
                        
                    </div>

                    <div class="col-12">
                        <hr>
                        <label>Subscription details</label>
                    </div>
                    <div class="col-lg-3 col-6 mb-4">

                        <label class="font-italic">Sponsored</label>
                        <base-button type="default" block @click="sponsorship.subscriptionDetails.sponsored = 0" v-if="sponsorship.subscriptionDetails.sponsored">
                            Yes
                        </base-button>
                        <base-button type="default" outline block @click="sponsorship.subscriptionDetails.sponsored = 1" v-else>
                            No
                        </base-button>
                        
                    </div>

                    <div class="col-lg-3 col-6 mb-4">

                        <label class="font-italic">Lifetime</label>
                        <base-button type="default" block @click="sponsorship.subscriptionDetails.lifetime = 0" v-if="sponsorship.subscriptionDetails.lifetime">
                            Yes
                        </base-button>
                        <base-button type="default" outline block @click="sponsorship.subscriptionDetails.lifetime = 1" v-else>
                            No
                        </base-button>
                        
                    </div>

                    <div class="col-lg-3 col-6 mb-4">

                        <label class="font-italic">Commenced</label>
                        <base-input
                          addon-left-icon="fad fa-calendar"
                        >
                          <flat-picker
                            slot-scope="{ focus, blur }"
                            @on-open="focus"
                            @on-close="blur"
                            :config="{ allowInput: true }"
                            class="form-control datepicker"
                            placeholder="Commenced"
                            v-model="tempCommenced"
                          >
                          </flat-picker>
                        </base-input>
                        
                    </div>

                    <div class="col-lg-3 col-6 mb-4" v-if="!sponsorship.subscriptionDetails.lifetime">

                        <label class="font-italic">Expires</label>
                        <base-input
                          addon-left-icon="fad fa-calendar"
                        >
                          <flat-picker
                            slot-scope="{ focus, blur }"
                            @on-open="focus"
                            @on-close="blur"
                            :config="{ allowInput: true }"
                            class="form-control datepicker"
                            placeholder="Expires"
                            v-model="tempExpired"
                          >
                          </flat-picker>
                        </base-input>
                        
                    </div>
                    <div class="col-lg-3 col-6 mb-4" v-else>

                        <label class="font-italic">Expires</label>
                        <input
                            type="text"
                            class="form-control disabled"
                            placeholder="Never expires"
                            disabled
                        >
                        
                    </div>

                    <div class="col-4 mb-4">

                        <label class="font-italic">Max Users</label>
                        <input
                            type="number"
                            class="form-control"
                            placeholder="Max Users"
                            v-model.number="sponsorship.subscriptionDetails.maxUsers"
                        >
                        
                    </div>

                    <div class="col-4 mb-4">

                        <label class="font-italic">Max Groups</label>
                        <input
                            type="number"
                            class="form-control"
                            placeholder="Max Groups"
                            v-model.number="sponsorship.subscriptionDetails.maxGroups"
                        >
                        
                    </div>

                    <div class="col-4  mb-4">

                        <label class="font-italic">Used Licenses</label>
                        <input
                            type="number"
                            class="form-control"
                            placeholder="Used Licenses"
                            v-model.number="sponsorship.subscriptionDetails.usedLicenses"
                        >
                        
                    </div>

                    <!-- <div class="col-12 p-5">
                        <pre>{{ sponsorship.subscriptionDetails }}</pre>
                    </div> -->

                </div>

                <div class="row justify-content-center text-center mt-5">

                    <div class="col-12 pb-2" v-if="sponsorship && sponsorship.uid && sponsorship.fullName">
                        <base-button type="default" size="lg" @click="updateLicensing">
                            Update Licensing
                        </base-button>
                    </div>
                    <div class="ol-12 pb-2" v-else>
                        <base-button type="default" size="lg" disabled block>
                            Update Licensing
                        </base-button>
                    </div>

                </div>

                <div class="row justify-content-center mt-5">

                    <a role="button" @click="clearSponsorship" class="pointer" title="Clear">
                        <icon name="fal fa-times" size="lg" type="danger" shadow rounded></icon>
                    </a>

                </div>

            </div>

            <div v-show="!createSponsor">

                <div class="col-12 p-0">

                    <ul class="list-group">

                        <li class="list-group-item" v-for="(record, index) in sponsorships" v-bind:key="index">

                            <div class="float-right">
                                <span class="btn btn-sm btn-default" @click="editSponsor(record)">
                                    <i class="fal fa-pencil"></i>
                                </span>
                            </div>

                            <img :alt="record.fullName" :src="record.photoURL" class="avatar avatar-sm rounded-circle mr-2" />
                            {{ record.fullName }} <span class="small text-muted ml-2">{{ record.email }}</span>
                        </li>

                    </ul>
                
                </div>

                <div class="col-12 p-0" v-if="sponsorships.length === 0">

                    <div class="alert alert-default text-center text-default p-5" v-if="!loading">

                        <!-- <i class="fad fa-analytics fa-3x"></i> -->
                        <span class="fa-stack fa-2x">
                            <i class="fas fa-square fa-stack-2x"></i>
                            <i class="fad fa-hands-helping fa-stack-1x fa-inverse"></i>
                        </span>
                        <p class="text-white font-weight-bold mt-3 mb-0">
                            We could not find any sponsorships
                        </p>

                    </div>
                    <div class="alert alert-default text-center text-white p-5" v-else>

                        <i class="fad fa-spinner-third fa-4x fa-spin"></i>
                        <h5 class="text-white mt-2">Loading reflection statistics</h5>

                    </div>

                </div>

            </div>

        </card>

    </div>
</template>
<script>

import firebase from 'firebase/app'
import { db } from '@/firebase';
import store from '@/store.js'
import { mapGetters } from "vuex";

import BaseDropdown from "@/components/BaseDropdown";

import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import _ from "lodash";

export default {
    name: 'sponsorship-management',
    // props: [],
    components: { 
        BaseDropdown,
        flatPicker
    },

    computed: {

        ...mapGetters({
            // map `this.user` to `this.$store.getters.user`
            // map `this.groups` to `this.$store.getters.groups`
            user: "user",
            groups: "groups"
        }),

        sponsoredToggle(){

            let toggle = this.sponsorship.subscriptionDetails.sponsored
            if(toggle){
                return true
            }else{
                return false
            }

        }

    },

    data () {
        return {
            
            loading: false,
            sponsorship: {},
            defaultLicense: {
              sponsored: 0,
              lifetime: false,
              commenced: null,
              expired: null,
              maxGroups: 5,
              maxUsers: 5,
              usedLicenses: 0
            },
            sponsorships: [],
            createSponsor: false,
            token: null,

            tempCommenced: null,
            tempExpired: null

        }
    },

    mounted() {
        // this.getToken();
        this.clearSponsorship();
        this.sponsorshipsQuery();
    },

    watch: {

    },

    methods: {

        getDate(datetime) {
            if (!datetime) return "";
            datetime = this.$dayjs.unix(datetime.seconds).format("YYYY-MM-DD");
            return datetime;
        },

        editSponsor(record){

            this.sponsorship = this.normaliseLicense(record);
            this.tempCommenced = this.getDate(this.sponsorship.subscriptionDetails.commenced);
            this.tempExpired = this.getDate(this.sponsorship.subscriptionDetails.expired);
            this.createSponsor = true;

        },

        clearSponsorship(){

            this.sponsorship = {}
            this.createSponsor = false

        },

        getUserFromEmail() {

            const vm = this;
            const axios = require("axios");

            firebase.auth().onAuthStateChanged(function (user) {
                if (user) {
                    user.getIdToken().then(function (idToken) {

                        let email = vm.sponsorship.email

                        // Send token to your backend via HTTPS
                        var config = {
                            headers: {
                                Authorization: "Bearer " + idToken,
                            },
                        };

                        // let url = "http://localhost:5001/my-reflection-22932/australia-southeast1/app/api/get_user_from_email";
                        let url = "https://australia-southeast1-my-reflection-22932.cloudfunctions.net/app/api/get_user_from_email";

                        vm.$analytics.logEvent("sponsorships_query", {
                            uid: vm.user.data.uid,
                            fullName: vm.user.data.fullName,
                            url: url
                        });

                        let body = {
                            email: email
                        }

                        // Make a request for a user with a given ID
                        axios
                            .post(url,body,config)
                            .then(function (response) {

                                // handle success
                                vm.sponsorship = vm.normaliseLicense(response.data)
                                vm.tempCommenced = vm.getDate(vm.sponsorship.subscriptionDetails.commenced);
                                vm.tempExpired = vm.getDate(vm.sponsorship.subscriptionDetails.expires);

                            })
                            .catch(function (error) {
                                // handle error
                                // console.log(error)
                            })
                            .finally(function () {
                                // always executed
                                vm.loading = false;
                                // vm.building = false
                            });

                    });
                }
            });

        },

        normaliseLicense(user){

            let license = _.cloneDeep(this.defaultLicense);

            if(!user.subscriptionDetails){
                user.subscriptionDetails = license
                return user;
            }

            if(!user.subscriptionDetails.sponsored){
                user.subscriptionDetails.sponsored = license.sponsored
            }

            if(!user.subscriptionDetails.lifetime){
                user.subscriptionDetails.lifetime = license.lifetime
            }

            if(!user.subscriptionDetails.commenced){
                user.subscriptionDetails.commenced = license.commenced
            }

            if(!user.subscriptionDetails.expired){
                user.subscriptionDetails.expired = license.expired
            }

            if(!user.subscriptionDetails.maxGroups){
                user.subscriptionDetails.maxGroups = license.maxGroups
            }

            if(!user.subscriptionDetails.maxUsers){
                user.subscriptionDetails.maxUsers = license.maxUsers
            }

            if(!user.subscriptionDetails.usedLicenses){
                user.subscriptionDetails.usedLicenses = license.usedLicenses
            }

            return user;


        },

        sponsorshipsQuery() {

            const vm = this;
            const axios = require("axios");

            vm.loading = true;

            firebase.auth().onAuthStateChanged(function (user) {
                if (user) {
                    user.getIdToken().then(function (idToken) {

                            // Send token to your backend via HTTPS
                            var config = {
                                headers: {
                                    Authorization: "Bearer " + idToken,
                                },
                            };

                            // let url = "http://localhost:5001/my-reflection-22932/australia-southeast1/app/api/sponsorship_query";
                            let url = "https://australia-southeast1-my-reflection-22932.cloudfunctions.net/app/api/sponsorship_query";

                            vm.$analytics.logEvent("sponsorships_query", {
                                uid: vm.user.data.uid,
                                fullName: vm.user.data.fullName,
                                url: url
                            });

                            // Make a request for a user with a given ID
                            axios
                                .get(url, config)
                                .then(function (response) {

                                    // handle success
                                    vm.sponsorships = response.data;

                                })
                                .catch(function (error) {
                                    // handle error
                                    // console.log(error)
                                })
                                .finally(function () {
                                    // always executed
                                    vm.loading = false;
                                    // vm.building = false
                                });

                    });
                }
            });


        },

        updateLicensing() {

            const vm = this;
            const axios = require("axios");

            vm.loading = true;
            let license = vm.sponsorship.subscriptionDetails;
            license.uid = vm.sponsorship.uid

            if(vm.tempCommenced){
                let cDate = new Date(vm.tempCommenced);
                license.commenced = new firebase.firestore.Timestamp.fromDate(cDate);
            }

            if(vm.tempExpired){
                let eDate = new Date(vm.tempExpired);
                license.expired = new firebase.firestore.Timestamp.fromDate(eDate);
            }

            firebase.auth().onAuthStateChanged(function (user) {
                if (user) {
                    user.getIdToken().then(function (idToken) {

                            // Send token to your backend via HTTPS
                            var config = {
                                headers: {
                                    Authorization: "Bearer " + idToken,
                                },
                            };

                            // let url = "http://localhost:5001/my-reflection-22932/australia-southeast1/app/api/sponsorship_set";
                            let url = "https://australia-southeast1-my-reflection-22932.cloudfunctions.net/app/api/sponsorship_set";

                            vm.$analytics.logEvent("sponsorship_set", {
                                uid: vm.user.data.uid,
                                fullName: vm.user.data.fullName,
                                license: license,
                                url: url
                            });

                            // Make a request for a user with a given ID
                            axios
                                .post(
                                    url,
                                    license,
                                    config
                                )
                                .then(function (response) {

                                    // handle success
                                    // console.log(response)
                                    vm.$toast.open({
                                        message: "Licensing details saved!",
                                        type: "success",
                                        // all of other options may go here
                                    });

                                })
                                .catch(function (error) {
                                    // handle error
                                    // console.log(error)
                                })
                                .finally(function () {
                                    // always executed
                                    vm.loading = false;
                                    // vm.building = false
                                });

                    });
                }
            });


        }

    }
};
</script>
<style scoped>

  .card-ellipse {
    position: absolute;
    text-align: right;
    top: 0;
    right: 0;
    z-index: 100;
  }

  .mr-logo {
    width: 90px !important;
    height: 90px !important;
  }

</style>