<template>

    <div class="progress mb-1">
        <div class="progress-bar bg-success" role="progressbar" :style="'width: ' + values.pos * 100 + '%'" :aria-valuenow="values.pos * 100" aria-valuemin="0" aria-valuemax="100"></div>
        <div class="progress-bar bg-light" role="progressbar" :style="'width: ' + values.neu * 100 + '%'" :aria-valuenow="values.neu * 100" aria-valuemin="0" aria-valuemax="100"></div>
        <div class="progress-bar bg-danger" role="progressbar" :style="'width: ' + values.neg * 100 + '%'" :aria-valuenow="values.neg * 100" aria-valuemin="0" aria-valuemax="100"></div>
    </div>

</template>
<script>

export default {
    name: 'sentiment-progress-bar',
    props: ['values']
};
</script>
<style></style>