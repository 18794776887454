<template>
  <div class="mb-3">
    <div class="card shadow p-0 bg-white rounded" v-if="selectedEntry">

      <!-- CARD HEADER -->
      <div class="mt-2 mb-0">
        <div class="float-right text-dark pt-2 pr-3">

          <img
            :alt="selectedEntry.member.fullName"
            :title="selectedEntry.member.fullName"
            :src="selectedEntry.member.photoURL"
            class="avatar-sm rounded-circle img-member ml-2"
            v-if="!actionsAllowed && selectedEntry.member"
          />

        </div>

        <div class="float-left ml-2 mr-1 p-2">
          <img
            :alt="selectedEntry.reflectionType"
            class="avatar avatar-sm bg-white border-avatar p-1"
            :src="getReflectionTypeImage(selectedEntry.reflectionType)"
          />
        </div>

        <p class="feed-title font-weight-bold text-default mt-2 mb-0">
          <span 
            >Group {{ selectedEntry.reflectionType }} reflection for
            {{ selectedEntry.activityName }}</span
          >
        </p>
        <p class="feed-date text-muted">
          {{ $dayjs(getReflectionDate(selectedEntry.creationTime)).fromNow() }}
        </p>
        <hr class="mt-0 mb-0" />
      </div>

      <!-- ACTIVITY DETAILS -->
      <div class="card-body mb-2 text-center">
        <div>

          <!-- PHOTO JOURNAL -->
          <img
            :src="selectedEntry.imageURL"
            class="img-fluid mb-3"
            v-if="selectedEntry.imageURL"
          />

          <!-- DAILY CHECKING QUESTIONS -->
          <div
            class="row justify-content-center pt-1 pb-4"
            v-if="selectedEntry.dailyCheckinQuestions"
          >
            <div
              class="col-12 pt-1 pb-2"
              v-for="(qn, index) in selectedEntry.dailyCheckinQuestions"
              v-bind:key="index"
            >
              <div v-if="qn.type === 'range'">
                <p class="card-title mt-4 mb-1 text-default font-weight-bold">
                  {{ qn.question }}<br />
                </p>
                <img
                  :alt="qn.value"
                  class="img-rating-question"
                  :src="getRatingImage(qn.value)"
                />
              </div>
              <div v-else>
                <p class="card-title mt-4 mb-1 text-default font-weight-bold">
                  {{ qn.question }}<br />
                </p>
                <p>
                  <span style="white-space: pre-wrap" v-html="qn.value"></span>
                </p>
              </div>
            </div>
          </div>

          <!-- ADVANCED ATTACHMENTS -->
          <advanced-attachments
            :attachment="consolidatedAttachments"
            :id="selectedEntry.id"
            :read-only="true"
            v-if="consolidatedAttachments"></advanced-attachments>

          <div v-if="showCarousel()" class="mb-5">

            <VueSlickCarousel v-bind="carouselSettings">

                <div v-for="(reflection, index) in selectedEntry.reflections" v-bind:key="index" class="pt-5">

                    <card type="white" shadow no-body class="m-4 p-3 border border-default" v-if="reflection.attachment">
                      
                      <div class="float-center">
                        <img
                          :alt="reflection.member.fullName"
                          :title="reflection.member.fullName"
                          :src="reflection.member.photoURL"
                          class="avatar rounded-circle img-attachment-member text-center"
                          v-if="reflection.member"
                        />
                      </div>

                      <div>

                          <advanced-attachments
                            :attachment="filteredAttachments(reflection.attachment)"
                            :id="reflection.id"
                            :read-only="true"
                          >
                          </advanced-attachments>

                      </div>

                    </card>

                </div>

            </VueSlickCarousel>

          </div>

          <!-- PREVIEW ALL REFLECTIONS -->
          <p class="card-title mb-1 text-default font-weight-bold">Ratings</p>
          <span v-for="(reflection, index) in selectedEntry.reflections" v-bind:key="index">

            <!-- RATING -->
            <span class="no-break m-2" v-if="reflection.rating">
              
              <img
                :alt="reflection.member.fullName"
                :title="reflection.member.fullName"
                :src="reflection.member.photoURL"
                class="avatar rounded-circle img-rating-member"
                v-if="reflection.member"
              />
              <img
                :alt="reflection.rating"
                class="img-rating-report"
                :src="getRatingImage(reflection.rating)"
              />

            </span>
            <span class="no-break m-2" v-else>
              
              <img
                :alt="reflection.member.fullName"
                :title="reflection.member.fullName"
                :src="reflection.member.photoURL"
                class="avatar rounded-circle img-rating-member"
                v-if="reflection.member"
              />
              <img
                alt="No Rating!"
                class="img-rating-report"
                src="/img/images/not-available.svg"
              />

            </span>

          </span><br>

          <!-- TAG -->
          <div
            class="badge badge-pill badge-dark mt-3"
          >
            <i class="fal fa-users-class"></i> {{ stripGroupId(selectedEntry.tag) }}
          </div>

          <!-- AUTOMATED -->
          <div
            class="badge badge-pill badge-success"
            v-if="selectedEntry.createdByWebhook"
          >
            <i class="fas fa-plug"></i> Automated
          </div>

          <!-- JOURNAL ENTRY -->
          <div
            class="row justify-content-center pl-3 pr-3 pt-3 mb-4"
            v-if="selectedEntry.activityDetails"
          >
            <span
              style="white-space: pre-wrap"
              v-html="selectedEntry.activityDetails"
            ></span>
          </div>
        </div>
      </div>

      <!-- SENTIMENT ANALYSIS -->
      <div class="bg-gradient-default pointer" v-if="selectedEntry.analysis && viewAnalysis" @click="viewAnalysis = false">
        <hr class="mt-0 mb-0" />

        <div class="card-body mb-4 text-center">

          <div class="row justify-content-center mt-3 mb-3">

            <div class="col-12 mb-3"> 
              <span class="badge badge-default text-white">
                  <i class="fad fa-robot mr-2"></i> View Sentiment Analysis
              </span>
            </div>

            <div class="col-lg-2 col-4"> 
              <img :src="'/img/moods/' + selectedEntry.analysis.overall.score_tag + '.svg'" class="analysis-mood" /><br>
              <p class="text-lowercase badge badge-default">
                {{ selectedEntry.analysis.overall.score_label }}
              </p>
            </div>

            <div class="col-lg-2 col-4">
              <img
                :src="'/img/moods/' + selectedEntry.analysis.overall.subjectivity + '.svg'"
                class="analysis-mood"
              /><br>
              <p class="text-lowercase badge badge-default">
                {{ selectedEntry.analysis.overall.subjectivity }}
              </p>
            </div>

          </div>

          <div class="row justify-content-center">

            <div class="col-lg-4 col-8">

                <sentiment-progress-bar :values="selectedEntry.analysis.overall.intensity"></sentiment-progress-bar>

            </div>

          </div>

        </div>

      </div>

      <div class="bg-gradient-default text-center pointer" v-if="selectedEntry.analysis && !viewAnalysis" @click="viewAnalysis = true">
        <hr class="mt-0 mb-0" />
        <div class="m-2 text-white">
              <span class="badge badge-default text-white">
                  <i class="fad fa-robot mr-2"></i> View Sentiment Analysis
              </span>
            <!-- <i class="fad fa-robot mr-2"></i> view sentiment analysis -->
        </div>
      </div>
      
      <!-- HIGHLIGHTS & LOWLIGHTS -->
      <div v-if="consolidatedHighlights.length > 0 || consolidatedLowlights.length > 0">
        <hr class="mt-0 mb-0" />

        <div class="card-body text-center">
          <div>

            <div
              class="row justify-content-center mt-2"
            >
              <div class="col-lg-5 col-12 m-1 p-2 text-center">
                <h6 class="text-default font-weight-bold small">highlights</h6>
                <div v-if="consolidatedHighlights.length > 0">
                  <ul class="list-group">
                    <li
                      class="list-group-item border-0 p-1"
                      v-for="(highlight, index) in consolidatedHighlights"
                      v-bind:key="index"
                    >
                      <img
                        :alt="highlight.member.fullName"
                        :title="highlight.member.fullName"
                        :src="highlight.member.photoURL"
                        class="avatar avatar-xs rounded-circle img-highlow-member"
                        v-if="highlight.member"
                      />
                      <span style="white-space: pre-wrap" v-html="highlight.text"></span>
                      <hr class="mt-1 mb-2 ml-5 mr-5" />
                    </li>
                  </ul>
                </div>
                <div v-else>
                  <p class="text-muted m-2 small text-center">
                    <i class="fal fa-exclamation-triangle"></i> No highlights
                  </p>
                </div>
              </div>

              <div class="col-lg-5 col-12 m-1 p-2 text-center">
                <h6 class="text-default font-weight-bold small">lowlights</h6>
                <div v-if="consolidatedLowlights.length > 0">
                  <ul class="list-group">
                    <li
                      class="list-group-item border-0 p-1"
                      v-for="(lowlight, index) in consolidatedLowlights"
                      v-bind:key="index"
                    >
                      <img
                        :alt="lowlight.member.fullName"
                        :title="lowlight.member.fullName"
                        :src="lowlight.member.photoURL"
                        class="avatar avatar-xs rounded-circle img-highlow-member"
                        v-if="lowlight.member"
                      />
                      <span style="white-space: pre-wrap" v-html="lowlight.text"></span>
                      <hr class="mt-1 mb-2 ml-5 mr-5" />
                    </li>
                  </ul>
                </div>
                <div v-else>
                  <p class="text-muted m-2 small text-center">
                    <i class="fal fa-exclamation-triangle"></i> No lowlights
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- NO HIGHLIGHTS -->
      <div v-if=" consolidatedHighlights.length === 0 && consolidatedLowlights.length === 0">
        <hr class="mt-0 mb-0" />
        <p class="text-muted m-2 small text-center">
          <i class="fal fa-exclamation-triangle"></i> No highlights or lowlights added
        </p>
      </div>

      <!-- SUPPORT SOCIAL ENGAGEMENTS -->
      <social :id="selectedEntry.groupDocumentId" fontColor="white" card-type="white" v-if="selectedEntry.id"></social>

    </div>
  </div>
</template>
<script>
import BaseDropdown from "@/components/BaseDropdown";
import AdvancedAttachments from "@/components/reflections/AdvancedAttachments";
import SentimentProgressBar from "@/components/SentimentProgressBar";
import Social from "@/components/social/Social";

import VueSlickCarousel from 'vue-slick-carousel'

import InlineRating from "@/components/reflections/InlineRating";


import firebase from "firebase/app";
import { db } from "@/firebase";

import store from "@/store.js";
import { mapGetters } from "vuex";

export default {
  name: "group-reflection-details",
  components: {
    BaseDropdown,
    SentimentProgressBar,
    AdvancedAttachments,
    InlineRating,
    VueSlickCarousel,
    Social
  },
  props: ["selectedEntry", "reflectionTypes", "actionsAllowed"],

  mounted() {
    this.consolidateData();
  },

  data() {
    return {
      // data here
      imagesArray: null,
      action: null,
      viewSentimentAnalysis: false,
      viewAnalysis: false,
      consolidated: false,
      consolidatedHighlights: [],
      consolidatedLowlights: [],
      consolidatedAttachments: [],

      carouselSettings: {
        "arrows": false,
        "dots": true,
        "infinite": true,
        "adaptiveHeight": true
      }

    };
  },
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      // map `this.groups` to `this.$store.getters.groups`
      user: "user",
      groups: "groups",
    }),
  },
  methods: {

    filteredAttachments(attachments){

      return attachments.filter((attachment) => {
        return attachment.requestData;
      });

    },

    showCarousel(){

      let show = false;

      var tmpReflections = this.selectedEntry.reflections;
      tmpReflections.forEach(function (r, ri) {

        var tmpAttachment = r.attachment
        tmpAttachment.forEach(function (a, ai) {
            if(a.requestData){
              show = true
            }
        });

      });

      return show;

    },

    consolidateData(){

      const vm = this
      if(this.consolidated){
        return
      }

      vm.consolidatedHighlights = [];
      vm.consolidatedLowlights = [];
      vm.consolidatedAttachments = [];

      var tmpReflections = this.selectedEntry.reflections;
      tmpReflections.forEach(function (r, ri) {

        var userMap = r.member
        
        var tmpHighlights = r.highlights
        tmpHighlights.forEach(function (h, hi) {
            vm.consolidatedHighlights.push({
              member: userMap,
              text: h
            })
        });

        var tmpLowlights = r.lowlights
        tmpLowlights.forEach(function (l, li) {
            vm.consolidatedLowlights.push({
              member: userMap,
              text: l
            })
        });

        var tmpAttachment = r.attachment
        tmpAttachment.forEach(function (a, ai) {
            if(!a.requestData){
              vm.consolidatedAttachments[ai] = a
            }
        });

      });

      this.consolidated = true

    },

    stripGroupId(tag) {
      if (!this.groups) {
        return tag;
      }

      let newTag = tag;

      var tmpGroups = this.groups;
      tmpGroups.forEach(function (t, i) {
        newTag = newTag.replace(t.id + "#", "");
      });

      return newTag;
    },

    getReflectionDate(datetime) {
      if (datetime._seconds) {
        return new Date(datetime._seconds * 1000);
      } else if (datetime.seconds) {
        return new Date(datetime.seconds * 1000);
      } else if (datetime.value) {
        return new Date(datetime.value);
      } else {
        let newdate = datetime.toString().substring(0, 10);
        return new Date(Number(newdate) * 1000);
      }
    },

    getRatingImage(rating) {
      return "/img/images/values/circled-" + rating + ".svg";
    },

    getReflectionTypeImage(type) {
        let rt = this.reflectionTypes.find(element => element.name === type)
        return rt.image
    },

    modalWasClosed() {
      this.action = null;
      this.$emit("reflectionDeleted");
    }

  },
};
</script>

<style scoped>
.feed-title {
  font-size: 0.9em;
  line-height: 1em;
}

.feed-date {
  font-size: 0.8em;
}

.border-avatar {
  border: 2px solid #8d6c9f;
}

.card-ellipse {
  position: absolute;
  text-align: right;
  top: 0;
  right: 0;
  z-index: 50;
}

.img-rt {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 45px;
  height: 45px;
}

.img-rating-report {
  /* position: absolute; */
  /* top: 12px; */
  /* margin: 45px -45px; */
  width: 60px;
  height: 60px;
}

.img-rating-question {
  width: 60px;
  height: 60px;
}

.img-member {
  border: 3px solid #8d6c9f;
}

.img-rating-member {
  border: 3px solid #8d6c9f;
  margin-right: -15px;
}

.avatar-xs {
  width: 20px;
  height: 20px;
}

.img-highlow-member {
  border: 1px solid #8d6c9f;
  margin-right: 5px;
}

.img-attachment-member {
  border: 3px solid #8d6c9f;
  margin-top: -60px;
}

.analysis-mood {
  width: 60px;
}

.analysis-sentence-mood {
  width: 24px;
}

.no-break {
  white-space:nowrap;
}

</style>
