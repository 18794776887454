<template>
<div>

    <!-- HERO INTRO -->
    <div class="position-relative">
        <!-- shape Hero -->
        <section class="section-shaped my-0">
            <div class="shape shape-style-1 bg-gradient-default shape-skew">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div class="container shape-container d-flex">
                <div class="col px-0">
                    <div class="row">
                        <div class="col-lg-8">
                            <h1 class="display-3 text-white">{{ content.title }}</h1>
                            <p class="lead text-white">
                                {{ content.headline }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- 1st Hero Variation -->
    </div>

    <!-- KEY BENEFITS -->

    <section class="section section-lg pt-lg-0 mt--300" v-if="content">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-12">

                    <div class="row row-grid">

                        <div class="col-12">

                            <card class="border-0 p-0 mb-4" hover shadow v-if="content.feature_image && content.feature_image.filename">
                                <img class="card-img m-0" :src="content.feature_image.filename" :alt="content.title">
                                <div class="card-img-overlay">
                                    <span></span>
                                </div>
                            </card>

                            <card class="border-0" hover shadow id="ourContent">

                                <div v-for="(segment,index) in content.body" v-bind:key="index">

                                    <!-- IF TEXT -->
                                    <rich-text-renderer v-if="segment.component === 'text'" :document="segment.content" />

                                    <!-- IF FEATURE -->
                                    <h3 v-if="segment.component === 'feature'" class="text-center display-3 text-primary font-weight-bold mt-2 mb-4">{{ segment.name }}</h3>

                                    <!-- IF TITLE-IMAGE -->
                                    <img v-if="segment.component === 'tile_image'" :class="segment.className" :src="segment.image.filename" :alt="segment.name">

                                </div>
                                <!-- <rich-text-renderer :document="segment"  v-for="(segment,index) in content.body" v-bind:key="index"></rich-text-renderer> -->
                            </card>

                        </div>

                    </div>

                </div>
            </div>
        </div>
    </section>
    <section class="section section-lg pt-lg-0 mt--200" v-else>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12">

                    <h2>Loading page...</h2>

                </div>
            </div>
        </div>
    </section>

</div>
</template>

<script>
// import FAQ from "@/reflection-components/FAQ";
import firebase from 'firebase/app'
import {
    db
} from '@/firebase';
import store from '@/store.js'
import StoryblokClient from 'storyblok-js-client'

const token = '6OPOpbp82sAZOEePnmeozgtt';
let storyapi = new StoryblokClient({
    accessToken: token
})

export default {
    name: "page",
    // metaInfo: {
    //   title: 'Login', // Get dynamically
    // },
    data() {

        return {
            slug: null,
            content: {}
        }

    },
    created() {
        window.storyblok.init({
            accessToken: token
        })
    },
    watch: {
        $route(to, from) {
            this.getStories();
        }
    },
    mounted() {
        this.getStories();
    },
    methods: {

        getStories() {

            this.slug = this.$route.name
            this.getContent(this.slug, 'published')

        },

        getContent(slug, version) {

            storyapi.get('cdn/stories/' + slug, {
                    "version": version
                })
                .then((response) => {

                    this.content = response.data.story.content

                })
                .catch((error) => {
                    // console.log(error);
                })

        }

    }

};
</script>

<style>
#ourContent img {
    display: block;
    margin: auto;
    text-align: center;
    max-width: 100%;
}

.my-image {
    max-height: 350px;
}

.tile-image {
    text-align: center;
}

.tile-image-sm {
    max-height: 100px;
}
</style>
