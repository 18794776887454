<template>
  <div class="mb-3">

    <section class="section my-0 bg-white">

      <div class="container">

        <div class="card-body justify-content-center text-center">

          <div class="profile-info">

              <h1 />

              <div class="card shadow rounded p-3 mb-5">

                <div class="row">

                    <div class="col">

                        <div class="float-right">
                            <div class="profile-image">
                              <img class="rounded-circle" />
                            </div>
                        </div>

                        <h3 class="mt-2" />
                        <span />

                    </div>

                </div>

              </div>

          </div>

        </div>

      </div>
    
    </section>

    <section class="section my-0 bg-secondary">

      <div class="container">

        <div class="card-body justify-content-center text-center">

          <div class="profile-info">

              <h1 />

                <div class="row">

                    <div class="col-4">

                        <div class="graph-image">
                          <img class="rounded-circle" />
                        </div>

                    </div>
                    <div class="col-8">

                        <h3 class="mt-2" />
                        <span class="w-100" />
                        <span class="w-100" />
                        <span class="w-100" />

                    </div>

                </div>

          </div>

        </div>

      </div>
    
    </section>


    <section class="section my-0 bg-white">

      <div class="container">

        <div class="card-body justify-content-center text-center">

          <div class="profile-info">

                <div class="row">

                    <div class="col-8">

                        <h1 />
                        <h3 class="mt-2" />
                        <span class="w-100" />
                        <span class="w-100" />
                        <span class="w-100" />

                    </div>
                    <div class="col-4 text-right">

                        <div class="graph-image">
                          <img class="rounded-circle" />
                        </div>

                    </div>

                </div>

          </div>

        </div>

      </div>
    
    </section>


  </div>
</template>
<script>

// import SkeletonCardHeader from "@/components/skeleton/CardHeader";

export default {
  name: "skeleton-deep-dive-report",
  // props: ["reflectionNotice"],
  components: {
      // SkeletonCardHeader
  }
};
</script>

<style scoped>

.profile-card {
  width: 100%;
  background: white;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;
}
.profile-image {
    width: 60px;
    height: 60px;
    float: center;
}

.graph-image {
    width: 150px;
    height: 150px;
    float: center;
}

.profile-info {
  width: 100%;
  margin: 0 0 0 10px;
  /* float: center; */
}

.profile-info span {
  text-transform: uppercase;
  color: #777;
  letter-spacing: 3px;
}
.profile-info h1 {
  margin: 0;
  font-size: 3.5em;
  letter-spacing: 8px;
  color: #222;
}
.profile-info h3 {
  margin: 0;
  font-size: 1.5em;
  color: #222;
}
.profile-info p {
  line-height: 140%;
  color: #777;
}
.profile-image img {
	width: 100%;
	padding-top: 100%;
	animation: pulse-bg 1s infinite;
  border-radius: 25px;
}
.graph-image img {
	width: 100%;
	padding-top: 100%;
	animation: pulse-bg 1s infinite;
  border-radius: 25px;
}
.profile-info span {
	width: 280px;
	height: 12px;
	display: block;
    margin: 10px 0 10px;
	animation: pulse-bg 1s infinite;
}
.profile-info h1 {
	width: 280px;
	height: 24px;
  margin: 0 0 25px;
	animation: pulse-bg 1s infinite;
}
.profile-info h3 {
	width: 90px;
	height: 16px;
  margin: 0 0 5px;
	animation: pulse-bg 1s infinite;
}
.profile-info p {
	width: 80%;
	height: 16px;
	animation: pulse-bg 1s infinite;
}
@keyframes pulse-bg {
	0% {
		background-color: #eee;
	}
	50% {
		background-color: #e9e9e9;
	}
	100% {
		background-color: #eee;
	}
}
</style>