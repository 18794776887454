<template>
<div>

    <div class="row justify-content-center m-0 p-0">

        <div class="col-12 text-center">

            <CoolLightBox :items="attachment.images" :index="index" :effect="'fade'" @close="index = null">
            </CoolLightBox>

            <div class="card-columns">
                <div class="card" v-for="(image, imageIndex) in attachment.images" :key="imageIndex">
                    <img class="pointer" :alt="'group post content ' + imageIndex" :src="image.src" @click="index = imageIndex" />
                </div>
            </div>

        </div>

    </div>

</div>
</template>

<script>
import firebase from 'firebase/app'
import {
    db
} from '@/firebase';

import store from '@/store.js'
import {
    mapGetters
} from "vuex";

const chunk = (arr, size) => arr
    .reduce((acc, _, i) =>
        (i % size) ?
        acc : [...acc, arr.slice(i, i + size)], []);

export default {
    name: 'image-embed',
    props: ['attachment'],
    // components: ['CoolLightBox'],
    mounted() {
        // this.user = this.currentUser;
    },

    data() {
        return {
            // data here
            expanded: false,
            largeImage: null,
            index: null
        }
    },
    computed: {

        ...mapGetters({
            // map `this.user` to `this.$store.getters.user`
            // map `this.groups` to `this.$store.getters.groups`
            user: "user",
            groups: "groups"
        }),

        imageClassObject: function () {
            return {
                'thumb-upto3-width': this.attachment.images.length <= 3,
                'thumb-morethan3-width': this.attachment.images.length > 3
            }
        },

        rows() {
            // const withBanners = chunk(this.attachment.images, 5).map((arr) => [...arr, {src: 'banner', type: 'Banner'}]).reduce((a, b) => a.concat(b), []);
            return chunk(this.attachment.images, 4);
        }

    },

    methods: {

    }
};
</script>

<style scoped>
.thumb-single-width {
    max-width: 350px;
}

.thumb-upto3-width {
    /* max-width: 250px; */
    max-width: 90px;
}

.thumb-morethan3-width {
    max-width: 90px;
}

.gallery-row {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
}

/* Create four equal columns that sits next to each other */
.gallery-column {
    flex: 25%;
    max-width: 25%;
    padding: 0 4px;
}

.gallery-column img {
    margin-top: 8px;
    vertical-align: middle;
    width: 100%;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
    .gallery-column {
        flex: 50%;
        max-width: 50%;
    }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .gallery-column {
        flex: 100%;
        max-width: 100%;
    }
}

.card-columns {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
    -webkit-column-gap: 1rem;
    -moz-column-gap: 1rem;
    column-gap: 1rem;
}

@media screen and (max-width: 600px) {
    .card-columns {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        -webkit-column-gap: 1rem;
        -moz-column-gap: 1rem;
        column-gap: 1rem;
    }
}

.card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    display: inline-block;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 2px;
    margin-bottom: 5px;
    width: 100%;
}

.card img {
    width: 100%;
    height: auto;
    margin: 0;
}
</style>
