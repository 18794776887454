var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"navbar",class:[
        {'navbar-expand-lg': _vm.expand},
        ( _obj = {}, _obj[("navbar-" + _vm.effect)] = _vm.effect, _obj ),
        {'navbar-transparent': _vm.transparent},
        ( _obj$1 = {}, _obj$1[("bg-" + _vm.type)] = _vm.type, _obj$1 ),
        {'rounded': _vm.round},
        {'shadow': _vm.shadow}
     ]},[_c('div',{staticClass:"container"},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }