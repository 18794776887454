<template>
    <div v-if="user"> <!--  v-touch:swipe.left="swipeLeftHandler" v-touch:swipe.right="swipeRightHandler"  -->

        <div class="container mb-5" v-if="user.data && !reportId">

            <div class="row justify-content-center">

                <div class="col-12 mt-3">

                    <a class="btn btn-primary btn-block text-white mb-3 shadow" role="button" @click="createDeepDive()"> 
                        <i class="fad fa-lightbulb-on mr-2"></i> New Deep Dive
                    </a>

                    <div v-if="loading">
                        <skeleton-deep-dive-card />
                        <skeleton-deep-dive-card />
                    </div>

                    <div v-for="(deepdive,d) in deepdives" v-bind:key="d">

                        <deep-dive-details
                            :selected-entry="deepdive"
                            :reflection-types="reflectionTypes"
                            ref="deepDiveDetailsPanel"
                            name="Deep Dive Cards"
                        >
                        </deep-dive-details>

                    </div>

                </div>

            </div>

        </div>
        <div v-else>

            <layout ref="DeepDiveReport" :deepdive="report" v-if="report"></layout>

            <div @click="modals.createdeepdive = true">
                <skeleton-deep-dive-report v-if="!report" />
            </div >
            
            <!-- UPDATE REPORT -->
            <section class="section my-0 bg-secondary">

                <div class="container">

                    <div class="card-body justify-content-center">

                        <div class="row justify-content-center">
                            
                            <div class="col-12 text-center">

                                <router-link to="/deep-dives">
                                    <base-button type="danger" size="lg" class="m-1">
                                    Close
                                    </base-button>
                                </router-link>
                                <base-button type="default" size="lg" @click="updateDeepDive" class="m-1">
                                    Update
                                </base-button>

                            </div>
                            
                        </div>

                    </div>

                </div>

            </section>

        </div>

        <modal :show.sync="modals.createdeepdive" modal-classes="modal-dialog-centered modal-sm p-1" v-on:close="createModalClosed">
            <create-deep-dive
                ref="deepdivePanel"
                name="create-deep-dive"
                :report-id="reportId"
                 v-on:modalClosed="modalWasClosed"
            ></create-deep-dive>
        </modal>

    </div>
</template>

<script>

import firebase from 'firebase/app'
import {
    db
} from '@/firebase';
import store from '@/store.js'
import { mapGetters } from "vuex";
import Modal from "@/components/Modal.vue";

import reflectionTypes from "@/models/reflectionTypes";

import CreateDeepDive from "@/components/deepdive/CreateDeepDive"
import Layout from "@/components/deepdive/Layout"
import DeepDiveDetails from "@/components/deepdive/Details"

import SkeletonDeepDiveReport from "@/components/skeleton/DeepDiveReport";
import SkeletonDeepDiveCard from "@/components/skeleton/DeepDiveCard";

export default {
    name: 'deep-dive',
    metaInfo: {
        title: 'Deep Dives',
    },
    components: {
        Modal,
        CreateDeepDive,
        Layout,
        DeepDiveDetails,
        SkeletonDeepDiveReport,
        SkeletonDeepDiveCard
    },

    data() {
        return {
            loading: true,
            modals: {
                createdeepdive:false,
                deletedeepdive:false
            },
            deepdives: [],
            reportId: null,
            report: null,
            reflectionTypes
        }
    },

    mounted() {

        this.setReportId();

    },

    watch: {

        $route(to, from) {

            if(to.name === 'deep-dives'){
                this.reportId = null;
                this.report = null;
            }

            this.setReportId()

        },

    },

    computed: {

        ...mapGetters({
            // map `this.user` to `this.$store.getters.user`
            // map `this.groups` to `this.$store.getters.groups`
            user: "user",
            groups: "groups"
        }),

    },

    methods: {

        modalWasClosed(){

            this.createModalClosed();
            this.modals.createdeepdive = false;
            
        },

        createModalClosed(){

            if(this.$refs.deepdivePanel){

                this.loading = false;

                // If we hit CANCEL then route back to /deep-dives/
                if(this.$refs.deepdivePanel.reportToClose){
                    this.$router.replace( '/deep-dives/' );
                    return
                }

                // If the report has been completed then update the report
                if( this.$refs.deepdivePanel.reportCompleted ){
                    this.report = this.$refs.deepdivePanel.report
                    setTimeout(this.updateDeepDive,500);
                    return
                }

                // Otherwise make sure the modal is still open!
                this.modals.createdeepdive = true;

            }
        
        },

        setReportId() {

            this.reportId = null
            if (this.$route.params.id) {
                
                this.reportId = this.$route.params.id;
                this.getReport(this.reportId)


            }else{

                this.getDeepDives();

            }

        },

        getReport(id){

            let action = this.$route.params.action;

            this.report = this.deepdives.find(report => report.id === id);
            if(!this.report && !action){
                this.getDeepDive();
                return
            }
            if(!this.report && action === 'create'){
                this.modals.createdeepdive = true;
                return
            }

        },

        createDeepDive(){

            var doc = db.collection("deepDives").doc();
            this.$router.replace( '/deep-dives/' + doc.id + '/create' );

        },


        getDeepDive(){

            const vm = this

            var docRef = db.collection("deepDives").doc(this.reportId);
            docRef.get().then((doc) => {
            
                if (doc.exists) {
                    // console.log("Document data:", doc.data());
                    vm.report = doc.data();

                }

            }).catch((error) => {
                
            });

        },

        getDeepDives(){

            if(this.deepdives.length > 0){
                return;
            }

            const vm = this

            var docRef = db.collection("deepDives");
            docRef = docRef.where("uid", "==", vm.user.data.uid);
            docRef = docRef.orderBy("startDate", "desc");
            docRef.onSnapshot(async function (docs) {
                vm.loading = true;
                docs.docChanges().forEach(function (change) {
                    let { newIndex, oldIndex, doc, type } = change;
                    if (type === "added") {
                        vm.deepdives.splice(newIndex, 0, doc.data());
                    } else if (type === "modified") {
                        vm.deepdives.splice(oldIndex, 1);
                        vm.deepdives.splice(newIndex, 0, doc.data());
                    } else if (type === "removed") {
                        vm.deepdives.splice(oldIndex, 1);
                    }
                    vm.loading = false;
                });
            });

        },

        updateDeepDive(){

            this.report = this.$refs.DeepDiveReport.report;
            this.report.id = this.reportId;
            this.report.analysis = this.$refs.DeepDiveReport.analysis;

            db.collection("deepDives")
                .doc(this.reportId)
                .set(this.report);

            this.$toast.open({
                message: "Deep Dive Updated!",
                type: "success",
                // all of other options may go here
            });

        }


    }

};
</script>

<style>

    .bg-rt {
        background: #e6dfea;
    }

    .img-rt {
        /* position: relative; */
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 50px;
        height: 50px;
        transition: transform 0.2s;
    }

    .img-rt-selection:hover {
        transform: scale(1.1);
    }

    .label-rt {
        font-size: 0.7em;
    }


    .default-avatar:hover {
        transform: scale(1.15);
        z-index: 999;
    }

    .default-avatar {
        background-color: #fff;
        font-weight: 500;
        color: #fff;
        font-size: 1.6rem;
    }

    .default-avatar,
    .member-overlap-item {
        height: 40px;
        width: 40px;
        transition: transform 0.2s;
        z-index: 500;
    }

    .member-overlap-item {
        /* margin-right: -10px; */
        border: 2px solid #fff;
    }

</style>
