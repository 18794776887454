export default [
    { index: Number(0), label : 'Love', name : 'love', image : '/img/reactions/love.svg', count: 0 },
    { index: Number(1), label : 'Cool', name : 'cool', image : '/img/reactions/cool.svg', count: 0 },
    { index: Number(2), label : 'Big Grin', name : 'biggrin', image : '/img/reactions/biggrin.svg', count: 0 },
    { index: Number(3), label : 'Cheeky Grin', name : 'cheekygrin', image : '/img/reactions/cheekygrin.svg', count: 0 },
    { index: Number(4), label : 'LOL!', name : 'lol', image : '/img/reactions/lol.svg', count: 0 },
    { index: Number(5), label : 'Neutral', name : 'neutral', image : '/img/reactions/neutral.svg', count: 0 },
    { index: Number(6), label : 'Shocked', name : 'shocked', image : '/img/reactions/shocked.svg', count: 0 },
    { index: Number(7), label : 'Sad', name : 'sad', image : '/img/reactions/sad.svg', count: 0 },
    { index: Number(8), label : 'Crying', name : 'crying', image : '/img/reactions/crying.svg', count: 0 },
    { index: Number(9), label : 'Puzzled', name : 'puzzled', image : '/img/reactions/puzzled.svg', count: 0 }
]