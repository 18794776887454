<template>
  <div>
    <!-- ADD Reply -->
    <div class="media align-items-center mt-0 mb-1">
      <img alt="Image placeholder" :src="user.data.photoURL" class="avatar avatar-sm rounded-circle mt-4 mb-4 mr-2" />
      <div class="media-body">
        <div class="input-group mt-4 mb-2">
          <textarea-autosize placeholder="Write your reply..." ref="replyTextarea" v-model="form.reply" :min-height="10" class="form-control" />
          <div class="input-group-append" v-if="form.reply">
            <button class="btn btn-default" type="button" id="button-addon2" @click="commitReply"><i class="fas fa-reply"></i></button>
          </div>
        </div>
      </div>
    </div>

    <div class="card border-0 bg-white mt-2" v-for="(reply, index) in post.replies" v-bind:key="index">
      <div class="body p-2" style="position: relative">

        <hr class="mt-0 mb-3" />

        <div class="float-left mr-2">
          <img alt="Image placeholder" :class="getAvatarClass(reply.user.uid)" :src="reply.user.photoURL" />
        </div>

        <p class="feed-title font-weight-bold text-default mt-2 mb-0">{{ reply.user.fullName }}</p>
        <p class="feed-date text-muted">
          <small>{{ $dayjs(getDate(reply.creationTime)).fromNow() }}</small>
        </p>

        <p class="small m-2" style="white-space: pre-wrap" v-html="reply.text"></p>

      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import { db } from "@/firebase";
import store from "@/store.js";
import { mapGetters } from "vuex";

export default {
  name: "Replies",
  props: ["post"],
  components: {
    // RegistrationForm
  },

  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      // map `this.groups` to `this.$store.getters.groups`
      user: "user",
      groups: "groups",
    }),
  },

  data() {
    return {
      replies: [],
      form: {
        reply: "",
      },
    };
  },
  methods: {
    getDate(datetime) {
      if (datetime._seconds) {
        return new Date(datetime._seconds * 1000);
      } else if (datetime.seconds) {
        return new Date(datetime.seconds * 1000);
      } else {
        let newdate = datetime.toString().substring(0, 10);
        return new Date(Number(newdate) * 1000);
      }
    },

    getAvatarClass(uid) {
      if (!this.user.data) {
        return "avatar-sm avatar-else rounded-circle";
      }

      if (uid === this.user.data.uid) {
        return "avatar-sm avatar-self rounded-circle";
      } else {
        return "avatar-sm avatar-else rounded-circle";
      }
    },

    commitReply() {
      const vm = this;

      let ourDate = new Date();
      let timeStamp = new firebase.firestore.Timestamp.fromDate(ourDate);

      let newPost = JSON.parse(JSON.stringify(vm.post));
      let reply = {
        user: {
          fullName: this.user.data.fullName,
          photoURL: this.user.data.photoURL,
          uid: this.user.data.uid,
        },
        text: this.form.reply,
        postId: newPost.id,
        creationTime: timeStamp,
        creationSeconds: timeStamp.seconds,
      };
      newPost.replies.unshift(reply);

      db.collection("groupPosts")
        .doc(newPost.id)
        .set(newPost)
        .then(function (docRef) {
          vm.form.reply = null;

          vm.$analytics.logEvent("post_reply", {
            postId: newPost.id,
            groupUid: newPost.groupUid,
            uid: vm.user.data.uid,
            fullName: vm.user.data.fullName,
            creationTime: timeStamp,
            reply: vm.form.reply,
          });
        })
        .catch(function (error) {
          // console.log(error)

          vm.$toast.open({
            message: "Something went wrong!",
            type: "error",
            duration: 10000,
            // all of other options may go here
          });
        });

      // ADD FIRESTORE STUFF!
    },
  },
};
</script>

<style scoped>

.feed-title {
  font-size: 0.9em;
  line-height: 1em;
}

.feed-date {
  font-size: 0.8em;
}

.avatar-self {
  border: 3px solid #8d6c9f;
}

.avatar-else {
  border: 3px solid #999;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.media-reply-avatar {
  margin-top: -4px;
  margin-right: -2rem;
  position: relative;
  z-index: 1;
  width: 64px;
  height: 64px;
  transition: all 0.15s ease;
}

.media-reply-avatar-self {
  border: 3px solid #8d6c9f;
}

.media-reply-avatar-else {
  border: 3px solid #999;
}

@media (prefers-reduced-motion: reduce) {
  .media-reply-avatar {
    transition: none;
  }
}

.media-reply-text {
  border-radius: 0.3rem;
  border-top-left-radius: 0;
  position: relative;
  background-color: #f6f9fc !important;
  padding: 1rem 1.25rem 1rem 2.5rem;
}

.media-reply:hover .media-reply-avatar {
  transform: scale(1.1);
}
</style>
