<template>
  <div class="row justify-content-center">
    <div class="col-lg-12 col-12 text-center align-middle mb-4 mt-2 p-0">

        <h4 class="text-default font-weight-bold mt-3">
          Sentiment analysis
        </h4>
        <h5 class="text-default font-weight-bold mt-0 mb-2">
          <small> by 
            <span class="badge badge-default">
              <i class="text-primary fas fa-robot"></i> Reflection Bot <i class="fad fa-question-circle pointer" @click="showExplainer = !showExplainer"></i>
            </span>
          </small>
        </h5>

        <div class="row justify-content-center mt-3 mb-3" v-if="showExplainer">
          <div class="col-lg-6 col-12"> 
            <p class="text-primary text-center">
                <i class="text-primary fas fa-robot"></i> Reflection Bot is an artificial intellengence tool that will scan
                your weekly reflection responses and your weeks reflections
            </p>
          </div>
        </div>

        <div v-if="status === 'PENDING'">

          <div class="m-3">
            <h2 class="text-primary font-weight-bold">
              <i class="fad fa-robot fa-beat fa-4x mb-2"></i>
            </h2>
          </div>

          <div v-if="!loading">
            <div class="m-3">
              <base-button type="default" outline @click="getReflectionAnalysis">
                Start analysis
              </base-button>
            </div>
          </div>
          <div v-else>
            <div class="m-3">
              <h2 class="text-primary font-weight-bold">
                <small>analysing...</small>
              </h2>
            </div>
          </div>

        </div>
        <div v-else-if="status === 'SUCCESS'">

            <div class="row justify-content-center mt-3 mb-3">
              <div class="col-lg-2 col-4"> 
                <img :src="'/img/moods/' + analysis.overall.score_tag + '.svg'" class="analysis-mood" /><br>
                <p class="text-lowercase badge badge-default">
                  {{ analysis.overall.score_label }}
                </p>
              </div>

              <div class="col-lg-2 col-4">
                <img
                  :src="'/img/moods/' + analysis.overall.subjectivity + '.svg'"
                  class="analysis-mood"
                /><br>
                <p class="text-lowercase badge badge-default">
                  {{ analysis.overall.subjectivity }}
                </p>
              </div>
            </div>

            <div class="row justify-content-center">

              <div class="col-lg-6 col-12">

                  <sentiment-progress-bar :values="analysis.overall.intensity"></sentiment-progress-bar>

              </div>

            </div>

            <div
              class="col-lg-12 col-12 text-center align-middle mb-2 mt-2"
              v-if="!showSentimentDetails"
            >
              <base-button
                type="secondary"
                outline
                size="sm"
                @click="showSentimentDetails = !showSentimentDetails"
              >
                <i class="fad fa-info-circle"></i> view details
              </base-button>

              <hr />
            </div>
            <div class="col-lg-12 col-12 text-center align-middle mb-4 mt-2" v-else>
              <base-button
                type="secondary"
                size="sm"
                @click="showSentimentDetails = !showSentimentDetails"
              >
                <i class="fad fa-info-circle"></i> hide details
              </base-button>

              <hr />
            </div>
            
            <div class="col-12" v-if="showSentimentDetails">

                  <ul class="list-group text-left">
                    <li class="list-group-item shadow mb-2 bg-default text-white" v-if="skippedValues > 0">
                      A total of {{ skippedValues }} details or reflections were skipped because
                      they were either not populated or they had short details that can not be
                      processed effectively. To get more value out of our Reflection Bot ensure
                      that your answers, details, highlights and lowlights are more than five
                      words.
                    </li>
                    <li
                      class="list-group-item shadow mb-2 text-default"
                      v-for="(phrase, p) in analysis.phrases"
                      v-bind:key="p"
                    >
                      <div>
                        <div class="badge badge-default">
                          <img
                            :src="'/img/moods/' + phrase.score_tag + '.svg'"
                            class="analysis-sentence-mood mr-1"
                          />
                          <span>{{ phrase.score_label }}</span>
                        </div>
                        {{ phrase.phrase }}
                      </div>
                      <div class="mt-2 mb-0">
                        <sentiment-progress-bar :values="phrase.intensity"></sentiment-progress-bar>
                      </div>
                      
                    </li>
                  </ul>

                  <hr />

            </div>

        </div>
        <div v-else-if="status === 'NO TEXT'">

            <div class="m-3">
              <p class="text-primary text-center">
                  <i class="text-primary fas fa-robot"></i> <i>Reflection Bot</i> was
                  unable to find any text to analyse this week.
                  To get more value out of our Reflection Bot ensure
                  that your answers, details, highlights and lowlights are more than five
                  words.
              </p>
            </div>

        </div>

    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import SentimentProgressBar from "@/components/SentimentProgressBar";

export default {
  props: ["document", "source", "skippedValues", "currentUser"],
  components: {SentimentProgressBar},
  data() {
    return {
      loading: false,
      showExplainer: false,
      analysis: null,
      status: "PENDING",
      showSentimentDetails: false
    };
  },

  watch: {
    source: {
      handler: function () {
        if (this.source) {
          // this.preloadAnalysis()
        }
      },
      deep: true,
    },
  },

  mounted() {
    this.preloadAnalysis()
  },

  methods: {

    preloadAnalysis(){

        if(this.document.analysis){

            this.analysis = this.document.analysis
            this.status = this.document.analysis.status
            this.skippedValue = this.document.analysis.skippedValue

        }else{

            this.getReflectionAnalysis()

        }

    },

    getReflectionAnalysis() {
      const vm = this;

      vm.loading = true;

      firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
          user.getIdToken().then(function (idToken) {
            // Get our data!
            vm.runAnalysis(idToken);
          });
        }
      });
    },

    runAnalysis(idToken) {
      const vm = this;
      const axios = require("axios");

      let sentimentSource = this.source;

      if (!sentimentSource) {
        vm.status = "NO TEXT";
        vm.loading = false;
        vm.analysis = { message:'There was no text to analysis' }
        return;
      }

      vm.$analytics.logEvent("sentiment_checker", {
        sentimentSource: sentimentSource,
        uid: vm.currentUser.uid,
        fullName: vm.currentUser.fullName,
        startDate: vm.startDate,
        endDate: vm.endDate,
      });

      // Send token to your backend via HTTPS
      var config = {
        headers: {
          Authorization: "Bearer " + idToken,
          "Content-Type": "application/json",
        },
      };

      let url = "https://australia-southeast1-my-reflection-22932.cloudfunctions.net/app/api/sentiment-checker";

      // Make a request for a user with a given ID
      axios
        .post(
          url,
          sentimentSource,
          config
        )
        .then(function (response) {
          // console.log(response);
          vm.analysis = response.data.analysis;
          vm.status = "SUCCESS";
          vm.$emit('updateParent');
        })
        .catch(function (error) {
          // handle error
          // console.log(error)
          vm.status = "ERROR";
        })
        .finally(function () {
          // always executed
          vm.loading = false;
          // vm.building = false
        });
    },
  },
};
</script>

<style scoped>
.ill-analysis {
  width: 60%;
  max-width: 350px;
}

.analysis-mood {
  width: 120%;
  max-width: 70px;
}

.analysis-sentence-mood {
  width: 24px;
}

.fa-beat {
  animation:fa-beat 5s ease infinite;
}
@keyframes fa-beat {
  0% {
    transform:scale(1);
  }
  5% {
    transform:scale(1.05);
  }
  20% {
    transform:scale(1);
  }
  30% {
    transform:scale(1);
  }
  35% {
    transform:scale(1.05);
  }
  50% {
    transform:scale(1);
  }
  55% {
    transform:scale(1.05);
  }
  70% {
    transform:scale(1);
  }
}

</style>
