<template>
    <div>

        <div class="card rounded shadow bg-gradient-primary mt-0 mb-4">

            <div class="card-body mt-0 p-lg-2 p-0">

                <div class="container m-0" v-if="!myVote">

                    <div class="row justify-content-center m-0 p-0">

                        <div class="col-lg-8 col-12 text-center">

                            <h5 class="text-white mt-lg-5 mt-4">
                                <span class="small text-secondary text-uppercase mb-0"><small><small>Group Poll <i class="fad fa-poll-h"></i><br></small></small></span>
                                {{ post.attachment.title }}
                            </h5>

                        </div>
                        <div class="col-lg-8 col-12 text-center mt-lg-5 mt-2">

                            <div class="btn btn-secondary btn-block btn-wrap-text" v-for="(options,oi) in post.attachment.options" v-bind:key="oi" @click="castVote(options,oi)">
                                {{ options.label }}
                            </div>

                        </div>
                        <div class="col-lg-8 col-12 mt-lg-4 mt-2 mb-0 text-center text-white">

                            <p class="small">{{ totalVotes }} vote(s) cast so far</p>

                        </div>

                    </div>

                </div>

                <div class="container m-0" v-else>

                    <div class="row justify-content-center m-0 p-0">

                        <div class="col-lg-8 col-12 text-center">

                            <h5 class="text-white mt-lg-5 mt-4">
                                <span class="small text-secondary text-uppercase mb-0"><small><small>Group Poll <i class="fad fa-poll-h"></i><br></small></small></span>
                                {{ post.attachment.title }}
                            </h5>


                        </div>
                        <div class="col-lg-8 col-12 text-left mt-lg-5 mt-2">

                            <div v-for="(options,oi) in votes" v-bind:key="oi">
                                <div class="float-right mb-0 mt-1 font-weight-bold small text-secondary">
                                    {{ options.votes }} votes
                                </div>
                                <p class="text-white mb-0">
                                    {{ options.label }} <small>({{ votePercentage(options.votes).toFixed(2) }}%)</small>
                                </p>
                                <div class="bg-white option-bar rounded mb-4" :style="'width: ' + votePercentage(options.votes) + '%'">
                                </div>
                                
                            </div>

                        </div>
                        <div class="col-lg-8 col-12 mt-lg-4 mt-2 mb-0 text-center text-white">

                            <p class="small">{{ totalVotes }} vote(s) cast so far</p>

                        </div>

                    </div>

                </div>

            </div>

        </div>

    </div>
</template>
<script>

import firebase from 'firebase/app'
import { db } from '@/firebase';

import store from '@/store.js'
import { mapGetters } from "vuex";

export default {
    name: 'poll-embed',
    props: ['post'],
    // components: ['CoolLightBox'],
    mounted() {
      // this.user = this.currentUser;
    },

    data () {
        return {
            // data here
            // myVote: null
        }
    },
    computed: {

        ...mapGetters({
            // map `this.user` to `this.$store.getters.user`
            // map `this.groups` to `this.$store.getters.groups`
            user: "user",
            groups: "groups"
        }),

        totalVotes(){

            if(!this.post.attachment.votes){
                return 0
            }

            return this.post.attachment.votes.length

        },

        myVoteArray(){

            if(this.post.attachment.votes){

                return this.post.attachment.votes.filter(vote => {
                    return vote.user.uid === this.user.data.uid
                })

            }

        },

        myVote(){

            if(!this.myVoteArray){
                return null
            }
            return this.myVoteArray[0]

        },

        votes(){

            let newOptions = JSON.parse(JSON.stringify(this.post.attachment.options));
            if(!this.post.attachment.votes){
                return newOptions
            }

            let newVotes = JSON.parse(JSON.stringify(this.post.attachment.votes));
            newVotes.forEach((vote,vi) => {
                newOptions[vote.index].votes++
            });

            return newOptions

        }

    },

    methods: {

        votePercentage(votes){

            return ( votes / this.totalVotes ) * 100

        },

        castVote(option,index){

            const vm = this;

            let ourDate = new Date();
            let timeStamp = new firebase.firestore.Timestamp.fromDate(ourDate) 

            let newPost = JSON.parse(JSON.stringify(vm.post));

            let vote = {
                user : {
                    fullName : this.user.data.fullName,
                    photoURL : this.user.data.photoURL,
                    uid : this.user.data.uid
                },
                label : option.label,
                index : index,
                creationTime : timeStamp,
                creationSeconds : timeStamp.seconds
            }

            if(!newPost.attachment.votes){
                newPost.attachment.votes = []
            }

            newPost.attachment.votes.unshift(vote)

            // UPDATE COLLECTION BASED ON IT BEING GROUP POST VS NOTIFICATION

            db.collection("groupPosts").doc(newPost.id).set(newPost)
            .then(function(docRef) {

                vm.$analytics.logEvent('poll_vote', {
                    postId: newPost.id,
                    groupUid: newPost.groupUid,
                    uid: vm.user.data.uid,
                    fullName : vm.user.data.fullName,
                    creationTime : timeStamp,
                    label : option.label,
                    index : index
                });

            })
            .catch(function(error) {

                // console.log(error)

                vm.$toast.open({
                    message: 'Something went wrong!',
                    type: 'error',
                    duration: 10000,
                    // all of other options may go here
                });

            });

            // ADD FIRESTORE STUFF!

        }


    }
};
</script>

<style scoped>

.option-bar {
    height: 5px;
}

.btn-wrap-text {
    white-space:normal !important;
}

</style>