export default {
    collection: null,
    cronExpression: null,
    document: null,
    lastExecution: null,
    performAt: null,
    recurring: false,
    schedule: {
        period: 0,
        time: '00:00:00',
        days: {
            mon: false,
            tue: false,
            wed: false,
            thu: false,
            fri: false,
            sat: false,
            sun: false
        },
        dayNums: {
            1: false,
            2: false,
            3: false,
            4: false,
            5: false,
            6: false,
            7: false,
            8: false,
            9: false,
            10: false,
            11: false,
            12: false,
            13: false,
            14: false,
            15: false,
            16: false,
            17: false,
            18: false,
            19: false,
            20: false,
            21: false,
            22: false,
            23: false,
            24: false,
            25: false,
            26: false,
            27: false,
            28: false,
            29: false,
            30: false,
            31: false
        },
        months: {
            jan: false,
            feb: false,
            mar: false,
            apr: false,
            may: false,
            jun: false,
            jul: false,
            aug: false,
            sep: false,
            oct: false,
            nov: false,
            dec: false
        }
    },
    timezone: null,
    status: 'scheduled',
    uid: null
}