<template>
  <div>
    <!-- HERO INTRO -->
    <div class="position-relative">
      <!-- shape Hero -->
      <section class="section-shaped my-0">
        <div class="shape shape-style-1 bg-gradient-default">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="container shape-container-links d-flex">
          <div class="col px-0">
            <div class="row">
              <div class="col-lg-8">
                <h1 class="display-4 text-white mb-5">Links &amp; articles</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- 1st Hero Variation -->
    </div>

    <!-- KEY BENEFITS -->

    <section class="section section-lg pt-lg-0">
      <div class="container pt-lg-5">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="row row-grid">
              <div class="col-lg-4 mb-lg-2">
                <a href="https://myreflections.app" target="_blank">
                  <card
                    class="border-0 text-center bg-default"
                    shadow
                    body-classes="py-5"
                  >
                    <p class="description text-center text-white">Get started</p>
                    <img src="/img/my-reflection-icon.png" class="mr-logo" alt="Get started!" />
                  </card>
                </a>
              </div>
              <div class="col-lg-4 mb-lg-2">
                <card class="border-0 text-center" shadow body-classes="py-5">
                  <p class="description text-center">follow us on social media</p>

                  <a href="https://www.facebook.com/myreflectionsapp" target="_blank">
                    <icon
                      name="fab fa-facebook-f"
                      type="primary"
                      rounded
                      class="m-2"
                    ></icon>
                  </a>

                  <a href="https://www.instagram.com/myreflectionsapp" target="_blank">
                    <icon name="fab fa-instagram" type="danger" rounded class="m-2"></icon
                  ></a>

                  <a href="https://www.twitter.com/myreflections20" target="_blank">
                    <icon name="fab fa-twitter" type="info" rounded class="m-2"> </icon
                  ></a>
                  <a
                    href="https://www.linkedin.com/company/my-reflections"
                    target="_blank"
                  >
                    <icon name="fab fa-linkedin" type="success" rounded class="m-2"></icon
                  ></a>
                </card>
              </div>
              <div class="col-lg-4 mb-lg-2">
                <card class="border-0 text-center" shadow body-classes="py-5">
                  <p class="description text-center">read our latest articles</p>

                  <a href="https://myreflectionsapp.medium.com/" target="_blank">
                    <icon
                      name="fal fa-chevron-right"
                      type="default"
                      rounded
                      size="sm"
                      class="m-1"
                    ></icon>
                    <icon
                      name="fab fa-medium-m"
                      type="default"
                      rounded
                      class="m-2"
                    ></icon>
                    <icon
                      name="fal fa-chevron-left"
                      type="default"
                      rounded
                      size="sm"
                      class="m-1"
                    ></icon>
                  </a>
                </card>
              </div>
              <div
                class="col-lg-4 mb-lg-2"
                v-for="(article, index) in articles"
                v-bind:key="index"
              >
                <card class="border-0" shadow body-classes="py-5">
                  <a :href="article.link" target="_blank">
                    <h6 class="text-default font-weight-bold">
                      {{ article.title }}
                    </h6>
                    <p class="description mt-3">
                      Published:
                      {{ $dayjs(convertDate(article.isoDate)).fromNow() }}
                    </p>
                    <p class="text-muted small">
                      <i class="fab fa-medium-m"></i> Hosted on medium
                    </p>
                  </a>
                </card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section section section-shaped my-0 overflow-hidden">
      <div class="shape shape-style-1 bg-gradient-secondary">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="container py-0">
        <div class="row row-grid align-items-center">
          <div class="col-12">
            <div class="d-flex px-3">
              <div>
                <icon
                  name="fad fa-dumbbell"
                  size="lg"
                  class="bg-gradient-primary"
                  color="secondary"
                  shadow
                  rounded
                ></icon>
              </div>
              <div class="pl-4 d-lg-block d-none">
                <h5 class="display-4 text-primary">
                  How does reflection fit in with your personal &amp; professional
                  development?
                </h5>
              </div>
              <div class="pl-4 d-lg-none d-md-block">
                <h5 class="text-primary">
                  How does reflection fit in with your personal &amp; professional
                  development?
                </h5>
              </div>
            </div>
          </div>
          <div class="col-md-6 order-lg-1 ml-lg-auto">
            <div class="d-flex px-3">
              <div class="pl-4">
                <p class="text-default">
                  Reflection forms a critical part of our learning, and forms part of,
                  what is academically referred to as,
                  <b>“the action-observation-reflection model”</b>.
                </p>
                <p class="text-default">
                  This model suggests that our learning and development is best plotted on
                  a "spiral of experience" as shown below. In short, we develop ourselves
                  through actions, observations and reflections; with knowledge, expertise
                  and effectiveness increasing the more cycles we experience these three
                  principles.
                </p>
              </div>
            </div>
            <div class="position-relative pl-md-5">
              <img src="/img/aor-model.png" alt="AOR Model" class="img-center img-fluid" />
            </div>
          </div>
          <div class="col-lg-6 order-lg-2">
            <card shadow class="shadow-lg--hover mt-5">
              <div class="d-flex px-3">
                <div>
                  <icon
                    name="fad fa-tasks"
                    gradient="success"
                    color="white"
                    shadow
                    rounded
                  ></icon>
                </div>
                <div class="pl-4">
                  <h5 class="title text-success">Actions...</h5>
                  <p>
                    should be thought of as the results of our decisions when put in any
                    situation in our personal or professional lives.
                  </p>
                  <!-- <a href="#" class="text-success">Learn more</a> -->
                </div>
              </div>
            </card>
            <card shadow class="shadow-lg--hover mt-5">
              <div class="d-flex px-3">
                <div>
                  <icon
                    name="fad fa-poll-h"
                    gradient="info"
                    color="white"
                    shadow
                    rounded
                  ></icon>
                </div>
                <div class="pl-4">
                  <h5 class="title text-info">Observations...</h5>
                  <p>
                    should be thought of as noting the outcomes of those actions. Were
                    they successful? Did they fail? Somewhere in between?
                  </p>
                  <!-- <a href="#" class="text-info">Learn more</a> -->
                </div>
              </div>
            </card>
            <card shadow class="shadow-lg--hover mt-5">
              <div class="d-flex px-3">
                <div>
                  <icon
                    name="fad fa-head-side-brain"
                    gradient="primary"
                    color="white"
                    shadow
                    rounded
                  ></icon>
                </div>
                <div class="pl-4">
                  <h5 class="title text-primary">Reflections...</h5>
                  <p>
                    are the process of evaluating your actions and subsequent observations
                    with the intent of making better decisions in the future
                  </p>
                  <!-- <a href="#" class="text-success">Learn more</a> -->
                </div>
              </div>
            </card>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Reflection from "@/components/reflections/Reflection";
import firebase from "firebase/app";
import { db } from "@/firebase";
import store from "@/store.js";
import { mapGetters } from "vuex";

// import Tabs from "@/components/Tabs/Tabs.vue";
// import TabPane from "@/components/Tabs/TabPane.vue";

// const currentUser = firebase.auth().currentUser;

export default {
  name: "landing-page",
  data() {
    return {
      articles: [],
    };
  },
  components: {
    // Tabs,
    // TabPane,
    Reflection,
  },
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      // map `this.groups` to `this.$store.getters.groups`
      user: "user",
      groups: "groups",
    }),
  },
  mounted() {
    this.getArticles();
  },
  methods: {
    //
    convertDate(isoDate) {
      return new Date(isoDate);
    },

    getArticles() {
      const vm = this;
      const axios = require("axios");

      let url = "https://australia-southeast1-my-reflection-22932.cloudfunctions.net/app/rss-feed";

      // Make a request for a user with a given ID
      axios
        .get(url)
        .then(function (response) {
          // handle success
          // console.log(response.data);
          vm.articles = response.data.articles;
        })
        .catch(function (error) {
          // handle error
          // console.log(error)
        })
        .finally(function () {
          // always executed
          vm.querying = false;
          // vm.building = false
        });
    },
  },
};
</script>

<style scoped>
.shape-container-links {
  padding-top: 3rem;
  padding-bottom: 2rem;
}
.mr-logo {
  width: 60px;
}
</style>
