<template>
    <div>

        <card shadow class="p-4" no-body v-if="reflectionTypes && user">
            <div class="row justify-content-center mb-2">
                <h4>Reflection Statistics</h4>
            </div>

            <div class="row justify-content-center" v-if="statsCollected">
                <div
                class="col-lg-6 col-12"
                v-for="(rt, t) in statistics"
                v-bind:key="t"
                >
                    <card type="secondary" class="card card-body mb-4 p-0">
                        <div class="row">
                            <div class="col-lg-4 col-md-4 col-sm-4 col-12 mb-2 text-center">
                                <div class="text-center">
                                    <img :alt="rt.name" class="img-rt-profile" :src="getReflectionTypeImage(rt.name)" />
                                </div>
                                <div class="label-rt text-center text-default font-weight-bold">
                                    {{ rt.name }}
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-4 col-sm-4 col-6 text-center">
                                <div class="badge badge-default">Reflections</div>
                                <h5
                                    class="text-center text-default font-weight-bold"
                                    v-if="rt.reflection_count"
                                >
                                    {{ parseInt(rt.reflection_count).toFixed(0) }}
                                </h5>
                                <h5 class="text-center text-default font-weight-bold" v-else>0</h5>
                            </div>

                            <div class="col-lg-4 col-md-4 col-sm-4 col-6 text-center">
                                <div class="badge badge-default">Avg. Rating</div>
                                <h5
                                class="text-center text-default font-weight-bold"
                                v-if="rt.reflection_average"
                                >
                                {{  parseInt(rt.reflection_average).toFixed(2) }}
                                </h5>
                                <h5 class="text-center text-default font-weight-bold" v-else>0.00</h5>
                            </div>
                        </div>
                    </card>
                </div>
            </div>
            <div class="row justify-content-center" v-else>

                <div class="col-lg-8 col-12 mt-3 mb-3 text-center text-primary">
                    <i class="fad fa-spinner-third fa-4x fa-spin"></i>
                    <h5 class="text-primary mt-2">Loading reflection statistics</h5>
                </div>

            </div>
        </card>

    </div>
</template>
<script>

import firebase from "firebase/app";
import { db } from "@/firebase";

import store from "@/store.js";
import { mapGetters } from "vuex";

export default {
    name: 'profile-statistics',
    props: ['reflectionTypes'],
    components: { 
        // RegistrationForm
    },

    computed: {

        ...mapGetters({
            // map `this.user` to `this.$store.getters.user`
            // map `this.groups` to `this.$store.getters.groups`
            user: "user",
            groups: "groups"
        })

    },

    mounted(){

        this.getStats();
        
    },

    data () {
        return {
            statsCollected: false,
            querying: false,
            totalReflections: 0,
            totalRating: 0,
            overallAverage: 0,
            statistics: [
                {
                    name: "appointment",
                    reflection_count: 0,
                    rating_total: 0,
                    reflection_average: 0
                },
                {
                    name: "daily checkin",
                    reflection_count: 0,
                    rating_total: 0,
                    reflection_average: 0
                },
                {
                    name: "event",
                    reflection_count: 0,
                    rating_total: 0,
                    reflection_average: 0
                },
                {
                    name: "game",
                    reflection_count: 0,
                    rating_total: 0,
                    reflection_average: 0
                },
                {
                    name: "general",
                    reflection_count: 0,
                    rating_total: 0,
                    reflection_average: 0
                },
                {
                    name: "journal entry",
                    reflection_count: 0,
                    rating_total: 0,
                    reflection_average: 0
                },
                {
                    name: "learning",
                    reflection_count: 0,
                    rating_total: 0,
                    reflection_average: 0
                },
                {
                    name: "meeting",
                    reflection_count: 0,
                    rating_total: 0,
                    reflection_average: 0
                },
                {
                    name: "personal",
                    reflection_count: 0,
                    rating_total: 0,
                    reflection_average: 0
                },
                {
                    name: "photo journal",
                    reflection_count: 0,
                    rating_total: 0,
                    reflection_average: 0
                },
                {
                    name: "project",
                    reflection_count: 0,
                    rating_total: 0,
                    reflection_average: 0
                },
                {
                    name: "sleep",
                    reflection_count: 0,
                    rating_total: 0,
                    reflection_average: 0
                },
                {
                    name: "task",
                    reflection_count: 0,
                    rating_total: 0,
                    reflection_average: 0
                },
                {
                    name: "training",
                    reflection_count: 0,
                    rating_total: 0,
                    reflection_average: 0
                },
                {
                    name: "wellness",
                    reflection_count: 0,
                    rating_total: 0,
                    reflection_average: 0
                }
            ],

        }
    },
    methods: {

        getReflectionTypeImage(type) {

            let rt = this.reflectionTypes.find(element => element.name === type)
            return rt.image

        },

        getStats() {

            // If we have already downloaded, don't query BQ again
            if (this.statsCollected) {
                return;
            }

            const vm = this;
            vm.querying = true;

            firebase.auth().onAuthStateChanged(function (user) {
                if (user) {
                user.getIdToken().then(function (idToken) {
                    // Get our data!
                    vm.bigQuery(idToken);
                });
                }
            });
        },

        bigQuery(idToken) {

            const vm = this;
            const axios = require("axios");

            vm.$analytics.logEvent("reflection_stats_query", {
                uid: vm.user.data.uid,
                fullName: vm.user.data.fullName
            });

            // Send token to your backend via HTTPS
            var config = {
                headers: {
                    Authorization: "Bearer " + idToken,
                },
            };

            // let url = "http://localhost:5001/my-reflection-22932/australia-southeast1/app/api/reflection_summary_query?global=1";
            let url = "https://australia-southeast1-my-reflection-22932.cloudfunctions.net/app/api/reflection_summary_query";

            // Make a request for a user with a given ID
            axios
                .get(url, config)
                .then(function (response) {

                    // handle success
                    vm.processStatistics(response.data);

                })
                .catch(function (error) {
                    // handle error
                    // console.log(error)
                })
                .finally(function () {
                    // always executed
                    vm.querying = false;
                    // vm.building = false
                });
        },

        processStatistics(stats){

            // this.statistics.findIndex(t => t.name === stat.name);
            const vm = this

            let totalReflections = 0;
            let totalRating = 0;

            stats.forEach(stat => {
                let i = vm.statistics.findIndex(t => t.name === stat.name);
                vm.statistics[i] = stat
                totalReflections += parseInt(stat.reflection_count)
                totalRating += parseInt(stat.rating_total)
            });
            
            vm.totalRating = totalRating;
            vm.overallAverage = totalRating / totalReflections;
            vm.totalReflections = totalReflections;

            vm.statsCollected = true;

        }

    }
};
</script>
<style scoped>

    .label-rt {
        font-size: 0.7em;
    }

    .mr-logo {
        width: 90px !important;
        height: 90px !important;
    }

    .img-rt-profile {
        /* position: relative; */
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 40px !important;
        height: 40px !important;
    }

</style>