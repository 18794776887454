export default {
    uid : null,
    publicLink: null,
    user : {
        uid: '',
        firstName: '',
        lastName: '',
        fullName: '',
        photoURL: ''
    },
    access : [],
    startTimestamp : null,
    startDate : null,
    endTimestamp : null,
    endDate : null,
    label : '',
    answers : {
        topThree: '',
        bottomThree: '',
        reflectionSplit: '',
        reflectionTagSplit: '',
        cardsReviewed: false,
        retrospectiveCompleted: false
    },
    graphs: {
        topThree: [],
        bottomThree: [],
        reflectionSplit: [],
        reflectionTagSplit: [],
        reflectionDay: [],
        reflectionDate: []
    },
    baseStatistics : {
        totalReflections : 0,
        sumRatings : 0,
        averageRating : 0,
        totalHighlights : 0,
        totalLowlights : 0,
        totalAdjustments : 0
    },
    reflectionsByTag : [],
    reflectionsByType : [],
    reflectionsByDay : [
        { 
            name : 'Monday',
            totalReflections : 0,
            averageRating : 0,
            sumRatings : 0,
            totalHighlights : 0,
            totalLowlights : 0,
            totalAdjustments : 0
        },
        { 
            name : 'Tuesday',
            totalReflections : 0,
            averageRating : 0,
            sumRatings : 0,
            totalHighlights : 0,
            totalLowlights : 0,
            totalAdjustments : 0
        },
        { 
            name : 'Wednesday',
            totalReflections : 0,
            averageRating : 0,
            sumRatings : 0,
            totalHighlights : 0,
            totalLowlights : 0,
            totalAdjustments : 0
        },
        { 
            name : 'Thursday',
            totalReflections : 0,
            averageRating : 0,
            sumRatings : 0,
            totalHighlights : 0,
            totalLowlights : 0,
            totalAdjustments : 0
        },
        { 
            name : 'Friday',
            totalReflections : 0,
            averageRating : 0,
            sumRatings : 0,
            totalHighlights : 0,
            totalLowlights : 0,
            totalAdjustments : 0
        },
        { 
            name : 'Saturday',
            totalReflections : 0,
            averageRating : 0,
            sumRatings : 0,
            totalHighlights : 0,
            totalLowlights : 0,
            totalAdjustments : 0
        },
        { 
            name : 'Sunday',
            totalReflections : 0,
            averageRating : 0,
            sumRatings : 0,
            totalHighlights : 0,
            totalLowlights : 0,
            totalAdjustments : 0
        }
    ],
    reflectionsByDate: [],
    deepDiveRetrospective: {
        highlights : [],
        lowlights : [],
        adjustments : []
    },
    reflections: [],
    adjustments: [],
    tags: [],
    comments: [],
    analysis: null
}