<template>
  <div class="mb-3">
    <div class="card shadow p-0 bg-white rounded" v-if="selectedEntry">

      <!-- CARD HEADER -->
      <div class="mt-2 mb-0">
        <div class="float-right text-dark pt-2 pr-3">

          <base-dropdown position="right" v-if="actionsAllowed">
            <a
              role="button"
              class="pointer"
              slot="title"
              data-toggle="dropdown"
              @click="action = ''"
            >
              <icon
                name="fal fa-ellipsis-v"
                size="sm"
                iconOnly
                textColor="dark"
                type="white"
                outline
                rounded
                class="text-dark"
              ></icon>
            </a>

            <router-link class="dropdown-item pointer" :to="'/mygroups/' + selectedEntry.tagData.groupId" v-if="taggedGroup()">
              <i class="fal fa-users-class"></i> Open Group
            </router-link>
            <router-link class="dropdown-item pointer" :to="'/reflectnow/' + selectedEntry.id">
              <i class="fal fa-pencil"></i> Edit
            </router-link>
            <a class="dropdown-item pointer text-dark" role="button" @click="action = 'delete'">
              <i class="fal fa-trash"></i> Delete
            </a>
          </base-dropdown>

          <img
            :alt="selectedEntry.member.fullName"
            :title="selectedEntry.member.fullName"
            :src="selectedEntry.member.photoURL"
            class="avatar-sm rounded-circle img-member ml-2"
            v-if="!actionsAllowed && selectedEntry.member"
          />

        </div>

        <div class="float-left ml-2 mr-1 p-2">
          <img
            :alt="selectedEntry.reflectionType"
            class="avatar avatar-sm bg-white border-avatar p-1"
            :src="getReflectionTypeImage(selectedEntry.reflectionType)"
          />
        </div>

        <p class="feed-title font-weight-bold text-default mt-2 mb-0">
          <router-link :to="'/reflectnow/' + selectedEntry.id">
            <span v-if="selectedEntry.reflectionType === 'journal entry'"
              >Your {{ selectedEntry.reflectionType }}</span
            >
            <span v-else-if="selectedEntry.reflectionType === 'daily checkin'"
              >Your {{ selectedEntry.reflectionType }}</span
            >
            <span v-else-if="selectedEntry.reflectionType === 'photo journal'"
              >Your {{ selectedEntry.reflectionType }} for
              {{ selectedEntry.activityName }}</span
            >
            <span v-else-if="selectedEntry.reflectionType === selectedEntry.activityName"
              >Your {{ selectedEntry.reflectionType }} reflection</span
            >
            <span v-else
              >Your {{ selectedEntry.reflectionType }} reflection for
              {{ selectedEntry.activityName }}</span
            >
          </router-link>
        </p>
        <p class="feed-date text-muted">
          {{ $dayjs(getReflectionDate(selectedEntry.creationTime)).fromNow() }}
        </p>
        <hr class="mt-0 mb-0" />
      </div>

      <!-- ACTIVITY DETAILS -->
      <div class="card-body mb-2 text-center">
        <div>

          <!-- PHOTO JOURNAL -->
          <img
            :src="selectedEntry.imageURL"
            class="img-fluid mb-3"
            v-if="selectedEntry.imageURL"
          />

          <!-- DAILY CHECKING QUESTIONS -->
          <div
            class="row justify-content-center pt-1 pb-4"
            v-if="selectedEntry.dailyCheckinQuestions"
          >
            <div
              class="col-12 pt-1 pb-2"
              v-for="(qn, index) in selectedEntry.dailyCheckinQuestions"
              v-bind:key="index"
            >
              <div v-if="qn.type === 'range'">
                <p class="card-title mt-4 mb-1 text-default font-weight-bold">
                  {{ qn.question }}<br />
                </p>
                <img
                  :alt="qn.value"
                  class="img-rating-question"
                  :src="getRatingImage(qn.value)"
                />
              </div>
              <div v-else>
                <p class="card-title mt-4 mb-1 text-default font-weight-bold">
                  {{ qn.question }}<br />
                </p>
                <p>
                  <span style="white-space: pre-wrap" v-html="qn.value"></span>
                </p>
              </div>
            </div>
          </div>

          <!-- ADVANCED ATTACHMENTS -->
          <advanced-attachments
            :attachment="selectedEntry.attachment"
            :id="selectedEntry.id"
            :allowEdit="false"
            v-if="selectedEntry.attachment"></advanced-attachments>

          <!-- RATING -->
          <div v-if="selectedEntry.rating">
            <p class="card-title mb-1 text-default font-weight-bold">Rating<br /></p>
            <img
              :alt="selectedEntry.rating"
              class="img-rating-report"
              :src="getRatingImage(selectedEntry.rating)"
            />
          </div>
          <div v-else-if="!actionsAllowed">
              <p class="card-title mb-1 text-default font-weight-bold">No rating captured</p>
          </div>
          <div v-else>
            <inline-rating ref="inline-editing" :selectedEntry="selectedEntry"></inline-rating>
          </div>

          <!-- TAG -->
          <div
            class="badge badge-pill badge-dark"
            v-if="selectedEntry.tagData && selectedEntry.tagData.scope === 'group'"
          >
            <i class="fal fa-users-class"></i> {{ selectedEntry.tagData.label }}
          </div>
          <div
            class="badge badge-pill badge-default"
            v-else-if="selectedEntry.tag && selectedEntry.tag !== 'none'"
          >
            <i class="fal fa-tag"></i> {{ stripGroupId(selectedEntry.tag) }}
          </div>

          <!-- AUTOMATED -->
          <div
            class="badge badge-pill badge-success"
            v-if="selectedEntry.createdByWebhook"
          >
            <i class="fas fa-plug"></i> Automated
          </div>

          <!-- SCHEDULED -->
          <div
            class="badge badge-pill badge-info"
            v-if="selectedEntry.scheduled"
          >
            <i class="fas fa-clock"></i> Scheduled
          </div>

          <!-- JOURNAL ENTRY -->
          <div
            class="row justify-content-center pl-3 pr-3 pt-3 mb-4"
            v-if="selectedEntry.activityDetails"
          >
            <span
              style="white-space: pre-wrap"
              v-html="selectedEntry.activityDetails"
            ></span>
          </div>
        </div>
      </div>

      <!-- SENTIMENT ANALYSIS -->
      <!-- <div class="bg-gradient-default text-center" v-if="selectedEntry.sentimentQueued">
        <hr class="mt-0 mb-0" />
        <div class="m-2 text-white">
              <span class="badge badge-default text-white">
                  <i class="fad fa-spinner-third fa-spin mr-2"></i> Sentiment analysis Queued.. check back soon
              </span>
        </div>
      </div> -->
      <div class="bg-gradient-default pointer" v-if="selectedEntry.analysis && viewAnalysis" @click="viewAnalysis = false">
        <hr class="mt-0 mb-0" />

        <div class="card-body mb-4 text-center">

          <div class="row justify-content-center mt-3 mb-3">

            <div class="col-12 mb-3"> 
              <span class="badge badge-default text-white">
                  <i class="fad fa-robot mr-2"></i> View Sentiment Analysis
              </span>
            </div>

            <div class="col-lg-2 col-4"> 
              <img :src="'/img/moods/' + selectedEntry.analysis.overall.score_tag + '.svg'" class="analysis-mood" /><br>
              <p class="text-lowercase badge badge-default">
                {{ selectedEntry.analysis.overall.score_label }}
              </p>
            </div>
<!-- 
            <div class="col-lg-2 col-4">
              <img
                :src="'/img/moods/' + selectedEntry.analysis.overall.subjectivity + '.svg'"
                class="analysis-mood"
              /><br>
              <p class="text-lowercase badge badge-default">
                {{ selectedEntry.analysis.overall.subjectivity }}
              </p>
            </div> -->

          </div>

          <div class="row justify-content-center">

            <div class="col-lg-4 col-8">

                <sentiment-progress-bar :values="selectedEntry.analysis.overall.intensity"></sentiment-progress-bar>

            </div>

          </div>

        </div>

      </div>
      <div class="bg-gradient-default text-center pointer" v-else-if="selectedEntry.analysis && !viewAnalysis" @click="viewAnalysis = true">
        <hr class="mt-0 mb-0" />
        <div class="m-2 text-white">
              <span class="badge badge-default text-white">
                  <i class="fad fa-robot mr-2"></i> View Sentiment Analysis
              </span>
            <!-- <i class="fad fa-robot mr-2"></i> view sentiment analysis -->
        </div>
      </div>
      
      <!-- HIGHLIGHTS, LOWLIGHTS & ADJUSTMENTS-->
      <!-- <div v-if="selectedEntry.highlights.length > 0 || selectedEntry.lowlights.length > 0 || selectedEntry.adjustments.length > 0">
        
        <div class="bg-gradient-success text-center pointer mt-0 mb-0" v-if="selectedEntry.highlights.length > 0" @click="viewHighlights = true">
          <div class="m-2 text-white p-2">
            <span class="badge badge-default text-white">
                <i class="fad fa-thumbs-up mr-2"></i> View Highlights
            </span>
          </div>
        </div>

        <div class="bg-gradient-danger text-center pointer mt-0 mb-0" v-if="selectedEntry.lowlights.length > 0" @click="viewLowlights = true">
          <div class="m-2 text-white p-2">
            <span class="badge badge-default text-white">
                <i class="fad fa-thumbs-up mr-2"></i> View Lowlights
            </span>
          </div>
        </div>

        <div class="bg-gradient-default text-center pointer mt-0 mb-0" v-if="selectedEntry.highlights.length > 0" @click="viewAdjustments = true">
          <div class="m-2 text-white p-2">
            <span class="badge badge-default text-white">
                <i class="fad fa-thumbs-up mr-2"></i> View Adjustments
            </span>
          </div>
        </div>

      </div> -->

      <!-- HIGHLIGHTS, LOWLIGHTS & ADJUSTMENTS-->
      <div v-if="selectedEntry.highlights.length > 0 || selectedEntry.lowlights.length > 0 || selectedEntry.adjustments.length > 0">
        <hr class="mt-0 mb-0" />

        <div class="card-body text-center">
          <div>

            <div
              class="row justify-content-center mt-2"
            >

              <div class="col-lg-8 col-12 m-1 p-2 text-center" v-if="selectedEntry.highlights.length > 0">
                <h6 class="text-muted small text-uppercase"><i class="fal fa-thumbs-up text-success"></i> highlights</h6>
                <div>
                  <ul class="list-group">
                    <li
                      class="list-group-item border-0 p-1"
                      v-for="(highlight, index) in selectedEntry.highlights"
                      v-bind:key="index"
                    >
                      <span style="white-space: pre-wrap" v-html="highlight"></span>
                      <!-- <hr class="mt-1 mb-2 ml-5 mr-5" /> -->
                      <br><i class="fal fa-horizontal-rule text-success small"></i>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-lg-8 col-12 m-1 p-2 text-center" v-if="selectedEntry.lowlights.length > 0">
                <h6 class="text-muted small text-uppercase"><i class="fal fa-thumbs-down text-danger"></i> lowlights</h6>
                <div >
                  <ul class="list-group">
                    <li
                      class="list-group-item border-0 p-1"
                      v-for="(lowlight, index) in selectedEntry.lowlights"
                      v-bind:key="index"
                    >
                      <span style="white-space: pre-wrap" v-html="lowlight"></span>
                      <!-- <hr class="mt-1 mb-2 ml-5 mr-5" /> -->
                      <br><i class="fal fa-horizontal-rule text-danger small"></i>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-lg-8 col-12 m-1 p-2 text-center" v-if="selectedEntry.adjustments.length > 0">
                <h6 class="text-muted small text-uppercase"><i class="fal fa-exchange-alt text-primary"></i> adjustments</h6>
                <div >
                  <ul class="list-group">
                    <li
                      class="list-group-item border-0 p-1"
                      v-for="(adjustment, index) in selectedEntry.adjustments"
                      v-bind:key="index"
                    >
                      <span style="white-space: pre-wrap" v-html="adjustment"></span>
                      <!-- <hr class="mt-1 mb-2 ml-5 mr-5" /> -->
                      <br><i class="fal fa-horizontal-rule text-primary small"></i>
                    </li>
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <!-- NO HIGHLIGHTS -->
      <div
        v-if="
          selectedEntry.highlights.length === 0 && selectedEntry.lowlights.length === 0 && selectedEntry.adjustments.length === 0
        "
      >
        <hr class="mt-0 mb-0" />
        <p class="text-muted m-2 small text-center">
          <i class="fal fa-exclamation-triangle"></i> No highlights, lowlights or adjustments
        </p>
        <!-- <div class="m-0 alert alert-secondary border-1 border-default">
        </div> -->
      </div>

      <!-- SUPPORT SOCIAL ENGAGEMENTS -->
      <social :id="socialId()" fontColor="white" card-type="white" v-if="taggedGroup()"></social>

    </div>
    <div v-if="action">
      <reflection-modals
        :entry="selectedEntry"
        :action="action"
        v-on:modalClosed="modalWasClosed"
      ></reflection-modals>
    </div>
  </div>
</template>
<script>
import BaseDropdown from "@/components/BaseDropdown";
import ReflectionModals from "@/components/reflections/Modals";
import AdvancedAttachments from "@/components/reflections/AdvancedAttachments";
import SentimentProgressBar from "@/components/SentimentProgressBar";

import InlineRating from "@/components/reflections/InlineRating";

import Social from "@/components/social/Social";


import firebase from "firebase/app";
import { db } from "@/firebase";

import store from "@/store.js";
import { mapGetters } from "vuex";

export default {
  name: "reflection-details",
  components: {
    BaseDropdown,
    ReflectionModals,
    InlineRating,
    SentimentProgressBar,
    AdvancedAttachments,
    Social
  },
  props: ["selectedEntry", "reflectionTypes", "actionsAllowed"],

  mounted() {
    // this.user = this.currentUser;
  },

  data() {
    return {
      // data here
      imagesArray: null,
      action: null,
      viewSentimentAnalysis: false,
      viewAnalysis: false
    };
  },
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      // map `this.groups` to `this.$store.getters.groups`
      user: "user",
      groups: "groups",
    }),
  },
  methods: {

    queueSentimentAnalysis() {

      let reflectionId = this.selectedEntry.id; 

      if(this.selectedEntry.highlights.length === 0 && this.selectedEntry.lowlights.length === 0){
          this.action = 'nohighlow'
          return
      }

      db.collection("reflections")
        .doc(reflectionId)
        .update({ "sentimentQueued": true });

      this.$toast.open({
        message: "Sentiment Analysis Queued!",
        type: "success",
        // all of other options may go here
      });
    },

    socialId(){

      if(this.selectedEntry.groupDocumentId){
        return this.selectedEntry.groupDocumentId
      }else{
        return this.selectedEntry.id
      }

    },
  
    stripGroupId(tag) {
      if (!this.groups) {
        return tag;
      }

      let newTag = tag;

      var tmpGroups = this.groups;
      tmpGroups.forEach(function (t, i) {
        newTag = newTag.replace(t.id + "#", "");
      });

      return newTag;
    },

    getReflectionDate(datetime) {
      if (datetime._seconds) {
        return new Date(datetime._seconds * 1000);
      } else if (datetime.seconds) {
        return new Date(datetime.seconds * 1000);
      } else if (datetime.value) {
        return new Date(datetime.value);
      } else {
        let newdate = datetime.toString().substring(0, 10);
        return new Date(Number(newdate) * 1000);
      }
    },

    getRatingImage(rating) {
      return "/img/images/values/circled-" + rating + ".svg";
    },

    getReflectionTypeImage(type) {
      let rt = this.reflectionTypes.find(element => element.name === type)
      return rt.image
    },

    modalWasClosed() {
      this.action = null;
      this.$emit("reflectionDeleted");
    },

    checkGroupType(groupId){

      let group = this.groups.find(groups => groups.id === groupId);
      if(!group) { return false }
      if(group.groupType && group.groupType === 'open'){
        return true
      }else{
        return false
      }

    },

    taggedGroup(){

      if(!this.selectedEntry){
        return
      }

      if(this.selectedEntry.tagData && this.selectedEntry.tagData.scope === 'group' && this.checkGroupType(this.selectedEntry.tagData.groupId)){
        return this.selectedEntry.tagData.groupId
      }else{
        return false
      }

    }
  },
};
</script>

<style scoped>
.feed-title {
  font-size: 0.9em;
  line-height: 1em;
}

.feed-date {
  font-size: 0.8em;
}

.border-avatar {
  border: 2px solid #8d6c9f;
}

.card-ellipse {
  position: absolute;
  text-align: right;
  top: 0;
  right: 0;
  z-index: 50;
}

.img-rt {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 60px;
  height: 60px;
}

.img-rating-report {
  /* position: absolute; */
  /* top: 12px; */
  /* margin: 45px -45px; */
  width: 60px;
  height: 60px;
}

.img-rating-question {
  width: 60px;
  height: 60px;
}

.img-member {
  border: 3px solid #8d6c9f;
}

.analysis-mood {
  width: 60px;
}

.analysis-sentence-mood {
  width: 24px;
}

</style>
