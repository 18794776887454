<template>
  <div class="mb-3">
    <div class="card shadow p-0 rounded" :class="selectedEntry.type ? 'bg-' + selectedEntry.type : 'bg-default'" v-if="selectedEntry">
      <div class="mt-2 mb-0" :class="fontColor(selectedEntry.type)">

        <div class="float-right text-dark pt-2 pr-3" v-if="selectedEntry.audience === this.user.data.uid">

            <a role="button" @click="hideAlert">

                <icon name="fal fa-times"
                    size="sm"
                    iconOnly
                    textColor="dark"
                    :type="selectedEntry.type"
                    outline
                    rounded
                    shadow
                    class="pointer" :class="fontColor(selectedEntry.type)"></icon>

            </a>

        </div>

        <div class="float-left ml-2 mr-1 p-2">
          <img
            alt="Image placeholder"
            class="avatar avatar-sm bg-default p-1 shadow shadow-sm" 
            :src="selectedEntry.user.photoURL"
          />
        </div>

        <p class="feed-title font-weight-bold mt-2 mb-0">
          {{ selectedEntry.user.fullName }}
          
        </p>
        <p class="feed-date">
          {{ $dayjs(getReflectionDate(selectedEntry.creationTime)).fromNow() }}
        </p>

        <hr class="mt-0 mb-0" />

        <div class="m-3">
          <p class="font-weight-bold" v-show="selectedEntry.title">{{ selectedEntry.title }}</p>
          <span
            style="white-space: pre-wrap"
            v-html="selectedEntry.text"
            v-show="selectedEntry.text"
          ></span>
        </div>

        <!-- <hr class="border-group-inner mt-0 mb-0" v-if="selectedEntry.attachment && selectedEntry.attachment[0]" /> -->

        <!-- ADVANCED ATTACHMENTS -->
        <advanced-attachments
          :attachment="selectedEntry.attachment"
          :id="selectedEntry.id"
          :allowEdit="false"
          :minimized="true"
          v-if="selectedEntry.attachment && selectedEntry.attachment[0]"></advanced-attachments>

        <!-- SUPPORT SOCIAL ENGAGEMENTS -->
        <social :id="selectedEntry.id" :fontColor="fontColor(selectedEntry.type)" :card-type="selectedEntry.type" v-if="selectedEntry.id"></social>

      </div>

    </div>
  </div>
</template>
<script>
import BaseDropdown from "@/components/BaseDropdown";
import firebase from "firebase/app";
import { db } from "@/firebase";

import store from "@/store.js";
import { mapGetters } from "vuex";

import AdvancedAttachments from "@/components/reflections/AdvancedAttachments";
import Social from "@/components/social/Social";

export default {
  name: "notification-details",
  components: {
    BaseDropdown,
    AdvancedAttachments,
    Social
    // ReportShare,
    // VideoEmbed,
    // PollEmbed,
    // ImageEmbed,
    // WebsiteEmbed,

  },
  props: ["selectedEntry"],

  mounted() {
    // this.user = this.currentUser;
  },

  data() {
    return {
      // data here
      imagesArray: null,
      action: null,
      commentsEnabled: [],
    };
  },
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      // map `this.groups` to `this.$store.getters.groups`
      user: "user",
      groups: "groups",
    }),
  },
  methods: {

    fontColor(type){

      let dark = [
        'success',
        'light',
        'white',
        // 'info'
      ]

      if( dark.find(element => element === type) ){
        return 'text-dark'
      }

      return 'text-white'

    },

    hideAlert(){

      const vm = this

      let notification = JSON.parse(JSON.stringify(this.selectedEntry));
      notification.alertHidden = true

      db.collection("notifications")
        .doc(notification.id)
        .set(notification)
        .then(function (docRef) {

          vm.$toast.open({
            message: "Notification hidden!",
            type: "success",
            // all of other options may go here
          });

        })
        .catch(function (error) {
          // console.log(error)

          vm.$toast.open({
            message: "Something went wrong!",
            type: "error",
            duration: 10000,
            // all of other options may go here
          });
        });

    },

    getReflectionDate(datetime) {
      let myDate = new Date(datetime.seconds * 1000);
      return myDate;
    }

  },
};
</script>

<style scoped>
.feed-title {
  font-size: 0.9em;
  line-height: 1em;
}

.feed-date {
  font-size: 0.8em;
}

.border-group {

  border-color: #CCC;

}

.border-avatar {
  border: 2px solid #8d6c9f;
}

.card-ellipse {
  position: absolute;
  text-align: right;
  top: 0;
  right: 0;
  z-index: 50;
}

.img-rt {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 60px;
  height: 60px;
}

.img-rating-report {
  /* position: absolute; */
  /* top: 12px; */
  /* margin: 45px -45px; */
  width: 60px;
  height: 60px;
}

.img-rating-question {
  width: 60px;
  height: 60px;
}

/* span::first-letter {
  font-size: 250%;
  color: #8d6c9f;
} */


.badge-count {
  border-radius: 24px;
}

.comments-count-image {
  width: 24px;
  height: 24px;
}

.comment {
  display: flex;
  align-items: flex-start;
}

.comment-body {
  flex: 1;
}

.comment-footer {
  border-top: 1px solid rgba(141, 108, 159, 0);
}

.comment-grouppost-avatar {
  margin-top: -4px;
  margin-right: -1rem;
  position: relative;
  z-index: 1;
  width: 42px;
  height: 42px;
  transition: all 0.15s ease;
}

.comment-grouppost-avatar-self {
  border: 3px solid #8d6c9f;
}

.comment-grouppost-avatar-else {
  border: 3px solid #999;
}

@media (prefers-reduced-motion: reduce) {
  .comment-grouppost-avatar {
    transition: none;
  }
}

.comment-grouppost-text {
  border-radius: 0.3rem;
  border-top-left-radius: 0;
  position: relative;
  background-color: #fff !important;
  padding: 1rem 1.25rem 1rem 2.5rem;
}

.comment-grouppost:hover .comment-grouppost-avatar {
  transform: scale(1.1);
}

</style>
