<template>
    <div>

        <!-- HERO INTRO -->
        <div class="position-relative">

            <!-- shape Hero -->
            <section class="section-shaped my-0 pb-5">
                <div class="shape shape-style-1 bg-dark">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div class="container shape-container d-flex">
                    <div class="col px-0">
                        <div class="row">
                            <div class="col-lg-8">
                                <h1 class="display-3 text-white">
                                Oh no... something has gone horribly wrong
                                </h1>
                                <p class="lead text-secondary">
                                The page you have arrived at doesn't exist unfortunately!
                                </p>
                                <p class="lead text-white">
                                    <router-link to="/reflectnow" class="mt-2 mr-2">
                                        <base-button type="white" class="mt-2">
                                            <i class="fad fa-head-side-brain"></i> Go Home!
                                        </base-button>
                                    </router-link>
                                    <a href="https://myreflections.freshdesk.com/" target="_blank" class="mt-2">
                                        <base-button type="white" class="mt-2">
                                            <i class="fad fa-user-headset"></i> Get Help
                                        </base-button>
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>

    </div>
</template>

<script>
import firebase from 'firebase/app'
import {
    db
} from '@/firebase';
import store from '@/store.js'

export default {

    name: "wrongpage",
    metaInfo: {
      title: 'Something Went Wrong', // Get dynamically
    },
    data() {
        return {}
    },
    created() {},
    mounted() {}

};
</script>

<style>
</style>
