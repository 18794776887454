<template>
    <div>

        <div class="row justify-content-center pl-3 pr-3 mb-2" v-if="!previewValue">

            <div class="col-12" v-show="attachment.title || attachment.summary">
                <p class="card-title mb-1 text-default font-weight-bold" v-show="attachment.title">{{ attachment.title }}<br /></p>
                <p v-show="attachment.summary">{{ attachment.summary }}</p>
            </div>

            <div class="col-lg-6 col-12">

                <div class="mt-4 mb-4 text-center">

                    <div class="btn btn-secondary btn-block btn-wrap-text" v-for="(options, index) in localData.data" v-bind:key="index" @click="castVote(options,index)">
                        {{ options.label }}
                    </div>

                </div>

                <p class="small">{{ totalVotes }} vote(s) cast so far</p>

            </div>
        </div>

        <div class="row justify-content-center m-0 p-0" v-else-if="previewValue">

            <div class="col-12 text-center" v-show="attachment.title || attachment.summary">
                <span class="small text-default text-uppercase mb-0"><small><small>Group Poll <i class="fad fa-poll-h"></i><br></small></small></span>
                <p class="card-title mb-1 text-default font-weight-bold" v-show="attachment.title">{{ attachment.title }}<br /></p>
                <p v-show="attachment.summary">{{ attachment.summary }}</p>
            </div>
            <div class="col-12 text-left mt-lg-5 mt-2">

                <div v-for="(options,oi) in localData.data" v-bind:key="oi">
                    <div class="float-right mb-0 mt-1 font-weight-bold small text-primary">
                        {{ options.value }} votes
                    </div>
                    <p class="text-primary mb-0">
                        {{ options.label }} <small>({{ votePercentage(options.value).toFixed(2) }}%)</small>
                    </p>
                    <div class="bg-primary option-bar rounded mb-4" :style="'width: ' + votePercentage(options.value) + '%'">
                    </div>
                    
                </div>

            </div>
            <div class="col-12 mt-lg-4 mt-2 mb-0 text-center text-primary">

                <p class="small">{{ totalVotes }} vote(s) cast so far</p>

            </div>

        </div>

    </div>
</template>
<script>

import _ from "lodash";

export default {
    name: 'embed-poll',
    props: ['attachment','id','index','readOnly'],
    components: {
        // Pie,
        // PieChart
    },
    mounted() {
      this.reqData = this.requestDataChecker();
    },

    data () {
        return {
            localData: _.cloneDeep(this.attachment),
            newData: false,
            editEnabled: false,
            reqData: false,
            previewValue: null,
            previewVotes: 0
        }
    },
    computed: {

        showEdit() {

            if(this.readOnly){
                return false
            }

            if(this.attachment.allowDataEdits && this.editEnabled){
                return true
            }

            // if(this.reqData){
            //     return true
            // }

            return false;
        },

        totalVotes(){

            if(!this.attachment.data){
                return 0
            }

            if(this.readOnly){
                return this.previewVotes;
            }
            
            // Count votes

        },

    },

    watch: {

        attachment: {
            handler: function () {
                setTimeout(this.resetNewData,200);
            },
            deep: true,
        }

    },

    methods: {

        votePercentage(votes){

            return ( votes / this.totalVotes ) * 100

        },

        requestDataChecker(){

            if(this.readOnly){
                return false
            }

            if(this.attachment.requestData && this.checkForValues()){
                return true
            }

            return false
        },

        checkForValues(){

            let data = this.localData.data
            let dataReq = false

            data.forEach((record,i) => {

                if(!record.value){
                    dataReq = true
                }

            });

            if(this.readOnly){
                dataReq = false
            }

            return dataReq

        },

        resetNewData(){
            this.newData = false;
        },

        castVote(option,index){
            
            if(this.readOnly){

                this.previewValue = {
                    index,
                    option
                }

                this.previewVotes = 0;

                let previewData = _.cloneDeep(this.localData.data);
                previewData.forEach((record,i) => {

                    this.localData.data[i].value = Math.floor(Math.random() * 5);
                    if(i === index){
                        this.localData.data[i].value++;
                    }

                    this.previewVotes += this.localData.data[i].value;

                });

            }else{

                // We're live so add the poll to firebase

            }
            
        },




    }
};
</script>

<style scoped>

    .option-bar {
        height: 5px;
    }

    .btn-wrap-text {
        white-space:normal !important;
    }

</style>