<template>
    <div>

        <card shadow class="p-4" no-body v-if="user">

            <div class="row">
                <div class="col-12">
                    <div class="text-muted text-uppercase float-left small font-weight-bold m-2">Group Mission</div>
                </div>
                <div class="col-12">

                    <blockquote class="blockquote bg-default shadow p-4 mb-0">
                        <h4 class="text-white text-center">"{{ group.groupMission }}"</h4>
                        <footer class="blockquote-footer text-secondary float-right">{{ group.members[0].fullName }}</footer>
                    </blockquote>

                </div>
            </div>

            <div class="row" v-if="user.data.uid === group.ownerId || group.groupType === 'open'">

                    <div class="col-12 mt-4">
                        <div class="text-muted text-uppercase float-left small font-weight-bold m-2">Group Members</div>
                    </div>
                    <div class="col-lg-4 col-12 mb-4" v-for="(member,index) in group.members" v-bind:key="index">

                        <div class="float-left m-0 p-0 mr-2">
                            <img :alt="member.fullName" :title="member.fullName" :src="member.photoURL" class="avatar avatar-sm rounded-circle img-member ml-2">
                        </div>
                        <p class="text-default font-weight-bold mt-2">{{ member.fullName }}</p>

                    </div>

            </div>
            <div class="row" v-else>

                    <div class="col-12 mt-5" >

                        <div class="alert alert-info text-center p-5">
                            <p class="h5 text-white">
                                <span>Please note that this is a "closed" or "hierarchical" group, meaning only the group owner can see members data. That's why this kind of looks empty!</span>
                            </p>
                        </div>

                    </div>

            </div>

            <div class="row justify-content-center" v-if="user.data.uid !== group.ownerId">
                <div class="col-lg-6 col-12 mt-5 mb-5">
                    <div class="text-center mt-5">
                        <group-leave :group="group"></group-leave>
                    </div>
                </div>
            </div>
        
        </card>

    </div>
</template>
<script>

// import firebase from 'firebase/app'
// import { db } from '@/firebase';
import store from '@/store.js'
import { mapGetters } from "vuex";

import GroupLeave from "@/components/mygroups/Leave";

export default {
    name: 'group-details',
    props: ['group'],
    components: { 
        GroupLeave
    },

    computed: {

        ...mapGetters({
            // map `this.user` to `this.$store.getters.user`
            // map `this.groups` to `this.$store.getters.groups`
            user: "user",
            groups: "groups"
        })

    },

    data () {
        return {
            //
        }
    },
    methods: {
        // 
    }
};
</script>
<style scoped>

    .img-member {
        border:3px solid #8d6c9f;
    }

</style>