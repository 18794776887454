// pieChart.js
import { Doughnut } from 'vue-chartjs'

export default {
  extends: Doughnut,
  props: ['chartData','chartLabels'],
  mounted () {
    // Overwriting base render method with actual data.
    this.renderChart(
      // Data
      {
          labels: this.chartLabels,
          datasets: [
              {
                // label: "Monthly incomes",
                backgroundColor: [
                  "#8D6C9F","#C0B5D1","#7A909B","#FFFD98","#866058","#9CA5B9","#C8CC89","#AD9163","#8A6B55","#624B41","#42304B","#ECE6EF","#739BA2","#7E2C5B","#23395B"
                ],
                data: this.chartData
              }
          ]
      },
      // Options
      {
          cutoutPercentage: 70,
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              boxWidth: 15,
            }
          }
      }
    )
  }
}
