<template>
    <div>

        <div class="row justify-content-center pl-3 pr-3 mb-2">

            <div class="col-12" v-show="attachment.title || attachment.summary">
                <p class="card-title mb-1 text-default font-weight-bold" v-show="attachment.title">{{ attachment.title }}<br /></p>
                <p v-show="attachment.summary">{{ attachment.summary }}</p>
            </div>

            <div class="col-12 text-center border border-1 p-0 border-primary">

                <pdf
                    :src="attachment.src"
                    @num-pages="pageCount = $event"
                    @page-loaded="currentPage = $event"
                    :page="validPage"
                >
                </pdf>

            </div>
            <div class="col-12 text-center bg-primary" v-if="pageCount > 0">

                <div class="float-left" v-show="currentPage > 1">
                    <div class="btn btn-sm btn-primary" @click="currentPage = currentPage-1">
                        <i class="fas fa-chevron-left"></i>
                    </div>
                </div>
                <div class="float-right">
                    <a :href="attachment.src" class="btn btn-sm btn-primary" target="_blank">
                        <i class="fas fa-download"></i>
                    </a>
                    <div class="btn btn-sm btn-primary" v-show="currentPage < pageCount" @click="currentPage = currentPage+1">
                        <i class="fas fa-chevron-right"></i>
                    </div>
                </div>
                <span class="text-white small">
                    
                    <input type="text" class="form-control d-inline form-control-sm bg-primary text-white border-0 text-center" style="width: 60px;" v-model.number="currentPage">
                     /
                     <input type="text" class="form-control d-inline form-control-sm bg-primary text-white border-0 text-center" readonly style="width: 60px;" :value="pageCount">

                </span>

            </div>

        </div>
    </div>
</template>
<script>

import _ from "lodash";
import pdf from 'vue-pdf'

export default {
    name: 'embed-pdf',
    props: ['attachment','id','index','readOnly'],
    components: {
        pdf
    },

    mounted() {
       // this.reqData = this.requestDataChecker();
    },

    data () {
        return {
            localData: _.cloneDeep(this.attachment),
            reqData: false,
			currentPage: 1,
			pageCount: 0,
        }
    },
    computed: {
        validPage(){

            if(!Number.isInteger(this.currentPage)){
                //this.currentPage = 1;
                return 1;
            }

            if(!this.currentPage){
                return 1;
            }

            if(this.currentPage < 1){
                return 1;
            }

            if(this.currentPage > this.pageCount){
                return this.pageCount;
            }

            return this.currentPage
        }
    },

    watch: {

    },

    methods: {

    }
};
</script>

<style scoped>

</style>