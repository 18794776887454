<template>
  <div>
    <div class="container">
      <div class="row mt-0" v-if="reflections.length > 0">
        <!-- 
        <div class="col-12 mt-4 mb-0 p-0 text-center">
            <small class="text-uppercase text-muted">Recent Reflections</small>
        </div> -->

        <div class="col-12 p-0" v-for="(record, index) in reflections" v-bind:key="index">

          <reflection-details
            ref="reflectionDetailsPanel"
            name="reflection cards"
            :selected-entry="record"
            :actions-allowed="actionsAllowed"
            :reflection-types="reflectionTypes"
            v-on:reflectionDeleted="reflectionWasDeleted"
            v-if="record.reflectionType && !record.reflections"
          ></reflection-details>

          <group-reflection-details
            ref="groupReflectionDetailsPanel"
            name="group reflection cards"
            :selected-entry="record"
            :actions-allowed="actionsAllowed"
            :reflection-types="reflectionTypes"
            v-on:reflectionDeleted="reflectionWasDeleted"
            v-if="record.reflectionType && record.reflections"
          ></group-reflection-details>

          <group-details
            ref="groupPostDetailsPanel"
            name="group post cards"
            :selected-entry="record"
            v-if="record.groupUid"
          ></group-details>

          <notification-details
            ref="notificationDetailsPanel"
            name="notification cards"
            :selected-entry="record"
            v-if="record.audience"
          ></notification-details>
        </div>

        <div v-if="action">
          <reflection-modals
            :entry="entry"
            :action="action"
            v-on:modalClosed="modalWasClosed"
          ></reflection-modals>
        </div>

      </div>
    </div>

    <div class="col-12 p-0 mb-5" v-if="!disableScroll && !loaded">

        <skeleton-reflection-card />
        <skeleton-reflection-card />
        <skeleton-reflection-card />
        <skeleton-reflection-card />
        <skeleton-reflection-card />

    </div>

    <div class="col-12 p-0 mb-5" v-if="!disableScroll && loaded && reflections.length === 0">

        <skeleton-reflection-card />
        <skeleton-reflection-card />
        <skeleton-reflection-card />
        <skeleton-reflection-card />
        <skeleton-reflection-card />

    </div>

  </div>
</template>
<script>
import BaseDropdown from "@/components/BaseDropdown";
import ReflectionDetails from "@/components/reflections/Details";
import GroupReflectionDetails from "@/components/mygroups/GroupReflectionDetails";
import GroupDetails from "@/components/reflections/GroupDetails";
import NotificationDetails from "@/components/reflections/NotificationDetails";
import ReflectionModals from "@/components/reflections/Modals";
import firebase from "firebase/app";
import { db } from "@/firebase";
import store from "@/store.js";
import { mapGetters } from "vuex";

import SkeletonReflectionCard from "@/components/skeleton/ReflectionCard";

export default {
  name: "reporting-feed",
  props: ["reflectionTypes", "reflections", "currentUser", "actionsAllowed","disableScroll","loaded"],
  components: {
    BaseDropdown,
    ReflectionModals,
    ReflectionDetails,
    GroupReflectionDetails,
    GroupDetails,
    NotificationDetails,
    SkeletonReflectionCard
  },

  mounted() {
    // this.user = this.currentUser;
  },

  data() {
    return {
      selectedEntry: null,
      entry: null,
      reflectionImages: [],
      imagesArray: null,
      // user: null,
      action: null,
    };
  },
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      // map `this.groups` to `this.$store.getters.groups`
      user: "user",
      groups: "groups",
    }),
  },
  methods: {

    stripGroupId(tag) {
      if (!this.groups) {
        return tag;
      }

      let newTag = tag;

      var tmpGroups = this.groups;
      tmpGroups.forEach(function (t, i) {
        newTag = newTag.replace(t.id + "#", "");
      });

      return newTag;

    },

    setupModal(record) {
      this.action = "";
      this.entry = record;
    },

    getReflectionDate(datetime) {
      if (datetime._seconds) {
        return new Date(datetime._seconds * 1000);
      } else if (datetime.seconds) {
        return new Date(datetime.seconds * 1000);
      } else if (datetime.value) {
        return new Date(datetime.value);
      } else {
        let newdate = datetime.toString().substring(0, 10);
        return new Date(Number(newdate) * 1000);
      }
    },

    getRatingClass(rating, index) {
      let sizeClass = "";
      if (index > 6) {
        sizeClass = "d-none d-lg-block";
      }
      return (
        "align-items-center align-self-center m-1 p-0 bg-default rating-bg rating-bg-" +
        rating +
        " " +
        sizeClass
      );
    },

    getRatingImageClass(rating) {
      return "align-self-center pointer img-rating img-rating-" + rating;
    },

    getRatingImage(rating) {
      return "img/images/values/circled-" + rating + ".svg";
    },

    getReflectionTypeImage(type) {
      let rt = this.reflectionTypes.find(element => element.name === type)
      return rt.image
    },

    modalWasClosed() {
      this.action = null;
      this.selectedEntry = null;
      // this.$emit('reflectionDeleted')
    },

    reflectionWasDeleted() {
      this.selectedEntry = null;
    },
  },
};
</script>
<style scoped>
.card-ellipse {
  position: absolute;
  text-align: right;
  z-index: 50;
  top: 0;
  right: 0;
}

.img-inthought {
  width: 50%;
  height: 50%;
  max-width: 300px;
}

.img-rt {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 60px;
  height: 60px;
}

.img-rating-report {
  position: absolute;
  /* top: 12px; */
  margin: 45px -45px;
  width: 30px;
  height: 30px;
}

.img-report {
  position: relative;
  top: 0;
  left: 0;
  width: 35px;
  height: 35px;
}

.img-rating {
  width: 35px;
  height: 35px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: rgba(255, 255, 255, 0.5);
  display: inline-block;
  vertical-align: middle;
}
</style>
