<template>
    <div>

        <card shadow class="p-4" no-body v-if="user">

            <div class="row justify-content-center mb-2">
                <div class="col-12">
                    <div class="float-right">
                        <a class="btn btn-sm btn-default text-white" role="button" @click="modals.create = true">
                            <i class="fal fa-clock mr-2"></i> New
                        </a>
                    </div>
                    <h4>
                        Scheduled Reflections
                    </h4>
                </div>
            <!-- </div> -->
            <div class="col-12" v-if="scheduledTasks.length > 0">

                <ul class="list-group">
                    <li class="list-group-item" v-for="(schedule,s) in scheduledTasks" v-bind:key="s">
                        <div class="float-right mt-3">

                            <div class="btn-group btn-group-sm" role="group">
                                <base-button type="default" @click="showDeleteModal(schedule.id)"><i class="fal fa-trash"></i></base-button>
                            </div>

                        </div>

                        <div class="float-left mr-1 p-1">
                            <img
                                :alt="schedule.document.reflectionType"
                                class="img-rt bg-white border-avatar p-1"
                                :src="getReflectionTypeImage(schedule.document.reflectionType)"
                            />
                        </div>

                        <p class="mt-2 mb-0">
                            {{ getPeriodName(schedule.schedule.period) | capitalize }} 
                            <span class="font-weight-bold">
                                {{ schedule.document.reflectionType }} reflection
                            </span>
                            <span v-if="schedule.schedule.period === 1">
                                on {{ grabDaysLabel(schedule.schedule.days) }}
                            </span>
                            <span v-if="schedule.schedule.period >= 2">
                                on the {{ grabDayNumsLabel(schedule.schedule.dayNums) }}
                            </span>
                            <span v-if="schedule.schedule.period === 3">
                                of {{ grabMonthsLabel(schedule.schedule.months) }}
                            </span>
                            <span>
                                at {{ schedule.schedule.time }}
                            </span>
                            <span v-if="schedule.document.activityName !== schedule.document.reflectionType">
                                labelled 
                                <span class="font-weight-bold">
                                    {{ schedule.document.activityName }}
                                </span>
                            </span>

                        </p>

                        <!-- {{ schedule }} -->

                    </li>
                </ul>

            </div>
            <div class="col-12" v-else>
                <div class="alert alert-default text-center" v-if="!loaded">
                    <i class="fad fa-spinner-third fa-4x fa-spin"></i>
                    <h5 class="text-white mt-2">Loading your scheduled reflections!</h5>
                </div>
                <div class="alert alert-info text-center" v-if="loaded">
                    No Scheduled Reflections
                </div>
            </div>
            </div>

        </card>

        <modal
            :show.sync="modals.create"
            modal-classes="modal-dialog-centered modal-sm p-1"
        >
            <div v-if="stepCurrent === 1">

                <div class="row justify-content-center">

                    <div class="col-12 pt-1 pb-4">
                        <h4 class="text-default mb-0 mt-0 text-left" v-if="user.data">
                            Hi {{ user.data.firstName}},
                        </h4>
                        <h4 class="text-default text-left font-weight-bold">
                            What type of reflection do you want to schedule?
                        </h4>
                    </div>
                    
                </div>

                <div class="row justify-content-center text-center">
                    <div
                        class="col-4 pt-2 pb-2 m-0 text-center justify-content-center"
                        v-for="(rt, index) in reflectionTypes"
                        v-bind:key="index"
                        @click="setReflectionType(rt)"
                    >
                    <div class="text-center">
                        <img
                            :alt="rt.name"
                            class="img-rt img-rt-selection pointer"
                            v-lazy="rt.image"
                        />
                    </div>
                    <div class="label-rt text-center text-default font-weight-bold pointer">
                        {{ rt.name }}
                    </div>
                    </div>
                </div>

            </div>

            <!-- GIVE THE REFLECTION A NAME -->
            <div v-if="stepCurrent === 2">

                <h4 class="text-default text-left font-weight-bold">
                    {{ reflectionType.labels.scheduleintro }}
                </h4>
                <p class="small">
                    TIP: Use this to better understand your scheduled reflection 
                    as it is automatically created.
                </p>

                <div class="row justify-content-center">
                    <div class="col-12 pt-1">
                    <base-input
                        
                        v-model="task.document.activityName"
                        :placeholder="reflectionType.labels.activity"
                        input-classes="text-dark"
                    >
                    </base-input>
                    </div>
                </div>
            </div>

            <!-- DEFINE A SCHEDULE -->
            <div v-if="stepCurrent === 3">

                <div class="row justify-content-center">

                    <div class="col-12">
                        <h4 class="text-default text-left font-weight-bold">
                            Set your schedule
                        </h4>
                    </div>
                    
                </div>

                <div class="row justify-content-center">

                    <div class="col-12">
                        
                        <label for="schedule">Create reflection</label>
                        <select class="form-control" id="schedule" v-model.number="task.schedule.period">
                            <option value="0">Daily</option>
                            <option value="1">Weekly</option>
                            <option value="2">Monthly</option>
                            <option value="3">Yearly</option>
                        </select>
                        
                    </div>
                </div>

                <div class="row justify-content-center mt-2" v-if="task.schedule.period === 1">

                    <div class="col-12">
                        
                        <label for="schedule">On these days</label><br>
                        <button :class="task.schedule.days.mon ? 'mb-2 btn btn-sm btn-default' : 'mb-2 btn btn-sm btn-outline-default'" @click="task.schedule.days.mon = !task.schedule.days.mon">Mon</button>
                        <button :class="task.schedule.days.tue ? 'mb-2 btn btn-sm btn-default' : 'mb-2 btn btn-sm btn-outline-default'" @click="task.schedule.days.tue = !task.schedule.days.tue">Tue</button>
                        <button :class="task.schedule.days.wed ? 'mb-2 btn btn-sm btn-default' : 'mb-2 btn btn-sm btn-outline-default'" @click="task.schedule.days.wed = !task.schedule.days.wed">Wed</button>
                        <button :class="task.schedule.days.thu ? 'mb-2 btn btn-sm btn-default' : 'mb-2 btn btn-sm btn-outline-default'" @click="task.schedule.days.thu = !task.schedule.days.thu">Thu</button>
                        <button :class="task.schedule.days.fri ? 'mb-2 btn btn-sm btn-default' : 'mb-2 btn btn-sm btn-outline-default'" @click="task.schedule.days.fri = !task.schedule.days.fri">Fri</button>
                        <button :class="task.schedule.days.sat ? 'mb-2 btn btn-sm btn-dark' : 'mb-2 btn btn-sm btn-outline-dark'" @click="task.schedule.days.sat = !task.schedule.days.sat">Sat</button>
                        <button :class="task.schedule.days.sun ? 'mb-2 btn btn-sm btn-dark' : 'mb-2 btn btn-sm btn-outline-dark'" @click="task.schedule.days.sun = !task.schedule.days.sun">Sun</button>
                        
                    </div>

                </div>

                <div class="row justify-content-center mt-2" v-if="task.schedule.period >= 2">

                    <div class="col-12">
                        
                        <label for="schedule">One these days</label><br>
                        <button
                            :class="task.schedule.dayNums[n] ? 'mb-2 btn btn-sm btn-default' : 'mb-2 btn btn-sm btn-outline-default'"
                            @click="task.schedule.dayNums[n] = !task.schedule.dayNums[n]"
                            v-for="n in 31" v-bind:key="n"
                        >
                            {{ n }}
                        </button>
                    </div>

                </div>

                <div class="row justify-content-center mt-2" v-if="task.schedule.period >= 3">

                    <div class="col-12">
                        
                        <label for="schedule">Of these months</label><br>
                        <button :class="task.schedule.months.jan ? 'mb-2 btn btn-sm btn-default' : 'mb-2 btn btn-sm btn-outline-default'" @click="task.schedule.months.jan = !task.schedule.months.jan">jan</button>
                        <button :class="task.schedule.months.feb ? 'mb-2 btn btn-sm btn-default' : 'mb-2 btn btn-sm btn-outline-default'" @click="task.schedule.months.feb = !task.schedule.months.feb">feb</button>
                        <button :class="task.schedule.months.mar ? 'mb-2 btn btn-sm btn-default' : 'mb-2 btn btn-sm btn-outline-default'" @click="task.schedule.months.mar = !task.schedule.months.mar">mar</button>
                        <button :class="task.schedule.months.apr ? 'mb-2 btn btn-sm btn-default' : 'mb-2 btn btn-sm btn-outline-default'" @click="task.schedule.months.apr = !task.schedule.months.apr">apr</button>
                        <button :class="task.schedule.months.may ? 'mb-2 btn btn-sm btn-default' : 'mb-2 btn btn-sm btn-outline-default'" @click="task.schedule.months.may = !task.schedule.months.may">may</button>
                        <button :class="task.schedule.months.jun ? 'mb-2 btn btn-sm btn-default' : 'mb-2 btn btn-sm btn-outline-default'" @click="task.schedule.months.jun = !task.schedule.months.jun">jun</button>
                        <button :class="task.schedule.months.jul ? 'mb-2 btn btn-sm btn-default' : 'mb-2 btn btn-sm btn-outline-default'" @click="task.schedule.months.jul = !task.schedule.months.jul">jul</button>
                        <button :class="task.schedule.months.aug ? 'mb-2 btn btn-sm btn-default' : 'mb-2 btn btn-sm btn-outline-default'" @click="task.schedule.months.aug = !task.schedule.months.aug">aug</button>
                        <button :class="task.schedule.months.sep ? 'mb-2 btn btn-sm btn-default' : 'mb-2 btn btn-sm btn-outline-default'" @click="task.schedule.months.sep = !task.schedule.months.sep">sep</button>
                        <button :class="task.schedule.months.oct ? 'mb-2 btn btn-sm btn-default' : 'mb-2 btn btn-sm btn-outline-default'" @click="task.schedule.months.oct = !task.schedule.months.oct">oct</button>
                        <button :class="task.schedule.months.nov ? 'mb-2 btn btn-sm btn-default' : 'mb-2 btn btn-sm btn-outline-default'" @click="task.schedule.months.nov = !task.schedule.months.nov">nov</button>
                        <button :class="task.schedule.months.dec ? 'mb-2 btn btn-sm btn-default' : 'mb-2 btn btn-sm btn-outline-default'" @click="task.schedule.months.dec = !task.schedule.months.dec">dec</button>
                        
                    </div>

                </div>

                <div class="form-row mt-2">
                    <div class="col">
                        <label for="hour">At this time</label>
                        <input type="time" class="form-control" v-model="task.schedule.time">
                    </div>
                    <div class="col">
                        <label for="hour">and timezone</label>
                        <select class="form-control" v-model="task.timezone">
                            <option v-for="option in timezones" v-bind:key="option.tzCode" :value="option.tzCode">
                                {{ option.label }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="row justify-content-center mt-3 mb-4">

                    <div class="col-12 text-default text-center small">
                        Will first run at {{ $dayjs( nextRun() ).format("ha ddd, MMM Do YYYY") }}
                    </div>

                </div>
                        

            </div>

            <!-- FOOTER -->
            <div class="row mt-3" v-if="stepCurrent > 1 && stepCurrent < 4">

              <div class="col text-left pt-3">

                <span class="small ml-2" v-for="step in 3" v-bind:key="step">

                  <i class="fas fa-circle text-primary" v-if="stepCurrent === step"></i>
                  <i class="fas fa-circle text-default pointer" @click="stepCurrent = step" v-else-if="stepCompleted >= step"></i>
                  <i class="fas fa-circle text-secondary" v-else></i>

                </span>

              </div>
              <div class="col" v-if="stepCurrent < 4">

                  <base-button type="default" block v-show="stepCurrent === 3 && cronValid" @click="createTask">
                    Create
                  </base-button>
                  <base-button type="default" block disabled v-show="stepCurrent === 3 && !cronValid">
                    Create
                  </base-button>

                  <div v-if="stepCurrent < 3">

                    <base-button type="default" block v-if="canProgress()" @click="completeStep()">
                      Next
                    </base-button>
                    <base-button type="default" block disabled v-else>
                      Next
                    </base-button>

                  </div>

              </div>

            </div>

        </modal>

        <modal
            :show.sync="modals.delete"
            v-if="deleteRef"
            gradient="danger"
            modal-classes="modal-danger modal-dialog-centered"
            >

            <div class="py-3 text-center">
                <i class="fad fa-bell fa-4x"></i>
                <h2 class="display-2 text-white mt-4">Are you sure?</h2>
                <p>Deleting this webhook will mean that any services using it will fail to work going forward.</p>
            </div>

            <template slot="footer">
                <base-button type="white" @click="deleteTask">Yes</base-button>
                <base-button type="link" text-color="white" class="ml-auto" @click="closeModal">
                Close
                </base-button>
            </template>
        </modal>

    </div>
</template>
<script>

import firebase from 'firebase/app'
import { db } from '@/firebase';
import store from '@/store.js'
import { mapGetters } from "vuex";
import { randomString } from "@/components/stringUtils"
import { getOrdinal,replaceLastComma } from "@/plugins/myReflectionsCommon"
import Modal from "@/components/Modal.vue";
const timezones = require('timezones-list');
const parser = require('cron-parser');


import _ from "lodash";

import reflectionTypes from "@/models/reflectionTypes";
import baseSchedule from "@/models/baseSchedule";
import baseReflection from "@/models/baseReflection";

export default {
    name: 'schedule-management',
    // props: [],
    components: { 
        Modal
    },

    computed: {

        ...mapGetters({
            // map `this.user` to `this.$store.getters.user`
            // map `this.groups` to `this.$store.getters.groups`
            user: "user",
            groups: "groups"
        })

    },

    data () {
        return {
            timezones: timezones.default,
            cronValid: false,
            task: _.cloneDeep(baseSchedule),
            reflectionType: null,
            loaded: false,
            scheduledTasks: [],
            viewing: null,
            deleteRef: null,
            stepCompleted: 0,
            stepCurrent: 1,
            modals: {
                create: false,
                delete: false
            },
            reflectionTypes
        }
    },

    mounted() {
        this.buildTask();
        this.getScheduledTasks();
    },

    methods: {

        canProgress(){

            if(this.stepCurrent >= 1){

                if(!this.task.document.activityName){
                    return false;
                }

            }

            return true;

        },

        completeStep(){

            if(this.stepCurrent >= this.stepCompleted){
                this.stepCompleted++;
            }
            this.stepCurrent++;

        },

        autoActivityName(name) {
            let autoActivityNames = ["sleep", "journal entry", "daily checkin"];
            return autoActivityNames.includes(name);
        },

        setReflectionType(rt) {

            this.hideModal = false;
            this.reflectionType = rt;
            this.task.document.reflectionType = rt.name;
            if (this.autoActivityName(rt.name)) {
                this.task.document.activityName = rt.name;
                this.completeStep();
            }
            this.completeStep();

        },

        buildTask(){

            this.task.collection = 'reflections';
            this.task.recurring = true;
            this.task.timezone = this.$dayjs.tz.guess();
            this.task.status = 'scheduled';
            this.task.uid = this.user.data.uid;
            this.task.document = _.cloneDeep(baseReflection);
            this.task.document.uid = this.user.data.uid;
            this.task.document.scheduled = true;

        },

        getReflectionTypeImage(type) {

            let rt = this.reflectionTypes.find(element => element.name === type)
            return rt.image

        },

        getPeriodName(period){

            let periods = [
                "daily",
                "weekly",
                "monthly",
                "yearly"
            ];

            return periods[period]

        },

        splitTime(){
            const split_time = this.task.schedule.time.split(":");
            return split_time;
        },

        grabDays(){

            let days = []

            if(this.task.schedule.days.mon){
                days.push('MON')
            }
            if(this.task.schedule.days.tue){
                days.push('TUE')
            }
            if(this.task.schedule.days.wed){
                days.push('WED')
            }
            if(this.task.schedule.days.thu){
                days.push('THU')
            }
            if(this.task.schedule.days.fri){
                days.push('FRI')
            }
            if(this.task.schedule.days.sat){
                days.push('SAT')
            }
            if(this.task.schedule.days.sun){
                days.push('SUN')
            }

            return days.join(",");

        },

        grabDaysLabel(schedule){

            let days = []

            if(schedule.mon){
                days.push(' Mon')
            }
            if(schedule.tue){
                days.push(' Tue')
            }
            if(schedule.wed){
                days.push(' Wed')
            }
            if(schedule.thu){
                days.push(' Thu')
            }
            if(schedule.fri){
                days.push(' Fri')
            }
            if(schedule.sat){
                days.push(' Sat')
            }
            if(schedule.sun){
                days.push(' Sun')
            }

            days = days.join(",");
            return replaceLastComma(days,' &');

        },

        grabDayNums(){

            let days = []
            for (let i = 0; i < 31; i++) {
                if(this.task.schedule.dayNums[i]){
                    days.push(i)
                }
            }

            return days.join(",");

        },

        grabDayNumsLabel(schedule){

            let days = []
            for (let i = 0; i < 31; i++) {
                if(schedule[i]){
                    days.push(' ' + getOrdinal(i))
                }
            }

            days = days.join(",");
            return replaceLastComma(days,' &');

        },

        grabMonths(){

            let months = []

            if(this.task.schedule.months.jan){
                months.push('JAN')
            }
            if(this.task.schedule.months.feb){
                months.push('FEB')
            }
            if(this.task.schedule.months.mar){
                months.push('MAR')
            }
            if(this.task.schedule.months.apr){
                months.push('APR')
            }
            if(this.task.schedule.months.may){
                months.push('MAY')
            }
            if(this.task.schedule.months.jun){
                months.push('JUN')
            }
            if(this.task.schedule.months.jul){
                months.push('JUL')
            }
            if(this.task.schedule.months.aug){
                months.push('AUG')
            }
            if(this.task.schedule.months.sep){
                months.push('SEP')
            }
            if(this.task.schedule.months.oct){
                months.push('OCT')
            }
            if(this.task.schedule.months.nov){
                months.push('NOV')
            }
            if(this.task.schedule.months.dec){
                months.push('DEC')
            }

            return months.join(",");

        },

        grabMonthsLabel(schedule){

            let months = []

            if(schedule.jan){
                months.push(' Jan')
            }
            if(schedule.feb){
                months.push(' Feb')
            }
            if(schedule.mar){
                months.push(' Mar')
            }
            if(schedule.apr){
                months.push(' Apr')
            }
            if(schedule.may){
                months.push(' May')
            }
            if(schedule.jun){
                months.push(' Jun')
            }
            if(schedule.jul){
                months.push(' Jul')
            }
            if(schedule.aug){
                months.push(' Aug')
            }
            if(schedule.sep){
                months.push(' Sep')
            }
            if(schedule.oct){
                months.push(' Oct')
            }
            if(schedule.nov){
                months.push(' Nov')
            }
            if(schedule.dec){
                months.push(' Dec')
            }

            months = months.join(",");
            return replaceLastComma(months,' &');

        },

        cronExpression(){

            let our_time = this.splitTime();

            // 0 0 0 * * ?
            if(this.task.schedule.period === 0){
                return '0 ' + Number(our_time[1]) + ' ' + Number(our_time[0]) + ' * * ?';
            }

            // 0 0 12 ? * SUN,SAT
            if(this.task.schedule.period === 1){
                return '0 ' + Number(our_time[1]) + ' ' + Number(our_time[0]) + ' ? * ' + this.grabDays();
            }

            // 0 0 12 15 * ?
            if(this.task.schedule.period === 2){
                return '0 ' + Number(our_time[1]) + ' ' + Number(our_time[0]) + ' ' + this.grabDayNums() + ' ' + ' * ?';
            }

            // 0 0 12 ? JAN *
            // 0 0 7 ? * MON#1 *
            if(this.task.schedule.period === 3){
                // return '0 0 7 ? * MON#1 *';
                return '0 ' + Number(our_time[1]) + ' ' + Number(our_time[0]) + ' ' + this.grabDayNums() + ' ' + this.grabMonths() + ' *';
            }

        },

        nextRun(){

            var options = {
                tz: this.task.timezone
            };

            try {
                var interval = parser.parseExpression(this.cronExpression(),options);
                this.cronValid = true;
                return interval.next().toString();

            } catch (err) {

                this.cronValid = false;
                // console.log('Error: ' + err.message);
            }

        },

        closeModal(){
            this.modals.create = false;
            this.modals.delete = false;
        },

        getScheduledTasks(){

            const vm = this

            var docRef = db.collection("scheduledTasks");
            docRef = docRef.where("uid", "==", vm.user.data.uid);
            docRef.onSnapshot(async function (docs) {
                vm.loaded = true;
                docs.docChanges().forEach(function (change) {
                    let { newIndex, oldIndex, doc, type } = change;
                    if (type === "added") {
                        vm.scheduledTasks.splice(newIndex, 0, doc.data());
                    } else if (type === "modified") {
                        vm.scheduledTasks.splice(oldIndex, 1);
                        vm.scheduledTasks.splice(newIndex, 0, doc.data());
                    } else if (type === "removed") {
                        vm.scheduledTasks.splice(oldIndex, 1);
                    }
                });
            });

        },

        showDeleteModal(id){

            this.deleteRef = id;
            this.modals.delete = true;

        },

        deleteTask() {

            const vm = this
            let doc = this.deleteRef;

            db.collection("scheduledTasks").doc(doc).delete().then(() => {

                vm.closeModal();
                vm.deleteRef = null;

                vm.$toast.open({
                    message: 'Scheduled reflection deleted!',
                    type: 'success',
                    // all of other options may go here
                });

            }).catch((error) => {
                // console.error("Error removing document: ", error);
            });

        },

        createTask() {

            const vm = this

            var scheduledTask = db.collection("scheduledTasks").doc();
            let id = scheduledTask.id

            this.task.cronExpression = this.cronExpression();

            let ourDate = new Date(this.nextRun());
            this.task.performAt = new firebase.firestore.Timestamp.fromDate(ourDate);

            this.task.id = id;

            if(this.task.document.reflectionType !== 'daily checkin'){
                this.task.document.dailyCheckinQuestions = null;
            }

            scheduledTask.set(this.task)
            .then(function(response) {

                // vm.api_key = api_key
                // vm.webhookDoc = webhookMap
                vm.modals.create = false

                vm.$toast.open({
                    message: 'Scheduled reflection created!',
                    type: 'success',
                    // all of other options may go here
                });

            })
            .catch(function(error) {

                // console.log(error)

                vm.$toast.open({
                    message: 'Something went wrong!',
                    type: 'error',
                    duration: 10000,
                    // all of other options may go here
                });

            });


        }

    }
};
</script>
<style scoped>

.textshadow .blurry-text {
   text-shadow: 2px 2px 5px green;
   color: transparent;
}


.textarea-placeholder::placeholder {
  padding-top: 25px;
}

.action_active {
  border: 2px dashed #8d6c9f;
  border-radius: 25px;
}

.bg-rt {
  background: #e6dfea;
}

.img-rt {
  /* position: relative; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  transition: transform 0.2s;
}

.img-rt-selection:hover {
  transform: scale(1.1);
}

.img-report {
  position: relative;
  top: 0;
  left: 0;
  width: 35px;
  height: 35px;
}

.label-rt {
  font-size: 0.7em;
}

.img-hlll {
  width: 75px;
  height: 75px;
}

.img-rating {
  position: absolute;
  top: 57px;
  margin: 0 0 0 -14px;
  width: 30px;
  height: 30px;
}

</style>