<template>

    <div>

        <div @mouseover="enterShowReactions" @mouseleave="leaveShowReactions" class="d-lg-block d-none">

            <span class="badge badge-white badge-count mr-2" v-if="!myReaction && !showReactions">
                <img src="/img/reactions/love.svg" class="reaction-count-image reaction-muted pointer" @click="enterShowReactions">
                <span class="ml-1 mr-2 text-default">
                    {{ totalReactions }}
                </span>
            </span>
            <!-- <span class="alert alert-primary badge-total font-weight-900 p-1 rounded-circle">
                {{ totalReactions }}
            </span> -->
            <span v-for="(reaction,ri) in allReactions" v-bind:key="ri" class="pointer">

                <span v-if="reaction.count > 0 || showReactions" class="badge badge-white mr-2 badge-count" @click="saveReaction(reaction,ri)" v-b-popover.hover.top="reaction.label">
                    <span>
                        <img
                            :src="reaction.image"
                            :class="getReactionCountClass(reaction)"
                        >
                    </span>
                    <span class="ml-1 mr-2 text-default">
                        <b>{{ reaction.count }}</b>
                    </span>
                </span>
                
            </span>

        </div>

        <div class="d-lg-none d-block">

            <base-dropdown direction="up">
                <a role="button" data-toggle="dropdown" slot="title">
                    <span class="badge badge-white badge-count mr-2 pointer">
                        <img :src="myReaction.image" class="reaction-count-image pointer" v-if="myReaction && myReaction.image">
                        <img src="/img/reactions/love.svg" class="reaction-count-image reaction-muted pointer" v-else>
                        <span class="ml-1 mr-2 text-default">
                            {{ totalReactions }}
                        </span>
                    </span>
                </a>
                <a class="dropdown-item pointer" role="button" v-for="(reaction,ri) in allReactions" v-bind:key="ri" @click="saveReaction(reaction,ri)">
                    <span>
                        <img
                            :src="reaction.image"
                            :class="getReactionCountClass(reaction)"
                        >
                    </span>
                    <span>
                        {{ reaction.label }}
                    </span>
                    <span class="badge badge-default ml-1 mr-2 text-default">
                        {{ reaction.count }}
                    </span>
                </a>
            </base-dropdown>

        </div>

    </div>
</template>
<script>

import firebase from 'firebase/app'
import { db } from '@/firebase';

import store from '@/store.js'
import { mapGetters } from "vuex";

import reactionTypes from "@/models/reactionTypes";

import BaseDropdown from "@/components/BaseDropdown";
import { VBPopover } from "bootstrap-vue/esm/directives/popover/popover";

export default {
    name: 'reactions',
    props: ['post'],

    directives: {
        BPopover: VBPopover
    },

    components: {
        BaseDropdown
    },

    mounted() {
      // this.user = this.currentUser;
      if(this.post.source === 'archive'){
          this.recalculateReactions()
      }
    },


    watch: {

        // post(){
        //     // this.setReactions()
        // }

    },

    data () {
        return {
            // data here
            showReactions: false,
            reactionTypes: reactionTypes,
            // myReaction: null,
            recalculatedReactions: null
        }
    },
    computed: {

        ...mapGetters({
            // map `this.user` to `this.$store.getters.user`
            // map `this.groups` to `this.$store.getters.groups`
            user: "user",
            groups: "groups"
        }),

        totalReactions(){

            let count = 0

            if(!this.allReactions){
                return count
            }

            var tmpReactions = this.allReactions;
            tmpReactions.forEach((reaction,ri) => {
                count += reaction.count
            });

            return count

        },

        myReactionArray(){

            if(this.post.memberReactions){

                return this.post.memberReactions.filter(reaction => {
                    return reaction.uid === this.user.data.uid
                })

            }

        },

        myReaction(){

            if(!this.myReactionArray){
                return null
            }
            return this.myReactionArray[0]

        },

        allReactions(){
            if(!this.recalculatedReactions){
                return this.post.reactions
            }else{
                return this.recalculatedReactions
            }
        }

    },
    methods: {

        recalculateReactions(){

            if(this.post.memberReactions.length === 0){
                return
            }

            this.recalculatedReactions = JSON.parse(JSON.stringify(this.post.reactions));

            var tmpReactions = this.post.memberReactions;
            tmpReactions.forEach((reaction,ri) => {
                
                this.recalculatedReactions[reaction.index].count++

            });

        },

        gotReaction(){

            if(this.myReaction){
                return true
            }

            return false

        },

        enterShowReactions(){
            this.showReactions = true
        },

        leaveShowReactions(){
            this.showReactions = false
        },

        getReactionClass(reaction){

            if(this.myReaction && reaction.index === this.myReaction.index){
                return 'reaction-image pointer mr-1'
            }

            return 'reaction-selection reaction-image pointer mr-1'

        },

        getReactionCountClass(reaction){

            if(reaction.count > 0){
                return 'reaction-count-image mr-1'
            }else{
                return 'reaction-selection reaction-count-image pointer mr-1'
            }

        },

        // setReactions(){

        //     this.myReaction = null
        //     const vm = this
            
        //     if(this.post.memberReactions){

        //         var tmpReactions = this.post.memberReactions;
        //         tmpReactions.forEach((reaction,ri) => {
        //             if(reaction.uid === vm.user.data.uid){
        //                 // vm.myReaction = JSON.parse(JSON.stringify(reaction));
        //             }
        //         });

        //     }

        // },

        subtractReaction(newPost,index){

            newPost.reactions[index].count -= 1

            let memberReactions = newPost.memberReactions
            memberReactions.forEach((reaction,ri) => {
                if(reaction.uid === this.user.data.uid){
                    newPost.memberReactions.splice(ri,1)
                }
            });

            return newPost

        },

        addReaction(newPost,index){
            newPost.reactions[index].count += 1
            return newPost
        },

        saveReaction(reaction,index){

            const vm = this

            let ourDate = new Date();
            let timeStamp = new firebase.firestore.Timestamp.fromDate(ourDate) 

            let newPost = JSON.parse(JSON.stringify(vm.post));
            if(!newPost.memberReactions){
                newPost.memberReactions = []
            }

            if(vm.myReaction){
                newPost = vm.subtractReaction(newPost,vm.myReaction.index)
            }

            if(!vm.myReaction || (vm.myReaction && vm.myReaction.index !== index)){
                newPost = vm.addReaction(newPost,index)
                newPost.memberReactions.push({ ...reaction , uid: vm.user.data.uid, fullName : vm.user.data.fullName, creationTime : timeStamp})
            }

            db.collection("groupPosts").doc(newPost.id).set(newPost)
            .then(function(docRef) {

                vm.$analytics.logEvent('post_reaction', {
                    postId: newPost.id,
                    groupUid: newPost.groupUid,
                    uid: vm.user.data.uid,
                    fullName : vm.user.data.fullName,
                    creationTime : timeStamp,
                    reaction : reaction.name
                });

            })
            .catch(function(error) {

                // console.log(error)

                vm.$toast.open({
                    message: 'Something went wrong!',
                    type: 'error',
                    duration: 10000,
                    // all of other options may go here
                });

            });

        }

    }
};
</script>

<style scoped>

.badge-total{
    position: absolute;
    left: -25px;
    margin-top: 3px;
    padding-left: 10px !important;
    padding-right: 10px !important;
    border-radius: 15%;
}

.badge-count{
    border-radius: 24px;
}

.reaction-count-image{
    width: 24px;
    height: 24px;
}

.reaction-image{
    width: 32px;
    height: 32px;
}

.reaction-selection{
    /* filter: grayscale(90%); */
    opacity: 0.6;
}

.reaction-selection:hover {
    /* filter: grayscale(0%); */
    opacity: 1;
}

.reaction-muted{
    filter: grayscale(100%);
    opacity: 0.5;
}

.reaction-muted:hover {
    /* filter: grayscale(0%); */
    opacity: 1;
}

</style>