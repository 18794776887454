<template>
  <div> <!-- v-touch:swipe.left="swipeLeftHandler" -->
    <div class="container" v-if="user.data">
      <div class="row justify-content-center">

        <div class="col-12 mt-3">
          <heart-beat
            ref="reportHeartBeat"
            name="heart beat"
            :reflections="sortedReflections"
          ></heart-beat>

          <goals
            ref="reportGoals"
            :goals="$attrs.goals"
            :reflections="sortedReflections"
            :reflection-types="reflectionTypes"
            v-if="$attrs.loaded.goals"
          ></goals>

          <tasks
            ref="reportTasks"
            :tasks="$attrs.tasks"
            :reflection-types="reflectionTypes"
            v-if="$attrs.loaded.tasks"
          ></tasks>

          <feed-view
            ref="reportFeedPanel"
            name="feed view"
            :actions-allowed="true"
            :current-user="user.data"
            :reflection-types="reflectionTypes"
            :reflections="sortedContent"
            :loaded="$attrs.loaded.reflections"
          ></feed-view>

          <div class="container" ref="loadMoreDiv" v-if="reflectionCount === 20 && !$attrs.archiveFullyLoaded">
            <div class="row mt-0">
              <div class="col-12 p-0 mb-5">

                <skeleton-reflection-card />

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="container" v-if="!user || loading">
      <div class="row justify-content-center mt-4">
        <div class="col-12 text-center">
          <div class="alert alert-default">
            <i class="fad fa-spinner-third fa-4x fa-spin"></i>
            <h5 class="text-white mt-2">Looking for your reflections</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Card from "@/components/Card";
// import Reflection from "@/components/reflections/Reflection";
// import ChartView from "@/components/reflections/ChartView";
// import CardView from "@/components/reflections/CardView";
import FeedView from "@/components/reflections/FeedView";
import HeartBeat from "@/components/reflections/HeartBeat";
import Goals from "@/components/goals/Goals";
import Tasks from "@/components/tasks/Tasks";
import firebase from "firebase/app";
import { db } from "@/firebase";
import store from "@/store.js";
import { mapGetters } from "vuex";
import md5 from "js-md5";
import reflectionTypes from "@/models/reflectionTypes";

import SkeletonReflectionCard from "@/components/skeleton/ReflectionCard";

export default {
  name: "reflect-now",
  metaInfo: {
    title: "Reflect Now",
  },
  components: {
    // Reflection,
    FeedView,
    HeartBeat,
    Goals,
    Tasks,
    SkeletonReflectionCard
  },

  data() {
    return {
      showFilter: false,
      loadedPage: 2,
      // reflections: [],
      notifications: [],
      reflectionTypes: reflectionTypes,
      filteringTags: false,
      tags: [],
      loading: false,
      lockSwipe: false,
      today: null,
      fullyLoaded: false

    };
  },

  mounted() {
    this.$watch("$refs.reflectPanel.reflectionType", (new_value, old_value) => {
      if (new_value) {
        this.lockSwipe = true;
      } else {
        this.lockSwipe = false;
      }
    });

    this.$watch("$refs.reportBasicPanel.lockSwipe", (new_value, old_value) => {
      if (new_value) {
        this.lockSwipe = true;
      } else {
        this.lockSwipe = false;
      }
    });

    this.$watch("$refs.reflectionDetails.action", (new_value, old_value) => {
      if (new_value) {
        this.lockSwipe = true;
      } else {
        this.lockSwipe = false;
      }
    });

    this.$watch("$refs.reportFeedPanel.action", (new_value, old_value) => {
      if (new_value) {
        this.lockSwipe = true;
      } else {
        this.lockSwipe = false;
      }
    });

    if (this.tags.length > 0) {
      this.buildTags();
    } else {
      setTimeout(this.buildTags, 2000);
    }

    this.setToday();
  },

  watch: {
    groups() {
      this.buildTags();
      //console.log(new_value,old_value)
    }

  },

  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      // map `this.groups` to `this.$store.getters.groups`
      user: "user",
      groups: "groups",
    }),

    reflectionCount(){
      return this.$attrs.reflections.length;
    },

    activeTags() {
      return this.tags.filter((tag) => {
        return tag.status === "active";
      });
    },

    filteredTags() {
      return this.activeTags.filter((tag) => {
        return tag.visible === true;
      });
    },

    filteredReflectionTypes() {
      return this.reflectionTypes.filter((type) => {
        return type.visible === true;
      });
    },

    filteredReflections() {

      if (this.$attrs.reflections) {
        var tmpReflections = this.$attrs.reflections;
        var tmpTags = this.filteredTags;
        var tmpTypes = this.filteredReflectionTypes;
        var filteredFirst = [];
        var filteredSecond = [];

        tmpReflections.forEach((filter) => {
          tmpTypes.forEach((record) => {
            if (filter.reflectionType === record.name) {
              filteredFirst.push(filter);
            }
          });
        });

        if (this.activeTags.length > 0 && this.filteringTags) {
          filteredFirst.forEach((filter) => {
            if (!filter.tag || filter.tag === "none") {
              filteredSecond.push(filter);
            } else {
              tmpTags.forEach((record) => {
                if (filter.tag === record.name) {
                  filteredSecond.push(filter);
                }
              });
            }
          });

          return filteredSecond;

        } else {
          
          return filteredFirst;

        }

      } else {
        return [];
      }
    },

    mergedReflections(){
      return this.filteredReflections
        .concat(this.$attrs.reflectionsArchive);
    },

    sortedReflections() {
      var content = this.mergedReflections;
      return content.sort((b, a) => (a.creationSeconds > b.creationSeconds ? 1 : -1));
    },

    mergedContent() {
      return this.mergedReflections
        .concat(this.$attrs.groupPosts)
        .concat(this.$attrs.notifications);
    },

    sortedContent() {
      var content = this.mergedContent;
      return content.sort((b, a) => (a.creationSeconds > b.creationSeconds ? 1 : -1));
    },

    entryAllowed() {
      if (this.user.subscription.status === 1) {
        return true;
      } else if (this.user.subscription.status === 0) {
        if (!this.user.data.reflectionToday) {
          return true;
        }

        if (
          (this.user.data.reflectionToday.date === this.today &&
            this.user.data.reflectionToday.reflections < 5) ||
          this.user.data.reflectionToday.date !== this.today
        ) {
          return true;
        }
      }

      return false;
    },

  },

  methods: {

    setToday() {
      this.today = this.$dayjs().format("YYYY-MM-DD");
    },

    // swipeLeftHandler() {
    //   if (!this.$isDesktop() && !this.lockSwipe) {
    //     this.$router.replace("weekly");
    //   }
    // },

    getTagClass(tag) {
      if (tag.scope === "group") {
        if (tag.visible === true) {
          return "pointer badge badge-pill badge-dark mb-2";
        } else {
          return "pointer badge badge-pill badge-light text-dark mb-2";
        }
      } else {
        if (tag.visible === true) {
          return "pointer badge badge-pill badge-default mb-2";
        } else {
          return "pointer badge badge-pill badge-light text-default mb-2";
        }
      }
    },

    buildTags() {
      const vm = this;

      vm.tags = [];
      let tmpTags = [];

      if (vm.user.data.tags) {
        tmpTags = JSON.parse(JSON.stringify(vm.user.data.tags));

        // Add our tmpPersonalTags
        tmpTags.forEach((tag, ti) => {
          tag.scope = "personal";
          tag.visible = true;
          vm.tags.push(tag);
        });
      }

      let tmpGroups = [];
      if (vm.groups) {
        tmpGroups = JSON.parse(JSON.stringify(vm.groups));
        // Cycle through our groups
        tmpGroups.forEach((group, gi) => {
          // Add our tmpGroupTags
          var tmpGroupTags = JSON.parse(JSON.stringify(group.tags));
          tmpGroupTags.forEach((tag, t2i) => {
            tag.groupId = group.id;
            tag.groupName = group.name;
            tag.label = tag.name;
            tag.name = group.id + "#" + tag.name;
            tag.visible = true;
            vm.tags.push(tag);
          });
        });
      }

      // vm.getGroupPosts()
      // vm.getNotifications()
    },

    setTypeFilters(mode) {
      this.updateTypeFilter("--" + mode + "--");
    },

    setTagFilters(mode) {
      this.updateTagFilter({
        name: "--" + mode + "--",
      });
    },

    updateTagFilter(tag) {
      this.filteringTags = true;

      let name = tag.name;
      let label = tag.name;
      if (tag.scope === "group") {
        label = tag.label;
      }

      const vm = this;

      var tmpTags = vm.tags;
      tmpTags.forEach(function (t, i) {
        if (name === "--all--") {
          vm.filteringTags = false;
          vm.tags[i].visible = true;
        } else if (name === "--none--") {
          vm.tags[i].visible = false;
        } else if (t.name === name || t.name === label) {
          vm.tags[i].visible = !vm.tags[i].visible;
        }
      });
    },

    updateTypeFilter(name) {
      const vm = this;

      var tmpReflectionTypes = vm.reflectionTypes;
      tmpReflectionTypes.forEach(function (t, i) {
        if (name === "--all--") {
          vm.reflectionTypes[i].visible = true;
        } else if (name === "--none--") {
          vm.reflectionTypes[i].visible = false;
        } else if (t.name === name) {
          vm.reflectionTypes[i].visible = !vm.reflectionTypes[i].visible;
        }
      });
    }

  },
  // ,
  // firestore() {
  //     return {
  //         reflections: db.collection('reflections').where('uid', '==', this.user.data.uid).orderBy('creationSeconds', "desc").limit(20),
  //         // groupPosts: db.collection("groupPosts").where("groupUid", 'in', this.groupIds).orderBy("creationSeconds", "desc").limit(20)
  //     }
  // }
};
</script>

<style>
.img-filter {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 35px;
  height: 35px;
}

.unselected {
  filter: grayscale(100%);
  opacity: 0.3;
}
</style>
