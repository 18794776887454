<template>
    <div>

        <div class="row justify-content-center text-center pl-3 pr-3 mb-2">

            <div class="col-12" v-show="attachment.title || attachment.summary">
                <p class="card-title mb-1 text-default font-weight-bold" v-show="attachment.title">{{ attachment.title }}<br /></p>
                <p v-show="attachment.summary">{{ attachment.summary }}</p>
            </div>

            <div class="col-lg-6 col-12">

                <div v-if="!reqData">

                    <card class="m-4" :type="record.class ? record.class : 'default'" v-for="(record, index) in attachment.data" v-bind:key="index">

                        <div class="rotate" v-if="record.icon">
                            <i class="fad fa-4x" :class="'fa-' + record.icon"></i>
                        </div>

                        <p class="m-0 text-white">{{ record.label}}</p>
                        <h2 class="text-white">{{ record.prefix }}
                            {{ record.value ? formatValue( record.value, record.format ) : 'N/A' }}
                        </h2>

                    </card>

                </div>

                <div v-else>

                    <p>The following information has been requested</p>

                    <card class="m-4" :type="record.class ? record.class : 'default'" v-for="(record, index) in attachment.data" v-bind:key="index">

                        <div class="rotate" v-if="record.icon">
                            <i class="fad fa-4x" :class="'fa-' + record.icon"></i>
                        </div>

                        <p class="m-0 text-white">{{ record.label}}</p>
                        <input type="number" class="text-center form-control form-control-lg" :placeholder="'Please enter your ' + record.label" v-model.number="localData.data[index].value">

                    </card>

                </div>

                <div class="mt-4 mb-4" v-if="(attachment.displayTable && !editEnabled) || (attachment.displayTable && !attachment.allowDataEdits && !editEnabled)">

                    <table class="table table-sm small">
                        <thead>
                            <tr>
                                <th>
                                    Label
                                </th>
                                <th>
                                    Value
                                </th>
                            </tr>
                        </thead>
                        <tbody> 
                            <tr v-for="(record, index) in attachment.data" v-bind:key="index">
                                <td>
                                    {{ record.label }}
                                </td>
                                <td>
                                    {{ record.prefix }}{{ record.value }}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>

                <div class="mt-4 mb-4" v-else-if="showEdit">

                    <table class="table table-sm small" v-if="localData">
                        <thead>
                            <tr>
                                <th>
                                    Label
                                </th>
                                <th>
                                    Value
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(record, index) in attachment.data" v-bind:key="index">
                                <td>
                                    {{ record.label }}
                                </td>
                                <td>
                                    <input type="number" class="form-control form-control-sm" v-model="localData.data[index].value">
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>

                

            </div>

        </div>
    </div>
</template>
<script>

import _ from "lodash";

export default {
    name: 'embed-metrics',
    props: ['attachment','id','index','readOnly'],
    components: {
        // Pie,
        // PieChart
    },
    mounted() {
       this.reqData = this.requestDataChecker();
    },

    data () {
        return {
            localData: _.cloneDeep(this.attachment),
            newData: false,
            editEnabled: false,
            reqData: false
        }
    },
    computed: {

        showEdit() {

            if(this.readOnly){
                return false
            }

            if(this.attachment.allowDataEdits && this.editEnabled){
                return true
            }

            // if(this.reqData){
            //     return true
            // }

            return false;
        }

    },

    watch: {

        attachment: {
            handler: function () {
                setTimeout(this.resetNewData,200);
            },
            deep: true,
        }

    },

    methods: {

        requestDataChecker(){

            if(this.readOnly){
                return false
            }

            if(this.attachment.requestData && this.checkForValues()){
                return true
            }

            return false
        },

        formatValue( value, format ){

            if(!value || !format){
                return value
            }

            if(format === 'money'){
                return parseFloat(value).toFixed(2);
            }

            return value

        },

        checkForValues(){

            let data = this.localData.data
            let dataReq = false

            data.forEach((record,i) => {

                if(!record.value){
                    dataReq = true
                }

            });

            return dataReq

        },

        resetNewData(){
            this.newData = false;
        },

        updateData(){

            let docId = this.id;
            let attachmentMap = this.localData
            this.newData = true;

            this.sendUpdate(attachmentMap,this.index);
            this.editEnabled = false;

            this.$toast.open({
                message: "Data Updated!",
                type: "success",
                // all of other options may go here
            });

        },

        sendUpdate(attachment,index){
            this.$emit('updateAttachment', attachment, index)
        }

    }
};
</script>

<style scoped>

.card-body .rotate i {
  color: rgba(255, 255, 255, 0.45);
  position: absolute;
  left: 0;
  left: auto;
  right: -5px;
  bottom: 0;
  display: block;
  -webkit-transform: rotate(-44deg);
  -moz-transform: rotate(-44deg);
  -o-transform: rotate(-44deg);
  -ms-transform: rotate(-44deg);
  transform: rotate(-44deg);
}

</style>