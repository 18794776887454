<template>
  <div
    v-if="user"
  >
    <div class="container" v-if="group" @click="getTab">
      <div class="row justify-content-center">
        <div class="col-12 mt-3">
          <!-- Show Members right of the group on desktop -->
          <div
            class="float-lg-right mb-2 d-lg-block d-none"
            v-if="group && group.members && (user.data.uid === group.ownerId || group.groupType === 'open')"
          >
            <img
              :alt="member.fullName"
              :title="member.fullName"
              :src="member.photoURL"
              class="avatar rounded-circle img-member ml-2 pointer"
              :class="visibilityClass(member.uid)"
              @click="toggleVisibility(member.uid)"
              v-for="(member, index) in group.members"
              v-bind:key="index"
            />
          </div>

          <h5 class="card-title text-default font-weight-bold mt-2 mb-0" v-if="group">
            {{ group.groupName }}
          </h5>

          <!-- Show Members under the group on mobile -->
          <div class="mt-1 ml-0 mb-2 d-lg-none d-md-block" v-if="group && group.members && (user.data.uid === group.ownerId || group.groupType === 'open')">
            <img
              :alt="member.fullName"
              :title="member.fullName"
              :src="member.photoURL"
              class="avatar avatar-xs rounded-circle border border-2 border-default ml-2 pointer"
              :class="visibilityClass(member.uid)"
              @click="toggleVisibility(member.uid)"
              v-for="(member, index) in group.members"
              v-bind:key="index"
            />
          </div>
        </div>

        <!-- TABS -->
        <div class="col-12 mb-5">

          <tabs ref="groupTabs" pills fill type="primary" :active-tab="defaultTab" :value="defaultTab">

            <tab-pane key="details" title="details">
                <template slot="title">
                    <i class="fad fa-info-circle mr-2"></i> Details
                </template>

                <group-details :group="group"></group-details>

            </tab-pane>


            <tab-pane key="reflections" title="reflections" v-if="user.data.uid === group.ownerId || group.groupType === 'open'">
                <template slot="title">
                    <i class="fad fa-head-side-brain mr-2"></i> Reflections
                </template>

                <card shadow class="p-4" no-body v-if="user">

                  <div class="row">
                      <div class="col-12">

                        <div class="float-right">
                            <a class="btn btn-sm btn-default text-white" role="button" @click="createReflection = true;">
                                <i class="fad fa-head-side-brain"></i> New
                            </a>
                        </div>

                        <div class="float-left text-center mb-4">
                          <span
                            class="badge badge-default pl-1 mr-1 p-2 pointer"
                            @click="setNewDate(-1)"
                          >
                            <i class="fas fa-chevron-left"></i>
                          </span>
                          <span class="badge badge-default p-2 d-md-inline d-none">
                            {{ $dayjs(startDate).format("ddd, MMM Do") }}
                              - 
                            {{ $dayjs(endDate).format("MMM Do YYYY") }}
                          </span>
                          <span
                            class="badge badge-default ml-2 p-2 pointer"
                            @click="setNewDate(1)"
                            v-if="endDate < today"
                          >
                            <i class="fas fa-chevron-right"></i>
                          </span>
                        </div>

                      </div>

                  </div>

                  <div v-if="createReflection">

                    <group-reflection
                      ref="reflectPanel"
                      name="reflection"
                      :group="group"
                      :current-user="user.data"
                    ></group-reflection>

                    <div class="row justify-content-center mt-5 mb-5">
                      <a role="button" @click="createReflection = false" class="pointer" title="Clear">
                        <icon name="fal fa-times" size="lg" type="danger" shadow rounded></icon>
                      </a>
                    </div>

                  </div>
                    
                  <div v-if="!createReflection">

                    <div v-if="!groupState">
                      <div class="alert alert-default p-4 text-center">
                        <i class="fad fa-spinner-third fa-4x fa-spin"></i>
                        <h5 class="text-white mt-2" v-if="querying">
                          Downloading your groups reflections
                        </h5>
                        <h5 class="text-white mt-2" v-else-if="building">
                          Pulling it all together for you now!
                        </h5>
                      </div>
                    </div>

                    <feed-view
                      ref="reportCardsPanel"
                      name="reflection cards"
                      :actionsAllowed="false"
                      :current-user="user.data"
                      :reflection-types="reflectionTypes"
                      :reflections="groupReflections"
                      :loaded="loaded"
                      v-if="groupState && groupReflections.length > 0"
                    ></feed-view>

                    <div v-if="groupState && groupReflections.length === 0">
                      <div class="alert alert-default text-center text-default p-5">
                        <!-- <i class="fad fa-analytics fa-3x"></i> -->
                        <span class="fa-stack fa-2x">
                          <i class="fas fa-square fa-stack-2x"></i>
                          <i class="fad fa-analytics fa-stack-1x fa-inverse"></i>
                        </span>
                        <p class="text-white font-weight-bold mt-3 mb-0">
                          We could not find any reflections in the selected reporting week
                        </p>
                        <p class="text-white">
                          Use the data selector to navigate other weeks or ask your group to start
                          reflecting more!
                        </p>
                      </div>
                    </div>

                  </div>

                </card>

            </tab-pane>
<!-- 
            <tab-pane key="feed" title="feed">
                <template slot="title">
                    <i class="fad fa-comment-alt-smile mr-2"></i> Feed
                </template>

                <feed :group-uid="groupId" :visible-members="visibleMembers"></feed>

            </tab-pane> -->

            <tab-pane key="reports" title="reports" v-if="user.data.uid === group.ownerId || group.groupType === 'open'">
                <template slot="title">
                    <i class="fad fa-analytics mr-2"></i> Reports
                </template>

                <card shadow class="p-4" no-body v-if="user">

                    <div class="row">

                        <div class="col-12">

                            <div class="text-center mb-4">

                                <span
                                  class="badge badge-default pl-1 mr-1 p-2 pointer"
                                  @click="setNewDate(-1)"
                                >
                                  <i class="fas fa-chevron-left"></i>
                                </span>
                                <span class="badge badge-default p-2">
                                  {{ $dayjs(startDate).format("ddd, MMM Do") }}
                                    - 
                                  {{ $dayjs(endDate).format("MMM Do YYYY") }}
                                </span>
                                <span
                                  class="badge badge-default ml-2 p-2 pointer"
                                  @click="setNewDate(1)"
                                  v-if="endDate < today"
                                >
                                  <i class="fas fa-chevron-right"></i>
                                </span>

                            </div>
                        
                        </div>
                        
                    </div>

                    <div class="row justify-content-center" v-if="!groupState">

                        <div class="col-12">

                            <div class="alert alert-default p-4 text-center">
                                <i class="fad fa-spinner-third fa-4x fa-spin"></i>
                                <h5 class="text-white mt-2" v-if="querying">
                                  Downloading your groups data
                                </h5>
                                <h5 class="text-white mt-2" v-else-if="building">
                                  Building your group reports
                                </h5>
                            </div>

                        </div>

                    </div>

                    <div class="row justify-content-center" v-else-if="groupData.reflections.length > 0">

                        <!-- <div class="col-12"> -->

                            <!-- Base Statistics -->
                            <div class="col-lg-6 col-12 text-center align-middle mb-4">
                              <stats-card
                                title="Total reflections"
                                type="gradient-default"
                                :sub-title="groupData.baseStatistics.totalReflections.toFixed(0)"
                                icon="fad fa-head-side-brain"
                                class="mb-xl-0"
                              >
                              </stats-card>
                            </div>
                            <div class="col-lg-6 col-12 text-center align-middle mb-4">
                              <stats-card
                                title="Average rating"
                                type="gradient-default"
                                :sub-title="groupData.baseStatistics.averageRating.toFixed(2)"
                                icon="fad fa-fist-raised"
                                class="mb-4 mb-xl-0"
                              >
                              </stats-card>
                            </div>
                            <div class="col-lg-6 col-12 text-center align-middle mb-4">
                              <stats-card
                                title="# of Highlights"
                                type="gradient-success"
                                :sub-title="groupData.baseStatistics.totalHighlights.toFixed(0)"
                                icon="fas fa-thumbs-up"
                                class="mb-4 mb-xl-0"
                              >
                              </stats-card>
                            </div>
                            <div class="col-lg-6 col-12 text-center align-middle mb-4">
                              <stats-card
                                title="# of Lowlights"
                                type="gradient-danger"
                                :sub-title="groupData.baseStatistics.totalLowlights.toFixed(0)"
                                icon="fas fa-thumbs-down"
                                class="mb-4 mb-xl-0"
                              >
                              </stats-card>
                            </div>

                            <div class="col-lg-8 col-12">
                              <div
                                class="card p-4 mb-4"
                                v-show="groupData.graphs.reflectionDayMember"
                              >
                                <h6 class="font-weight-bold text-center mb-3">
                                  Reflections by Member by Day
                                </h6>

                                <line-chart-members
                                  :chartData="groupData.graphs.reflectionDayMember.chartData"
                                  :chartLabels="groupData.graphs.reflectionDay.chartLabels"
                                ></line-chart-members>
                              </div>

                              <div class="card p-4 mb-4">
                                <h6 class="font-weight-bold text-center mb-3">
                                  Reflections &amp; Average Ratings by Day
                                </h6>

                                <line-chart
                                  :chartDataRef="groupData.graphs.reflectionDay.chartDataRef"
                                  :chartDataAvg="groupData.graphs.reflectionDay.chartDataAvg"
                                  :chartLabels="groupData.graphs.reflectionDay.chartLabels"
                                ></line-chart>
                              </div>
                            </div>

                            <div class="col-lg-4 col-12">
                              <div class="card p-4 mb-4">
                                <h6 class="font-weight-bold text-center mb-3">
                                  Reflections by Member
                                </h6>
                                <div v-show="groupData.graphs.reflectionMemberSplit.chartData">
                                  <pie-chart
                                    :chartData="groupData.graphs.reflectionMemberSplit.chartData"
                                    :chartLabels="groupData.graphs.reflectionMemberSplit.chartLabels"
                                  ></pie-chart>
                                </div>
                              </div>

                              <div class="card p-4 mb-4">
                                <h6 class="font-weight-bold text-center mb-3">Reflections by Type</h6>
                                <div v-show="groupData.graphs.reflectionSplit.chartData">
                                  <pie-chart
                                    :chartData="groupData.graphs.reflectionSplit.chartData"
                                    :chartLabels="groupData.graphs.reflectionSplit.chartLabels"
                                  ></pie-chart>
                                </div>
                              </div>

                              <div class="card p-4 mb-4">
                                <h6 class="font-weight-bold text-center mb-3">Reflections by Tag</h6>
                                <div v-show="groupData.graphs.reflectionTagSplit.chartData">
                                  <pie-chart
                                    :chartData="groupData.graphs.reflectionTagSplit.chartData"
                                    :chartLabels="groupData.graphs.reflectionTagSplit.chartLabels"
                                  ></pie-chart>
                                </div>
                              </div>
                            </div>

                        <!-- </div> -->

                    </div>

                    <div class="row justify-content-center" v-else>

                      <div class="col-12">

                          <div class="alert alert-default text-center text-default p-5">

                              <!-- <i class="fad fa-analytics fa-3x"></i> -->
                              <span class="fa-stack fa-2x">
                                <i class="fas fa-square fa-stack-2x"></i>
                                <i class="fad fa-analytics fa-stack-1x fa-inverse"></i>
                              </span>
                              <p class="text-white font-weight-bold mt-3 mb-0">
                                We could not find any reflections in the selected reporting week
                              </p>
                              <p class="text-white">
                                Use the data selector to navigate other weeks or ask your group to start
                                reflecting more!
                              </p>

                          </div>

                      </div>

                    </div>

                </card>

            </tab-pane>

          </tabs>

        </div>

      </div>
    </div>

    <div class="container" v-else>
      <div class="row justify-content-center mt-4">
        <div class="col-8 text-center">
          <div class="alert alert-default p-4">
            <i class="fad fa-spinner-third fa-4x fa-spin"></i>
            <h5 class="text-white mt-2">Gathering your group information</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseDropdown from "@/components/BaseDropdown";

import firebase from "firebase/app";
import { db } from "@/firebase";
import store from "@/store.js";
import { mapGetters } from "vuex";

import baseGroupReport from "@/models/baseGroupReport";
import reflectionTypes from "@/models/reflectionTypes";

import Feed from "@/components/mygroups/Feed";
import GroupDetails from "@/components/mygroups/Details";
import GroupReflection from "@/components/mygroups/GroupReflection";

import StatsCard from "@/components/StatsCard";

import VueCharts from "vue-chartjs";
import { Pie, Bar, Line } from "vue-chartjs";
import PieChart from "@/models/pieChart";
import LineChart from "@/models/lineChartGroup";
import LineChartMembers from "@/models/lineChartGroupMembers";

import FeedView from "@/components/reflections/FeedView";

import Modal from "@/components/Modal.vue";
import Tabs from "@/components/Tabs/Tabs.vue";
import TabPane from "@/components/Tabs/TabPane.vue";

// const currentUser = firebase.auth().currentUser;

export default {
  name: "viewgroup",
  metaInfo: {
    title: "Group",
  },
  components: {
    BaseDropdown,
    StatsCard,
    PieChart,
    LineChart,
    LineChartMembers,
    Feed,
    GroupDetails,
    GroupReflection,
    FeedView,
    Modal,
    Tabs,
    TabPane
  },

  data() {
    return {

      modals: {
        reflection: false
      },

      defaultTab: 'details',
      currentTabIndex: 0,
      createReflection: false,
      groupId: null,
      group: null,
      today: null,
      startDate: null,
      startTimestamp: null,
      endDate: null,
      endTimestamp: null,
      visibleMembers: [],
      datesDownloaded: [],
      reflections: [],
      groupReflectionsCheck: [],
      groupReflections: [],
      tags: [],
      querying: false,
      building: true,
      groupData: null,
      reflectionTypes: reflectionTypes,
      currentView: {
          name: "details",
          class: "fad fa-info-circle mr-1",
          label: "Details",
          openAccess: true,
      },
      views: [
        {
          name: "details",
          class: "fad fa-info-circle mr-1",
          label: "Details",
          openAccess: true,
        },
        {
          name: "reflections",
          class: "fad fa-head-side-brain mr-1",
          label: "Reflections",
          openAccess: false,
        },
        // {
        //   name: "feed",
        //   class: "fad fa-comment-alt-smile mr-1",
        //   label: "Feed",
        //   openAccess: true,
        // },
        {
          name: "reports",
          class: "fad fa-analytics mr-1",
          label: "Reports",
          openAccess: false,
        }
      ],
    };
  },

  mounted() {

    this.setGroupId();

  },

  watch: {
    $route(to, from) {
      this.querying = true;
      this.building = true;
      this.groupData = null;
      this.datesDownloaded = [];
      this.setGroupId();
      this.getGroupReflections();
    },

    currentView() {
      this.getGroupReflections();
    },

    startDate() {
      this.getGroupReflections();
    },

    groupId(id) {
      if (!id) {
        return;
      }

      // $bind automatically unbinds the previously bound property
      this.$bind("group", db.collection("groups").doc(id)).then((group) => {
        this.populateVisibleMembers();
      });
      // this.getGroupReflections()
      this.setStartingDates();

    },
  },

  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      // map `this.groups` to `this.$store.getters.groups`
      user: "user",
      groups: "groups",
    }),

    loaded(){
      return !this.building
    },

    groupState() {
      return !this.querying && !this.building;
    },

    subscription() {
      if (this.user.data.subscription) {
        if (this.user.data.subscription.status === 1) {
          return this.user.data.subscription;
        }
      }

      return {
        tier: 0,
        name: "free",
      };
    },

    filteredTags() {
      if (!this.group) {
        return [];
      }

      if (this.group.tags) {
        return this.group.tags.filter((tag) => {
          return tag.status === "active";
        });
      } else {
        return [];
      }
    },

    filteredViews() {
      if (!this.group) {
        return [];
      }

      return this.views.filter((view) => {
        return (
          this.group.ownerId === this.user.data.uid ||
          this.group.groupType === "open" ||
          (this.group.groupType !== "open" && view.openAccess === true)
        );
      });
    },
  },

  methods: {
    // swipeLeftHandler() {
    //   if (!this.$isDesktop()) {
    //     this.$router.replace("/profile");
    //   }
    // },

    // swipeRightHandler() {
    //   if (!this.$isDesktop()) {
    //     this.$router.replace("/weekly");
    //   }
    // },

    openReflection() {
      this.modals.reflection = true;
    },

    getTab(){

      let newIndex = this.$refs.groupTabs.activeTabIndex
      this.currentTabIndex = newIndex
      this.currentView = this.views[newIndex]

    },

    populateVisibleMembers() {
      var tmpMembers = JSON.parse(JSON.stringify(this.group.members));
      tmpMembers.forEach((member, ti) => {
        this.visibleMembers.push({
          uid: member.uid,
          visible: true,
        });
      });
    },

    visibilityClass(uid) {
      if (!this.visibleMembers.some((el) => el.uid === uid && el.visible === true)) {
        return "unselected";
      }
    },

    toggleVisibility(uid) {
      var tmpMembers = JSON.parse(JSON.stringify(this.visibleMembers));
      tmpMembers.forEach((member, ti) => {
        if (member.uid === uid) {
          this.visibleMembers[ti].visible = !this.visibleMembers[ti].visible;
        }
      });

      this.building = true;
      setTimeout(this.buildData, 500);
    },

    setGroupId() {
      if (this.$route.params.guid) {
        if (this.groupId !== this.$route.params.guid) {
          this.groupId = this.$route.params.guid;
        }
      }
    },

    setNewDate(variation) {
      this.startDate = this.$dayjs(this.startDate).add(variation, 'week').format("YYYY-MM-DD");
      this.endDate = this.$dayjs(this.endDate).add(variation, 'week').format("YYYY-MM-DD");
    },

    setStartingDates() {

      this.startDate = this.$dayjs().startOf('isoWeek').format("YYYY-MM-DD");
      this.endDate = this.$dayjs().endOf('isoWeek').format("YYYY-MM-DD");
      this.today = this.$dayjs().format("YYYY-MM-DD");

      this.startTimestamp = new firebase.firestore.Timestamp.fromDate(
        new Date(this.startDate)
      );
      this.endTimestamp = new firebase.firestore.Timestamp.fromDate(
        new Date(this.endDate)
      );
    },

    viewGroup(index) {
      this.entry = this.groups[index];

      if (index === this.view) {
        this.view = null;
      } else {
        this.view = index;
      }
    },

    checkDate(start, end) {
      function isDownloaded(dates) {
        return dates.startDate === start;
      }

      if (typeof this.datesDownloaded.find(isDownloaded) === "object") {
        return true;
      }
      return false;
    },

    getMember(uid){
      return this.group.members.find(u => u.uid === uid);
    },

    getGroupReflections() {

      if (this.currentView.name === "feed" || this.currentView.name === "details" || this.querying) {
        return;
      }

      // If we have already downloaded, don't query BQ again
      if (this.checkDate(this.startDate, this.endDate)) {
        this.building = true;
        setTimeout(this.buildData, 500);
        return;
      }

      const vm = this;
      this.querying = true;

      firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
          user.getIdToken().then(function (idToken) {
            // Get our data!
            vm.bigQuery(idToken);
          });
        }
      });
    },

    bigQuery(idToken) {
      const vm = this;
      const axios = require("axios");

      vm.$analytics.logEvent("group_report_query", {
        groupUid: vm.groupId,
        uid: vm.user.data.uid,
        fullName: vm.user.data.fullName,
        startDate: vm.startDate,
        endDate: vm.endDate,
      });

      // Send token to your backend via HTTPS
      var config = {
        headers: {
          Authorization: "Bearer " + idToken,
        },
      };

      // let url = 'http://localhost:5001/my-reflection-22932/australia-southeast1/app/api/group_report_query?start=' + vm.startDate + '&end=' + vm.endDate + '&group=' + vm.groupId
      let url =
        "https://australia-southeast1-my-reflection-22932.cloudfunctions.net/app/api/group_report_query?start=" +
        vm.startDate +
        "&end=" +
        vm.endDate +
        "&group=" +
        vm.groupId;

      // Make a request for a user with a given ID
      axios
        .get(url, config)
        .then(function (response) {
          // handle success
          vm.convertReflections(response.data);
          //   console.log(response.data);
          vm.datesDownloaded.push({
            startDate: vm.startDate,
            endDate: vm.endDate,
          });
        })
        .catch(function (error) {
          // handle error
          // console.log(error)
        })
        .finally(function () {
          // always executed
          vm.querying = false;
          // vm.building = false
        });
    },

    convertReflections(data) {
      const vm = this;

      let reflectionsCheck = [];
      let index;

      data.forEach(function (reflection, i) {

        let activityDetails = reflection.activityDetails;
        let activityName = reflection.activityName;
        let creationSeconds = parseInt(reflection.creationSeconds);
        let creationTime = reflection.creationTime;
        let createdByWebhook = reflection.createdByWebhook;
        let id = reflection.document_id;
        let groupDocumentId = reflection.groupDocumentId;
        let imageURL = reflection.imageURL;
        let rating = parseInt(reflection.rating);
        let reflectionDate = reflection.reflectionDate;
        let reflectionType = reflection.reflectionType;
        let tag = reflection.tag;
        let tagData = {
          groupId : reflection.tagData_groupId,
          groupName : reflection.tagData_groupName,
          label : reflection.tagData_label,
          scope : reflection.tagData_scope
        }
        let uid = reflection.uid;
        let highlights = [];
        let dailyCheckinQuestions = [];
        let lowlights = [];
        let attachment = [];

        // If we haven't see this reflection yet
        if (reflectionsCheck.indexOf(id) === -1) {

          var rMap = {
            activityDetails: activityDetails,
            activityName: activityName,
            creationSeconds: creationSeconds,
            creationTime: creationTime,
            createdByWebhook: createdByWebhook,
            id: id,
            groupDocumentId: groupDocumentId,
            groupReflection: true,
            imageURL: imageURL,
            rating: rating,
            member: vm.getMember(uid),
            highlights: highlights,
            lowlights: lowlights,
            attachment: attachment,
            dailyCheckinQuestions: dailyCheckinQuestions,
            analysis: null,
            reflectionDate: reflectionDate,
            reflectionType: reflectionType,
            tag: tag,
            tagData: tagData,
            uid: uid,
            source: "bigquery",
          };

          // store our document_id in our check object
          reflectionsCheck.push(id);

          // Create our reflection record
          index = vm.reflections.push(rMap) - 1;
        }

        if (reflection.highlights_member) {
          vm.reflections[index].highlights.push(reflection.highlights_member.replace(/^"(.+(?="$))"$/, '$1'));
        }
        if (reflection.lowlights_member) {
          vm.reflections[index].lowlights.push(reflection.lowlights_member.replace(/^"(.+(?="$))"$/, '$1'));
        }
        if (reflection.dailyCheckinQuestions_member) {
          vm.reflections[index].dailyCheckinQuestions.push(JSON.parse(reflection.dailyCheckinQuestions_member));
        }
        if (reflection.attachment_member) {
          vm.reflections[index].attachment.push(JSON.parse(reflection.attachment_member));
        }
        if (reflection.analysis_score_label) {
          vm.reflections[index].analysis = {
            overall: {
              score_label: reflection.analysis_score_label,
              score_tag: reflection.analysis_score_tag,
              subjectivity: reflection.analysis_subjectivity,
              intensity: {
                compound: reflection.analysis_intensiy_compound,
                neg: reflection.analysis_intensiy_neg,
                neu: reflection.analysis_intensiy_neu,
                pos: reflection.analysis_intensiy_pos
              }
            }
          };
        }


      });

      vm.buildData();
    },

    buildData() {
      // reset our newReport data to the baseReport just in case
      this.groupData = JSON.parse(JSON.stringify(baseGroupReport));
      this.groupReflectionsCheck = []
      this.groupReflections = []

      if (this.filteredTags.length > 0) {
        this.groupData.tags = JSON.parse(JSON.stringify(this.filteredTags));
      }

      var tmpTags = this.groupData.tags;
      tmpTags.forEach((tag, ti) => {
        let tagRecord = {
          name: tag.name,
          type: tag.type,
          totalReflections: 0,
          sumRatings: 0,
          averageRating: 0,
          totalHighlights: 0,
          totalLowlights: 0,
        };
        this.groupData.reflectionsByTag.push(tagRecord);
      });

      var tmpMembers = JSON.parse(JSON.stringify(this.group.members));
      tmpMembers.forEach((member, ti) => {
        if (
          !this.visibleMembers.some((el) => el.uid === member.uid && el.visible === true)
        ) {
          return;
        }

        let memberRecord = {
          fullName: member.fullName,
          name: member.fullName,
          uid: member.uid,
          photoURL: member.photoURL,
          totalReflections: 0,
          sumRatings: 0,
          averageRating: 0,
          totalHighlights: 0,
          totalLowlights: 0,
          reflections: [],
          byDay: [
            {
              name: "Monday",
              totalReflections: 0,
              averageRating: 0,
              sumRatings: 0,
              totalHighlights: 0,
              totalLowlights: 0,
            },
            {
              name: "Tuesday",
              totalReflections: 0,
              averageRating: 0,
              sumRatings: 0,
              totalHighlights: 0,
              totalLowlights: 0,
            },
            {
              name: "Wednesday",
              totalReflections: 0,
              averageRating: 0,
              sumRatings: 0,
              totalHighlights: 0,
              totalLowlights: 0,
            },
            {
              name: "Thursday",
              totalReflections: 0,
              averageRating: 0,
              sumRatings: 0,
              totalHighlights: 0,
              totalLowlights: 0,
            },
            {
              name: "Friday",
              totalReflections: 0,
              averageRating: 0,
              sumRatings: 0,
              totalHighlights: 0,
              totalLowlights: 0,
            },
            {
              name: "Saturday",
              totalReflections: 0,
              averageRating: 0,
              sumRatings: 0,
              totalHighlights: 0,
              totalLowlights: 0,
            },
            {
              name: "Sunday",
              totalReflections: 0,
              averageRating: 0,
              sumRatings: 0,
              totalHighlights: 0,
              totalLowlights: 0,
            },
          ],
        };
        // console.log(memberRecord)
        this.groupData.reflectionsByMember.push(memberRecord);
      });

      const vm = this;

      var tmpReflections = vm.reflections;
      tmpReflections.forEach((reflection, ri) => {
        vm.logReflection(reflection);
      });

      // Add our reflectionsByType
      var tmpRT = vm.groupData.reflectionsByType;
      tmpRT.forEach((refType, rti) => {
        vm.groupData.reflectionsByType[rti].averageRating = Number(
          vm.groupData.reflectionsByType[rti].sumRatings /
            vm.groupData.reflectionsByType[rti].totalReflections
        );
      });

      // Add our reflectionsByTag
      var tmpRT = vm.groupData.reflectionsByTag;
      tmpRT.forEach((tag, ti) => {
        vm.groupData.reflectionsByTag[ti].averageRating = Number(
          vm.groupData.reflectionsByTag[ti].sumRatings /
            vm.groupData.reflectionsByTag[ti].totalReflections
        );
      });

      // Add our reflectionsByDay
      var tmpRD = vm.groupData.reflectionsByDay;
      tmpRD.forEach((refDay, rdi) => {
        vm.groupData.reflectionsByDay[rdi].averageRating = Number(
          vm.groupData.reflectionsByDay[rdi].sumRatings /
            vm.groupData.reflectionsByDay[rdi].totalReflections
        );
      });

      // Get our type pie chart data
      let reflectionsByTypeLabel = Array();
      let reflectionsByTypeData = Array();

      var tmpRT = vm.groupData.reflectionsByType;
      tmpRT.forEach((refType, rti) => {
        reflectionsByTypeLabel.push(refType.name);
        reflectionsByTypeData.push(refType.totalReflections);
      });

      vm.groupData.graphs.reflectionSplit = {
        chartLabels: reflectionsByTypeLabel,
        chartData: reflectionsByTypeData,
      };

      // Get our tag pie chart data
      let reflectionsByTagLabel = Array();
      let reflectionsByTagData = Array();

      var tmpTag = vm.groupData.reflectionsByTag;
      tmpTag.forEach((tag, ti) => {
        reflectionsByTagLabel.push(tag.name);
        reflectionsByTagData.push(tag.totalReflections);
      });

      vm.groupData.graphs.reflectionTagSplit = {
        chartLabels: reflectionsByTagLabel,
        chartData: reflectionsByTagData,
      };

      // Get our member pie chart data
      let reflectionsByMemberLabel = Array();
      let reflectionsByMemberData = Array();

      var tmpMember = vm.groupData.reflectionsByMember;
      tmpMember.forEach((member, ti) => {
        if (
          !this.visibleMembers.some((el) => el.uid === member.uid && el.visible === true)
        ) {
          return;
        }
        reflectionsByMemberLabel.push(member.name);
        reflectionsByMemberData.push(member.totalReflections);
      });

      vm.groupData.graphs.reflectionMemberSplit = {
        chartLabels: reflectionsByMemberLabel,
        chartData: reflectionsByMemberData,
      };

      // Get our line chart data
      let reflectionsByDayLabel = Array();
      let reflectionsByDayDataRef = Array();
      let reflectionsByDayDataAvg = Array();

      var tmpRD = vm.groupData.reflectionsByDay;
      tmpRD.forEach((refDay, rti) => {
        let average = refDay.averageRating;
        if (isNaN(average)) {
          average = 0;
        }

        reflectionsByDayLabel.push(refDay.name);
        reflectionsByDayDataRef.push(refDay.totalReflections);
        reflectionsByDayDataAvg.push(Number(average.toFixed(2)));
      });

      vm.groupData.graphs.reflectionDay = {
        chartLabels: reflectionsByDayLabel,
        chartDataRef: reflectionsByDayDataRef,
        chartDataAvg: reflectionsByDayDataAvg,
      };

      // Get our line chart data
      let reflectionsByDayByMemberLabel = Array();
      let reflectionsByDayByMemberData = [];

      var tmpMember = vm.groupData.reflectionsByMember;
      tmpMember.forEach((member, ti) => {
        if (
          !this.visibleMembers.some((el) => el.uid === member.uid && el.visible === true)
        ) {
          return;
        }
        reflectionsByDayByMemberLabel.push(member.name);
        reflectionsByDayByMemberData.push({
          name: member.name,
          data: member.byDay,
        });
      });

      vm.groupData.graphs.reflectionDayMember = {
        chartLabels: reflectionsByDayByMemberLabel,
        chartData: reflectionsByDayByMemberData,
      };

      // Set our averages
      vm.groupData.baseStatistics.averageRating = Number(
        vm.groupData.baseStatistics.sumRatings /
          vm.groupData.baseStatistics.totalReflections
      );

      vm.building = false;
    },

    logReflection(reflection) {
      const vm = this;

      let reflectionType = reflection.reflectionType;
      let reflectionTag = reflection.tag.replace(this.groupId + "#", "");
      let reflectionUid = reflection.uid;
      let reflectionGroupDocumentId = reflection.groupDocumentId;
      let reflectionRating = parseInt(reflection.rating);
      if (isNaN(reflectionRating)) {
        reflectionRating = 0;
      }
      let reflectionDay = this.$dayjs().format("dddd");
      let reflectionHighlights = reflection.highlights.length;
      let reflectionLowlights = reflection.lowlights.length;
      let grIndex = null;

      // Filter out reflections that are not within our date range
      if ( reflection.reflectionDate < this.startDate || reflection.reflectionDate > this.endDate) {
        return;
      }

      // Filter out reflections of members that aren't selected
      if (
        !this.visibleMembers.some((el) => el.uid === reflectionUid && el.visible === true)
      ) {
        return;
      }

      vm.groupData.reflections.push(reflection);

      if(reflectionGroupDocumentId){

        // If we haven't see this reflection yet
        if (vm.groupReflectionsCheck.indexOf(reflectionGroupDocumentId) === -1) {
          var rMap = {

            activityName: reflection.activityName,
            activityDetails: reflection.activityDetails,
            imageURL: reflection.imageURL,
            creationTime: reflection.creationTime,
            creationSeconds: reflection.creationSeconds,
            reflectionDate: reflection.reflectionDate,
            id: reflection.id,
            groupDocumentId: reflectionGroupDocumentId,
            groupReflection: true,
            reflectionType: reflection.reflectionType,
            tag: reflection.tag,
            attachment: reflection.attachment,
            timestamp: reflection.timestamp,
            reflections: []

          };

          // store our document_id in our check object
          vm.groupReflectionsCheck.push(reflectionGroupDocumentId);

          // Create our reflection record
          vm.groupReflections.push(rMap);

        }

        grIndex = vm.groupReflectionsCheck.findIndex(t => t === reflectionGroupDocumentId);
        vm.groupReflections[grIndex].reflections.push(reflection);

      }else{

        vm.groupReflectionsCheck.push(reflection.id);
        vm.groupReflections.push(reflection);

      }

      // Add our baseStatistics
      vm.groupData.baseStatistics.totalReflections++;
      vm.groupData.baseStatistics.sumRatings += reflectionRating;
      vm.groupData.baseStatistics.totalHighlights += reflectionHighlights;
      vm.groupData.baseStatistics.totalLowlights += reflectionLowlights;

      // Add our reflectionsByType
      var tmpRT = vm.groupData.reflectionsByType;
      tmpRT.forEach((refType, rti) => {
        if (refType.name === reflectionType) {
          vm.groupData.reflectionsByType[rti].totalReflections++;
          vm.groupData.reflectionsByType[rti].sumRatings += reflectionRating;
          vm.groupData.reflectionsByType[rti].totalHighlights += reflectionHighlights;
          vm.groupData.reflectionsByType[rti].totalLowlights += reflectionLowlights;
        }
      });

      // Add our reflectionsByTag
      var tmpTag = vm.groupData.reflectionsByTag;
      tmpTag.forEach((tag, ti) => {
        if (tag.name === reflectionTag) {
          vm.groupData.reflectionsByTag[ti].totalReflections++;
          vm.groupData.reflectionsByTag[ti].sumRatings += reflectionRating;
          vm.groupData.reflectionsByTag[ti].totalHighlights += reflectionHighlights;
          vm.groupData.reflectionsByTag[ti].totalLowlights += reflectionLowlights;
        }
      });

      // Add our reflectionsByMember
      var tmpMember = vm.groupData.reflectionsByMember;
      tmpMember.forEach((member, mi) => {
        if (member.uid === reflectionUid) {
          vm.groupData.reflectionsByMember[mi].reflections.push(reflection);

          vm.groupData.reflectionsByMember[mi].totalReflections++;
          vm.groupData.reflectionsByMember[mi].sumRatings += reflectionRating;
          vm.groupData.reflectionsByMember[mi].totalHighlights += reflectionHighlights;
          vm.groupData.reflectionsByMember[mi].totalLowlights += reflectionLowlights;

          // Add our reflectionsByDay
          var tmpRD = vm.groupData.reflectionsByMember[mi].byDay;
          tmpRD.forEach((refDay, rdi) => {
            if (refDay.name === reflectionDay && member.uid === reflectionUid) {
              vm.groupData.reflectionsByMember[mi].byDay[rdi].totalReflections++;
              vm.groupData.reflectionsByMember[mi].byDay[
                rdi
              ].sumRatings += reflectionRating;
              vm.groupData.reflectionsByMember[mi].byDay[
                rdi
              ].totalHighlights += reflectionHighlights;
              vm.groupData.reflectionsByMember[mi].byDay[
                rdi
              ].totalLowlights += reflectionLowlights;
            }
          });
        }
      });

      // Add our reflectionsByDay
      var tmpRD = vm.groupData.reflectionsByDay;
      tmpRD.forEach((refDay, rdi) => {
        if (refDay.name === reflectionDay) {
          vm.groupData.reflectionsByDay[rdi].totalReflections++;
          vm.groupData.reflectionsByDay[rdi].sumRatings += reflectionRating;
          vm.groupData.reflectionsByDay[rdi].totalHighlights += reflectionHighlights;
          vm.groupData.reflectionsByDay[rdi].totalLowlights += reflectionLowlights;
        }
      });
    },
  },
};
</script>

<style scoped>
.unselected {
  filter: grayscale(100%);
  opacity: 0.3;
}

.card-ellipse {
  position: absolute;
  text-align: right;
  /* z-index: 10; */
  top: 0;
  right: 0;
}

.img-groups {
  width: 25%;
  height: 25%;
}

.img-member {
  border: 3px solid #8d6c9f;
}

.row-striped:nth-of-type(odd) {
  background-color: #efefef;
  /* border-left: 4px #000000 solid; */
}

.row-striped:nth-of-type(even) {
  background-color: #ffffff;
  /* border-left: 4px #efefef solid; */
}

.row-striped {
  padding: 15px 0;
}
</style>
