<template>
    <div>

        <div class="row justify-content-center pl-3 pr-3 mb-2">

            <div class="col-12" v-show="attachment.title || attachment.summary">
                <p class="card-title mb-1 text-default font-weight-bold" v-show="attachment.title">{{ attachment.title }}</p>
                <p v-show="attachment.summary">{{ attachment.summary }}</p>
            </div>

            <div class="col-12">

                <div v-if="!reqData">

                    <div v-if="attachment.src">
                        <website-embed class="rounded" :attachment="attachment"></website-embed>
                    </div>
                    <div v-else>
                        No website url provided
                    </div>
                    

                </div>
                <div class="mt-4 mb-4" v-else>

                    <input type="text" class="text-center form-control" placeholder="Enter Website URL" v-model="localData.src">

                </div>

            </div>

        </div>
    </div>
</template>
<script>

import firebase from "firebase/app";
import { db } from "@/firebase";
import WebsiteEmbed from "@/components/embeds/Website";
import _ from "lodash";

export default {
    name: 'embed-website',
    props: ['attachment','id','index','readOnly'],
    components: {
        WebsiteEmbed
    },
    mounted() {
       this.reqData = this.requestDataChecker();
    },

    data () {
        return {
            localData: _.cloneDeep(this.attachment),
            reqData: false
        }
    },
    computed: {

    },

    watch: {

    },

    methods: {

        requestDataChecker(){

            if(this.readOnly){
                return false
            }

            if(this.attachment.requestData && this.checkForValues()){
                return true
            }

            return false
        },

        checkForValues(){

            let data = this.localData.src
            let dataReq = false

            if(!data){
                dataReq = true
            }

            return dataReq

        },

    }
};
</script>

<style scoped>

</style>