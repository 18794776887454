<template>
    <div>

        <div class="row justify-content-center pl-3 pr-3 mb-2">

            <div class="col-12" v-show="attachment.summary">
                <p v-show="attachment.summary">{{ attachment.summary }}</p>
            </div>

            <div class="col-12 text-center" v-if="ctaType === 'link'">

                <a :class="attachment.class ? 'btn btn-' + attachment.class : 'btn btn-default'" :href="attachment.src" target="_blank">
                    <i :class="attachment.icon" v-if="attachment.icon"></i>
                    {{ attachment.title }}
                </a>

            </div>
            <div class="col-12 text-center" v-else>

                <router-link :class="attachment.class ? 'btn btn-' + attachment.class : 'btn btn-default'" :to="attachment.src">
                    <i :class="attachment.icon" v-if="attachment.icon"></i>
                    {{ attachment.title }}
                </router-link>

            </div>

        </div>
    </div>
</template>
<script>

import _ from "lodash";

export default {
    name: 'embed-calltoaction',
    props: ['attachment','id','index','readOnly'],

    mounted() {
       this.checkForRoute();
    },

    data () {
        return {
            localData: _.cloneDeep(this.attachment),
            reqData: false,
            ctaType: 'link'
        }
    },
    computed: {

    },

    watch: {

    },

    methods: {

        checkForRoute(){

            let resolved = this.$router.resolve(this.attachment.src)
            if(resolved.route.name !== 'other'){
                this.ctaType = 'router'
            }

        }

    }
};
</script>

<style scoped>

</style>