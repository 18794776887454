<template>
    <div class="justify-content-center">

        <div class="btn btn-sm btn-outline-muted" v-if="!showConfirmation" @click="showConfirmation = !showConfirmation">
            <i class="fad fa-portal-exit"></i> LEAVE THIS GROUP
        </div>

        <div class="alert bg-gradient-danger" v-else-if="!processing">
            <i class="fad fa-bell fa-4x"></i><br>
            <h5 class="text-white mt-2">Are you sure????</h5>
            <p class="text-white">You are about to leave this group. You will not be able to join again without contacting the group owner</p>
            <base-button size="lg" @click="showConfirmation = !showConfirmation" type="white">
                No
            </base-button>
            <base-button size="lg" @click="leaveGroup" type="white">
                Yes
            </base-button>
        </div>

        <div class="alert bg-gradient-danger" v-else-if="processing">
            <i class="fad fa-spinner-third fa-4x fa-spin"></i>
            <h5 class="text-white mt-2">Processing your request</h5>
        </div>

    </div>
</template>
<script>

import firebase from 'firebase/app'
// import { db } from '@/firebase';
import store from '@/store.js'
import { mapGetters } from "vuex";

export default {
    name: 'group-leave',
    props: ['group'],
    components: { 
        // RegistrationForm
    },

    computed: {

        ...mapGetters({
            // map `this.user` to `this.$store.getters.user`
            // map `this.groups` to `this.$store.getters.groups`
            user: "user",
            groups: "groups"
        })

    },

    data () {
        return {
            showConfirmation: false,
            processing: false
        }
    },
    methods: {


        leaveGroup(){

            this.processing = true

            const vm = this
            const axios = require('axios');

            firebase.auth().currentUser.getIdToken(true).then(function(idToken) {

                // Send token to your backend via HTTPS
                var config = {
                    headers: { 'Authorization': "Bearer " + idToken }
                };

                // let url = 'http://localhost:5001/my-reflection-22932/australia-southeast1/app/api/leavegroup'
                let url = 'https://australia-southeast1-my-reflection-22932.cloudfunctions.net/app/api/leavegroup'

                // Post my data to the API
                axios.post(url, {

                    groupId: vm.group.id,
                    uid: vm.user.data.uid,
                    fullName: vm.user.data.fullName

                }, config).then(function (response) {

                    // looks good console log
                    vm.$toast.open({
                        message: 'You successfully left the ' + vm.group.groupName +' group',
                        type: 'info',
                        // all of other options may go here
                    });

                    vm.$router.replace('/mygroups')

                }).catch(function (error) {

                    // oh noooo console log
                    //   console.log(error);

                });

            // ...
            }).catch(function(error) {
                // Handle error
                // console.log(error)
            });


        }

    }
};
</script>
<style scoped>

    .img-member {
        border:3px solid #8d6c9f;
    }

</style>