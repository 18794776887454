export default {
    analysis: null,
    reflectionType: "",
    activityName: "",
    activityDetails: "",
    imageURL: "",
    rating: null,
    highlights: [],
    lowlights: [],
    adjustments: [],
    tempHighlight: "",
    tempLowlight: "",
    tempAdjustment: "",
    tag: null,
    tagData: null,
    alertStatus: null,
    dailyCheckinQuestions: [
      {
        id: "feeltoday",
        question: "How do I feel today?",
        type: "range",
        value: 5,
      },
      {
        id: "bestthing",
        question: "What was the best thing that happened to me today?",
        type: "text",
        value: "",
      },
      {
        id: "accomplishtomorrow",
        question: "What is the most important thing I must accomplish tomorrow?",
        type: "text",
        value: "",
      },
      {
        id: "newthing",
        question: "What is one thing that I can do to improve my day tomorrow?",
        type: "text",
        value: "",
      },
    ]
}