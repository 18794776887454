<template>
  <div v-if="user"> <!-- v-touch:swipe.left="swipeLeftHandler"  v-touch:swipe.right="swipeRightHandler" -->

    <div class="container" v-if="user.data">
      <div class="row justify-content-center" v-if="action !== 'create'">
        <div class="col p-3 mb-5 mt-4">
          <div class=" ">
            <h5 class="card-title text-default font-weight-bold">My Groups</h5>

            <ul class="list-group" v-if="groups.length > 0">
              <router-link
                :to="'/mygroups/' + group.id"
                tag="li"
                v-for="(group, g) in groups"
                v-bind:key="g"
                class="list-group-item list-group-item-action group-rounded shadow mb-2 font-weight-bold text-default pointer"
              >
                <span class="fa-stack mr-2" v-if="group.groupType === 'open'">
                  <i class="fas fa-circle fa-stack-2x"></i>
                  <i class="fad fa-users fa-stack-1x fa-inverse"></i>
                </span>
                <span class="fa-stack mr-2" v-else>
                  <i class="fas fa-circle fa-stack-2x"></i>
                  <i class="fad fa-chart-network fa-stack-1x fa-inverse"></i>
                </span>

                <span class="mt-4 pt-2">{{ group.groupName }}</span>
              </router-link>
            </ul>

            <div v-if="groups.length === 0">
              <p class="mb-4">You currently have no groups!</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container" v-else>
      <div class="row justify-content-center mt-4">
        <div class="col-8 text-center">
          <div class="alert alert-default">
            <i class="fad fa-spinner-third fa-4x fa-spin"></i>
            <h5 class="text-white mt-2">Grabbing your groups</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseDropdown from "@/components/BaseDropdown";

import firebase from "firebase/app";
import { db } from "@/firebase";
import store from "@/store.js";
import { mapGetters } from "vuex";


// const currentUser = firebase.auth().currentUser;

export default {
  name: "mygroups",
  metaInfo: {
    title: "My Groups",
  },
  components: {
    BaseDropdown,
    // 'b-collapse': BCollapse
  },

  data() {
    return {
      action: null,
      entry: null,
      view: null,
      resent: [],
      tempInvite: null,
      licensesUsed: 0,
      showInviteForm: false,
    };
  },

  mounted() {
    // this.checkGroups();
  },

  watch: {
    groups() {
      // this.checkGroups()
    },
  },

  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      // map `this.groups` to `this.$store.getters.groups`
      user: "user",
      groups: "groups",
    }),

  },

  methods: {

    viewGroup(index) {
      this.entry = this.groups[index];

      if (index === this.view) {
        this.view = null;
      } else {
        this.view = index;
      }
    },

    checkGroups() {
      if (this.groups.length === 1) {
        if (!this.groups[0]) {
          return;
        }

        let groupId = this.groups[0].id;
        this.$router.replace("/mygroups/" + groupId);
      }
    },

    // swipeLeftHandler() {
    //   if (!this.currentWeek && !this.$isDesktop()) {
    //     this.$router.replace("profile");
    //   }
    // },

    // swipeRightHandler() {
    //   if (!this.currentWeek && !this.$isDesktop()) {
    //     this.$router.replace("weekly");
    //   }
    // },
  }

};
</script>
<style scoped>
.card-ellipse {
  position: absolute;
  text-align: right;
  /* z-index: 10; */
  top: 0;
  right: 0;
}

.img-groups {
  width: 25%;
  height: 25%;
}

</style>
