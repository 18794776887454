<template>
    <div>

        <!-- HERO INTRO -->
        <div class="bg-gradient-default" style="height:100vh; overflow:scroll;">
            <!-- shape Hero -->
            <section class="section vertical-center" ref="topOfWelcome" >
                <div class="container">

                    <div class="row mb-5 align-middle">

                        <div class="col-12" v-if="stepCurrent === 1">

                            <h2 class="text-white mb-2 mt-0 text-left" v-if="user.data">
                                Welcome to My Reflections <span class="font-weight-bold">{{ user.data.firstName}}</span>,
                                <span class="font-weight-bold">congratulations</span> on launching your <span class="font-weight-bold">self-reflection</span> journey!
                            </h2>
                            <p class="text-center">
                                <img src="img/welcome/personal-launch.png" class="img-fluid float-center card-lift--hover" style="max-width: 250px;" />
                            </p>
                            <p class="text-white text-lg-center lead mb-2">
                                Let us get you <span class="font-weight-bold">started</span> on your way to self-awareness &amp; self-improvement
                            </p>

                        </div>

                        <div class="col-12" v-else-if="stepCurrent === 2">

                            <h3 class="text-white mb-2 mt-0 text-left" v-if="user.data">
                                <span class="font-weight-bold">My Reflections</span> focuses on self-reflections
                                associated with <span class="font-weight-bold">common activity types</span>
                            </h3>
                            <p class="text-center">
                                <img src="img/welcome/activity-types.png" class="img-fluid float-center" style="max-width: 300px;" />
                            </p>
                            <div class="d-flex justify-content-center">

                                    <div
                                        class="bg-white rounded-circle default-avatar member-overlap-item"
                                        :style="getImageStyle(rt.image)"
                                        v-for="(rt, index) in reflectionTypes"
                                        v-bind:key="index"
                                    >
                                    </div>

                            </div>
                            <p class="text-white text-lg-center lead mb-2">
                                Activity types range from <span class="font-weight-bold">projects, tasks, wellness</span> through to
                                <span class="font-weight-bold">sleep, daily checkins &amp; journals</span> 
                            </p>

                        </div>

                        <div class="col-12" v-else-if="stepCurrent === 3">

                            <h3 class="text-white mb-4 mt-0 text-left" v-if="user.data">
                                Rate your the outcomes of your <span class="font-weight-bold">activities &amp; decisions</span>
                                between <span class="font-weight-bold">1 and 10</span>
                            </h3>
                            
                            <center>
                                <card class="border-0 text-center m-3 mt-3" style="max-width: 450px;" shadow body-classes="pt-1 pb-1">

                                    <p class="text-default font-weight-bold mb-0 mt-1">Rate it out of 10</p>
                                    <div class="row justify-content-md-center">
                                        <div class="col-12 pt-1 pb-2">
                                            <input
                                                v-model="rating"
                                                type="range"
                                                min="1"
                                                max="10"
                                                class="rating"
                                            />
                                            <small>currently rated {{ rating }} out of 10</small>
                                        </div>
                                    </div>

                                </card>
                            </center>

                            <div class="d-flex justify-content-center">

                                    <div
                                        class="bg-white rounded-circle rating-avatar rating-overlap-item"
                                        style="background: url(img/images/values/circled-1.svg) 0 0 no-repeat; background-size: cover;"
                                    ></div>
                                    <div
                                        class="bg-white rounded-circle rating-avatar rating-overlap-item"
                                        style="background: url(img/images/values/circled-4.svg) 0 0 no-repeat; background-size: cover;"
                                    ></div>
                                    <div
                                        class="bg-white rounded-circle rating-avatar rating-overlap-item"
                                        style="background: url(img/images/values/circled-5.svg) 0 0 no-repeat; background-size: cover;"
                                    ></div>
                                    <div
                                        class="bg-white rounded-circle rating-avatar rating-overlap-item"
                                        style="background: url(img/images/values/circled-7.svg) 0 0 no-repeat; background-size: cover;"
                                    ></div>
                                    <div
                                        class="bg-white rounded-circle rating-avatar rating-overlap-item"
                                        style="background: url(img/images/values/circled-9.svg) 0 0 no-repeat; background-size: cover;"
                                    ></div>
                                    <div
                                        class="bg-white rounded-circle rating-avatar rating-overlap-item"
                                        style="background: url(img/images/values/circled-10.svg) 0 0 no-repeat; background-size: cover;"
                                    ></div>

                            </div>

                            <p class="text-white text-lg-center lead mb-2">
                                Rating the <span class="font-weight-bold">outcomes</span> of your <span class="font-weight-bold">activities</span>
                                and their associated <span class="font-weight-bold">decisions</span> allows you to monitor your
                                <span class="font-weight-bold">progress</span> and <span class="font-weight-bold">observe trends</span> in your self-reflection and the activities that you are
                                focusing on. 
                            </p>


                        </div>

                        <div class="col-12" v-else-if="stepCurrent === 4">

                            <h3 class="text-white mb-2 mt-0 text-left" v-if="user.data">
                                Identify what you <span class="font-weight-bold">did well</span>, what you could have <span class="font-weight-bold">done better</span>,
                                and what you would do <span class="font-weight-bold">differently</span> next time.
                            </h3>
                            <p class="text-center">
                                <img src="img/welcome/highlowadjustments.png" class="img-fluid float-center" style="max-width: 300px;" />
                            </p>
                            <p class="text-white text-lg-center lead mb-2">
                                In <span class="font-weight-bold">My Reflections</span>, these are called
                                <span class="font-weight-bold">highlights</span>,
                                <span class="font-weight-bold">lowlights</span> &amp; 
                                <span class="font-weight-bold">adjustments!</span>
                            </p>

                        </div>

                        <div class="col-12" v-else>

                            <h2 class="text-white mb-2 mt-0 text-left" v-if="user.data">
                                You are <span class="font-weight-bold">ready to get started!</span>
                            </h2>
                            <p class="text-center">
                                <img src="img/welcome/ready.png" class="img-fluid float-center" style="max-width: 300px;" />
                            </p>
                            <p class="text-white text-lg-center lead mb-2">
                                There are other aspects of <span class="font-weight-bold">My Reflections</span> that you should know about but
                                get in and <span class="font-weight-bold">start your first reflection</span> for now and we will share more information
                                with you soon!
                            </p>
                            <p class="text-white text-lg-center lead mb-2">
                                Enjoy!
                            </p>
                            <!-- <hr />
                            <p class="text-white text-left mb-0 ml-lg-5">
                                We will share information over the coming days about how to get even more out of
                                <span class="font-weight-bold">My Reflections</span> such as;
                            </p>

                            <ul class="text-white mb-2 mt-0 ml-lg-5">
                                <li>using <span class="badge badge-secondary">tags</span> to support more specific reflections</li>
                                <li>setting <span class="badge badge-secondary">goals</span> give your self-reflection focus and direction</li>
                                <li>using <span class="badge badge-secondary">deep-dives</span> to explore themes &amp; trends and reflect at a higher level</li>
                                <li><span class="badge badge-secondary">pro</span> features that empower you to reflect in groups or teams and to automate your reflections from your daily activities and habits</li>
                            </ul> -->

                        </div>

                    </div>

                </div>
            </section>
            <!-- 1st Hero Variation -->
        </div>

        <mobile-nav
            class="fixed-bottom navbar-expand shadow mr-0 pr-0"
            type="dark"
            effect="light"
            ref="baseNavBar"
        >
            <div class="row w-100 mr-0 pr-0">
                
                <div class="col-12 text-left ml-0 pl-0 mr-0 pr-0" v-if="stepCurrent > 1">

                    <div class="float-right mr-0 pr-0 text-right" v-if="stepCurrent < 6">

                        <router-link to="/reflectnow" v-show="stepCurrent === 5">
                            <base-button type="default">
                                Start
                            </base-button>
                        </router-link>

                        <div v-if="stepCurrent < 5">

                            <base-button type="default" @click="completeStep()">
                                Next
                            </base-button>

                        </div>

                    </div>

                    <span class="small ml-2" v-for="step in 5" v-bind:key="step">

                        <i class="fas fa-circle text-primary mt-3" v-if="stepCurrent === step"></i>
                        <i class="fas fa-circle text-default mt-3 pointer" @click="returnToStep(step)" v-else-if="stepCompleted >= step"></i>
                        <i class="fas fa-circle text-secondary mt-3" v-else></i>

                    </span>

                </div>
                <div class="col-12 text-right ml-0 pl-0 mr-0 pr-0" v-else>

                    <base-button type="default" @click="completeStep()">
                        Get Started
                    </base-button>

                </div>
    
            </div>
        </mobile-nav>
       
    </div>
</template>
<script>

// import WeeklyReflectionReport from "@/components/weekly/Report";
import firebase from 'firebase/app'
import { db } from '@/firebase';
import store from '@/store.js'
import { mapGetters } from "vuex";

// const currentUser = firebase.auth().currentUser;
import MobileNav from "@/components/MobileNav";
import reflectionTypes from "@/models/reflectionTypes";

export default {
    name: 'welcome-to-my-reflections',

    components: {
        // WeeklyReflectionReportLayout,
        // Reflection,
        // Comments,
        MobileNav
        
    },

    data() {
      return {
        stepCompleted: 0,
        stepCurrent: 1,
        rating: 5,
        reflectionTypes
      }
    },

    metaInfo: {
      title: 'Welcome to My Reflections'
    },

    mounted() {

      // this.setReportUUID()

    },

    watch: {
        // reportuuid: function(){
        //     this.loadReport()
        // }
    },

    computed: {

        ...mapGetters({
            // map `this.user` to `this.$store.getters.user`
            // map `this.groups` to `this.$store.getters.groups`
            user: "user",
            groups: "groups"
        })

    },


    methods: {

        getImageStyle(image){

            return 'background: url(' + image + ') 0 0 no-repeat; background-size: cover;'

        },

        goTop(refName) {
          var element = this.$refs[refName];
          element.scrollIntoView({ behavior: 'smooth' });
        },

        returnToStep(step){

            this.stepCurrent = step;
            this.goTop("topOfWelcome");

        },

        completeStep(){

            if(this.stepCurrent >= this.stepCompleted){
                this.stepCompleted++;
            }
            this.stepCurrent++;
            this.goTop("topOfWelcome");

        }

    }

};
</script>
<style scoped>

.vertical-center {
  min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
}

.default-avatar:hover {
  transform: scale(1.4);
  z-index: 999;
}

.default-avatar {
  background-color: #fff;
  font-weight: 500;
  color: #fff;
  font-size: 1.6rem;
}

.default-avatar,
.member-overlap-item {
  height: 30px;
  width: 30px;
  transition: transform 0.2s;
  z-index: 500;
}

.member-overlap-item {
  margin-right: -10px;
  border: 2px solid #fff;
}

.rating-avatar:hover {
  transform: scale(1.4);
  z-index: 999;
}

.rating-avatar {
  background-color: #fff;
  font-weight: 500;
  color: #fff;
  font-size: 1.6rem;
}

.rating-avatar,
.rating-overlap-item {
  height: 50px;
  width: 50px;
  transition: transform 0.2s;
  z-index: 500;
}

.rating-overlap-item {
  margin-right: -10px;
  border: 2px solid #fff;
}

/* rating */

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  margin: 15.7px 0;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4.6px;
  cursor: pointer;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
  background: #e6dfea;
  border-radius: 2.4px;
  border: 0px solid rgba(1, 1, 1, 0);
}

input[type="range"]::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid rgba(0, 0, 0, 0);
  height: 36px;
  width: 36px;
  border-radius: 47px;
  background: #8d6c9f;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -15.7px;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #f2eef4;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 4.6px;
  cursor: pointer;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
  background: #e6dfea;
  border-radius: 2.4px;
  border: 0px solid rgba(1, 1, 1, 0);
}

input[type="range"]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid rgba(0, 0, 0, 0);
  height: 36px;
  width: 36px;
  border-radius: 47px;
  background: #8d6c9f;
  cursor: pointer;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 4.6px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type="range"]::-ms-fill-lower {
  background: #dad0e0;
  border: 0px solid rgba(1, 1, 1, 0);
  border-radius: 4.8px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
}

input[type="range"]::-ms-fill-upper {
  background: #e6dfea;
  border: 0px solid rgba(1, 1, 1, 0);
  border-radius: 4.8px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
}

input[type="range"]::-ms-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid rgba(0, 0, 0, 0);
  height: 36px;
  width: 36px;
  border-radius: 47px;
  background: #8d6c9f;
  cursor: pointer;
  height: 4.6px;
}

input[type="range"]:focus::-ms-fill-lower {
  background: #e6dfea;
}

input[type="range"]:focus::-ms-fill-upper {
  background: #f2eef4;
}

</style>
