<template>
    <div>
        
        <card shadow class="p-4" no-body v-if="user">
            <div class="row justify-content-center mb-2">
                <div class="col-12">
                    <div class="float-right">
                        <a class="btn btn-sm btn-default text-white" role="button" @click="modals.create = true">
                            <i class="fad fa-tag"></i> New
                        </a>
                    </div>
                    <h4>
                        Tags &amp; Stats
                        <small>
                            <i class="fad fa-question-circle text-default pointer" @click="showTip = !showTip"></i>
                        </small>
                    </h4>
                    <div class="alert alert-default text-center" v-show="showTip">
                        Tags allow you to group common activities, irrespective of their
                        reflection type. This could be used to track a project, a goal, a target
                        and associate and report on the reflections tagged as such.
                    </div>
                </div>
            </div>

            <div class="row justify-content-center">

                <div
                class="col-lg-6 col-12"
                v-for="(tag, index) in filteredTags"
                v-bind:key="index"
                >
                <card type="white" class="card card-body mb-4 p-0">
                    <div class="m-2 card-ellipse">
                        <base-dropdown position="right">
                            <a role="button" class="pointer" slot="title" data-toggle="dropdown">
                            <icon
                                name="fal fa-ellipsis-v"
                                size="sm"
                                iconOnly
                                textColor="dark"
                                type="white"
                                outline
                                rounded
                                class="text-dark"
                            ></icon>
                            </a>

                            <a
                            class="dropdown-item pointer"
                            role="button"
                            @click="archiveTag(tag)"
                            v-show="tag.total > 0"
                            >
                            <i class="fal fa-archive"></i> Archive
                            </a>
                            <a
                            class="dropdown-item pointer"
                            role="button"
                            @click="deleteTag(tag)"
                            v-show="tag.total === 0"
                            >
                            <i class="fal fa-trash"></i> Delete
                            </a>
                        </base-dropdown>
                    </div>

                    <div class="row">
                        <div class="col-12 mb-4 text-center">
                            <div class="text-center text-default font-weight-bold">
                                {{ tag.name }}
                            </div>
                            <div class="text-center text-muted font-weight-bold" v-if="tag.type">
                                <small>{{ tag.type }}</small>
                            </div>
                            <div class="text-center text-muted font-weight-bold" v-else>
                                <small>all types</small>
                            </div>
                        </div>

                        <div class="col-6 mb-2 text-center">
                            <div class="badge badge-default">Reflections</div>
                            <h5
                            class="text-center text-default font-weight-bold"
                            v-if="tag.total"
                            >
                            {{ tag.total.toFixed(0) }}
                            </h5>
                            <h5 class="text-center text-default font-weight-bold" v-else>0</h5>
                        </div>

                        <div class="col-6 mb-2 text-center">
                            <div class="badge badge-default">Avg. Rating</div>
                            <h5
                            class="text-center text-default font-weight-bold"
                            v-if="tag.ratings"
                            >
                            {{ (tag.ratings / tag.total).toFixed(2) }}
                            </h5>
                            <h5 class="text-center text-default font-weight-bold" v-else>0.00</h5>
                        </div>
                    </div>
                </card>
                </div>

                <div
                v-if="!filteredTags || (filteredTags && filteredTags.length === 0)"
                class="text-muted"
                >
                You currently do not have any tags, add your first now!
                </div>
            </div>

        </card>

        <modal
            :show.sync="modals.create"
            modal-classes="modal-dialog-centered"
        >
            <div class="row justify-content-center">

                <div class="col-lg-6 col-12 pt-1 pb-4">
                    <h3 class="modal-title text-center text-default mb-3" id="modal-title-default">
                        Create your tag
                    </h3>
                </div>
            </div>

            <div class="row justify-content-center">

                <div class="col-12">
                    
                    <label>Tag/name</label>
                    <base-input
                        type="text"
                        placeholder="Add a new tag"
                        v-model="tempTag"
                    >
                    </base-input>
                    
                </div>

                <div class="col-12">

                    <label>This tag will be available for these reflection/activity types</label>
                    <select class="form-control" v-model="tempType">
                        <option selected :value="null">all reflection types</option>
                        <option v-for="(type, t) in reflectionTypes" v-bind:key="t">
                        {{ type.name }}
                        </option>
                    </select>

                </div>

            </div>

            <template slot="footer">
                <base-button type="secondary" class="ml-auto" @click="closeModal">
                    Close
                </base-button>
                <base-button type="primary" v-if="tempTag" @click="commitTag">Create</base-button>
                <base-button type="primary" v-else disabled>Create</base-button>
            </template>
        </modal>

    </div>
</template>
<script>

import firebase from 'firebase/app'
import { db } from '@/firebase';
import store from '@/store.js'
import { mapGetters } from "vuex";
import reflectionTypes from "@/models/reflectionTypes";
import BaseDropdown from "@/components/BaseDropdown";
// import Modals from "@/components/tags/Modals";
import Modal from "@/components/Modal.vue";

export default {
    name: 'tag-management',
    // props: [],
    components: { 
        BaseDropdown,
        Modal
    },

    computed: {

        ...mapGetters({
            // map `this.user` to `this.$store.getters.user`
            // map `this.groups` to `this.$store.getters.groups`
            user: "user",
            groups: "groups"
        }),

        filteredTags() {
            if (this.user.data.tags) {
                return this.user.data.tags.filter((tag) => {
                    return tag.status === "active";
                });
            }

            return [];
        },

    },

    data () {
        return {
            
            action: null,
            loaded: false,
            showTip: false,
            tempTag: null,
            tempTags: [],
            tempType: null,
            viewing: null,
            reflectionTypes,

            modals: {
                create: false
            },

        }
    },

    mounted() {
        // this.checkForToken();
    },

  watch: {
    tempTag() {
        this.tempTag = this.slugify(this.tempTag);
    }
  },

    methods: {

        slugify(text){

            return text.toString().toLowerCase()
                .replace(/\s+/g, '-') // Replace spaces with -
                .replace(/&/g, '-and-') // Replace & with 'and'
                .replace(/[^\w\-]+/g, '') // Remove all non-word chars
                .replace(/--+/g, '-'); // Replace multiple - with single -
        },

        closeModal(){
            this.modals.create = false;
        },

        commitTag() {
            if (this.tempTag) {
                let newUser = JSON.parse(JSON.stringify(this.user.data));
                let type = null;
                if (this.tempType) {
                    type = this.tempType;
                }

                if (!newUser.tags) {
                    newUser.tags = [];
                }

                let tag = {
                    name: this.tempTag,
                    status: "active",
                    type: type,
                    total: 0,
                    ratings: 0,
                };
                newUser.tags.push(tag);

                this.$store.dispatch("updateUser", newUser);

                this.$toast.open({
                    message: "Tag added!",
                    type: "success",
                    // all of other options may go here
                });

                this.tempTag = "";
                this.modals.create = false;
            }
        },

        archiveTag(tag) {

            let newUser = JSON.parse(JSON.stringify(this.user.data));
            let tmpTags = newUser.tags;

            tmpTags.some((t, i) => {
                if (t.name === tag.name) {
                tmpTags[i].status = "archived";
                }
            });

            this.$store.dispatch("updateUser", newUser);

            this.$toast.open({
                message: "Tag archived!",
                type: "warning",
                // all of other options may go here
            });
        },

        deleteTag(tag) {

            let newUser = JSON.parse(JSON.stringify(this.user.data));
            let tmpTags = newUser.tags;

            tmpTags.some((t, i) => {
                if (t.name === tag.name) {
                tmpTags[i].status = "deleted";
                }
            });

            this.$store.dispatch("updateUser", newUser);

            this.$toast.open({
                message: "Tag deleted!",
                type: "warning",
                // all of other options may go here
            });

        },

    }
};
</script>
<style scoped>

  .card-ellipse {
    position: absolute;
    text-align: right;
    top: 0;
    right: 0;
    z-index: 100;
  }

  .mr-logo {
    width: 90px !important;
    height: 90px !important;
  }

</style>