<template>
  <div>

    <div class="container mt-5" v-if="reflection">
        <div class="row justify-content-center">

            <div class="col-12">

              <div class="row justify-content-center">

                <div class="col-8 pt-1 pb-4 text-center">

                    <h4 class="text-default">
                      Review/update your <span class="font-weight-bold">reflection</span>
                    </h4>

                </div>

                <div class="col-8 pt-1 pb-4 text-center">
                  <div class="text-center">
                    <div v-if="!switchType">
                      <img class="img-rt pointer" :src="getReflectionTypeImage(reflection.reflectionType)" @click="switchType = true" />
                    </div>
                    <div class="row justify-content-center" v-if="switchType">

                        <div
                          class="col-lg-2 col-sm-4 col-4 pt-2 pb-2 m-0 text-center justify-content-center"
                          v-for="(rt, index) in reflectionTypes"
                          v-bind:key="index"
                          @click="setReflectionType(rt)"
                        >
                          <div class="text-center">
                            <img
                              :alt="rt.name"
                              class="img-rt img-rt-selection pointer"
                              :src="rt.image"
                            />
                          </div>
                          <div class="label-rt text-center text-default font-weight-bold pointer">
                            {{ rt.name }}
                          </div>
                        </div>

                    </div>
                  </div>
                  <div class="text-center">
                    <span class="badge badge-default pointer" @click="setDate(-1)">
                      <i class="fas fa-chevron-left"></i>
                    </span>
                    <span class="badge badge-default" v-if="reflectionDate">
                      {{ $dayjs.unix( reflectionDate.seconds ).format("ddd, MMM Do YYYY") }} 
                    </span>
                    <span class="badge badge-default pointer" @click="setDate(1)">
                      <i class="fas fa-chevron-right"></i>
                    </span>
                  </div>
                </div>

                

                <div class="col-8 pt-1 pb-4 text-center mt-5" v-show="showActivity">
                  <h5 class="text-default text-center font-weight-bold">
                    {{ reflectionType.labels.introduction }}
                  </h5>
                  <base-input
                    inputClasses="form-control-lg text-center"
                    v-model="reflection.activityName"
                    class="mb-3"
                    :placeholder="reflectionType.labels.introduction"
                    input-classes="text-dark"
                  >
                  </base-input>
                </div>

                <template v-if="reflection.reflectionType === 'journal entry'">

                    <div
                      class="col-lg-8 col-12 pt-1 pb-4 text-center"
                    >
                      <h5 class="card-title text-default font-weight-bold">
                        Journal Entry
                      </h5>
                      <textarea-autosize
                        placeholder="Start entering your journal"
                        ref="detailsTextarea"
                        v-model="reflection.activityDetails"
                        :min-height="50"
                        class="form-control text-dark"
                      />
                    </div>

                </template>

                <template v-else-if="reflection.reflectionType === 'daily checkin'">

                    <div
                      class="col-lg-8 col-12 text-center"
                      v-for="(qn, index) in reflection.dailyCheckinQuestions"
                      v-bind:key="index"
                    >
                      <h5 class="card-title mt-4 text-default font-weight-bold text-center">{{ qn.question }}</h5>
                      <div class="row justify-content-center text-center">
                        <div class="col-lg-8 col-10 pt-1 pb-2 mb-5 text-center" v-if="qn.type === 'range'">
                          <input
                            v-model="reflection.dailyCheckinQuestions[index].value"
                            type="range"
                            min="1"
                            max="10"
                            class="rating"
                          />
                          <small
                            >currently rated {{ reflection.dailyCheckinQuestions[index].value }} out of
                            10</small
                          >
                        </div>
                        <div class="col-12 input-group mb-5 text-center" v-else>
                          <textarea-autosize
                            placeholder="Type your answer..."
                            :ref="reflection.dailyCheckinQuestions[index].id"
                            v-model="reflection.dailyCheckinQuestions[index].value"
                            :min-height="50"
                            class="form-control text-center textarea-placeholder text-dark"
                          />
                        </div>
                      </div>
                    </div>

                </template>

                <template v-else-if="reflection.reflectionType === 'photo journal'">

                  <div class="col-lg-6 col-10 pt-1 pb-2 text-center">

                    <img
                      :src="reflection.imageURL"
                      class="img-fluid mb-3"
                      v-if="reflection.imageURL"
                    />

                  </div>

                </template>

              </div>

              <!-- ADVANCED ATTACHMENTS -->
              <advanced-attachments
                :attachment="reflection.attachment"
                :id="reflection.id"
                :allowEdit="true"
                v-if="reflection.attachment"></advanced-attachments>

              <div class="row justify-content-center">

                <div class="col-lg-6 col-10 pt-1 pb-2 mt-5 text-center">
                  <h5 class="card-title text-default font-weight-bold">
                    {{ reflectionType.labels.rating }}
                  </h5>
                  <input v-model="reflection.rating" type="range" min="1" max="10" class="rating" />
                  <small>currently rated {{ reflection.rating }} out of 10</small>
                </div>

              </div>

              <div class="row justify-content-center pt-1 mt-5">

                <div class="col-lg-8 col-12 text-center">

                    <h5 class="card-title text-default font-weight-bold">
                      {{ reflectionType.labels.highlows }}
                    </h5>

                </div>

              </div>
              
              <div class="row justify-content-center pt-1">

                <div class="col-lg-4 col-12 p-2 text-center">
                  <div class="input-group">
                    <textarea-autosize
                      placeholder="What did you do well; the highlights?"
                      ref="highlightTextarea"
                      v-model="tempHighlight"
                      :min-height="80"
                      class="form-control text-center textarea-placeholder text-dark"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-default"
                        type="button"
                        id="button-addon2"
                        @click="commitHighlight"
                      >
                        <i class="fad fa-thumbs-up"></i>
                      </button>
                    </div>
                  </div>

                  <div v-if="reflection.highlights.length > 0" class="mt-3">
                    <ul class="list-group">
                      <li
                        class="list-group-item"
                        v-for="(highlight, index) in reflection.highlights"
                        v-bind:key="index"
                      >
                        <div class="float-right">
                          <i class="fa fa-trash pointer" @click="deleteHighlight(index)"></i>
                        </div>
                        <span style="white-space: pre-wrap" v-html="highlight"></span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-lg-4 col-12 p-2 text-center">
                  <div class="input-group">
                    <textarea-autosize
                      placeholder="What could have been better; the lowlights?"
                      ref="lowlightTextarea"
                      v-model="tempLowlight"
                      :min-height="80"
                      class="form-control text-center textarea-placeholder-lowlight"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-default"
                        type="button"
                        id="button-addon2"
                        @click="commitLowlight"
                      >
                        <i class="fad fa-thumbs-down"></i>
                      </button>
                    </div>
                  </div>

                  <div v-if="reflection.lowlights.length > 0" class="mt-3">
                    <ul class="list-group">
                      <li
                        class="list-group-item"
                        v-for="(lowlight, index) in reflection.lowlights"
                        v-bind:key="index"
                      >
                        <div class="float-right">
                          <i class="fa fa-trash pointer" @click="deleteLowlight(index)"></i>
                        </div>
                        <span style="white-space: pre-wrap" v-html="lowlight"></span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-lg-4 col-12 p-2 text-center">
                  <div class="input-group">
                    <textarea-autosize
                      placeholder="What adjustments should you make?"
                      ref="adjustmentTextarea"
                      v-model="tempAdjustment"
                      :min-height="80"
                      class="form-control text-center textarea-placeholder text-dark"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-default"
                        type="button"
                        id="button-addon2"
                        @click="commitAdjustment"
                      >
                        <i class="fad fa-exchange-alt"></i>
                      </button>
                    </div>
                  </div>

                  <div v-if="reflection.adjustments.length > 0" class="mt-3">
                    <ul class="list-group">
                      <li
                        class="list-group-item"
                        v-for="(adjustment, index) in reflection.adjustments"
                        v-bind:key="index"
                      >
                        <div class="float-right">
                          <i class="fa fa-trash pointer" @click="deleteAdjustment(index)"></i>
                        </div>
                        <span style="white-space: pre-wrap" v-html="adjustment"></span>
                      </li>
                    </ul>
                  </div>
                </div>

              </div>

              <div class="row justify-content-center ">
                <div class="col-lg-8 col-12 mt-5 mb-0 p-2 text-center">
                  <h5 class="card-title text-default font-weight-bold">
                    Tag your activity
                  </h5>
                </div>
                <div class="col-lg-8 col-12 mt-0 mb-0 p-2 text-center">
                  <span
                    :class="getTagClass(tag)"
                    v-for="(tag, index) in filteredTags"
                    v-bind:key="index"
                    @click="setTag(tag)"
                    :title="tag.title"
                  >
                    <span v-if="tag.scope === 'group'">
                      <i class="fal fa-users-class"></i> {{ tag.label }}
                    </span>
                    <span v-else> <i class="fal fa-tag"></i> {{ tag.name }} </span>
                  </span>

                  <!-- 
                    <span :class="getTagClass(tag)" v-for="(tag, index) in filteredTags" v-bind:key="index" @click="setTag(tag)"> <i class="fal fa-tag"></i> {{ tag.name }} </span>
                  -->
                </div>
              </div>

              <div class="row justify-content-center">
                <div class="col-lg-8 col-10 mt-5 mb-0 p-2 text-center">

                  <analysis
                      ref="ourAnalysis"
                      :document="reflection"
                      :current-user="user.data"
                  ></analysis>

                </div>
              </div>



              <div class="row justify-content-center">
                <div class="col-lg-8 col-12 mt-5 mb-5 p-2 text-center">

                  <router-link to="/reflectnow">
                    <base-button type="danger" size="lg" class="m-1">
                      Close
                    </base-button>
                  </router-link>
                  <base-button type="default" disabled size="lg" v-if="!updatesMade" class="m-1">
                    Update
                  </base-button>
                  <base-button type="default" size="lg" @click="updateReflection" class="m-1" v-else>
                    Update
                  </base-button>

                </div>
              </div>
            
            </div>
        </div>
    </div>

    <div class="container" v-if="!user || loading">
      <div class="row justify-content-center mt-4">
        <div class="col-lg-8 col-12  text-center">
          <div class="alert alert-default">
            <i class="fad fa-spinner-third fa-4x fa-spin"></i>
            <h5 class="text-white mt-2">Looking for your reflection</h5>
          </div>
        </div>
      </div>
    </div>

    <div class="container" v-if="!reflection && !loading">
      <div class="row justify-content-center mt-4">
        <div class="col-lg-8 col-12 text-center">
          <div class="alert alert-danger">
            <i class="fad fa-sad-tear fa-4x"></i>
            <h5 class="text-white mt-2">We couldn't find that reflection</h5>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
// import Card from "@/components/Card";
import ReflectionCard from "@/components/reflections/Details";
const Analysis = () => import('@/components/analysis/ReflectionAnalysis');
const AdvancedAttachments = () => import('@/components/reflections/AdvancedAttachments');

import firebase from "firebase/app";
import { db } from "@/firebase";
import store from "@/store.js";
import { mapGetters } from "vuex";
import md5 from "js-md5";

import reflectionTypes from "@/models/reflectionTypes";

export default {
  name: "reflection-loader",
  metaInfo: {
    title: "View Reflection",
  },
  components: {
    ReflectionCard,
    Analysis,
    AdvancedAttachments
  },

  data() {
    return {

      mountedPause: 0,

      reflection: null,
      reflectionType: null,
      reflectionTypes,
      loading: true,
      analysisRunning: false,
      switchType: false,
      showActivity: true,
      showAnalysis: true,
      updatesMade: false,
      updateParent: null,

      reflectionDate: null,
      reflectionAdjustment: 0,
      imagesArray: null,

      tempHighlight: "",
      tempLowlight: "",
      tempAdjustment: "",

      consolidatedTags: [],

      // Sentiment Analysis
      sentimentSource: null,
      skippedValues: 0,
      skipTolerance: 2

    };
  },

  mounted() {

    this.getReflection();
    this.buildTags();
    this.updatesMade = false;
    this.pauseWatcher();

  },

  watch: {
    groups() {
      this.buildTags();
      //console.log(new_value,old_value)
    },

    reflection: {
      handler: function () {

        if(this.updatesMade){
          return
        }

        if(this.mountedPause > this.getSeconds()){
          return
        }

        this.updatesMade = true;

      },
      deep: true,
    },

  },

  computed: {

    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      // map `this.groups` to `this.$store.getters.groups`
      user: "user",
      groups: "groups",
      reflectionActions: "reflectionActions"
    }),

    filteredTags() {
      if (this.consolidatedTags) {
        return this.consolidatedTags.filter((tag) => {
          return (
            tag.status === "active" &&
            (!tag.type || tag.type === this.reflection.reflectionType || tag.type === "none")
          );
        });
      }

      return [];
    }

  },

  methods: {

    getSeconds(){

      let getDate = new Date();
      return getDate.getTime();

    },

    pauseWatcher(){

      this.mountedPause = this.getSeconds() + 1000;

    },

    getTagData(tag){

      let tagData = this.user.data.tags.filter((t) => {
        return t.name === tag
      });

      if(tagData.length === 1){
        tagData[0].label = tagData[0].name
        tagData[0].scope = "personal"
        return tagData[0]
      }

    },

    getReflection(){

      const vm = this
      let doc = this.$route.params.id;

      this.reflection = this.$attrs.reflections.find(r => r.id === doc);

      if(this.reflection){
        vm.setDate(0);
        vm.setReflectionType();
        vm.loading = false;
        return
      }

      var docRef = db.collection("reflections").doc(doc);

      docRef.get().then((doc) => {
        
          if (doc.exists) {
              // console.log("Document data:", doc.data());
              vm.reflection = doc.data();
              vm.setDate(0);
              vm.setReflectionType();
              if(vm.reflection.tag && vm.reflection.tag !== 'none' && !vm.reflection.tagData){
                vm.reflection.tagData  = vm.getTagData(vm.reflection.tag)
              }
              if(!vm.reflection.adjustments){
                vm.reflection.adjustments = [];
              }
              vm.loading = false;
          } else {
              // doc.data() will be undefined in this case
              vm.loading = false;
          }
      }).catch((error) => {
          vm.loading = false;
      });

    },

    buildTags() {
      const vm = this;

      if (!vm.user.data) {
        return;
      }

      vm.consolidatedTags = [];

      let tmpPersonalTags = [];
      if (vm.user.data.tags) {
        tmpPersonalTags = JSON.parse(JSON.stringify(vm.user.data.tags));
      }
      tmpPersonalTags.forEach((tag, ti) => {
        tag.scope = "personal";
        tag.label = tag.name;
        vm.consolidatedTags.push(tag);
      });

      // Cycle through our groups
      let tmpGroups = [];
      if (vm.groups) {
        tmpGroups = JSON.parse(JSON.stringify(vm.groups));
      }

      tmpGroups.forEach((group, gi) => {
        // Add our tmpGroupTags
        var tmpGroupTags = JSON.parse(JSON.stringify(group.tags));
        tmpGroupTags.forEach((tag, t2i) => {
          tag.groupId = group.id;
          tag.title = group.groupName;
          tag.label = tag.name;
          tag.name = group.id + "#" + tag.name;
          vm.consolidatedTags.push(tag);
        });
      });
    },

    setTag(tag) {
      if (this.reflection.tag === tag.name) {
        this.reflection.tag = null;
        this.reflection.tagData = null;
      } else {
        this.reflection.tag = tag.name;
        this.reflection.tagData = tag;
      }
    },

    getTagClass(tag) {
      if (tag.scope === "group") {
        if (tag.name === this.reflection.tag) {
          return "pointer badge badge-pill badge-dark mb-2";
        } else {
          return "pointer badge badge-pill badge-light text-dark mb-2";
        }
      } else {
        if (tag.name === this.reflection.tag) {
          return "pointer badge badge-pill badge-default mb-2";
        } else {
          return "pointer badge badge-pill badge-light text-default mb-2";
        }
      }
    },

    getReflectionDate(datetime) {
      let myDate = new Date(datetime.seconds * 1000);
      return myDate;
    },

    setDate(days) {

      this.reflectionAdjustment += days;

      let ourMoment = this.$dayjs.unix(this.reflection.creationTime.seconds).add(this.reflectionAdjustment, 'day').format();

      let ourDate = new Date(ourMoment);
      this.reflectionDate = new firebase.firestore.Timestamp.fromDate(ourDate);
    },

    setReflectionType(rt) {

      let rtName
      if(!rt){
        rtName = this.reflection.reflectionType
      }else{
        rtName = rt.name
      }

      this.reflectionType = this.reflectionTypes.find(r => r.name === rtName);

      this.reflection.reflectionType = rtName;

      if (this.autoActivityName(rtName) && (!this.reflection.activityName || this.reflection.activityName === rtName)) {
        this.reflection.activityName = rtName;
        this.showActivity = false;
      }

      this.switchType = false;

    },

    autoActivityName(name) {
      let autoActivityNames = ["sleep", "journal entry", "daily checkin"];
      return autoActivityNames.includes(name);
    },

    commitHighlight() {

      if(!this.tempHighlight){
        return;
      }

      this.reflection.highlights.push(this.tempHighlight.trim());
      this.tempHighlight = "";
    },

    commitLowlight() {

      if(!this.tempLowlight){
        return;
      }

      this.reflection.lowlights.push(this.tempLowlight.trim());
      this.tempLowlight = "";
    },

    commitAdjustment() {

      if(!this.tempAdjustment){
        return;
      }

      this.reflection.adjustments.push(this.tempAdjustment.trim());
      this.tempAdjustment = "";
    },

    deleteHighlight(i) {
      this.reflection.highlights.splice(i, 1);
    },

    deleteLowlight(i) {
      this.reflection.lowlights.splice(i, 1);
    },

    deleteAdjustment(i) {
      this.reflection.adjustments.splice(i, 1);
    },

    getRatingImage(rating) {
      return "img/images/values/circled-" + rating + ".svg";
    },

    getReflectionTypeImage(type) {
      let rt = this.reflectionTypes.find(element => element.name === type)
      return rt.image
    },
    
    updateAnalysis(){

      this.reflection.analysis = this.$refs.ourAnalysis.analysis
      this.analysisRunning = false
      // this.updateReflection();

    },


    reflectionatDailyCheckinQuestions(name, questions) {
      if (name !== "daily checkin") {
        return null;
      }

      questions.forEach((q, i) => {
        if (q.type === "range") {
          questions[i].value = Number(q.value);
        }
      });

      return questions;
    },

    updateReflection() {

      if(!this.reflection.attachment){
        this.reflection.attachment = []
      }

      this.updateAnalysis();

      let attachment = []
      let isAttachmentArray = Array.isArray(this.reflection.attachment)
      if(!isAttachmentArray){
        attachment.push(this.reflection.attachment);
        this.reflection.attachment = attachment;
      }

      if(!this.reflection.rating){
        this.reflection.rating = 5;
      }

      let docId = this.reflection.id;
      let docMap = {
        reflectionType: this.reflection.reflectionType,
        activityName: this.reflection.activityName,
        activityDetails: this.reflection.activityDetails,
        analysis: this.reflection.analysis,
        attachment: this.reflection.attachment,
        rating: this.reflection.rating,
        highlights: this.reflection.highlights,
        lowlights: this.reflection.lowlights,
        adjustments: this.reflection.adjustments,
        creationTime: this.reflectionDate,
        creationSeconds: this.reflectionDate.seconds,
        tag: this.reflection.tag,
        tagData: this.reflection.tagData,
        dailyCheckinQuestions: this.reflectionatDailyCheckinQuestions(
          this.reflection.reflectionType,
          this.reflection.dailyCheckinQuestions
        ),
      }

      db.collection("reflections")
        .doc(docId)
        .update(docMap);

      // console.log("REFLECTION_UPDATED", docId);
      this.$store.commit("REFLECTION_UPDATED", docId);

      // this.closeModal();
      this.updatesMade = false;
      this.updateParent = docMap;

      this.$toast.open({
        message: "Reflection updated!",
        type: "success",
        // all of other options may go here
      });
    }


  }

};
</script>

<style scoped>

.card-ellipse {
  position: absolute;
  text-align: right;
  top: 0;
  right: 0;
}

.img-rt {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 60px;
  height: 60px;
}

.img-rt-selection:hover {
  transform: scale(1.1);
}

.img-rating-report {
  position: absolute;
  /* top: 12px; */
  margin: 45px -45px;
  width: 30px;
  height: 30px;
}

.textarea-placeholder::placeholder {
  padding-top: 18px;
}

.textarea-placeholder-lowlight::placeholder {
  padding-top: 10px;
}

/* rating */

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  margin: 15.7px 0;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4.6px;
  cursor: pointer;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
  background: #e6dfea;
  border-radius: 2.4px;
  border: 0px solid rgba(1, 1, 1, 0);
}
input[type="range"]::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid rgba(0, 0, 0, 0);
  height: 36px;
  width: 36px;
  border-radius: 47px;
  background: #8d6c9f;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -15.7px;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #f2eef4;
}
input[type="range"]::-moz-range-track {
  width: 100%;
  height: 4.6px;
  cursor: pointer;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
  background: #e6dfea;
  border-radius: 2.4px;
  border: 0px solid rgba(1, 1, 1, 0);
}
input[type="range"]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid rgba(0, 0, 0, 0);
  height: 36px;
  width: 36px;
  border-radius: 47px;
  background: #8d6c9f;
  cursor: pointer;
}
input[type="range"]::-ms-track {
  width: 100%;
  height: 4.6px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type="range"]::-ms-fill-lower {
  background: #dad0e0;
  border: 0px solid rgba(1, 1, 1, 0);
  border-radius: 4.8px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
}
input[type="range"]::-ms-fill-upper {
  background: #e6dfea;
  border: 0px solid rgba(1, 1, 1, 0);
  border-radius: 4.8px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
}
input[type="range"]::-ms-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid rgba(0, 0, 0, 0);
  height: 36px;
  width: 36px;
  border-radius: 47px;
  background: #8d6c9f;
  cursor: pointer;
  height: 4.6px;
}
input[type="range"]:focus::-ms-fill-lower {
  background: #e6dfea;
}
input[type="range"]:focus::-ms-fill-upper {
  background: #f2eef4;
}

</style>
