<template>
  <div class="mb-3">
    <div class="card shadow p-0 bg-white rounded">

      <!-- CARD HEADER -->
      <div class="mt-2 mb-0">
        <div class="w-100">
          <skeleton-card-header />
        </div>
        <hr class="mt-0 mb-0" />

      </div>

      <!-- ACTIVITY DETAILS -->
      <div class="card-body mb-2 text-center">

        <div class="row">

          <div class="col" v-for="n in 4" v-bind:key="n">

              <!-- RATING -->
              <div class="profile-info" style="position: relative;">
                <center>
                  <div class="profile-icon text-center">
                    <img />
                  </div>
                  <h3 />
                  <div class="profile-icon text-center">
                    <img />
                  </div>
                </center>
              </div>

          </div>

        </div>

      </div>

      <!-- NO HIGHLIGHTS -->
      <div>
        <hr class="mt-0 mb-0" />
        <div class="profile-info">
          <center>
            <span />
          </center>
        </div>
      </div>

    </div>

  </div>
</template>
<script>

import SkeletonCardHeader from "@/components/skeleton/CardHeader";

export default {
  name: "skeleton-deep-dive-card",
  components: {
      SkeletonCardHeader
  }
};
</script>

<style scoped>

.profile-card {
  width: 100%;
  background: white;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;
}
.profile-icon {
    width: 45px;
    height: 45px;
    float: center;
    margin: 10px 0 10px 0;
}

.profile-info {
  width: 100%;
  margin: 0 0 0 10px;
  float: center;
}

.profile-info span {
  text-transform: uppercase;
  color: #777;
  letter-spacing: 3px;
}
.profile-info h3 {
  margin: 0;
  font-size: 1.5em;
  color: #222;
}
.profile-info p {
  line-height: 140%;
  color: #777;
}
.profile-icon img {
	width: 100%;
	padding-top: 100%;
	animation: pulse-bg 1s infinite;
}
.profile-info span {
	width: 280px;
	height: 12px;
	display: block;
    margin: 10px 0 10px;
	animation: pulse-bg 1s infinite;
}
.profile-info h3 {
	width: 90px;
	height: 16px;
  margin: 0 0 5px;
	animation: pulse-bg 1s infinite;
}
.profile-info p {
	width: 80%;
	height: 16px;
	animation: pulse-bg 1s infinite;
}
@keyframes pulse-bg {
	0% {
		background-color: #eee;
	}
	50% {
		background-color: #e9e9e9;
	}
	100% {
		background-color: #eee;
	}
}
</style>