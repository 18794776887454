import { render, staticRenderFns } from "./EmbedPieChart.vue?vue&type=template&id=536bd922&scoped=true&"
import script from "./EmbedPieChart.vue?vue&type=script&lang=js&"
export * from "./EmbedPieChart.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "536bd922",
  null
  
)

export default component.exports