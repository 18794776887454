<template>

<!-- 
    <div class="card">
        <div class="card-body p-2">
            <div class="d-flex justify-content-between px-md-1">
                <div class="align-self-center">
                    <i :class="icon + ' ' + iconSize"></i>
                </div>
                <div class="text-end">
                    <h3 class="font-weight-bold stat-value my-0">{{subTitle}}</h3>
                    <p class="mb-0 small text-uppercase my-0">{{title}}</p>
                </div>
            </div>
        </div>
    </div>
 -->
          <!-- Card -->
          <div class="card border-0 mb-4">

            <!-- Card content -->
            <div class="card-body p-2">

              <!-- Icon -->
              <i :class="icon + ' ' + iconSize"></i>

              <!-- Title -->
              <h6 class="card-title text-muted small text-uppercase mt-2 mb-0">{{title}}</h6>

              <!-- Stat Value -->
              <div class="card-text mt-0">
                  <span class="h1 font-weight-bold text-primary">{{subTitle}}</span>
                  <span class="h4 font-weight-bold text-primary small" v-if="decValue">.{{decValue}}</span>
              </div>

            </div>
            <!-- Card content -->

          </div>
          <!-- Card -->

</template>
<script>
  // import Card from './Card.vue';

  export default {
    name: 'stats-card-clean',
    components: {
      // Card
    },
    props: ["icon", "iconSize","title","subTitle","decValue"],
    // props: {
    //   icon: String,
    //   iconSize: String,
    //   title: String,
    //   subTitle: String
    // }
  };
</script>
<style>

.stat-value {
  font-size: 1.4em !important;
}

</style>
