<template>
  <div>
    <!-- REFLECT ON THE NUMBER OF REFLECTIONS BY TYPE -->
    <div class="card-body justify-content-center text-center" v-if="newReport.label">
      <h2
        class="card-title text-default font-weight-bold text-center mb-1"
        v-if="showTitle"
      >
        Weekly Reflection
      </h2>
      <div class="badge badge-default mt-0 mb-4" v-if="showTitle">
        {{ $dayjs(newReport.startDate).format("Do MMM") }}
          - 
        {{ $dayjs(newReport.endDate).format("Do MMM YYYY") }}
      </div>

      <div class="row justify-content-center mt-4">
        <blockquote class="blockquote bg-default p-4 mb-0">
          <h4 class="text-white">"{{ newReport.label }}"</h4>
          <footer class="blockquote-footer text-secondary float-right">
            {{ newReport.user.fullName }}
          </footer>
        </blockquote>
      </div>
    </div>

    <!-- REFLECT ON THE NUMBER OF REFLECTIONS BY TYPE -->
    <h5 class="card-title text-default font-weight-bold text-center mb-1 mt-4">
      Spread of reflection types
    </h5>
    <div class="card-body justify-content-center">
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6 col-12 mb-4">
          <pie-chart
            :chartData="newReport.graphs.reflectionSplit.chartData"
            :chartLabels="newReport.graphs.reflectionSplit.chartLabels"
          ></pie-chart>
        </div>

        <div
          class="col-lg-6 col-12 mt-4 mb-4 pt-1"
          v-if="newReport.answers.reflectionSplit !== 'skipped'"
        >
          <div :cite="newReport.user.fullName" class="customBlockquote mb-0 text-center">
            <p class="font-weight-bold display-4 mt-4 text-darker">
              {{ newReport.answers.reflectionSplit }}
            </p>
          </div>
        </div>
        <div class="col-12 mt-4 pt-1" v-else>
          <p class="text-muted text-center">No insights provided</p>
        </div>
      </div>
    </div>

    <!-- REFLECT ON THE NUMBER OF REFLECTIONS BY TAG -->
    <h5
      class="card-title text-default font-weight-bold text-center mb-1 mt-4"
      v-if="newReport.tags && newReport.tags.length > 0"
    >
      Spread of reflections across your tags
    </h5>
    <div
      class="card-body justify-content-center"
      v-if="newReport.tags && newReport.tags.length > 0"
    >
      <div class="row justify-content-center">
        <div
          class="col-lg-6 col-12 mt-4 mb-4 pt-1"
          v-if="newReport.answers.reflectionTagSplit !== 'skipped'"
        >
          <div :cite="newReport.user.fullName" class="customBlockquote mb-0 text-center">
            <p class="font-weight-bold display-4 mt-4 text-darker">
              {{ newReport.answers.reflectionTagSplit }}
            </p>
          </div>
        </div>
        <div class="col-12 mt-4 pt-1" v-else>
          <p class="text-muted text-center">No insights provided</p>
        </div>

        <div class="col-lg-4 col-md-6 col-12 mb-4">
          <pie-chart
            :chartData="newReport.graphs.reflectionTagSplit.chartData"
            :chartLabels="newReport.graphs.reflectionTagSplit.chartLabels"
          ></pie-chart>
        </div>
      </div>
    </div>

    <!-- REFLECT ON YOUR HIGHEST RATED REFLECTIONS -->
    <h5 class="card-title text-default font-weight-bold text-center mb-1 mt-4">
      Highest average ratings were in these reflection types
    </h5>
    <div class="card-body justify-content-center">
      <div class="row justify-content-center">
        <div class="col-lg-12 col-md-6 col-12 mb-4">
          <div class="row">
            <div
              class="col-lg-4 col-12 pt-2 pb-2 m-0 text-center justify-content-center"
              v-for="(rt, index) in newReport.graphs.topThree"
              v-bind:key="index"
            >
              <div class="text-center">
                <img :alt="rt.name" class="img-rt" :src="reflectionImage(rt.name)" />
              </div>
              <div class="label-rt text-center text-default font-weight-bold">
                {{ rt.name }}
              </div>
              <div class="badge badge-default">{{ rt.totalReflections }} reflections</div>
              <div class="badge badge-default" v-if="rt.averageRating">
                average rating of {{ rt.averageRating.toFixed(2) }}
              </div>
              <div class="badge badge-default">
                with {{ rt.totalHighlights }} highlights &amp;
                {{ rt.totalHighlights }} lowlights
              </div>
            </div>
          </div>
        </div>

        <div
          class="col-lg-6 col-12 mt-4 pt-1"
          v-if="newReport.answers.topThree !== 'skipped'"
        >
          <div :cite="newReport.user.fullName" class="customBlockquote mb-0 text-center">
            <p class="font-weight-bold display-4 mt-4 text-darker">
              {{ newReport.answers.topThree }}
            </p>
          </div>
        </div>
        <div class="col-12 mt-4 pt-1" v-else>
          <p class="text-muted text-center">No insights provided</p>
        </div>
      </div>
    </div>

    <!-- REFLECT ON YOUR LOWEST RATED REFLECTIONS -->
    <h5 class="card-title text-default font-weight-bold text-center mb-1 mt-4">
      Lowest average ratings were in these reflection types
    </h5>
    <div class="card-body justify-content-center">
      <div class="row justify-content-center">
        <div class="col-lg-12 col-md-6 col-12 mb-4">
          <div class="row">
            <div
              class="col-lg-4 col-12 pt-2 pb-2 m-0 text-center justify-content-center"
              v-for="(rt, index) in newReport.graphs.bottomThree"
              v-bind:key="index"
            >
              <div class="text-center">
                <img :alt="rt.name" class="img-rt" :src="reflectionImage(rt.name)" />
              </div>
              <div class="label-rt text-center text-default font-weight-bold">
                {{ rt.name }}
              </div>
              <div class="badge badge-default">{{ rt.totalReflections }} reflections</div>
              <div class="badge badge-default" v-if="rt.averageRating">
                average rating of {{ rt.averageRating.toFixed(2) }}
              </div>
              <div class="badge badge-default">
                with {{ rt.totalHighlights }} highlights &amp;
                {{ rt.totalHighlights }} lowlights
              </div>
            </div>
          </div>
        </div>

        <div
          class="col-lg-6 col-12 mt-4 pt-1"
          v-if="newReport.answers.bottomThree !== 'skipped'"
        >
          <div :cite="newReport.user.fullName" class="customBlockquote mb-0 text-center">
            <p class="font-weight-bold display-4 mt-4 text-darker">
              {{ newReport.answers.bottomThree }}
            </p>
          </div>
        </div>
        <div class="col-12 mt-4 pt-1" v-else>
          <p class="text-muted text-center">No insights provided</p>
        </div>
      </div>
    </div>

    <!-- REFLECT ON BASE STATS and REFLECTION STATS -->
    <h5 class="card-title text-default font-weight-bold text-center mb-1 mt-4">
      Useful statistics &amp; numbers
    </h5>
    <div
      class="card-body justify-content-center"
      v-if="newReport.answers.retrospectiveCompleted"
    >
      <div class="row justify-content-center">
        <div class="col-lg-12 col-12 text-center align-middle mb-4 mt-4">
          <h4>overall reflection statistics</h4>
        </div>

        <!-- Base Statistics -->
        <div class="col-lg-6 col-12 text-center align-middle mb-4">
          <stats-card
            title="Total reflections"
            type="gradient-default"
            :sub-title="newReport.baseStatistics.totalReflections.toFixed(0)"
            icon="fad fa-head-side-brain"
            class="mb-xl-0"
          >
            <template slot="footer" v-if="newReport.previousReport">
              <span
                class="text-success mr-2"
                v-if="
                  returnPerc(
                    newReport.baseStatistics.totalReflections,
                    newReport.previousReport.baseStatistics.totalReflections
                  ) >= 0
                "
                ><i class="fa fa-arrow-up"></i>
                {{
                  returnPerc(
                    newReport.baseStatistics.totalReflections,
                    newReport.previousReport.baseStatistics.totalReflections
                  ).toFixed(2)
                }}
                %</span
              >
              <span class="text-warning mr-2" v-else
                ><i class="fa fa-down-up"></i>
                {{
                  returnPerc(
                    newReport.baseStatistics.totalReflections,
                    newReport.previousReport.baseStatistics.totalReflections
                  ).toFixed(2)
                }}
                %</span
              >
              <span class="text-nowrap"
                >Since Previous week ({{
                  newReport.previousReport.baseStatistics.totalReflections.toFixed(0)
                }})</span
              >
            </template>
          </stats-card>
        </div>
        <div class="col-lg-6 col-12 text-center align-middle">
          <stats-card
            title="Average rating"
            type="gradient-default"
            :sub-title="newReport.baseStatistics.averageRating.toFixed(2)"
            icon="fad fa-fist-raised"
            class="mb-4 mb-xl-0"
          >
            <template slot="footer" v-if="newReport.previousReport">
              <span
                class="text-success mr-2"
                v-if="
                  returnPerc(
                    newReport.baseStatistics.averageRating,
                    newReport.previousReport.baseStatistics.averageRating
                  ) >= 0
                "
                ><i class="fa fa-arrow-up"></i>
                {{
                  returnPerc(
                    newReport.baseStatistics.averageRating,
                    newReport.previousReport.baseStatistics.averageRating
                  ).toFixed(2)
                }}
                %</span
              >
              <span class="text-warning mr-2" v-else
                ><i class="fa fa-down-up"></i>
                {{
                  returnPerc(
                    newReport.baseStatistics.averageRating,
                    newReport.previousReport.baseStatistics.averageRating
                  ).toFixed(2)
                }}
                %</span
              >
              <span class="text-nowrap"
                >Since Previous week ({{
                  newReport.previousReport.baseStatistics.averageRating.toFixed(2)
                }})</span
              >
            </template>
          </stats-card>
        </div>
        <div class="col-lg-6 col-12 text-center align-middle">
          <stats-card
            title="# of Highlights"
            type="gradient-success"
            :sub-title="newReport.baseStatistics.totalHighlights.toFixed(0)"
            icon="fas fa-thumbs-up"
            class="mb-4 mb-xl-0"
          >
            <template slot="footer" v-if="newReport.previousReport">
              <span
                class="text-success mr-2"
                v-if="
                  returnPerc(
                    newReport.baseStatistics.totalHighlights,
                    newReport.previousReport.baseStatistics.totalHighlights
                  ) >= 0
                "
                ><i class="fa fa-arrow-up"></i>
                {{
                  returnPerc(
                    newReport.baseStatistics.totalHighlights,
                    newReport.previousReport.baseStatistics.totalHighlights
                  ).toFixed(2)
                }}
                %</span
              >
              <span class="text-warning mr-2" v-else
                ><i class="fa fa-down-up"></i>
                {{
                  returnPerc(
                    newReport.baseStatistics.totalHighlights,
                    newReport.previousReport.baseStatistics.totalHighlights
                  ).toFixed(2)
                }}
                %</span
              >
              <span class="text-nowrap"
                >Since Previous week ({{
                  newReport.previousReport.baseStatistics.totalHighlights.toFixed(0)
                }})</span
              >
            </template>
          </stats-card>
        </div>
        <div class="col-lg-6 col-12 text-center align-middle mb-4">
          <stats-card
            title="# of Lowlights"
            type="gradient-danger"
            :sub-title="newReport.baseStatistics.totalLowlights.toFixed(0)"
            icon="fas fa-thumbs-down"
            class="mb-4 mb-xl-0"
          >
            <template slot="footer" v-if="newReport.previousReport">
              <span
                class="text-success mr-2"
                v-if="
                  returnPerc(
                    newReport.baseStatistics.totalLowlights,
                    newReport.previousReport.baseStatistics.totalLowlights
                  ) >= 0
                "
                ><i class="fa fa-arrow-up"></i>
                {{
                  returnPerc(
                    newReport.baseStatistics.totalLowlights,
                    newReport.previousReport.baseStatistics.totalLowlights
                  ).toFixed(2)
                }}
                %</span
              >
              <span class="text-warning mr-2" v-else
                ><i class="fa fa-down-up"></i>
                {{
                  returnPerc(
                    newReport.baseStatistics.totalLowlights,
                    newReport.previousReport.baseStatistics.totalLowlights
                  ).toFixed(2)
                }}
                %</span
              >
              <span class="text-nowrap"
                >Since Previous week ({{
                  newReport.previousReport.baseStatistics.totalLowlights.toFixed(0)
                }})
              </span>
            </template>
          </stats-card>
        </div>

        <!-- Relfections by Type -->
        <div
          class="col-lg-12 col-12 text-center align-middle mb-2 mt-2"
          v-if="!showReflectionsByType"
        >
          <base-button
            type="default"
            outline
            block
            size="lg"
            @click="showReflectionsByType = !showReflectionsByType"
          >
            <span class="d-lg-block d-none">reflections by activity type</span>
            <span class="d-lg-none d-md-block">by activity type</span>
          </base-button>
        </div>
        <div class="col-lg-12 col-12 text-center align-middle mb-4 mt-2" v-else>
          <base-button
            type="default"
            block
            size="lg"
            @click="showReflectionsByType = !showReflectionsByType"
          >
            <span class="d-lg-block d-none">reflections by activity type</span>
            <span class="d-lg-none d-md-block">by activity type</span>
          </base-button>
        </div>
        <div
          class="col-lg-6 col-12"
          v-for="(rbt, ri) in newReport.reflectionsByType"
          v-bind:key="ri"
        >
          <div class="card card-body mb-4" v-if="showReflectionsByType">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-12 text-center">
                <img :alt="rbt.name" class="img-rt-statcard mt-2" :src="reflectionImage(rbt.name)" />
              </div>

              <div class="col-lg-3 col-md-3 col-12 text-center">
                <div class="badge badge-default">Reflections</div>
                <h5 class="text-center text-default font-weight-bold">
                  {{ rbt.totalReflections.toFixed(0) }}
                </h5>
              </div>

              <div class="col-lg-3 col-md-3 col-12 text-center">
                <div class="badge badge-default">Avg. Rating</div>
                <h5
                  class="text-center text-default font-weight-bold"
                  v-if="rbt.averageRating"
                >
                  {{ rbt.averageRating.toFixed(2) }}
                </h5>
                <h5 class="text-center text-default font-weight-bold" v-else>0.00</h5>
              </div>

              <div class="col-lg-3 col-md-3 col-12 text-center">
                <div class="badge badge-default">
                  &nbsp;&nbsp;&nbsp;<i class="fas fa-thumbs-up"></i
                  >&nbsp;&nbsp;&amp;&nbsp;&nbsp;<i class="fas fa-thumbs-down"></i
                  >&nbsp;&nbsp;&nbsp;
                </div>
                <h5 class="text-center text-default font-weight-bold">
                  {{ rbt.totalHighlights.toFixed(0) }} &amp;
                  {{ rbt.totalLowlights.toFixed(0) }}
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mb-4 text-center" v-if="showReflectionsByType">
          <base-button
            type="default"
            shadow
            rounded
            iconOnly
            icon="fas fa-chevron-up"
            @click="showReflectionsByType = !showReflectionsByType"
          >
          </base-button>
        </div>

        <!-- Relfections by Tag -->
        <div
          class="col-lg-12 col-12 text-center align-middle mb-2 mt-2"
          v-if="newReport.tags && newReport.tags.length > 0 && !showReflectionsByTag"
        >
          <base-button
            type="default"
            outline
            block
            size="lg"
            @click="showReflectionsByTag = !showReflectionsByTag"
          >
            <span class="d-lg-block d-none">reflections by tag</span>
            <span class="d-lg-none d-md-block">by tag</span>
          </base-button>
        </div>
        <div
          class="col-lg-12 col-12 text-center align-middle mb-4 mt-2"
          v-else-if="newReport.tags && newReport.tags.length > 0"
        >
          <base-button
            type="default"
            block
            size="lg"
            @click="showReflectionsByTag = !showReflectionsByTag"
          >
            <span class="d-lg-block d-none">reflections by tag</span>
            <span class="d-lg-none d-md-block">by tag</span>
          </base-button>
        </div>
        <div
          class="col-lg-6 col-12"
          v-for="(rbtag, tagindex) in newReport.reflectionsByTag"
          :key="`${tagindex}-${rbtag.name}`"
        >
          <div class="card card-body mb-4" v-if="showReflectionsByTag">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-12 text-center">
                <div class="text-center text-default font-weight-bold">
                  <small>{{ rbtag.name }}</small>
                </div>
                <div class="text-center text-muted" v-if="rbtag.type">
                  <small>{{ rbtag.type }}</small>
                </div>
                <div class="text-center text-muted" v-else><small>all types</small></div>
              </div>

              <div class="col-lg-3 col-md-3 col-12 text-center">
                <div class="badge badge-default">Reflections</div>
                <h5 class="text-center text-default font-weight-bold">
                  {{ rbtag.totalReflections.toFixed(0) }}
                </h5>
              </div>

              <div class="col-lg-3 col-md-3 col-12 text-center">
                <div class="badge badge-default">Avg. Rating</div>
                <h5
                  class="text-center text-default font-weight-bold"
                  v-if="rbtag.averageRating"
                >
                  {{ rbtag.averageRating.toFixed(2) }}
                </h5>
                <h5 class="text-center text-default font-weight-bold" v-else>0.00</h5>
              </div>

              <div class="col-lg-3 col-md-3 col-12 text-center">
                <div class="badge badge-default">
                  &nbsp;&nbsp;&nbsp;<i class="fas fa-thumbs-up"></i
                  >&nbsp;&nbsp;&amp;&nbsp;&nbsp;<i class="fas fa-thumbs-down"></i
                  >&nbsp;&nbsp;&nbsp;
                </div>
                <h5 class="text-center text-default font-weight-bold">
                  {{ rbtag.totalHighlights.toFixed(0) }} &amp;
                  {{ rbtag.totalLowlights.toFixed(0) }}
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-12 mb-4 text-center"
          v-if="newReport.tags && newReport.tags.length > 0 && showReflectionsByTag"
        >
          <base-button
            type="default"
            shadow
            rounded
            iconOnly
            icon="fas fa-chevron-up"
            @click="showReflectionsByTag = !showReflectionsByTag"
          >
          </base-button>
        </div>

        <!-- Relfections by Day -->
        <div
          class="col-lg-12 col-12 text-center align-middle mb-2 mt-2"
          v-if="!showReflectionsByDay"
        >
          <base-button
            type="default"
            outline
            block
            size="lg"
            @click="showReflectionsByDay = !showReflectionsByDay"
          >
            <span class="d-lg-block d-none"
              >reflections &amp; average ratings by day</span
            >
            <span class="d-lg-none d-md-block">by day</span>
          </base-button>
        </div>
        <div class="col-lg-12 col-12 text-center align-middle mb-4 mt-2" v-else>
          <base-button
            type="default"
            block
            size="lg"
            @click="showReflectionsByDay = !showReflectionsByDay"
          >
            <span class="d-lg-block d-none"
              >reflections &amp; average ratings by day</span
            >
            <span class="d-lg-none d-md-block">by day</span>
          </base-button>
        </div>

        <div class="col-12" v-if="showReflectionsByDay">
          <div class="card card-body mb-4">
            <div class="line-chart mb-4" v-if="newReport.previousReport">
              <line-chart
                :chartDataRef="newReport.graphs.reflectionDay.chartDataRef"
                :chartDataRefPrev="
                  newReport.previousReport.graphs.reflectionDay.chartDataRef
                "
                :chartDataAvg="newReport.graphs.reflectionDay.chartDataAvg"
                :chartDataAvgPrev="
                  newReport.previousReport.graphs.reflectionDay.chartDataAvg
                "
                :chartLabels="newReport.graphs.reflectionDay.chartLabels"
              ></line-chart>
            </div>
            <div class="line-chart mb-4" v-else>
              <line-chart
                :chartDataRef="newReport.graphs.reflectionDay.chartDataRef"
                :chartDataAvg="newReport.graphs.reflectionDay.chartDataAvg"
                :chartLabels="newReport.graphs.reflectionDay.chartLabels"
              ></line-chart>
            </div>
          </div>
        </div>

        <div class="col-12 mb-4 text-center" v-if="showReflectionsByDay">
          <base-button
            type="default"
            shadow
            rounded
            iconOnly
            icon="fas fa-chevron-up"
            @click="showReflectionsByDay = !showReflectionsByDay"
          >
          </base-button>
        </div>

        <!-- Relfections Details -->
        <div
          class="col-lg-12 col-12 text-center align-middle mb-2 mt-2"
          v-if="!showReflectionsDetails"
        >
          <base-button
            type="default"
            outline
            block
            size="lg"
            @click="showReflectionsDetails = !showReflectionsDetails"
          >
            reflection details
          </base-button>
        </div>
        <div class="col-lg-12 col-12 text-center align-middle mb-4 mt-2" v-else>
          <base-button
            type="default"
            block
            size="lg"
            @click="showReflectionsDetails = !showReflectionsDetails"
          >
            reflection details
          </base-button>
        </div>
      </div>
      <div class="row justify-content-center" v-if="showReflectionsDetails">
        <feed-view
          name="reflection cards"
          :actionsAllowed="false"
          :reflection-types="reflectionTypes"
          :reflections="newReport.reflections"
        ></feed-view>

        <div class="col-12 mb-4 text-center">
          <base-button
            type="default"
            shadow
            rounded
            iconOnly
            icon="fas fa-chevron-up"
            @click="showReflectionsDetails = !showReflectionsDetails"
          >
          </base-button>
        </div>
      </div>

      <analysis ref="ourAnalysis" :document="newReport" :source="null" :skippedValues="null" :current-user="null"></analysis>

      <!-- Retrospectic -->
      <h5 class="card-title text-default font-weight-bold text-center mt-4 mb-4">
        the weeks highlights and lowlights
      </h5>

      <div
        class="row justify-content-center"
        v-if="
          newReport.weeklyRetrospective.highlights.length > 0 ||
          newReport.weeklyRetrospective.lowlights.length > 0 ||
          newReport.weeklyRetrospective.adjustments.length > 0
        "
      >
        <div
          class="col-lg-5 col-12 m-1 p-2 text-center"
          v-if="newReport.weeklyRetrospective.highlights.length > 0"
        >
          <div class="text-primary font-weight-bold mb-2">
            What did you do well this week?
          </div>

          <div class="mt-3">
            <ul class="list-group">
              <li
                class="list-group-item"
                v-for="(highlight, index) in newReport.weeklyRetrospective.highlights"
                v-bind:key="index"
              >
                <i class="fad fa-thumbs-up"></i> {{ highlight }}
              </li>
            </ul>
          </div>
        </div>

        <div
          class="col-lg-5 col-12 m-1 p-2 text-center"
          v-if="newReport.weeklyRetrospective.lowlights.length > 0"
        >
          <div class="text-primary font-weight-bold mb-2">
            What could you have done better?
          </div>

          <div class="mt-3">
            <ul class="list-group">
              <li
                class="list-group-item"
                v-for="(lowlight, index) in newReport.weeklyRetrospective.lowlights"
                v-bind:key="index"
              >
                <i class="fad fa-thumbs-down"></i> {{ lowlight }}
              </li>
            </ul>
          </div>
        </div>

        <div
          class="col-lg-10 col-12 m-1 p-2 text-center"
          v-if="newReport.weeklyRetrospective.adjustments.length > 0"
        >
          <div class="text-primary font-weight-bold mb-2">
            What adjustments will you make this week??
          </div>

          <div class="mt-3">
            <ul class="list-group">
              <li
                class="list-group-item"
                v-for="(adjustment, index) in newReport.weeklyRetrospective.adjustments"
                v-bind:key="index"
              >
                <i class="fad fa-check-circle"></i> {{ adjustment }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row justify-content-center" v-else>
        <div class="col-12 mt-4 pt-1">
          <p class="text-muted text-center">No insights provided</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import StatsCard from "@/components/StatsCard";

import firebase from "firebase/app";
import { db } from "@/firebase";

import baseReport from "@/models/baseReport";

import VueCharts from "vue-chartjs";
import { Pie, Bar, Line } from "vue-chartjs";
import PieChart from "@/models/pieChart";
import PieChartNew from "@/models/pieChart";
import LineChart from "@/models/lineChart";
import reflectionTypes from "@/models/reflectionTypes";
// import CardView from '@/components/reflections/CardView';
import FeedView from "@/components/reflections/FeedView";
import Analysis from "@/components/analysis/Analysis";

// const currentUser = firebase.auth().currentUser;

export default {
  name: "weekly-reflections-report-layout",
  components: {
    PieChart,
    PieChartNew,
    LineChart,
    StatsCard,
    FeedView,
    Analysis
  },

  props: ["newReport", "showTitle", "expandedDetails"],

  data() {
    return {
      showReflectionsByTag: false,
      showReflectionsByType: false,
      showReflectionsByDay: false,
      showReflectionsDetails: false,
      reflectionTypes: reflectionTypes,
    };
  },

  mounted() {
    this.checkExpanded();
  },

  watch: {},

  // computed: {

  // },

  methods: {

    reflectionImage(rtype){
      let reflectionType = this.reflectionTypes.find(r => r.name === rtype);
      return reflectionType.image
    },

    returnPerc(previous, current) {
      return ((previous - current) / previous) * 100;
    },

    checkExpanded() {
      if (this.expandedDetails) {
        this.showReflectionsByType = true;
        this.showReflectionsByTag = true;
        this.showReflectionsByDay = true;
        this.showReflectionsDetails = true;
      }
    },
  },
};
</script>
<style>
.customBlockquote {
  display: block;
  border-width: 2px 0;
  border-style: dashed;
  border-color: rgba(141, 108, 159, 0.3);
  padding: 1.5em 0 0.5em;
  margin: 1.5em 0;
  position: relative;
}
.customBlockquote:before {
  content: "\201C";
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  width: 3rem;
  height: 2rem;
  font: 6em/1.08em "PT Sans", sans-serif;
  color: #8d6c9f;
  text-align: center;
}
.customBlockquote:after {
  content: "\2013 \2003"attr(cite);
  display: block;
  text-align: right;
  font-size: 0.875em;
  color: #8d6c9f;
}

.line-chart {
  /* max-width: 100%; */
  height: 350px;
  /* margin:  50px auto; */
}

.img-rt-statcard {
  /* position: relative; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 35px;
  height: 35px;
}

.img-rt {
  /* position: relative; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 60px;
  height: 60px;
}

.label-rt {
  font-size: 0.7em;
}

.img-filter {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 35px;
  height: 35px;
}

.unselected {
  filter: grayscale(100%);
  opacity: 0.3;
}
</style>
