<template>
  <div>
    <div class="container p-0 mb-0">
      <div class="row">
        <div class="col">
          <div class="text-center">

            <!-- STEP 1 : Select reflectionType -->
            <div class="card-body p-0" v-if="stepCurrent === 1">
                <h4 class="text-default mb-0 mt-0 text-left" v-if="user.data">
                    Hi {{ user.data.firstName}}, let's start your deep dive!
                </h4>
                <p class="text-left mt-4 mb-4">A deep dive will allow you to build a custom report based on your choice of;
                  <span class="font-weight-bold">reflection/activity types, tags and/or date ranges</span>.
                </p>
            </div>

            <!-- STEP 2 : Select reflectionType -->
            <div class="card-body p-0" v-if="stepCurrent === 2">
                <h4 class="text-default text-left">
                    Which <span class="font-weight-bold">activity types</span> would you like to dive into?
                </h4>
                <div class="row justify-content-center text-center">
                    <div
                      class="col-4 pt-2 pb-2 m-0 text-center justify-content-center"
                      v-for="(rt, index) in selectedReflectionTypes"
                      v-bind:key="index"
                      @click="toggleReflectionType(index)"
                    >
                        <div class="text-center">
                            <img
                              :alt="rt.name"
                              :class="activityTypeClass(rt)"
                              :src="rt.image"
                            />
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center text-center">
                    <div class="col-12 mt-3 pt-2 pb-2 m-0 text-center justify-content-center">
                        <base-button type="secondary" size="sm" @click="buildReflectionTypes(true)">
                          All
                        </base-button>
                        <base-button type="secondary" size="sm" @click="buildReflectionTypes(false)">
                          Clear
                        </base-button>
                    </div>
                </div>
            </div>

            <!-- STEP 3 : Tags -->
            <div class="card-body p-0" v-if="stepCurrent === 3">
              <h4 class="text-default text-left">
                Would you like to <span class="font-weight-bold">target specific tags</span>?
              </h4>

              <div class="row justify-content-center pt-1 pb-4" v-if="user.data">

                <div class="col-12 mt-1 mb-0 p-2 text-center">
                  <span
                    :class="getTagClass(tag)"
                    v-for="(tag, index) in consolidatedTags"
                    v-bind:key="index"
                    @click="toggleTag(index)"
                    :title="tag.title"
                  >
                    <span v-if="tag.scope === 'group'">
                      <i class="fal fa-users-class"></i> {{ tag.label }}
                    </span>
                    <span v-else> <i class="fal fa-tag"></i> {{ tag.name }} </span>
                  </span>
                </div>

              </div>
              <div class="row justify-content-center text-center">
                  <div class="col-12 mt-3 pt-2 pb-2 m-0 text-center justify-content-center">
                      <base-button type="secondary" size="sm" @click="buildTags(true)">
                        All
                      </base-button>
                      <base-button type="secondary" size="sm" @click="buildTags(false)">
                        Clear
                      </base-button>
                  </div>
              </div>

            </div>

            <!-- STEP 4 : Reflection Details -->
            <div class="card-body p-0" v-if="stepCurrent === 4">
              <h4 class="text-default text-left">
                What <span class="font-weight-bold">time period</span> are you looking at?
              </h4>

              <div class="row justify-content-center">
                <div class="col-12 pt-1">
                  <date-picker
                      v-model="range"
                      is-range
                      is-expanded
                      title-position="left"
                      color="gray"
                  />
                </div>
              </div>

            </div>

            <!-- STEP 5 - Saving -->
            <div class="card-body p-0" v-if="stepCurrent === 5">

              <h4 class="text-default text-left">
                The magic is happening for your <span class="font-weight-bold">deep dive</span> report!
              </h4>
              <p class="mt-5 mb-5" v-if="querying">
                We're checking for matching reflections... won't be long...
              </p>
              <div class="mt-5 mb-5 alert alert-danger" v-else-if="!querying && rawResults.length === 0">
                We couldn't find any results, try a different query
              </div>
              <p class="mt-5 mb-5" v-else>
                Your report is ready to go!
              </p>

            </div>

            <!-- FOOTER -->
            <div class="row mt-3" v-if="stepCurrent < 6">

              <div class="col text-left pt-2">

                <span class="small ml-2" v-for="step in 5" v-bind:key="step">

                  <i class="fas fa-circle text-primary" v-if="stepCurrent === step"></i>
                  <i class="fas fa-circle text-default pointer" @click="stepCurrent = step" v-else-if="stepCompleted >= step"></i>
                  <i class="fas fa-circle text-secondary" v-else></i>

                </span>

              </div>
              <div class="col" v-if="stepCurrent < 6">

                  <base-button type="default" block disabled v-if="stepCurrent === 5 && querying">
                    Downloading...
                  </base-button>
                  <base-button type="default" @click="stepCurrent = 2" block v-else-if="stepCurrent === 5 && !querying && rawResults.length === 0">
                    Restart
                  </base-button>
                  <base-button type="default" block v-else-if="stepCurrent === 5 && !querying" @click="sendClose()">
                    Let's go!
                  </base-button>

                  <div v-if="stepCurrent < 5">

                    <base-button type="default" block v-if="canProgress()" @click="completeStep()">
                      Next
                    </base-button>
                    <base-button type="default" block disabled v-else>
                      Next
                    </base-button>

                  </div>

              </div>
              <div class="col-12 text-center cancel-button text-danger pointer" v-if="stepCurrent < 6" @click="cancelDeepDive()">
                <small>CANCEL</small>
              </div>

            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Card from "@/components/Card";
// import Reflection from "@/components/reflections/Reflection";
// import Modal from "@/components/Modal.vue";
// import BaseSwitch from "@/components/BaseSwitch.vue";
import firebase from "firebase/app";
import { db } from "@/firebase";
import store from "@/store.js";
import { mapGetters } from "vuex";

import reflectionTypes from "@/models/reflectionTypes";
import baseDeepDive from "@/models/baseDeepDive";

import { VBPopover } from "bootstrap-vue/esm/directives/popover/popover";
import DatePicker from 'v-calendar/lib/components/date-picker.umd'


const Analysis = () => import('@/components/analysis/ReflectionAnalysis');
import _ from "lodash";

export default {
  name: "create-deep-dive",
  components: {
    Analysis,
    DatePicker
  },

  directives: {
    BPopover: VBPopover,
  },

  props: ["reportId"],

  data() {
    return {

      querying: true,
      reportCompleted: false,
      reportToClose: false,
      rawResults: null,

      report: null,
      range: null,

      // notag: false,

      stepCompleted: 0,
      stepCurrent: 1,

      selectedReflectionTypes: [],
      consolidatedTags: [],

      modals: {
        reflectionSaved: false,
      },

      // Sentiment Analysis
      sentimentSource: null,
      skippedValues: 0,
      skipTolerance: 2

    };
  },
  mounted() {
    this.buildTags(true);
  },
  watch: {
    reportId: function (val) {
      // console.log("Report Id Loaded");
      this.startReport();
    },
  },
  computed: {

    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      // map `this.groups` to `this.$store.getters.groups`
      user: "user",
      groups: "groups",
    }),

    onlySelectedReflectionsTypes() {

        return this.selectedReflectionTypes.filter((rt) => {
            return rt.selected;
        });

    },

    onlySelectedTags() {

        return this.consolidatedTags.filter((tag) => {
            return tag.selected;
        });

    }

  },
  methods: {

    cancelDeepDive(){

        this.resetDeepDive();
        this.reportToClose = true;
        this.sendClose();

    },

    sendClose(){
        this.$emit('modalClosed')
        this.reportToClose = false;
    },

    resetDeepDive(){

        this.querying= true
        this.reportCompleted=false
        this.rawResults=null

        this.report=null
        this.range=null

        this.stepCompleted=0
        this.stepCurrent=1

        this.selectedReflectionTypes = []
        this.consolidatedTags = []

        this.buildTags(true);

    },

    startReport(){

      this.resetDeepDive()

      // console.log('Starting the report!')
      this.report = _.cloneDeep(baseDeepDive);
      this.buildReflectionTypes(true);

    },

    migrateReflectionTypes(){
      this.report.reflectionsByType = _.cloneDeep(this.onlySelectedReflectionsTypes);
    },

    migrateTags(){
      this.report.reflectionsByTag = _.cloneDeep(this.onlySelectedTags);
      this.report.tags = _.cloneDeep(this.onlySelectedTags);
    },

    migrateDateRange(){

      this.report.startTimestamp = new firebase.firestore.Timestamp.fromDate(new Date(Date.parse(this.range.start)));
      this.report.endTimestamp = new firebase.firestore.Timestamp.fromDate(new Date(Date.parse(this.range.end)));

      this.report.startDate = this.$dayjs(this.range.start).format("YYYY-MM-DD");
      this.report.endDate = this.$dayjs(this.range.end).format("YYYY-MM-DD");

      this.report.reflectionsByDate = [];
      for (var d = new Date(Date.parse(this.range.start)); d <= new Date(Date.parse(this.range.end)); d.setDate(d.getDate() + 1)) {
          this.report.reflectionsByDate.push({
            reportDate: this.$dayjs(d).format("YYYY-MM-DD"),
            totalReflections : 0,
            averageRating : 0,
            sumRatings : 0,
            totalHighlights : 0,
            totalLowlights : 0,
            totalAdjustments : 0
          });
      }

    },

    canProgress(){

      if(this.stepCurrent >= 2){

        if(this.onlySelectedReflectionsTypes.length === 0){
            return false;
        }

        if(this.stepCurrent === 4){

          if(!this.range){
            return false;
          }

          if(!this.range.start || !this.range.end){
            return false;
          }

        }

        if(this.stepCurrent === 5){

          if(this.rawResults.length === 0){
            return false;
          }

        }

      }

      return true;

    },

    completeStep(){

      // this.commitHighlight();
      // this.commitLowlight();
      // this.commitAdjustment();

      if(this.stepCurrent === 2){
        this.migrateReflectionTypes();
      }

      if(this.stepCurrent === 3){
        this.migrateTags();
      }

      if(this.stepCurrent === 4){
        this.migrateDateRange();
        this.getDeepDiveData();
      }

      if(this.stepCurrent >= this.stepCompleted){
        this.stepCompleted++;
      }
      this.stepCurrent++;

    },

    ////////////////////////////////
    //
    //    Tag Management
    //
    ////////////////////////////////

    buildTags(selected) {
      const vm = this;
      vm.consolidatedTags = []

      if (!vm.user.data) {
        return;
      }

      let tmpPersonalTags = [];
      if (vm.user.data.tags) {
        tmpPersonalTags = _.cloneDeep(vm.user.data.tags);
      }
      tmpPersonalTags.forEach((tag, ti) => {
        tag.scope = "personal"
        tag.label = tag.name
        tag.selected = selected
        tag.totalReflections = 0
        tag.averageRating = 0
        tag.sumRatings = 0
        tag.totalHighlights = 0
        tag.totalLowlights = 0
        tag.totalAdjustments = 0

        if(tag.status === "active" || tag.status === "archived"){
          vm.consolidatedTags.push(tag);
        }
        
      });

      // Cycle through our groups
      let tmpGroups = [];
      if (vm.groups) {
        tmpGroups = _.cloneDeep(vm.groups);
      }

      tmpGroups.forEach((group, gi) => {
        // Add our tmpGroupTags
        var tmpGroupTags = _.cloneDeep(group.tags);
        tmpGroupTags.forEach((tag, t2i) => {
          tag.groupId = group.id
          tag.title = group.groupName
          tag.label = tag.name
          tag.name = group.id + "#" + tag.name
          tag.selected = selected
          tag.totalReflections = 0
          tag.averageRating = 0
          tag.sumRatings = 0
          tag.totalHighlights = 0
          tag.totalLowlights = 0
          tag.totalAdjustments = 0
          if(tag.status === "active" || tag.status === "archived"){
            vm.consolidatedTags.push(tag);
          }
        });
      });
    },

    toggleTag(index){

      if(this.consolidatedTags[index].selected){
        this.consolidatedTags[index].selected = false;
      }else{
        this.consolidatedTags[index].selected = true
      }

    },
    

    getTagClass(tag) {

      let tagClass = ' pointer badge badge-pill mb-2 '

      if(tag.scope === "group"){
        if(tag.selected == true){
          tagClass = tagClass + ' badge-dark'
        }else{
          tagClass = tagClass + ' badge-light text-dark'
        }
      }else{
        if(tag.selected == true){
          tagClass = tagClass + ' badge-default'
        }else{
          tagClass = tagClass + ' badge-light text-default'
        }
      }

      return tagClass;

    },

    getReflectionDate(datetime) {
      let myDate = new Date(datetime.seconds * 1000);
      return myDate;
    },

    ////////////////////////////////
    //
    //    Reflection Types
    //
    ////////////////////////////////

    toggleReflectionType(index) {

      if(this.selectedReflectionTypes[index].selected){
        this.selectedReflectionTypes[index].selected = false;
      }else{
        this.selectedReflectionTypes[index].selected = true
      }

    },

    buildReflectionTypes(selected){

      this.selectedReflectionTypes = [];

      let tmpRT = _.cloneDeep(reflectionTypes);
      tmpRT.forEach(rt => {
        this.selectedReflectionTypes.push(
          {
            name: rt.name,
            image: rt.image,
            selected: selected,
            totalReflections : 0,
            averageRating : 0,
            sumRatings : 0,
            totalHighlights : 0,
            totalLowlights : 0,
            totalAdjustments : 0
          }
        );
      });

    },

    activityTypeClass(rt){

      if(rt.selected == true){
        return 'img-rt img-rt-selection pointer'
      }
      return 'img-rt img-rt-selection pointer img-rt-gray'

    },

    autoActivityName(name) {
      let autoActivityNames = ["sleep", "journal entry", "daily checkin"];
      return autoActivityNames.includes(name);
    },

    ratingSaved() {
      // We want to track public event hits (i.e. those for non-logged in users)
      if (!this.user.data) {
        this.$analytics.logEvent("public_reflection_created");
      }
    },

    highlightsSaved(){

      var doc = db.collection("reflections").doc();
      this.form.id = doc.id;

      this.commitHighlight();
      this.commitLowlight();
      this.commitAdjustment();
      // this.buildSentimentSource();

    },

    buildTagLink() {
      let popover = {
        variant: "primary",
        content:
          'Not sure what tags are? <a href="https://www.myreflections.app/faq/reflection-tips/what-are-tags" target="_blank">click here to learn</a>',
        html: true,
      };

      return popover;
    },

    formatDailyCheckinQuestions(name, questions) {
      if (name !== "daily checkin") {
        return null;
      }

      questions.forEach((q, i) => {
        if (q.type === "range") {
          questions[i].value = Number(q.value);
        }
      });

      return questions;
    },

    createAlertStatus() {
      if (!this.user.data.alerts) {
        return null;
      }

      return JSON.stringify(this.user.data.alerts);
    },

    updateAnalysis(){
      this.report.analysis = this.$refs.ourAnalysis.analysis
    },

    // Get Token
    getDeepDiveData() {

      const vm = this;

      this.querying = true;

      firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
          user.getIdToken().then(function (idToken) {
            // Get our data!
            vm.bigQuery(idToken);
          });
        }
      });
    },

    // Query BigQuery
    bigQuery(idToken) {
      const vm = this;
      const axios = require("axios");

      // let start = vm.getLastCreationSecond();

      // vm.$analytics.logEvent("deep_dive_query", {
      //   groupUid: vm.groupUid,
      //   uid: vm.user.data.uid,
      //   fullName: vm.user.data.fullName,
      //   start: start,
      // });

      let body = {
        startDate: this.report.startDate,
        endDate: this.report.endDate,
      }

      if(this.consolidatedTags.length !== this.report.reflectionsByTag.length){

        if(this.report.reflectionsByTag.length === 0){
          body.notag = true
        }else{
          body.tags = this.report.reflectionsByTag
        }
        
      }
      
      if(reflectionTypes.length !== this.report.reflectionsByType.length){
        body.reflectionTypes = this.report.reflectionsByType
      }

      // Send token to your backend via HTTPS
      var config = {
        headers: {
          Authorization: "Bearer " + idToken,
        },
      };

      // let url = 'http://localhost:5001/my-reflection-22932/australia-southeast1/app/api/deep_dive_query'
      let url = "https://australia-southeast1-my-reflection-22932.cloudfunctions.net/app/api/deep_dive_query";

      // Make a request for a user with a given ID
      axios
        .post(
            url,
            body,
            config
        )
        .then(function (response) {
          // handle success
          vm.rawResults = response.data;
          vm.convertReflections(response.data);
          setTimeout(vm.buildReport,500);

        })
        .catch(function (error) {
          // handle error
          // console.log(error)
        })
        .finally(function () {
          // always executed
          setTimeout(vm.queryFinished,800);
        });
    },

    convertReflections(data) {
      const vm = this;

      let feedCheck = [];
      let index;

      data.forEach(function (entry, i) {

        let activityDetails = entry.activityDetails;
        let activityName = entry.activityName;
        let creationSeconds = parseInt(entry.creationSeconds);
        let creationTime = entry.creationTime;
        let createdByWebhook = entry.createdByWebhook;
        let id = entry.document_id;
        let attachment = [];
        let highlights = [];
        let lowlights = [];
        let adjustments = [];
        let dailyCheckinQuestions = [];
        let imageURL = entry.imageURL;
        let rating = entry.rating;
        let reflectionDate = entry.reflectionDate;
        let reflectionType = entry.reflectionType;
        let tag = entry.tag;
        let uid = entry.uid;

        // If we haven't see this reflection yet
        if (feedCheck.indexOf(id) === -1) {
          var rMap = {
            activityDetails: activityDetails,
            activityName: activityName,
            creationSeconds: creationSeconds,
            creationTime: creationTime,
            createdByWebhook: createdByWebhook,
            id: id,
            imageURL: imageURL,
            rating: rating,
            highlights: highlights,
            lowlights: lowlights,
            adjustments: adjustments,
            attachment: attachment,
            dailyCheckinQuestions: dailyCheckinQuestions,
            analysis: null,
            reflectionDate: reflectionDate,
            reflectionType: reflectionType,
            tag: tag,
            uid: uid,
            source: "archive",
          };

          // store our id in our check object
          feedCheck.push(id);

          // Create our reflection record
          index = vm.report.reflections.push(rMap) - 1;

        }

        // // If we have a image, add it
        if (entry.highlights_member) {
          vm.report.reflections[index].highlights.push(entry.highlights_member.replace(/^"(.+(?="$))"$/, '$1'));
        }
        if (entry.lowlights_member) {
          vm.report.reflections[index].lowlights.push(entry.lowlights_member.replace(/^"(.+(?="$))"$/, '$1'));
        }
        if (entry.adjustments_member) {
          vm.report.reflections[index].adjustments.push(entry.adjustments_member.replace(/^"(.+(?="$))"$/, '$1'));
        }
        if (entry.dailyCheckinQuestions_member) {
          vm.report.reflections[index].dailyCheckinQuestions.push(JSON.parse(entry.dailyCheckinQuestions_member));
        }
        if (entry.attachment_member) {
          vm.report.reflections[index].attachment.push(JSON.parse(entry.attachment_member));
        }
        if (entry.analysis_score_label) {
          vm.report.reflections[index].analysis = {
            overall: {
              score_label: entry.analysis_score_label,
              score_tag: entry.analysis_score_tag,
              subjectivity: entry.analysis_subjectivity,
              intensity: {
                compound: entry.analysis_intensiy_compound,
                neg: entry.analysis_intensiy_neg,
                neu: entry.analysis_intensiy_neu,
                pos: entry.analysis_intensiy_pos
              }
            }
          };
        }

      });
    },

    buildReport() {

      this.report.uid = this.user.data.uid;
      this.report.user = {
        uid: this.user.data.uid,
        firstName: this.user.data.firstName,
        lastName: this.user.data.lastName,
        fullName: this.user.data.fullName,
        photoURL: this.user.data.photoURL,
      };
      this.report.access.push(this.user.data.uid);

      // if (this.oldReport) {
      //   this.report.previousReport = JSON.parse(JSON.stringify(this.oldReport));
      // }

      const vm = this;

      if(vm.report.reflections.length === 0){
        return
      }

      var tmpReflections = vm.report.reflections;
      tmpReflections.forEach((reflection, ri) => {
        vm.logReflection(reflection);
      });

      // Set our averages
      vm.report.baseStatistics.averageRating = Number(
        vm.report.baseStatistics.sumRatings /
          vm.report.baseStatistics.totalReflections
      );

      // Add our reflectionsByType
      var tmpRT = vm.report.reflectionsByType;
      tmpRT.forEach((refType, rti) => {
        let average = Number(
          vm.report.reflectionsByType[rti].sumRatings /
            vm.report.reflectionsByType[rti].totalReflections
        );
        if (isNaN(average)) {
          average = 0;
        }
        vm.report.reflectionsByType[rti].averageRating = average;
      });

      // Add our reflectionsByTag
      var tmpRT = vm.report.reflectionsByTag;
      tmpRT.forEach((tag, ti) => {

        let average = Number(
          vm.report.reflectionsByTag[ti].sumRatings /
            vm.report.reflectionsByTag[ti].totalReflections
        );
        if (isNaN(average)) {
          average = 0;
        }
        vm.report.reflectionsByTag[ti].averageRating = average;

      });

      // Add our reflectionsByDay
      var tmpRD = vm.report.reflectionsByDay;
      tmpRD.forEach((refDay, rdi) => {

        let average = Number(
          vm.report.reflectionsByDay[rdi].sumRatings /
            vm.report.reflectionsByDay[rdi].totalReflections
        );
        if (isNaN(average)) {
          average = 0;
        }
        vm.report.reflectionsByDay[rdi].averageRating = average;

      });

      // Add our reflectionsByDate
      var tmpRDate = vm.report.reflectionsByDate;
      tmpRDate.forEach((refDay, rdi) => {

        let average = Number(
          vm.report.reflectionsByDate[rdi].sumRatings /
            vm.report.reflectionsByDate[rdi].totalReflections
        );
        if (isNaN(average)) {
          average = 0;
        }
        vm.report.reflectionsByDate[rdi].averageRating = average;

      });

      // Get our Top Three
      let tmpTopThree = _.cloneDeep(vm.report.reflectionsByType);
      tmpTopThree = tmpTopThree.filter((rtt) => {
          return rtt.totalReflections > 0;
      });
      tmpTopThree.sort((b, a) =>
        a.averageRating > b.averageRating ? 1 : b.averageRating > a.averageRating ? -1 : 0
      );

      vm.report.graphs.topThree = tmpTopThree.splice(0, 3);

      // Get our Bottom Three
      let tmpBottomThree = _.cloneDeep(vm.report.reflectionsByType);
      tmpBottomThree = tmpBottomThree.filter((rbt) => {
          return rbt.totalReflections > 0;
      });
      tmpBottomThree.sort((a, b) =>
        a.averageRating > b.averageRating ? 1 : b.averageRating > a.averageRating ? -1 : 0
      );

      vm.report.graphs.bottomThree = tmpBottomThree.splice(0, 3);

      // Get our type pie chart data
      let reflectionsByTypeLabel = Array();
      let reflectionsByTypeData = Array();

      var tmpRT = vm.report.reflectionsByType;
      tmpRT.forEach((refType, rti) => {
        reflectionsByTypeLabel.push(refType.name);
        reflectionsByTypeData.push(refType.totalReflections);
      });

      vm.report.graphs.reflectionSplit = {
        chartLabels: reflectionsByTypeLabel,
        chartData: reflectionsByTypeData,
      };

      // Get our tag pie chart data
      let reflectionsByTagLabel = Array();
      let reflectionsByTagData = Array();

      var tmpTag = vm.report.reflectionsByTag;
      tmpTag.forEach((tag, ti) => {
        reflectionsByTagLabel.push(tag.name);
        reflectionsByTagData.push(tag.totalReflections);
      });

      vm.report.graphs.reflectionTagSplit = {
        chartLabels: reflectionsByTagLabel,
        chartData: reflectionsByTagData,
      };

      // Get our line chart data
      let reflectionsByDayLabel = Array();
      let reflectionsByDayDataRef = Array();
      let reflectionsByDayDataAvg = Array();

      var tmpRD = vm.report.reflectionsByDay;
      tmpRD.forEach((refDay, rti) => {
        let average = refDay.averageRating;
        if (isNaN(average)) {
          average = 0;
        }

        reflectionsByDayLabel.push(refDay.name);
        reflectionsByDayDataRef.push(refDay.totalReflections);
        reflectionsByDayDataAvg.push(Number(average.toFixed(2)));

      });

      vm.report.graphs.reflectionDay = {
        chartLabels: reflectionsByDayLabel,
        chartDataRef: reflectionsByDayDataRef,
        chartDataAvg: reflectionsByDayDataAvg,
      };


      // Get our line chart data
      let reflectionsByDateLabel = Array();
      let reflectionsByDateDataRef = Array();
      let reflectionsByDateDataAvg = Array();

      var tmpRBDate = vm.report.reflectionsByDate;
      tmpRBDate.forEach((refDay, rti) => {
        let average = refDay.averageRating;
        if (isNaN(average)) {
          average = 0;
        }

        reflectionsByDateLabel.push(refDay.reportDate);
        reflectionsByDateDataRef.push(refDay.totalReflections);
        reflectionsByDateDataAvg.push(Number(average.toFixed(2)));

      });

      vm.report.graphs.reflectionDate = {
        chartLabels: reflectionsByDateLabel,
        chartDataRef: reflectionsByDateDataRef,
        chartDataAvg: reflectionsByDateDataAvg,
      };

      // Create our Document now!
      // vm.createWeeklyReflectionDocument();
      vm.reportCompleted = true;

    },

    logReflection(reflection) {
      const vm = this;

      let reflectionType = reflection.reflectionType;
      let reflectionTag = reflection.tag;
      let reflectionRating = parseInt(reflection.rating);
      if (isNaN(reflectionRating)) {
        reflectionRating = 5;
      }
      let reflectionDay = this.$dayjs(reflection.creationTime.value).format("dddd");
      let reflectionDate = this.$dayjs(reflection.creationTime.value).format("YYYY-MM-DD");
      let reflectionHighlights = reflection.highlights.length;
      let reflectionLowlights = reflection.lowlights.length;
      let reflectionAdjustments = reflection.adjustments.length;

      if(reflectionAdjustments > 0){

        // Add our reflectionsByType
        var tmpAdjustments = reflection.adjustments;
        tmpAdjustments.forEach((adjustment, adj) => {
            vm.report.adjustments.push(
                {
                    activityName: reflection.activityName,
                    reflectionType: reflection.reflectionType,
                    creationSeconds: reflection.creationSeconds,
                    reflectionDate: reflectionDate,
                    adjustment: adjustment,
                    status: 'pending'
                }
            )
        });

      }


      // Add our baseStatistics
      vm.report.baseStatistics.totalReflections++;
      vm.report.baseStatistics.sumRatings += reflectionRating;
      vm.report.baseStatistics.totalHighlights += reflectionHighlights;
      vm.report.baseStatistics.totalLowlights += reflectionLowlights;
      vm.report.baseStatistics.totalAdjustments += reflectionAdjustments;

      // Add our reflectionsByType
      var tmpRT = vm.report.reflectionsByType;
      tmpRT.forEach((refType, rti) => {
        if (refType.name === reflectionType) {
          vm.report.reflectionsByType[rti].totalReflections++;
          vm.report.reflectionsByType[rti].sumRatings += reflectionRating;
          vm.report.reflectionsByType[rti].totalHighlights += reflectionHighlights;
          vm.report.reflectionsByType[rti].totalLowlights += reflectionLowlights;
          vm.report.reflectionsByType[rti].totalAdjustments += reflectionAdjustments;
        }
      });

      // Add our reflectionsByTag
      var tmpTag = vm.report.reflectionsByTag;
      tmpTag.forEach((tag, ti) => {
        if (tag.name === reflectionTag) {
          vm.report.reflectionsByTag[ti].totalReflections++;
          vm.report.reflectionsByTag[ti].sumRatings += reflectionRating;
          vm.report.reflectionsByTag[ti].totalHighlights += reflectionHighlights;
          vm.report.reflectionsByTag[ti].totalLowlights += reflectionLowlights;
          vm.report.reflectionsByTag[ti].totalAdjustments += reflectionAdjustments;
        }
      });

      // Add our reflectionsByDay
      var tmpRD = vm.report.reflectionsByDay;
      tmpRD.forEach((refDay, rdi) => {
        if (refDay.name === reflectionDay) {
          vm.report.reflectionsByDay[rdi].totalReflections++;
          vm.report.reflectionsByDay[rdi].sumRatings += reflectionRating;
          vm.report.reflectionsByDay[rdi].totalHighlights += reflectionHighlights;
          vm.report.reflectionsByDay[rdi].totalLowlights += reflectionLowlights;
          vm.report.reflectionsByDay[rdi].totalAdjustments += reflectionAdjustments;
        }
      });

      // Add our reflectionsByDay
      var tmpRBDate = vm.report.reflectionsByDate;
      tmpRBDate.forEach((refDay, rdi) => {
        if (refDay.reportDate === reflectionDate) {
          vm.report.reflectionsByDate[rdi].totalReflections++;
          vm.report.reflectionsByDate[rdi].sumRatings += reflectionRating;
          vm.report.reflectionsByDate[rdi].totalHighlights += reflectionHighlights;
          vm.report.reflectionsByDate[rdi].totalLowlights += reflectionLowlights;
          vm.report.reflectionsByDate[rdi].totalAdjustments += reflectionAdjustments;
        }
      });


    },

    queryFinished(){
      this.querying = false;
    }

  },
};
</script>

<style scoped>

.cancel-button{
  margin-bottom: -25px;
  padding: 10px 0 10px;
}

.textarea-placeholder::placeholder {
  padding-top: 25px;
}

.action_active {
  border: 2px dashed #8d6c9f;
  border-radius: 25px;
}

.bg-rt {
  background: #e6dfea;
}

.img-rt {
  /* position: relative; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  transition: transform 0.2s;
}

.img-rt-gray {
  filter: grayscale(100%);
  opacity: 0.3;
}

.img-rt-selection:hover {
  transform: scale(1.1);
}

.img-report {
  position: relative;
  top: 0;
  left: 0;
  width: 35px;
  height: 35px;
}

.label-rt {
  font-size: 0.7em;
}

.img-hlll {
  width: 75px;
  height: 75px;
}

.img-rating {
  position: absolute;
  top: 57px;
  margin: 0 0 0 -14px;
  width: 30px;
  height: 30px;
}

/* rating */

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  margin: 15.7px 0;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4.6px;
  cursor: pointer;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
  background: #e6dfea;
  border-radius: 2.4px;
  border: 0px solid rgba(1, 1, 1, 0);
}

input[type="range"]::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid rgba(0, 0, 0, 0);
  height: 36px;
  width: 36px;
  border-radius: 47px;
  background: #8d6c9f;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -15.7px;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #f2eef4;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 4.6px;
  cursor: pointer;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
  background: #e6dfea;
  border-radius: 2.4px;
  border: 0px solid rgba(1, 1, 1, 0);
}

input[type="range"]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid rgba(0, 0, 0, 0);
  height: 36px;
  width: 36px;
  border-radius: 47px;
  background: #8d6c9f;
  cursor: pointer;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 4.6px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type="range"]::-ms-fill-lower {
  background: #dad0e0;
  border: 0px solid rgba(1, 1, 1, 0);
  border-radius: 4.8px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
}

input[type="range"]::-ms-fill-upper {
  background: #e6dfea;
  border: 0px solid rgba(1, 1, 1, 0);
  border-radius: 4.8px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
}

input[type="range"]::-ms-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid rgba(0, 0, 0, 0);
  height: 36px;
  width: 36px;
  border-radius: 47px;
  background: #8d6c9f;
  cursor: pointer;
  height: 4.6px;
}

input[type="range"]:focus::-ms-fill-lower {
  background: #e6dfea;
}

input[type="range"]:focus::-ms-fill-upper {
  background: #f2eef4;
}
</style>
