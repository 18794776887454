<template>
  <div>

    <!-- DEEP DIVES DETAILS -->
    <section class="section my-0 bg-white">

      <div class="container">

        <div class="card-body justify-content-center text-center">
          <h3 class="text-primary mb-5 mt-0 text-left" v-if="user.data">
              Hi {{ user.data.firstName}}, let's get into your reflection deep dive
          </h3>

          <!-- HANDLE OUR LABEL -->
          <div v-if="!report.label">

            <h4 class="text-default text-left">
              Give your deep dive a <span class="font-weight-bold">label or title</span>
            </h4>

            <div class="row justify-content-center">
              <div class="col-12 pt-1">
                <div class="input-group mb-3">
                    <input type="text" class="form-control text-dark" v-model="tmpAnswers.label" placeholder="Enter a label...">
                    <div class="input-group-append">
                      <button type="button" class="btn btn-default" v-if="tmpAnswers.label" @click="commitTmpLabel">Save</button>
                      <button type="button" class="btn btn-default" disabled v-else>Save</button>
                    </div>
                </div>

              </div>
            </div>

          </div>
          <div v-else>

            <div class="card rounded shadow mb-0 text-left p-3">
              <div>

                  <div class="float-right">
                    <img :src="report.user.photoURL" class="avatar avatar-lg round mr-2 mt-2" />
                  </div>

                    <h2 class="text-dark mb-0 font-weight-bold">{{ report.label }}</h2>
                    <p class="text-muted mb-0 mt-2 text-left">
                        for
                          {{ $dayjs( report.startDate ).format("Do MMM") }} - 
                          {{ $dayjs( report.endDate ).format("Do MMM, YYYY") }} 
                    </p>

              </div>
              
            </div>

          </div>

        </div>

      </div>
    
    </section>


    <!-- REFLECT ON THE NUMBER OF REFLECTIONS BY TYPE -->
    <section class="section my-0 bg-secondary" v-if="report.reflectionsByType.length > 1">

      <div class="container">

          
          <div class="card-body justify-content-center text-center">

            <div class="row justify-content-center">
              
              <div class="col-12 mb-4">
                <h4 class="text-default text-left">
                  You spent your <span class="font-weight-bold">reflection time</span> on the
                  following <span class="font-weight-bold">reflection <i class="fal fa-head-side-brain"></i> types</span>
                </h4>
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-lg-4 col-md-6 col-12 mb-4">
                <pie-chart
                  :chartData="report.graphs.reflectionSplit.chartData"
                  :chartLabels="report.graphs.reflectionSplit.chartLabels"
                ></pie-chart>
              </div>

              <div
                class="col-lg-8 col-md-6 col-12 mt-4 mb-4 pt-1 pointer"
                v-if="report.answers.reflectionSplit"
                @click="editAnswer('reflectionSplit')"
              >
                <div class="card rounded shadow mb-0 text-right p-3">
                  <p class="text-dark mb-0" style="white-space: pre-wrap" v-html="report.answers.reflectionSplit"></p>
                  <div class="font-weight-bold text-muted mb-0 mt-2 small">
                    <div class="float-right">
                      <img :src="report.user.photoURL" class="avatar round avatar-xs ml-2" />
                    </div>
                    <div class="mt-2 text-right">
                      {{ report.user.fullName }}
                    </div>
                    
                  </div>
                </div>
              </div>
              <div class="col-lg-8 col-md-6 col-12 mt-4 mb-4 pt-1" v-else>
                <div class="input-group mt-3">
                  <textarea-autosize
                    placeholder="Do you believe that your split of reflection was effective? Did you put your time &amp; energy into the right things?"
                    ref="reflectionSplitTextarea"
                    id="reflectionSplitTextarea"
                    v-model="tmpAnswers.reflectionSplit"
                    :min-height="200"
                    class="form-control text-dark text-center textarea-placeholder"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-default"
                      type="button"
                      id="button-addon2"
                      @click="commitTmpValue('reflectionSplit')"
                    >
                      <i class="fas fa-check"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

          </div>

      </div>

    </section>


    <!-- REFLECT ON THE NUMBER OF REFLECTIONS BY TAG  -->
    <section class="section my-0 bg-white" v-if="report.tags && report.tags.length > 1">

      <div class="container">

        <div class="card-body justify-content-center text-center">

          <div class="row justify-content-center">
            
            <div class="col-12 mb-4">
              <h4 class="text-default text-left">
                You tagged <span class="font-weight-bold">your reflections</span> with the
                following <span class="font-weight-bold">tags <i class="fal fa-tags"></i></span>
              </h4>
            </div>

          </div>

          <div class="row justify-content-center">

            <div
              class="col-lg-8 col-md-6 col-12 mt-4 mb-4 pt-1 pointer"
              v-if="report.answers.reflectionTagSplit"
              @click="editAnswer('reflectionTagSplit')"
            >
              <div class="card rounded shadow mb-0 text-left p-3">
                <p class="text-dark mb-0" style="white-space: pre-wrap" v-html="report.answers.reflectionTagSplit"></p>
                <div class="font-weight-bold text-muted mb-0 mt-2 small">
                  <div class="float-left">
                    <img :src="report.user.photoURL" class="avatar round avatar-xs mr-2" />
                  </div>
                  <div class="mt-2">
                    {{ report.user.fullName }}
                  </div>
                  
                </div>
              </div>
            </div>
            <div class="col-lg-8 col-md-6 col-12 mt-4 mb-4 pt-1" v-else>
              <div class="input-group mt-3">
                <textarea-autosize
                  placeholder="Whats your first thought when you see this split of your reflections? Did you utilise tags effectively?"
                  ref="reflectionTagSplitTextarea"
                  v-model="tmpAnswers.reflectionTagSplit"
                  :min-height="200"
                  class="form-control text-dark text-center textarea-placeholder"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-default"
                    type="button"
                    id="button-addon2"
                    @click="commitTmpValue('reflectionTagSplit')"
                  >
                    <i class="fas fa-check"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-12 mb-4">
              <pie-chart
                :chartData="report.graphs.reflectionTagSplit.chartData"
                :chartLabels="report.graphs.reflectionTagSplit.chartLabels"
              ></pie-chart>
            </div>

          </div>

        </div>

      </div>

    </section>


    <!-- REFLECT ON YOUR HIGHEST RATED REFLECTIONS -->
    <section class="section my-0 bg-secondary" v-if="report.graphs.topThree.lengh > 2">

      <div class="container">
    
          <div class="card-body justify-content-center">

            <div class="row justify-content-center">
              
              <div class="col-12 mb-4">
                <h4 class="text-default text-left">
                  These were the <span class="font-weight-bold">reflections</span> with your
                  <span class="font-weight-bold">highest <i class="fal fa-level-up"></i> average rating</span>
                </h4>
              </div>
              
            </div>

            <div class="row justify-content-center">
              
              <div class="col-lg-12 col-md-6 col-12 mb-4">
                <div class="row">
                  <div
                    class="col-lg-4 col-12 pt-2 pb-2 m-0 text-center justify-content-center"
                    v-for="(rt, index) in report.graphs.topThree"
                    v-bind:key="index"
                  >
                    <div class="text-center">
                      <img :alt="rt.name" class="img-rt" :src="reflectionImage(rt.name)" />
                    </div>
                    <div class="label-rt text-center text-default font-weight-bold">
                      {{ rt.name }}
                    </div>
                    <div class="badge badge-default">{{ rt.totalReflections }} reflections</div>
                    <div class="badge badge-default" v-if="rt.averageRating">
                      average rating of {{ rt.averageRating.toFixed(2) }}
                    </div>
                    <div class="badge badge-default">
                      with {{ rt.totalHighlights }} highlights &amp;
                      {{ rt.totalHighlights }} lowlights
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="col-lg-8 col-md-12 col-12 mt-4 mb-4 pt-1 pointer"
                v-if="report.answers.topThree"
                @click="editAnswer('topThree')"
              >
                <div class="card rounded shadow mb-0 text-left p-3">
                  <p class="text-dark mb-0" style="white-space: pre-wrap" v-html="report.answers.topThree"></p>
                  <div class="font-weight-bold text-muted mb-0 mt-2 small">
                    <div class="float-left">
                      <img :src="report.user.photoURL" class="avatar round avatar-xs mr-2" />
                    </div>
                    <div class="mt-2">
                      {{ report.user.fullName }}
                    </div>
                    
                  </div>
                </div>
              </div>
              <div class="col-lg-8 col-md-12 col-12 mt-4 mb-4 pt-1" v-else>
                <div class="input-group mt-3">
                  <textarea-autosize
                    placeholder="You should be proud of the things you've done well, but what do you think about your top 3 reflection types?"
                    ref="topThreeTextarea"
                    v-model="tmpAnswers.topThree"
                    :min-height="200"
                    class="form-control text-dark text-center textarea-placeholder-fw"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-default"
                      type="button"
                      id="button-addon2"
                      @click="commitTmpValue('topThree')"
                    >
                      <i class="fas fa-check"></i>
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </div>


      </div>

    </section>


    <!-- REFLECT ON YOUR LOWEST RATED REFLECTIONS -->
    <section class="section my-0 bg-white" v-if="report.graphs.bottomThree.lengh > 2">

      <div class="container">
    
        <div class="card-body justify-content-center">

          <div class="row justify-content-center">
            
            <div class="col-12 mb-4">
              <h4 class="text-default text-left">
                These were the <span class="font-weight-bold">reflections</span> with your
                <span class="font-weight-bold">lowest <i class="fal fa-level-down"></i> average rating</span>
              </h4>
            </div>
            
          </div>

          <div class="row justify-content-center">
            <div class="col-lg-12 col-md-6 col-12 mb-4">
              <div class="row">
                <div
                  class="col-lg-4 col-12 pt-2 pb-2 m-0 text-center justify-content-center"
                  v-for="(rt, index) in report.graphs.bottomThree"
                  v-bind:key="index"
                >
                  <div class="text-center">
                    <img :alt="rt.name" class="img-rt" :src="reflectionImage(rt.name)" />
                  </div>
                  <div class="label-rt text-center text-default font-weight-bold">
                    {{ rt.name }}
                  </div>
                  <div class="badge badge-default">{{ rt.totalReflections }} reflections</div>
                  <div class="badge badge-default" v-if="rt.averageRating">
                    average rating of {{ rt.averageRating.toFixed(2) }}
                  </div>
                  <div class="badge badge-default">
                    with {{ rt.totalHighlights }} highlights &amp;
                    {{ rt.totalHighlights }} lowlights
                  </div>
                </div>
              </div>
            </div>

            <div
              class="col-lg-8 col-md-12 col-12 mt-4 mb-4 pt-1 pointer"
              v-if="report.answers.bottomThree"
              @click="editAnswer('bottomThree')"
            >
              <div class="card rounded shadow mb-0 text-left p-3">
                <p class="text-dark mb-0" style="white-space: pre-wrap" v-html="report.answers.bottomThree"></p>
                <div class="font-weight-bold text-muted mb-0 mt-2 small">
                  <div class="float-left">
                    <img :src="report.user.photoURL" class="avatar round avatar-xs mr-2" />
                  </div>
                  <div class="mt-2">
                    {{ report.user.fullName }}
                  </div>
                  
                </div>
              </div>
            </div>
            <div class="col-lg-8 col-md-12 col-12 mt-4 mb-4 pt-1" v-else>
              <div class="input-group mt-3">
                <textarea-autosize
                  placeholder="We all have strengths and weaknesses; are these three reflection types things that you should be focusing on? Why do you think they were rated lower than the rest?"
                  ref="bottomThreeTextarea"
                  v-model="tmpAnswers.bottomThree"
                  :min-height="200"
                  class="form-control text-dark text-center textarea-placeholder-fw"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-default"
                    type="button"
                    id="button-addon2"
                    @click="commitTmpValue('bottomThree')"
                  >
                    <i class="fas fa-check"></i>
                  </button>
                </div>
              </div>
            </div>

          </div>
        </div>


      </div>

    </section>


    <!-- REFLECT ON YOUR ADJUSTMENTS -->
    <section class="section my-0 bg-default">

      <div class="container" v-if="report.adjustments.length > 0">

        <div class="card-body justify-content-center">

            <div class="row justify-content-center">
              
                <div class="col-12 mb-4">
                  <h4 class="text-white text-left">
                    You made <span class="font-weight-bold">commitments to yourself </span> to make some <span class="font-weight-bold">adustments</span>.
                    Were you successful with your <span class="font-weight-bold">adustments</span>?
                  </h4>
                </div>

                <div class="col-12">

                  <ul class="list-group">
                      <li
                        class="list-group-item card rounded shadow mb-3"
                        v-for="(adj, index) in report.adjustments"
                        v-bind:key="index"
                      >
                        <div class="text-primary">
                            When reflecting on your <span class="font-weight-bold">{{ adj.reflectionType }} activity</span> that you titled 
                            <span class="font-weight-bold">{{ adj.activityName }}</span> you said <span class="font-weight-bold">"{{ adj.adjustment }}"</span>
                        </div>

                        <div class="justify-content-center text-center mt-3">

                            <button class="btn btn-success btn-sm mb-2" v-if="adj.status === 'successful'">Successful</button>
                            <button class="btn btn-outline-success btn-sm mb-2" v-else @click="report.adjustments[index].status = 'successful'">Successful</button>

                            <button class="btn btn-info btn-sm mb-2" v-if="adj.status === 'workinprogress'">Work In Progress</button>
                            <button class="btn btn-outline-info btn-sm mb-2" v-else @click="report.adjustments[index].status = 'workinprogress'">Work In Progress</button>

                            <button class="btn btn-danger btn-sm mb-2" v-if="adj.status === 'unsuccessful'">Unsuccessful</button>
                            <button class="btn btn-outline-danger btn-sm mb-2" v-else @click="report.adjustments[index].status = 'unsuccessful'">Unsuccessful</button>

                        </div>

                      </li>
                  </ul>

                </div>
              
            </div>

        </div>

      </div>
      <div class="container" v-else>

        <div class="card-body justify-content-center">

            <div class="row justify-content-center">
              
                <div class="col-12">

                    <h4 class="text-white text-left">
                      You documented no adjustments for the reflections identified in this report.
                      Identifying and committing to adjustments is an important part of your self-development process!
                    </h4>

                </div>

            </div>

        </div>
      </div>

    </section>


    <!-- REFLECT ON BASE STATS and REFLECTION STATS -->
    <section class="section my-0 bg-white">

      <div class="container">

            <div class="card-body justify-content-center">

                <div class="row justify-content-center">
                  
                    <div class="col-12">
                      <h4 class="text-default text-left">
                        Use the below <span class="font-weight-bold">reflection statistics, details</span> and
                        <span class="font-weight-bold">graphs <i class="fal fa-chart-line"></i> </span> to take a <span class="font-weight-bold">deep dive</span> into your
                        <span class="font-weight-bold">reflection habits</span> and their <span class="font-weight-bold">outcomes!</span>
                      </h4>
                    </div>
                  
                </div>

            </div>
            <tabs ref="deepDoveTabs" pills fill type="primary" :active-tab="defaultTab" :value="defaultTab">

              <tab-pane key="statistics" title="statistics">
                  <template slot="title">
                      Statistics
                  </template>

                  <div class="container">

                      <div class="row text-center justify-content-center border p-4">

                        <!-- Base Statistics -->
                        <div class="col-xl-3 col-sm-6 col-6">
                          <stats-card
                            title="Reflections"
                            type="gradient-default"
                            iconSize="fa-2x ml-2"
                            :sub-title="report.baseStatistics.totalReflections.toFixed(0)"
                            icon="fad fa-head-side-brain text-default"
                            class="mb-xl-0"
                          >
                          </stats-card>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-6">
                          <stats-card
                            title="Avg. Rating"
                            type="gradient-default"
                            :sub-title="report.baseStatistics.averageRating.toFixed(2)"
                            icon="fad fa-fist-raised text-info"
                            iconSize="fa-2x ml-2"
                            class="mb-4 mb-xl-0"
                          >
                          </stats-card>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-6">
                          <stats-card
                            title="Activity Types"
                            type="gradient-default"
                            iconSize="fa-2x ml-2"
                            :sub-title="report.reflectionsByType.length"
                            icon="fad fa-crosshairs text-muted"
                            class="mb-xl-0"
                          >
                          </stats-card>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-6">
                          <stats-card
                            title="Tags"
                            type="gradient-default"
                            :sub-title="report.reflectionsByTag.length"
                            icon="fad fa-tags text-dark"
                            iconSize="fa-2x ml-2"
                            class="mb-4 mb-xl-0"
                          >
                          </stats-card>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-6">
                          <stats-card
                            title="Highlights"
                            type="gradient-success"
                            :sub-title="report.baseStatistics.totalHighlights.toFixed(0)"
                            icon="fad fa-thumbs-up text-success"
                            iconSize="fa-2x ml-2"
                            class="mb-4 mb-xl-0"
                          >
                          </stats-card>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-6 mb-4">
                          <stats-card
                            title="Lowlights"
                            type="gradient-danger"
                            :sub-title="report.baseStatistics.totalLowlights.toFixed(0)"
                            icon="fad fa-thumbs-down text-danger"
                            iconSize="fa-2x ml-2"
                            class="mb-4 mb-xl-0"
                          >
                          </stats-card>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-6 mb-4">
                          <stats-card
                            title="Adjustments"
                            type="gradient-primary"
                            :sub-title="report.baseStatistics.totalAdjustments.toFixed(0)"
                            icon="fad fa-exchange-alt text-primary"
                            iconSize="fa-2x ml-2"
                            class="mb-4 mb-xl-0"
                          >
                          </stats-card>
                        </div>

                      </div>

                  </div>


              </tab-pane>

              <tab-pane key="reflections" title="reflections">
                  <template slot="title">
                      Reflections
                  </template>

                  <div class="row justify-content-center pl-3 pr-3">

                    <feed-view
                        name="reflection cards"
                        :actions-allowed="false"
                        :disable-scroll="true"
                        :reflection-types="reflectionTypes"
                        :reflections="report.reflections"
                    ></feed-view>

                  </div>
              </tab-pane>

              <tab-pane key="by-activity-type" title="by-activity-type">
                  <template slot="title"> 
                      By Activity Type
                  </template>

                  <div class="row justify-content-center">

                    <div
                      class="col-lg-6 col-12"
                      v-for="(rbt, ri) in report.reflectionsByType"
                      v-bind:key="ri"
                    >
                      <div class="card card-body mb-4">
                        <div class="row">
                          <div class="col-lg-3 col-md-3 col-12 text-center">
                            <img :alt="rbt.name" class="img-rt-statcard mt-2" :src="reflectionImage(rbt.name)" />
                          </div>

                          <div class="col-lg-3 col-md-3 col-12 text-center">
                            <div class="badge badge-default">Reflections</div>
                            <h5 class="text-center text-default font-weight-bold">
                              {{ rbt.totalReflections.toFixed(0) }}
                            </h5>
                          </div>

                          <div class="col-lg-3 col-md-3 col-12 text-center">
                            <div class="badge badge-default">Avg. Rating</div>
                            <h5
                              class="text-center text-default font-weight-bold"
                              v-if="rbt.averageRating"
                            >
                              {{ rbt.averageRating.toFixed(2) }}
                            </h5>
                            <h5 class="text-center text-default font-weight-bold" v-else>0.00</h5>
                          </div>

                          <div class="col-lg-3 col-md-3 col-12 text-center">
                            <div class="badge badge-default">
                              &nbsp;&nbsp;&nbsp;<i class="fas fa-thumbs-up"></i
                              >&nbsp;&nbsp;&amp;&nbsp;&nbsp;<i class="fas fa-thumbs-down"></i
                              >&nbsp;&nbsp;&amp;&nbsp;&nbsp;<i class="fas fa-exchange-alt"></i
                              >&nbsp;&nbsp;&nbsp;
                            </div>
                            <h5 class="text-center text-default font-weight-bold">
                              {{ rbt.totalHighlights.toFixed(0) }} &amp;
                              {{ rbt.totalLowlights.toFixed(0) }} &amp;
                              {{ rbt.totalAdjustments.toFixed(0) }}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

              </tab-pane>

              <tab-pane key="by-tag" title="by-tag">
                  <template slot="title">
                      By Tag
                  </template>

                  <div class="row justify-content-center">

                    <div
                      class="col-lg-6 col-12"
                      v-for="(rbtag, tagindex) in report.reflectionsByTag"
                      :key="`${tagindex}-${rbtag.name}`"
                    >

                      <div class="card card-body mb-4">
                        <div class="row">
                          <div class="col-lg-3 col-md-3 col-12 text-center">
                            <div class="text-center text-default font-weight-bold">
                              <small>{{ rbtag.name }}</small>
                            </div>
                            <div class="text-center text-muted" v-if="rbtag.type">
                              <small>{{ rbtag.type }}</small>
                            </div>
                            <div class="text-center text-muted" v-else><small>all types</small></div>
                          </div>

                          <div class="col-lg-3 col-md-3 col-12 text-center">
                            <div class="badge badge-default">Reflections</div>
                            <h5 class="text-center text-default font-weight-bold">
                              {{ rbtag.totalReflections.toFixed(0) }}
                            </h5>
                          </div>

                          <div class="col-lg-3 col-md-3 col-12 text-center">
                            <div class="badge badge-default">Avg. Rating</div>
                            <h5
                              class="text-center text-default font-weight-bold"
                              v-if="rbtag.averageRating"
                            >
                              {{ rbtag.averageRating.toFixed(2) }}
                            </h5>
                            <h5 class="text-center text-default font-weight-bold" v-else>0.00</h5>
                          </div>

                          <div class="col-lg-3 col-md-3 col-12 text-center">
                            <div class="badge badge-default">
                              &nbsp;&nbsp;&nbsp;<i class="fas fa-thumbs-up"></i
                              >&nbsp;&nbsp;&amp;&nbsp;&nbsp;<i class="fas fa-thumbs-down"></i
                              >&nbsp;&nbsp;&amp;&nbsp;&nbsp;<i class="fas fa-exchange-alt"></i
                              >&nbsp;&nbsp;&nbsp;
                            </div>
                            <h5 class="text-center text-default font-weight-bold">
                              {{ rbtag.totalHighlights.toFixed(0) }} &amp;
                              {{ rbtag.totalLowlights.toFixed(0) }} &amp;
                              {{ rbtag.totalAdjustments.toFixed(0) }}
                            </h5>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>

              </tab-pane>

              <tab-pane key="by-day" lazy title="by-day">
                  <template slot="title">
                      By Day
                  </template>

                  <div class="row justify-content-center" v-if="loadByDayGroup">

                      <div class="col-12">

                        <div class="card card-body mb-4">
                          <div class="line-chart mb-4">
                            <line-chart
                              :chartDataRef="report.graphs.reflectionDay.chartDataRef"
                              :chartDataAvg="report.graphs.reflectionDay.chartDataAvg"
                              :chartLabels="report.graphs.reflectionDay.chartLabels"
                            ></line-chart>
                          </div>
                        </div>

                      </div>

                  </div>
              </tab-pane>

              <tab-pane key="by-date" lazy title="by-date">
                  <template slot="title">
                      By Date
                  </template>

                  <div class="row justify-content-center"  v-if="loadByDateGroup">

                      <div class="col-12">

                        <div class="card card-body mb-4">
                          <div class="line-chart mb-4">
                            <line-chart
                              :chartDataRef="report.graphs.reflectionDate.chartDataRef"
                              :chartDataAvg="report.graphs.reflectionDate.chartDataAvg"
                              :chartLabels="report.graphs.reflectionDate.chartLabels"
                            ></line-chart>
                          </div>
                        </div>

                      </div>

                  </div>
              </tab-pane>

            </tabs>

        </div>
    </section>


    <!-- DEEP DIVE HIGHLIGHTS & LOWLIGHTS -->
    <section class="section my-0 bg-dark">

      <div class="container">

          <div
            class="card-body justify-content-center"
          >

            <div class="row justify-content-center">
              
                <div class="col-12 mb-4">
                  <h4 class="text-white text-left">
                    Based on the <span class="font-weight-bold">insights</span> from your <span class="font-weight-bold">reflections</span>,
                    are there <span class="font-weight-bold">deeper highlights, lowlights or adjustments</span>  that you would like to commit to? 
                  </h4>
                </div>
              
            </div>
                    
            <div class="row justify-content-center pt-1">

              <div class="col-lg-4 col-12 p-2 text-center">
                <div class="input-group">
                  <textarea-autosize
                    placeholder="What did you do well; the highlights?"
                    ref="highlightTextarea"
                    v-model="tempHighlight"
                    :min-height="80"
                    class="form-control text-center textarea-placeholder-highlight text-dark"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-default"
                      type="button"
                      id="button-addon2"
                      @click="commitHighlight"
                    >
                      <i class="fad fa-thumbs-up"></i>
                    </button>
                  </div>
                </div>

                <div v-if="report.deepDiveRetrospective.highlights.length > 0" class="mt-3">
                  <ul class="list-group">
                    <li
                      class="list-group-item"
                      v-for="(highlight, index) in report.deepDiveRetrospective.highlights"
                      v-bind:key="index"
                    >
                      <div class="float-right">
                        <i class="fa fa-trash pointer" @click="deleteHighlight(index)"></i>
                      </div>
                      <span style="white-space: pre-wrap" v-html="highlight"></span>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-lg-4 col-12 p-2 text-center">
                <div class="input-group">
                  <textarea-autosize
                    placeholder="What could have been better; the lowlights?"
                    ref="lowlightTextarea"
                    v-model="tempLowlight"
                    :min-height="80"
                    class="form-control text-center textarea-placeholder-lowlight"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-default"
                      type="button"
                      id="button-addon2"
                      @click="commitLowlight"
                    >
                      <i class="fad fa-thumbs-down"></i>
                    </button>
                  </div>
                </div>

                <div v-if="report.deepDiveRetrospective.lowlights.length > 0" class="mt-3">
                  <ul class="list-group">
                    <li
                      class="list-group-item"
                      v-for="(lowlight, index) in report.deepDiveRetrospective.lowlights"
                      v-bind:key="index"
                    >
                      <div class="float-right">
                        <i class="fa fa-trash pointer" @click="deleteLowlight(index)"></i>
                      </div>
                      <span style="white-space: pre-wrap" v-html="lowlight"></span>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-lg-4 col-12 p-2 text-center">
                <div class="input-group">
                  <textarea-autosize
                    placeholder="What adjustments should you make?"
                    ref="adjustmentTextarea"
                    v-model="tempAdjustment"
                    :min-height="80"
                    class="form-control text-center textarea-placeholder-adjustment text-dark"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-default"
                      type="button"
                      id="button-addon2"
                      @click="commitAdjustment"
                    >
                      <i class="fad fa-exchange-alt"></i>
                    </button>
                  </div>
                </div>

                <div v-if="report.deepDiveRetrospective.adjustments.length > 0" class="mt-3">
                  <ul class="list-group">
                    <li
                      class="list-group-item"
                      v-for="(adjustment, index) in report.deepDiveRetrospective.adjustments"
                      v-bind:key="index"
                    >
                      <div class="float-right">
                        <i class="fa fa-trash pointer" @click="deleteAdjustment(index)"></i>
                      </div>
                      <span style="white-space: pre-wrap" v-html="adjustment"></span>
                    </li>
                  </ul>
                </div>
              </div>

            </div>

          </div>

      </div>

    </section>


    <!-- SENTIMENT ANALYSIS -->
    <section class="section my-0 bg-secondary">

      <div class="container">

          <div class="card-body justify-content-center">

              <div class="row justify-content-center">
                
                  <div class="col-12">

                      <h4 class="text-primary text-left">
                        Congratulations! We ran a <span class="font-weight-bold">sentiment analysis</span> on your reflections &amp; deep dive responses
                      </h4>
                      <p>Use this to gain insights into potential subconscious feelings related to this activity</p>

                  </div>
                
              </div>

          </div>
          <analysis
              ref="ourAnalysis"
              :document="report"
              :current-user="user.data"
              :hide-title="true"
          ></analysis>
          

      </div>

    </section>

  </div>
</template>
<script>

import store from '@/store.js'
import { mapGetters } from "vuex";

import StatsCard from "@/components/StatsCardClean";
import VueCharts from "vue-chartjs";
import { Pie, Bar, Line } from "vue-chartjs";
import PieChart from "@/models/pieChart";
import PieChartNew from "@/models/pieChart";
import LineChart from "@/models/lineChart";
import reflectionTypes from "@/models/reflectionTypes";
// import CardView from '@/components/reflections/CardView';
import FeedView from "@/components/reflections/FeedView";
import Analysis from "@/components/analysis/DeepDiveAnalysis";

import Tabs from "@/components/Tabs/Tabs.vue";
import TabPane from "@/components/Tabs/TabPane.vue";

export default {
  name: "deep-dive-layout",
  components: {
    PieChart,
    PieChartNew,
    LineChart,
    StatsCard,
    FeedView,
    Analysis,
    Tabs,
    TabPane
  },

  props: ["deepdive", "showTitle", "expandedDetails"],

  data() {
    return {
      analysis: null,
      report: _.cloneDeep(this.deepdive),
      defaultTab: null,
      loadByDayGroup: false,
      loadByDateGroup: false,
      reflectionTypes,
      tmpAnswers : {
          label: '',
          topThree: '',
          bottomThree: '',
          reflectionSplit: '',
          reflectionTagSplit: '',
          cardsReviewed: false,
          retrospectiveCompleted: false
      },
      tempHighlight: null,
      tempLowlight: null,
      tempAdjustment: null
    };
  },

  mounted() {

    this.$watch(
        "$refs.deepDoveTabs.activeTabIndex", (new_value, old_value) => {

            if(new_value === 4){
              this.loadByDayGroup = true;
            }

            if(new_value === 5){
              this.loadByDateGroup = true;
            }

        }
    );

    this.$watch(
        "$refs.ourAnalysis.analysis", (new_value, old_value) => {

            this.updateAnalysis()

        }
    );

  },

  watch: {},

  computed: {

      ...mapGetters({
          // map `this.user` to `this.$store.getters.user`
          // map `this.groups` to `this.$store.getters.groups`
          user: "user",
          groups: "groups"
      }),

  },

  methods: {

    // getTextAreaPadding(){

    //   let textarea = document.getElementById("reflectionSplitTextarea");
    //   let height = textarea.scrollHeight;
    //   let style="{ 'textarea:placeholder': padding-top: " + height * .4 + "px }"
    //   console.log(style)
    //   return style

    // },

    editAnswer(value){

      this.tmpAnswers[value] = this.report.answers[value];
      this.report.answers[value] = null;

    },

    commitTmpValue(value){
      this.report.answers[value] = this.tmpAnswers[value];
    },

    commitTmpLabel(){
      this.report.label = this.tmpAnswers.label;
    },

    reflectionImage(rtype){
      let reflectionType = this.reflectionTypes.find(r => r.name === rtype);
      return reflectionType.image
    },

    returnPerc(previous, current) {
      return ((previous - current) / previous) * 100;
    },

    commitHighlight() {

      if(!this.tempHighlight){
        return;
      }

      this.report.deepDiveRetrospective.highlights.push(this.tempHighlight.trim());
      this.tempHighlight = "";
    },

    commitLowlight() {

      if(!this.tempLowlight){
        return;
      }

      this.report.deepDiveRetrospective.lowlights.push(this.tempLowlight.trim());
      this.tempLowlight = "";
    },

    commitAdjustment() {

      if(!this.tempAdjustment){
        return;
      }

      this.report.deepDiveRetrospective.adjustments.push(this.tempAdjustment.trim());
      this.tempAdjustment = "";
    },

    deleteHighlight(i) {
      this.report.deepDiveRetrospective.highlights.splice(i, 1);
    },

    deleteLowlight(i) {
      this.report.deepDiveRetrospective.lowlights.splice(i, 1);
    },

    deleteAdjustment(i) {
      this.report.deepDiveRetrospective.adjustments.splice(i, 1);
    },

    updateAnalysis(){

      this.analysis = this.$refs.ourAnalysis.analysis

    }

  },
};
</script>
<style>

/* // Small devices (mobile) */
.textarea-placeholder::placeholder {
  padding-top: 35px;
  padding-right: 5px;
}
.textarea-placeholder-fw::placeholder {
  padding-top: 30px;
  padding-right: 5px;
}
.textarea-placeholder-highlight::placeholder {
  padding-top: 18px;
}
.textarea-placeholder-lowlight::placeholder {
  padding-top: 10px;
}
.textarea-placeholder-adjustment::placeholder {
  padding-top: 18px;
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 
  
    .textarea-placeholder::placeholder {
      padding-top: 70px;
      padding-right: 5px;
    }
    .textarea-placeholder-fw::placeholder {
      padding-top: 70px;
      padding-right: 5px;
    }
    .textarea-placeholder-highlight::placeholder {
      padding-top: 18px;
    }
    .textarea-placeholder-lowlight::placeholder {
      padding-top: 10px;
    }
    .textarea-placeholder-adjustment::placeholder {
      padding-top: 18px;
    }

 }

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 

    .textarea-placeholder::placeholder {
      padding-top: 35px;
      padding-right: 5px;
    }
    .textarea-placeholder-fw::placeholder {
      padding-top: 70px;
      padding-right: 5px;
    }
    .textarea-placeholder-highlight::placeholder {
      padding-top: 18px;
    }
    .textarea-placeholder-lowlight::placeholder {
      padding-top: 10px;
    }
    .textarea-placeholder-adjustment::placeholder {
      padding-top: 18px;
    }

 }

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

    .textarea-placeholder::placeholder {
      padding-top: 70px;
      padding-right: 5px;
    }
    .textarea-placeholder-fw::placeholder {
      padding-top: 70px;
      padding-right: 5px;
    }
    .textarea-placeholder-highlight::placeholder {
      padding-top: 18px;
    }
    .textarea-placeholder-lowlight::placeholder {
      padding-top: 10px;
    }
    .textarea-placeholder-adjustment::placeholder {
      padding-top: 18px;
    }

 }

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 

    .textarea-placeholder::placeholder {
      padding-top: 70px;
      padding-right: 5px;
    }
    .textarea-placeholder-highlight::placeholder {
      padding-top: 18px;
    }
    .textarea-placeholder-lowlight::placeholder {
      padding-top: 10px;
    }
    .textarea-placeholder-adjustment::placeholder {
      padding-top: 18px;
    }

 }


.line-chart {
  /* max-width: 100%; */
  height: 350px;
  /* margin:  50px auto; */
}

.img-rt-statcard {
  /* position: relative; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 35px;
  height: 35px;
}

.img-rt {
  /* position: relative; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 60px;
  height: 60px;
}

.label-rt {
  font-size: 0.7em;
}

.img-filter {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 35px;
  height: 35px;
}

.unselected {
  filter: grayscale(100%);
  opacity: 0.3;
}
</style>
