<template>
    <div>
        
        <div class="text-center" v-for="(attach, index) in attachmentData" v-bind:key="index">

          <div v-if="attach">

              <embed-pie-chart
                :attachment="attach"
                :id="id"
                :index="index"
                :ref="'attachment-' + index"
                :read-only="readOnly"
                v-if="attach.type === 'piechart'"
              >
              </embed-pie-chart>

              <embed-metric
                :attachment="attach"
                :id="id"
                :index="index"
                :ref="'attachment-' + index"
                :read-only="readOnly"
                v-if="attach.type === 'metrics'"
              >
              </embed-metric>

              <embed-table
                :attachment="attach"
                :id="id"
                :index="index"
                :ref="'attachment-' + index"
                :read-only="readOnly"
                v-if="attach.type === 'table'"
              >
              </embed-table>

              <embed-sports-result
                :attachment="attach"
                :id="id"
                :index="index"
                :ref="'attachment-' + index"
                :read-only="readOnly"
                v-if="attach.type === 'sportsresult'"
              >
              </embed-sports-result>

              <embed-video
                :attachment="attach"
                :index="index"
                :ref="'attachment-' + index"
                :read-only="readOnly"
                v-if="attach.type === 'video'"
                
              >
              </embed-video>

              <embed-website
                :attachment="attach"
                :index="index"
                :ref="'attachment-' + index"
                :read-only="readOnly"
                v-if="attach.type === 'website'"
                
              >
              </embed-website>

              <embed-cta
                :attachment="attach"
                :index="index"
                :ref="'attachment-' + index"
                :read-only="readOnly"
                v-if="attach.type === 'cta'"
                
              >
              </embed-cta>

              <embed-podcast-episode
                :attachment="attach"
                :index="index"
                :ref="'attachment-' + index"
                :read-only="readOnly"
                v-if="attach.type === 'podcast-episode'"
                
              >
              </embed-podcast-episode>

              <embed-image
                :attachment="attach"
                :index="index"
                :ref="'attachment-' + index"
                :read-only="readOnly"
                v-if="attach.type === 'image'"
                
              >
              </embed-image>

              <embed-pdf
                :attachment="attach"
                :index="index"
                :ref="'attachment-' + index"
                :read-only="readOnly"
                v-if="attach.type === 'pdf'"
                
              >
              </embed-pdf>

              <embed-survey
                :attachment="attach"
                :id="id"
                :index="index"
                :ref="'attachment-' + index"
                :read-only="readOnly"
                v-if="attach.type === 'survey'"
              >
              </embed-survey>

              <embed-poll
                :attachment="attach"
                :id="id"
                :index="index"
                :ref="'attachment-' + index"
                :read-only="readOnly"
                v-if="attach.type === 'poll'"
              >
              </embed-poll>

              <div v-if="!id">

                <a role="button" v-if="index !== 0" @click="reorderAttachment(index,-1)">
                  <icon
                      size="sm"
                      rounded
                      type="default"
                      name="fal fa-chevron-up"
                      class="pointer m-1"
                  >
                  </icon>
                </a>

                <a role="button" @click="removeAttachment(index)">
                  <icon
                      size="sm"
                      rounded
                      type="default"
                      name="fad fa-trash"
                      class="pointer m-1"
                  >
                  </icon>
                </a>

                <a role="button" v-if="index !== attachmentData.length-1" @click="reorderAttachment(index,1)">
                  <icon
                      size="sm"
                      rounded
                      type="default"
                      name="fal fa-chevron-down"
                      class="pointer m-1"
                  >
                  </icon>
                </a>

              </div>

              <hr class="separator separator--dots" v-if="attachmentData.length > 1 && !minimized">

          </div>

        </div>

        <div v-if="id">

          <base-button class="mb-5" type="danger" outline v-if="editMode" @click="disableEdit">Cancel</base-button>
          <base-button class="mb-5" type="default" outline v-if="showSave" @click="saveAttachments">Save</base-button>

          <base-button class="mb-5" type="default" outline v-if="showEdit && !editMode" @click="enableEdit">Edit</base-button>

        </div>

    </div>
</template>
<script>

const EmbedPieChart = () => import('@/components/embeds/EmbedPieChart');
const EmbedMetric = () => import('@/components/embeds/EmbedMetric');
const EmbedTable = () => import('@/components/embeds/EmbedTable');
const EmbedSportsResult = () => import('@/components/embeds/EmbedSportsResult');
const EmbedSurvey = () => import('@/components/embeds/EmbedSurvey');
const EmbedPoll = () => import('@/components/embeds/EmbedPoll');
const EmbedVideo = () => import('@/components/embeds/EmbedVideo');
const EmbedWebsite = () => import('@/components/embeds/EmbedWebsite');
const EmbedCta = () => import('@/components/embeds/EmbedCTA');
const EmbedImage = () => import('@/components/embeds/EmbedImage');
const EmbedPdf = () => import('@/components/embeds/EmbedPDF');
const EmbedPodcastEpisode = () => import('@/components/embeds/EmbedPodcastEpisode');

import firebase from "firebase/app";
import { db } from "@/firebase";

export default {

    name: 'advanced-attachments',

    components: {
        EmbedPieChart,
        EmbedMetric,
        EmbedSportsResult,
        EmbedTable,
        EmbedVideo,
        EmbedSurvey,
        EmbedPoll,
        EmbedWebsite,
        EmbedCta,
        EmbedImage,
        EmbedPdf,
        EmbedPodcastEpisode
    },

    props: ['attachment','id','readOnly','minimized'],

    mounted() {

      this.prepareAttachment();

    },

    watch: {

        attachment: {
            handler: function () {

                this.attachmentData = []
                setTimeout(this.prepareAttachment,200);
            },
            deep: true,
        }

    },

    computed: {

        showSave() {

          let att = this.attachmentData
          let show = false

          if(this.readOnly){
            return false
          }

          att.forEach((record,i) => {

              if(record.type !== 'table' && record.data && this.requestDataChecker(record)){
                show = true;
              }

          });

          if(this.editMode){
            show = true;
          }

          return show;

        },

        showEdit() {

          let att = this.attachmentData
          let show = false
          att.forEach((record,i) => {

              if(record.type !== 'table' && record.data && this.editDataChecker(record)){
                show = true;
              }

          });

          return show;

        }

    },

    data () {
        return {
          attachmentData: [],
          editMode: false
        }
    },
    methods: {

      removeAttachment(index){
        this.$emit('updateAttachment', { action: 'delete', index: index })
      },

      reorderAttachment(index,order){
        this.$emit('updateAttachment', { action: 'reorder', order: order, index: index })
      },

      editDataChecker(record){

          if(this.readOnly){
            return false
          }

          if(record.allowDataEdits){
              return true
          }

          return false
      },

      enableEdit(){

        if(this.readOnly){
          this.editMode = false;
          return
        }

        let att = this.attachmentData
        att.forEach((record,i) => {
            
            let ref = 'attachment-' + i

            if(this.$refs[ref][0].localData.allowDataEdits){
              this.$refs[ref][0].editEnabled = true
              this.editMode = true
            }


        });

      },

      disableEdit(){

        let att = this.attachmentData
        att.forEach((record,i) => {
            
            let ref = 'attachment-' + i

            if(this.$refs[ref][0].refresh === false){
                this.$refs[ref][0].refresh = true
            }

            if(this.$refs[ref][0].localData.allowDataEdits){
              this.$refs[ref][0].editEnabled = false
              this.editMode = false
            }


        });

      },

      requestDataChecker(record){

          if(record.allowDataEdits && this.editMode){
            return true
          }

          if(record.requestData && this.checkForValues(record)){
            return true
          }

          return false
      },

      checkForValues(record){

          let data = record.data
          let dataReq = false

          data.forEach((r,i) => {

              if(!r.value){
                  dataReq = true
              }

          });

          return dataReq

      },

      prepareAttachment(){

        let isAttachmentArray = Array.isArray(this.attachment)
        if(!isAttachmentArray){
          this.attachmentData.push(this.attachment);
        }else{
          this.attachmentData = this.attachment;
        }

      },

      saveAttachments(){

        let att = this.attachmentData
        let newAttachment = []

        att.forEach((record,i) => {
            
            let ref = 'attachment-' + i
            newAttachment.push(this.$refs[ref][0].localData)

        });

        db.collection("reflections")
            .doc(this.id)
            .update({
                "attachment": newAttachment
            });

        this.disableEdit()

      }

    }
};
</script>

<style scoped>

.separator {
  margin-top: 25px;
  margin-bottom: 50px;
  border: 0;
}

.separator--dots {
  overflow: visible;
  border: 0;
  content: "";
  background-color: #8d6c9f;
  display: block;
  height: 3px;
  width: 25px;
  /* border-radius: 50%; */
  position: relative;
}
.separator--dots:before {
  content: "";
  background-color: #ccc;
  display: block;
  height: 3px;
  width: 25px;
  /* border-radius: 50%; */
  position: absolute;
  left: -25px;
  -webkit-animation: dot-move-left 1s ease-out forwards;
          animation: dot-move-left 1s ease-out forwards;
}
.separator--dots:after {
  content: "";
  background-color: #ccc;
  display: block;
  height: 3px;
  width: 25px;
  /* border-radius: 50%; */
  position: absolute;
  left: 25px;
  -webkit-animation: dot-move-right 1s ease-out forwards;
          animation: dot-move-right 1s ease-out forwards;
}

</style>