<template>
    <div>

        <div class="row justify-content-center text-center pl-3 pr-3 mb-2">

            <div class="col-12" v-show="attachment.title || attachment.summary">
                <p class="card-title mb-1 text-default font-weight-bold" v-show="attachment.title">{{ attachment.title }}<br /></p>
                <p v-show="attachment.summary">{{ attachment.summary }}</p>
            </div>

            <div class="col-lg-4 col-12">

                <pie-chart
                    :chartData="chart.values"
                    :chartLabels="chart.labels"
                    v-if="!newData && chartDataBuilt"
                ></pie-chart>

                <div class="mt-4 mb-4" v-if="(attachment.displayTable && readOnly) || (attachment.displayTable && !attachment.allowDataEdits && readOnly)">

                    <table class="table table-sm small">
                        <thead>
                            <tr>
                                <th>
                                    Label
                                </th>
                                <th>
                                    Value
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(record, index) in localData.data" v-bind:key="index">
                                <td>
                                    {{ record.label }}
                                </td>
                                <td>
                                    {{ record.value }}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>

                <div class="mt-4 mb-4" v-else-if="localData.allowDataEdits && !readOnly">

                    <table class="table table-sm small">
                        <thead>
                            <tr>
                                <th>
                                    Label
                                </th>
                                <th>
                                    Value
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(record, index) in localData.data" v-bind:key="index">
                                <td>
                                    {{ record.label }}
                                </td>
                                <td>
                                    <input type="number" class="form-control form-control-sm" v-model="localData.data[index].value">
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>

            </div>

        </div>
    </div>
</template>
<script>

import { Pie } from "vue-chartjs";
import PieChart from "@/models/pieChart";

import _ from "lodash";

export default {
    name: 'embed-piechart-embed',
    props: ['attachment','id','index','readOnly'],
    components: {
        Pie,
        PieChart
    },
    mounted() {
      this.convertForChart()
    },

    data () {
        return {
            localData: _.cloneDeep(this.attachment),
            chart: {
                labels: [],
                values: []
            },
            newData: false,
            chartDataBuilt: false,
            editEnabled: false,
            refresh: false
        }
    },
    computed: {

    },

    watch: {

        attachment: {
            handler: function () {
                setTimeout(this.resetNewData,200);
            },
            deep: true,
        },

        refresh: function(newVal,oldVal){
            if(newVal === true){
                this.localData = _.cloneDeep(this.attachment)
                this.refresh = false
            }
        }

    },

    methods: {

        resetNewData(){
            this.newData = false;
        },

        convertForChart(){

            const vm = this

            let data = this.attachment.data

            data.forEach((record,i) => {

                vm.chart.labels.push(record.label);
                vm.chart.values.push(record.value);

            });

            vm.chartDataBuilt = true


        }

    }
};
</script>

<style scoped>

</style>