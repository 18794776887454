export default [
  {
    id: "cta",
    name: "call to action",
    image: "/img/attachmentTypes/cta.svg",
    share: true,
    request: false,
    admin: true,
    baseline: {
        title: null,
        summary: null,
        requestData: false,
        allowDataEdits: false,
        showAdvanced: false,
        src: null,
        class: null,
        icon: null,
        type: 'cta'
    }
  },
  {
    id: "pdf",
    name: "pdf",
    image: "/img/attachmentTypes/pdf.svg",
    share: true,
    request: false,
    baseline: {
        title: null,
        summary: null,
        requestData: false,
        allowDataEdits: false,
        src: null,
        type: 'pdf'
    }
  },
  {
    id: "image",
    name: "image",
    image: "/img/attachmentTypes/image.svg",
    share: true,
    request: false,
    baseline: {
        title: null,
        summary: null,
        requestData: false,
        allowDataEdits: false,
        src: null,
        type: 'image'
    }
  },
  {
    id: "metrics",
    name: "cards",
    image: "/img/attachmentTypes/metrics.svg",
    share: true,
    request: true,
    baseline: {
        title: null,
        summary: null,
        requestData: false,
        allowDataEdits: false,
        data: [
            { 
                label: null,
                value: null,

                showAdvanced: false,
                class: null,
                prefix: null,
                icon: null,
                format: null
              }
        ],
        type: 'metrics'
    }
  },
  {
    id: "pie chart",
    name: "pie chart",
    image: "/img/attachmentTypes/piechart.svg",
    share: true,
    request: false,
    baseline: {
        title: null,
        summary: null,
        type: 'piechart',
        requestData: false,
        allowDataEdits: false,
        displayTable: false,
        showAdvanced: false,
        data: [
          { label: null, value: null }
      ],
      }
  },
  {
    id: "podcast-episode",
    name: "podcast episode",
    image: "/img/attachmentTypes/podcast-episode.svg",
    share: true,
    request: false,
    baseline: {
        title: null,
        summary: null,
        requestData: false,
        allowDataEdits: false,
        src: null,
        type: 'podcast-episode'
    }
  },
  {
    id: "sportsresult",
    name: "sports result",
    image: "/img/attachmentTypes/sportsresult.svg",
    share: true,
    request: true,
    baseline: {
        title: null,
        summary: null,
        requestData: false,
        allowDataEdits: false,
        data: [
            { label: null, value: null, showAdvanced: false, class: null },
            { label: null, value: null, showAdvanced: false, class: null  }
        ],
        type: 'sportsresult',
        showAdvanced: false,
        class: null
    }
  },
  {
    id: "survey",
    name: "survey",
    image: "/img/attachmentTypes/survey.svg",
    share: false,
    request: true,
    baseline: {
        title: null,
        summary: null,
        requestData: false,
        allowDataEdits: false,
        data: [
          { label: null, value: null, type: null }
      ],
        type: 'survey'
    }
  },
  // {
  //   id: "poll",
  //   name: "poll",
  //   image: "/img/attachmentTypes/poll.svg",
  //   share: false,
  //   request: true,
  //   baseline: {
  //       customLabel: 'What do you want to ask?',
  //       title: null,
  //       summary: null,
  //       requestData: true,
  //       allowDataEdits: false,
  //       data: [
  //         { label: null, value: null }
  //     ],
  //       type: 'poll'
  //   }
  // },
  {
    id: "table",
    name: "table",
    image: "/img/attachmentTypes/table.svg",
    share: true,
    request: false,
    baseline: {
        title: null,
        summary: null,
        inputFormat: "json",
        inputData: null,
        requestData: false,
        reqVerification: true,
        allowDataEdits: false,
        headings: [],
        data: [],
        type: 'table'
    }
  },
  {
    id: "video",
    name: "video",
    image: "/img/attachmentTypes/video.svg",
    share: true,
    request: true,
    baseline: {
        title: null,
        summary: null,
        requestData: false,
        allowDataEdits: false,
        src: null,
        type: 'video'
    }
  },
  {
    id: "website",
    name: "website",
    image: "/img/attachmentTypes/website.svg",
    share: true,
    request: false,
    baseline: {
        title: null,
        summary: null,
        requestData: false,
        allowDataEdits: false,
        src: null,
        pageImage: null,
        pageTitle: null,
        pageDescription: null,
        type: 'website'
    }
  }
];
