<template>
  <div>
    <div class="container" v-if="user.androidDetected">
      <div class="row mb-4">
        <div class="col-12 mb-4 pt-4">
          <h5 class="card-title text-default font-weight-bold">
            This feature requires a subscription but they are not currently available on
            Android
            <small
              ><br />you can get access this subscription features via the webapp</small
            >
          </h5>
        </div>
      </div>
    </div>
    <div class="container" v-else-if="requiredLicense">
      <div class="row mb-4">
        <div class="col-12 mb-4 pt-4">
          <h4 class="text-default text-center mb-4">
            Pricing &amp; Subscription Details
          </h4>
        </div>
      </div>

      <div class="row">
        <!-- <div class="col-12 col-lg-4 mb-4">
          <div class="mt-4 d-none d-lg-block d-xl-block"><br /></div>
          <div class="mt-4 d-none d-lg-block d-xl-block"><br /></div>
          <div class="mt-4 d-none d-lg-block d-xl-block"><br /></div>
          <div class="mt-4 d-none d-lg-block d-xl-block"><br /></div>
          <div class="mt-4 d-none d-lg-block d-xl-block"><br /></div>

          <h5 class="text-default text-center text-lg-right text-xl-right mt-4">
            Choose a plan that<br />
            is right for you
          </h5>
        </div> -->

        <div
          class="col-12 col-md-6 col-lg-4 mb-4 text-center clearfix"
          v-for="(license, l) in licenseTypes"
          v-bind:key="l"
        >
          <card shadow no-body>
            <template slot="header">
              <span class="text-default font-weight-bold text-uppercase">{{
                license.name
              }}</span>
            </template>
            <ul class="list-group text-left">
              <li
                class="list-group-item list-group-item-action"
                v-for="(feature, f) in license.features"
                v-bind:key="f"
              >
                <span class="text-default small" v-if="feature.available === true">
                  <i class="fas fa-check mr-2"></i> {{ feature.name }}
                </span>

                <span class="text-muted small" v-else-if="feature.available === false">
                  <i class="fas fa-times mr-2"></i> {{ feature.name }}
                </span>

                <span
                  class="text-default small"
                  v-else
                  v-b-popover.hover.top="feature.label"
                >
                  <i class="fas fa-question mr-2"></i> {{ feature.name }}
                </span>
              </li>
            </ul>
            <template
              slot="footer"
              v-if="
                user.subscription.tier === license.tier && !user.subscription.sponsored
              "
            >
              <base-button type="default" outline v-if="license.tier === 0"
                >Current</base-button
              >
              <base-button
                type="default"
                icon="fas fa-check"
                outline
                v-else
                @click="createPortalLink"
                >Manage</base-button
              >
            </template>
            <template
              slot="footer"
              v-else-if="
                user.subscription.tier > license.tier && !user.subscription.sponsored
              "
            >
              <base-button type="default" @click="createPortalLink"
                >Downgrade</base-button
              >
            </template>
            <template slot="footer" v-else-if="!user.subscription.sponsored">
              <!-- <base-button type="default">Upgrade</base-button> -->
              <base-button type="default" @click="loadCheckout">UPGRADE</base-button>
            </template>
          </card>
        </div>

        <modal
          :show.sync="modals.stripeLoading"
          gradient="default"
          modal-classes="modal-default modal-dialog-centered"
        >
          <div class="py-3 text-center">
            <i class="fad fa-spinner-third fa-4x fa-spin"></i>
            <h4 class="heading mt-4">We are redirecting you...</h4>
            <p>
              You are being redirected to your billing page where you can manage your
              subscription.. please wait
            </p>
          </div>
        </modal>
      </div>
    </div>
  </div>
</template>
<script>
import firebase from "firebase/app";
import { db, functions } from "@/firebase";
import store from "@/store.js";
import { mapGetters } from "vuex";
import licenseTypes from "@/models/licenseTypes";
import Modal from "@/components/Modal.vue";
import { VBPopover } from "bootstrap-vue/esm/directives/popover/popover";

export default {
  name: "subscriptions",
  props: ["requiredLicense", "currentLicense"],
  components: {
    Modal,
  },
  directives: {
    BPopover: VBPopover,
  },

  mounted() {
    this.requiredLicenseText();
  },

  data() {
    return {
      gifted: false,
      requestSent: false,
      requiredLicenseName: null,
      licenseTypes: licenseTypes,
      requestText: null,
      modals: {
        stripeLoading: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      // map `this.groups` to `this.$store.getters.groups`
      user: "user",
      groups: "groups",
    }),
  },
  methods: {
    getSubsriptionPlans() {
      db.collection("plans")
        .where("active", "==", true)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(async function (doc) {
            // console.log(doc.id, ' => ', doc.data());
            const priceSnap = await doc.ref.collection("prices").get();
            priceSnap.docs.forEach((doc) => {
              // console.log(doc.id, ' => ', doc.data());
            });
          });
        });
    },

    async loadCheckout() {
      this.modals.stripeLoading = true;

      const docRef = await db
        .collection("users")
        .doc(this.user.data.uid)
        .collection("checkout_sessions")
        .add({
          // price: 'price_1HojFULkK5V4hmYRnHUp4n2m', // DEV
          price: "price_1HqFGqLkK5V4hmYR6DaQS1QV", // PROD SUBSCRIPTION
          success_url: window.location.origin,
          cancel_url: window.location.origin,
        });

      // Wait for the CheckoutSession to get attached by the extension
      docRef.onSnapshot((snap) => {
        const { error, sessionId } = snap.data();
        if (error) {
          // Show an error to your customer and
          // inspect your Cloud Function logs in the Firebase console.
          alert(`An error occured: ${error.message}`);
        }
        if (sessionId) {
          // We have a session, let's redirect to Checkout
          // Init Stripe
          // const stripe = Stripe('pk_test_51Hoiu3LkK5V4hmYRF1xNrh2LLfo2dwRFGD445C9rgC4D0J9TD8N3bnAHJ7Gvx3ZpEPAEgiwLpRjzdQkS6nl7vLvs0076k7YVhN'); // DEV
          const stripe = Stripe(
            "pk_live_51Hoiu3LkK5V4hmYRfieXjPwbBGW5znDQSsE56QVzzzOIOwsqjdjOX8jGyLcND8cKh9gm6V6BbJ2my2Wz4XXyd1Sx00K2tNTvot"
          ); // PROD
          stripe.redirectToCheckout({ sessionId });
        }
      });
    },

    createPortalLink() {
      this.modals.stripeLoading = true;

      const functionLocation = "australia-southeast1";

      var functionRef = firebase
        .app()
        .functions(functionLocation)
        .httpsCallable("ext-firestore-stripe-subscriptions-createPortalLink");
      functionRef({ returnUrl: window.location.origin })
        .then(function (result) {
          // Read result of the Cloud Function.
          window.location.assign(result.data.url);
        })
        .catch(function (error) {
          // console.log(error)

          // Getting the Error details.
          var code = error.code;
          var message = error.message;
          var details = error.details;

          // ...
        });
    },

    requiredLicenseText() {
      let vm = this;

      if (vm.requiredLicense) {
        let rlt = vm.requiredLicense;
        let tmpLicenses = vm.licenseTypes;
        tmpLicenses.forEach(function (licenseType, index) {
          if (licenseType.tier === rlt) {
            vm.requiredLicenseName = licenseType.name;
          }
        });
      }
    },
  },
};
</script>
<style scoped></style>
