<template>

    <div>

        <template>

            <div class="row" v-if="!form.groupDetailsAdded">

                <div class="col-12">

                    <h2 class="card-title text-default font-weight-bold text-center mb-4">Setup your new group!</h2>

                </div>
                <div class="col-12">

                    <div class="form-group text-left">
                        <label class="text-muted text-left">Group Name</label><br>
                        <validation-provider mode="lazy" name="Group Name" rules="required" v-slot="{ errors }">
                            <base-input alternative
                                        v-model="form.groupName"
                                        required
                                        class="mb-3"
                                        placeholder="Group Name"
                                        :error="errors[0]"
                                        >
                            </base-input>
                        </validation-provider>
                    </div>

                </div>
                <div class="col-12">

                    <div class="form-group text-left">
                        <label class="text-muted">Mission</label><br>
                        <textarea class="form-control mb-3" v-model="form.groupMission" id="exampleFormControlTextarea1" rows="3" placeholder="What's the purpose/mission for this group?"></textarea>
                    </div>

                </div>

                <div class="col-12">
                    
                    <div class="form-group text-left p-0">
                        <label class="text-muted">Group Type</label> <i class="fad fa-question-circle text-default pointer" @click="showTipGroupType = !showTipGroupType"></i>
                        <div class="alert alert-default text-center" v-if="showTipGroupType">
                            <i class="fas fa-users"></i> <b>Open / Shared</b> groups allow all team members to see each others reflections<br>
                            <i class="fas fa-chart-network"></i> <b>Hierarchical</b> groups only allow the owner or leaders to see members reflections
                        </div>

                        <div class="btn-group btn-block btn-lg move-left" role="group">
                            <button type="button" class="btn btn-lg ml-0 mt-0" @click="form.groupType = 'open'" :class="[form.groupType === 'open' ? 'btn-default' : 'btn-outline-default']" >
                                <i class="fas fa-users"></i> <span class="d-none d-lg-inline">Open / Shared</span>
                            </button>
                            <button type="button" class="btn btn-lg ml-0 mt-0" @click="form.groupType = 'hierarchical'" :class="[form.groupType === 'hierarchical' ? 'btn-default' : 'btn-outline-default']">
                                <i class="fas fa-chart-network"></i> <span class="d-none d-lg-inline">Hierarchical</span> 
                            </button>
                        </div>
                    </div>

                </div>

                <div class="col-12 text-center mt-4 pb-2" v-if="form.groupName && form.groupMission">
                    <!-- <base-button type="secondary" size="lg" @click="changeStep('groupDetailsAdded',false)">
                        Back
                    </base-button> -->
                    <base-button type="default" size="lg" @click="changeStep('groupDetailsAdded',true)">
                        Next
                    </base-button>
                </div>
                <div class="col-12 text-center mt-4 pb-2" v-else>
                    <!-- <base-button type="secondary" size="lg" @click="changeStep('groupDetailsAdded',false)">
                        Back
                    </base-button> -->
                    <base-button type="default" disabled size="lg">
                        Next
                    </base-button>
                </div>

            </div>

            <div class="row" v-else-if="!form.tagsAdded">

                <div class="col-12">

                    <h2 class="card-title text-default font-weight-bold text-center mb-4">Add your group tags <i class="fad fa-question-circle text-default pointer" @click="showTipGroupTags = !showTipGroupTags"></i></h2>
                    <div class="alert alert-default text-center" v-if="showTipGroupTags">
                        Group tags allow your members to group common activities and associate them with this group.
                        Any reflections logged against group tags will be visible in group reports!
                    </div>

                </div>

                <div class="col-12">

                    <div class="form-row mb-4">
                        <div class="col-sm-5 my-1">
                            <input type="text" placeholder="Add a new tag" v-slugify v-model="tempTag" class="form-control">
                        </div>
                        <div class="col-sm-5 my-1">
                            <!-- <label><small>Reflection Type</small></label> -->
                            <select class="form-control" v-model="tempType">
                                <option selected :value="null">all reflection types</option>
                                <option v-for="(type,t) in reflectionTypes" v-bind:key="t">{{ type.name }}</option>
                            </select>
                        </div>
                        <div class="col-sm-2 my-1">
                            <button class="btn btn-default btn-block" type="button" @click="commitTag" v-if="tempTag"><i class="fal fa-plus"></i> Add</button>
                            <button class="btn btn-default btn-block" disabled type="button" v-else><i class="fal fa-plus"></i> Add</button>
                        </div>
                    </div>

                    <div class="badge badge-lg badge-default pointer" v-for="(tag,index) in form.tags" v-bind:key="index" @click="deleteTag(index)">
                        {{ tag.name }} <i class="fad fa-trash"></i>
                    </div>

                    <div v-if="form.tags.length === 0" class="badge badge-lg badge-secondary">
                        You currently do not have any tags, your members will not be able to associate their reflections to the group without a tag to select!
                    </div>

                </div>

                <div class="col-12 text-center mt-4 pb-2" v-if="form.tags.length > 0">
                    <base-button type="secondary" size="lg" @click="changeStep('groupDetailsAdded',false)">
                        Back
                    </base-button>
                    <base-button type="default" size="lg" @click="changeStep('tagsAdded',true)">
                        Next
                    </base-button>
                </div>
                <div class="col-12 text-center mt-4 pb-2" v-else>
                    <base-button type="secondary" size="lg" @click="changeStep('groupDetailsAdded',false)">
                        Back
                    </base-button>
                    <base-button type="default" disabled size="lg">
                        Next
                    </base-button>
                </div>

            </div>

            <div class="row" v-else-if="!form.invitationsAdded">

                <div class="col-12">

                    <h2 class="card-title text-default font-weight-bold text-center mb-4">Invite your members</h2>
                    <div class="alert alert-default text-center">
                        Members will receive an email invitation to join your group
                    </div>

                </div>

                <div class="col-12">

                    <div class="form-row mb-4" v-if="totalMembers < user.subscription.maxUsers">
                        <div class="col-sm-10 my-1">
                            <input type="input" placeholder="Add a new email" v-model="tempInvite" class="form-control">
                        </div>
                        <div class="col-sm-2 my-1">
                            <button class="btn btn-default btn-block" type="button" @click="commitInvite" v-if="validEmail(tempInvite)"><i class="fal fa-envelope"></i> Add</button>
                            <button class="btn btn-default btn-block" disabled type="button" v-else><i class="fal fa-envelope"></i> Add</button>
                        </div>
                    </div>
                    <div v-else class="alert alert-primary text-center">
                        Sorry, but can't send any more invites as you have reached your members quota
                    </div>

                    <div class="badge badge-lg badge-default pointer" v-for="(invite,index) in form.invitations" v-bind:key="index" @click="deleteInvite(index)">
                        {{ invite.email }} <i class="fad fa-trash"></i>
                    </div>

                    <div v-if="form.invitations.length === 0" class="badge badge-lg badge-secondary">
                        You have not invited any members yet, you can do this later if you choose!
                    </div>

                </div>

                <div class="col-12 text-center mt-4 pb-2">
                    <base-button type="secondary" size="lg" @click="changeStep('tagsAdded',false)">
                        Back
                    </base-button>
                    <base-button type="default" size="lg" @click="changeStep('invitationsAdded',true)">
                        Next
                    </base-button>
                </div>

            </div>

            <div class="row" v-else-if="form.invitationsAdded">

                <div class="col-12">

                    <h2 class="card-title text-default font-weight-bold text-center mb-4">Confirm the details</h2>

                </div>

                <div class="col-12">

                    <div class="form-group text-left">
                        <label class="text-muted text-left">Name</label><br>
                        {{ form.groupName }}
                    </div>

                    <div class="form-group text-left">
                        <label class="text-muted text-left">Mission</label><br>
                        {{ form.groupMission }}
                    </div>

                    <div class="form-group text-left">
                        <label class="text-muted text-left">Type</label><br>
                        {{ form.groupType }}
                    </div>

                    <div class="form-group text-left">
                        <label class="text-muted text-left">Tags</label><br>
                        <div class="badge badge-lg badge-default pointer" v-for="(tag,index) in form.tags" v-bind:key="index">
                            {{ tag.name }}
                        </div>
                    </div>

                    <div class="form-group text-left">
                        <label class="text-muted text-left">Invitations</label><br>
                        <div class="badge badge-lg badge-default pointer" v-for="(invite,index) in form.invitations" v-bind:key="index">
                            {{ invite.email }}
                        </div>
                    </div>

                </div>

                <div class="col-12 text-center mt-4 pb-2">

                    <base-button type="secondary" size="lg" @click="changeStep('invitationsAdded',false)">
                        Back
                    </base-button>
                    <base-button native-type="submit" size="lg" type="primary" @click="createGroup">
                        Create Group
                    </base-button>

                </div>

            </div>
    
        </template>

        <div class="container">

            <div class="row justify-content-center mt-5">

                <a role="button" @click="cancelCreate" class="pointer m-4" title="Cancel">
                    <icon name="fal fa-times" size="lg" type="danger" shadow rounded></icon>
                </a>

            </div>

        </div>

    </div>

</template>
<script>
  import firebase from 'firebase/app'
  import { db } from '@/firebase';
  import store from '@/store.js'
  import { mapGetters } from "vuex";

  import reflectionTypes from "@/models/reflectionTypes";
  import BaseDropdown from "@/components/BaseDropdown";

  import { ValidationProvider,ValidationObserver } from 'vee-validate';
  import { extend } from 'vee-validate';
  import { required, email } from 'vee-validate/dist/rules';

    extend('email', email);
    extend('required',required );
  
  export default {
    name: 'create-group-form',
    props: ['user','groups','licensesUsed'],
    components: {
        ValidationProvider,
        ValidationObserver,
        BaseDropdown
    },
    data() {
      return {
        form: {
            groupName: null,
            groupMission: null,
            groupType: 'open',
            groupComms: 'myreflections',
            groupCommsLink: '',
            groupDetailsAdded: false,
            tags: [],
            tagsAdded: false,
            invitations: [],
            invitationsAdded: false
        },
        reflectionTypes: null,
        tempTag: null,
        tempInvite: null,
        tempType: null,

        showTipGroupType: false,
        showTipCommsMethod: false,
        showTipGroupTags: false,
      }
    },
    mounted() {

        this.reflectionTypes = reflectionTypes

    },
    computed: {

        subscription(){

            if(this.user.data.subscription){

                if(this.user.data.subscription.status === 1){
                    return this.user.data.subscription
                }
                
            }

            return { tier: 0, name:'free' }

        },

        totalMembers(){
            return this.licensesUsed + this.form.invitations.length
        }

    },
    methods: {

        validEmail: function (email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },

        changeStep(step,value){

            this.form[step] = value

        },

        cancelCreate(){
            this.$emit('cancelParentCreate')
        },

        commitTag(){

            if(this.tempTag){

                let type = 'none'
                if(this.tempType){
                    type = this.tempType
                }

                let tag = { 'name': this.tempTag, 'status':'active', 'type': type, 'total': 0, 'ratings': 0, 'scope': 'group' }
                this.form.tags.push( tag )

                this.tempTag = ''

            }

        },

        commitInvite(){

            if(this.tempInvite){

                let invite = { 'email': this.tempInvite, 'status':'pending' }
                this.form.invitations.push( invite )

                this.tempInvite = ''

            }

        },

        deleteTag(i){
          this.form.tags.splice(i, 1);
        },

        deleteInvite(i){
          this.form.invitations.splice(i, 1);
        },

        sendInvites(subject,group,invitations){

            let inviteDoc = {
                subject: subject,
                group: group,
                invitations: invitations
            }
            this.$emit('receiveInvites',inviteDoc)
        },
        
        createGroup() {

            const vm = this;

            let ourDate = new Date();
            let timeStamp = new firebase.firestore.Timestamp.fromDate(ourDate) 

            // Add a new document with a generated id.
            var docRef = db.collection("groups").doc();
            let guid = docRef.id

            const groupMap = {
                id : guid,
                groupName : vm.form.groupName.trim(),
                groupMission : vm.form.groupMission.trim(),
                groupType : vm.form.groupType,
                groupComms : vm.form.groupComms,
                groupCommsLink : vm.form.groupCommsLink,
                photoURL :  '',
                tags: vm.form.tags,
                ownerId: vm.user.data.uid,
                uids: [
                    vm.user.data.uid
                ],
                members: [
                    {
                        uid: vm.user.data.uid,
                        firstName: vm.user.data.firstName,
                        lastName: vm.user.data.lastName,
                        fullName: vm.user.data.fullName,
                        photoURL: vm.user.data.photoURL,
                        roleId: 3,
                        roleName: 'owner'
                    }
                ],
                invitations: vm.form.invitations,
                creationTime : timeStamp
            };

            // later...
            docRef.set(groupMap)
            .then(function(docRef) {

                // Update our Group Store
                var tmpGroups = JSON.parse(JSON.stringify(vm.groups))
                tmpGroups.push(groupMap)
                // vm.$store.commit("SET_GROUPS", tmpGroups)

                // Send our Analytics event
                vm.$analytics.logEvent('group_created', groupMap);

                // Send our invitations
                if(groupMap.invitations.length > 0){
                    // vm.createInvitations(groupMap);
                    let subject = 'Invitation to ' + groupMap.groupName + ' @ myreflections.app'
                    vm.sendInvites(subject,groupMap,groupMap.invitations)
                }
                
                // Clear the Group
                vm.clearGroup()

                vm.$toast.open({
                    message: 'Group created!',
                    type: 'success',
                    // all of other options may go here
                });

            })
            .catch(function(error) {

                // console.log(error)

                vm.$toast.open({
                    message: 'Something went wrong!',
                    type: 'error',
                    duration: 10000,
                    // all of other options may go here
                });

            });

        },

        clearGroup(){
            this.form = {
                groupName: null,
                groupMission: null,
                groupType: 'open',
                groupComms: 'myreflections',
                groupCommsLink: '',
                groupDetailsAdded: false,
                tags: [],
                tagsAdded: false,
                invitations: [],
                invitationsAdded: false
            }
        }

    }

  }
</script>
<style scoped>

    .mr-column img {
        width: 21px;
        margin: auto;    
        display: block;
    }

    .mr-icon {
        width: 21px;
    }

    .mr-column span {
        margin: auto;    
        display: block;
    }

    .move-left {
        margin-top: -10px;
        margin-left: -15px;
    }

</style>