export default {
    baseStatistics : {
        totalReflections : 0,
        sumRatings : 0,
        averageRating : 0,
        totalHighlights : 0,
        totalLowlights : 0
    },
    reflections: [],
    reflectionsByTag : [],
    reflectionsByMember : [],
    reflectionsByType : [
        { 
            name : 'meeting',
            image : 'img/images/reflectionTypes/rtMeeting.svg',
            totalReflections : 0,
            sumRatings : 0,
            averageRating : 0,
            totalHighlights : 0,
            totalLowlights : 0
        },
        { 
            name : 'task', 
            image : 'img/images/reflectionTypes/rtTask.svg',
            totalReflections : 0,
            sumRatings : 0,
            averageRating : 0,
            totalHighlights : 0,
            totalLowlights : 0
        },
        { 
            name : 'project', 
            image : 'img/images/reflectionTypes/rtProject.svg',
            totalReflections : 0,
            averageRating : 0,
            sumRatings : 0,
            totalHighlights : 0,
            totalLowlights : 0
        },
        { 
            name : 'training',
            image : 'img/images/reflectionTypes/rtTraining.svg',
            totalReflections : 0,
            averageRating : 0,
            sumRatings : 0,
            totalHighlights : 0,
            totalLowlights : 0
        },
        { 
            name : 'game',
            image : 'img/images/reflectionTypes/rtGame.svg',
            totalReflections : 0,
            averageRating : 0,
            sumRatings : 0,
            totalHighlights : 0,
            totalLowlights : 0
        },
        { 
            name : 'wellness',
            image : 'img/images/reflectionTypes/rtWellness.svg',
            totalReflections : 0,
            averageRating : 0,
            sumRatings : 0,
            totalHighlights : 0,
            totalLowlights : 0
        },
        { 
            name : 'appointment',
            image : 'img/images/reflectionTypes/rtAppointment.svg',
            totalReflections : 0,
            averageRating : 0,
            sumRatings : 0,
            totalHighlights : 0,
            totalLowlights : 0
        },
        { 
            name : 'learning',
            image : 'img/images/reflectionTypes/rtLearning.svg',
            totalReflections : 0,
            averageRating : 0,
            sumRatings : 0,
            totalHighlights : 0,
            totalLowlights : 0
        },
        { 
            name : 'personal',
            image : 'img/images/reflectionTypes/rtPersonal.svg',
            totalReflections : 0,
            averageRating : 0,
            sumRatings : 0,
            totalHighlights : 0,
            totalLowlights : 0
        },
        { 
            name : 'general',
            image : 'img/images/reflectionTypes/rtGeneral.svg',
            totalReflections : 0,
            averageRating : 0,
            sumRatings : 0,
            totalHighlights : 0,
            totalLowlights : 0    
        },
        { 
            name : 'sleep',
            image : 'img/images/reflectionTypes/rtSleep.svg',
            totalReflections : 0,
            averageRating : 0,
            sumRatings : 0,
            totalHighlights : 0,
            totalLowlights : 0  
        },
        { 
            name : 'event',
            image : 'img/images/reflectionTypes/rtEvent.svg',
            totalReflections : 0,
            averageRating : 0,
            sumRatings : 0,
            totalHighlights : 0,
            totalLowlights : 0  
        },
        { 
            name : 'daily checkin',
            image : 'img/images/reflectionTypes/rtDailyCheckin.svg',
            totalReflections : 0,
            averageRating : 0,
            sumRatings : 0,
            totalHighlights : 0,
            totalLowlights : 0  
        },
        { 
            name : 'journal entry',
            image : 'img/images/reflectionTypes/rtJournalEntry.svg',
            totalReflections : 0,
            averageRating : 0,
            sumRatings : 0,
            totalHighlights : 0,
            totalLowlights : 0  
        },
        { 
            name : 'photo journal',
            image : 'img/images/reflectionTypes/rtPhotoJournal.svg',
            totalReflections : 0,
            averageRating : 0,
            sumRatings : 0,
            totalHighlights : 0,
            totalLowlights : 0  
        }
    ],
    reflectionsByDay : [
        {
            name : 'Monday',
            totalReflections : 0,
            averageRating : 0,
            sumRatings : 0,
            totalHighlights : 0,
            totalLowlights : 0
        },
        { 
            name : 'Tuesday',
            totalReflections : 0,
            averageRating : 0,
            sumRatings : 0,
            totalHighlights : 0,
            totalLowlights : 0
        },
        { 
            name : 'Wednesday',
            totalReflections : 0,
            averageRating : 0,
            sumRatings : 0,
            totalHighlights : 0,
            totalLowlights : 0
        },
        { 
            name : 'Thursday',
            totalReflections : 0,
            averageRating : 0,
            sumRatings : 0,
            totalHighlights : 0,
            totalLowlights : 0
        },
        { 
            name : 'Friday',
            totalReflections : 0,
            averageRating : 0,
            sumRatings : 0,
            totalHighlights : 0,
            totalLowlights : 0
        },
        { 
            name : 'Saturday',
            totalReflections : 0,
            averageRating : 0,
            sumRatings : 0,
            totalHighlights : 0,
            totalLowlights : 0
        },
        { 
            name : 'Sunday',
            totalReflections : 0,
            averageRating : 0,
            sumRatings : 0,
            totalHighlights : 0,
            totalLowlights : 0
        }
    ],
    tags:[],
    graphs: {
        reflectionDay: null,
        reflectionTagSplit: null,
        reflectionSplit: null
    }
}