<template>
    <div>

        <card shadow class="p-4" no-body v-if="reflectionTypes && user">
            <div class="row justify-content-center mb-2">
                <div class="col">
                    <h4>Task History</h4>
                </div>
            </div>

            <div class="row justify-content-center" v-if="tasks.length > 0">
                <div
                class="col-12 py-2"
                v-for="(task, t) in sortedTasks"
                v-bind:key="t"
                >

                    <div class="float-left h-100 mr-3 align-middle">
                        <i class="fad fa-check-circle task-check text-success"></i>
                    </div>

                    {{ task.title }}

                </div>
            </div>
            <div class="row justify-content-center" v-else>

                <div class="col-12 mt-3 mb-3 text-center text-primary">

                    <div class="alert alert-default text-center" v-if="!loaded">
                        <i class="fad fa-spinner-third fa-4x fa-spin"></i>
                        <h5 class="text-white mt-2">Loading your tasks!</h5>
                    </div>

                    <div class="alert alert-defaultt text-center" v-if="loaded">
                        No Tasks
                    </div>

                </div>

            </div>
        </card>

    </div>
</template>
<script>

import firebase from "firebase/app";
import { db } from "@/firebase";

import store from "@/store.js";
import { mapGetters } from "vuex";

export default {
    name: 'profile-tasks',
    props: ['reflectionTypes'],

    computed: {

        ...mapGetters({
            // map `this.user` to `this.$store.getters.user`
            // map `this.groups` to `this.$store.getters.groups`
            user: "user",
            groups: "groups"
        }),

        sortedTasks() {
            var content = this.tasks;
            return content.sort((b, a) => (a.creationSeconds > b.creationSeconds ? 1 : -1));
        }

    },

    mounted(){

        this.getTasks();
        
    },

    data () {
        return {
            loaded: false,
            querying: false,
            tasks: []

        }
    },
    methods: {

        getTasks() {

            const vm = this;

            if (vm.user.loggedIn && vm.user.data && vm.user.data.uid) {
                // console.log('Getting your reflections')

                var docRef = db.collection("users").doc(vm.user.data.uid).collection("tasks");
                docRef = docRef.where("status", "!=", "active");
                docRef = docRef.orderBy("status", "asc");
                // docRef = docRef.limit(3);
                docRef.onSnapshot(async function(docs) {
                    vm.loaded = true;
                    docs.docChanges().forEach(function(change) {
                        let { newIndex, oldIndex, doc, type } = change;
                        let docData = doc.data();
                        if (type === "added") {
                            vm.tasks.splice(newIndex, 0, docData);
                        } else if (type === "modified") {
                            vm.tasks.splice(oldIndex, 1);
                            vm.tasks.splice(newIndex, 0, docData);
                        } else if (type === "removed") {
                            vm.tasks.splice(oldIndex, 1);
                        }
                    });
                });

            }
        }

    }
};
</script>
<style scoped>

    .border-avatar {
        border: 2px solid #8d6c9f;
    }

    .task-details{
        display:block;
        width:100%;
        /* height:calc(2.75rem + 2px); */
        padding:.625rem .75rem;
        font-size:1rem;
        line-height:1.5;
        color:#8d6c9f;
        background-color:#fff;
        background-clip:padding-box;
        border:1px solid #e0e3e6;
        border-radius:.25rem;
        -webkit-box-shadow:none;
        box-shadow:none;
        -webkit-transition:all .2s cubic-bezier(.68,-.55,.265,1.55);
        transition:all .2s cubic-bezier(.68,-.55,.265,1.55)
    }

    .taskIcon {
        color: #ffffff;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        height: 48px;
        width: 48px; 
    } 

    .taskIcon img {
        width: 100%;
    }

    .taskIcon-xs {
        width: 32px;
        height: 32px;
    }

    .status-icon {
        position: absolute;
        right: 15%;
        bottom: 20%;
        width: 32px;
        height: 32px;
        z-index: 999;
    }

    .status-icon-xs {
        position: absolute;
        right: 10%;
        bottom: 10%;
        width: 24px;
        height: 24px;
        z-index: 999;
    }

    .img-rt {
        /* position: relative; */
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 60px;
        height: 60px;
        transition: transform 0.2s;
        z-index: 899;
    }

    .img-rt-selection:hover {
        transform: scale(1.1);
    }

    .label-rt {
        font-size: 0.7em;
    }

    .feed-title {
        font-size: 0.9em;
    }

    .feed-date {
        font-size: 0.8em;
    }

</style>