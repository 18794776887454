<template>
    <div :class="fontColor">

        <hr class="border-group-inner mt-0 mb-0" />

        <div class="container">

            <div class="row" v-if="socialData">
                
                <!-- REACTIONS -->
                <div class="col small text-left ml-0 mt-2 mb-2 mr-2">

                    <span @click="like" v-if="!iReacted">
                        <i class="fal fa-heart mr-2 pointer"></i>
                    </span>
                    <span @click="unlike" v-else>
                        <i class="fas fa-heart mr-2 pointer"></i>
                    </span>

                    {{ reactionText() }}

                </div>

                <!-- COMMENTS -->
                <div class="col small text-right mr-0 mt-2 mb-2 ml-0" @click="displayComments = !displayComments">
                    <span class="pointer comment-text">

                        {{ commentText() }}

                        <span v-if="!iCommented">
                            <i class="fal fa-comment-alt-lines ml-2"></i>
                        </span>
                        <span v-else>
                            <i class="fas fa-comment-alt-lines ml-2"></i>
                        </span>

                    </span>
                </div>

            </div>

        </div>

        <div class="container bg-white rounded-bottom">
            <div class="row mb-2" v-if="socialData && displayComments && socialData.replies.length > 0">

                <div class="col-12 text-dark" v-for="(reply, index) in socialData.replies" v-bind:key="index">

                    <hr class="mt-3 mb-3 comment-sep" v-if="index > 0" />

                    <div class="media align-items-center mt-2 mb-1">

                        <img alt="Image placeholder" :class="getAvatarClass(reply.uid)" :src="reply.photoURL" />
                        <div class="media-body ml-3">
                                <p class="feed-title font-weight-bold text-default mt-2 mb-0">{{ reply.fullName }}</p>
                                <p class="feed-date text-muted mb-0">
                                    <small>{{ $dayjs(getDate(reply.creationTime)).fromNow() }}</small>
                                </p>

                                <p class="small m-0" style="white-space: pre-wrap" v-html="reply.comment"></p>
                        </div>
                        <div class="text-right" v-if="reply.uid === user.data.uid">
                            <button class="btn btn-sm btn-white" @click="removeComment(reply)">
                                <i class="fad fa-trash-alt"></i>
                            </button>
                        </div>
                    </div>

                </div>

            </div>
            <div class="row" v-if="socialData">

                <div class="col-12">

                    <div class="media align-items-center mt-2 mb-1">
                        <img alt="Image placeholder" :src="user.data.photoURL" class="avatar avatar-sm rounded-circle mt-2 mb-2" />
                        <div class="media-body ml-3">
                            <div class="input-group mt-2 mb-2">
                            <textarea-autosize placeholder="Write your comment..." ref="replyTextarea" v-model="comment" :min-height="50" class="form-control" :class="cardType ? 'border border-1 border-light ' : 'border border-1 border-default'" />
                            <div class="input-group-append">
                                <button class="btn" :class="cardType ? 'border border-1 border-light btn-' + cardType : 'bg-default'" type="button" id="button-addon2" @click="addComment" v-if="comment">
                                    <i class="fas fa-paper-plane" :class="fontColor"></i>
                                </button>
                                <button class="btn disabled" :class="cardType ? 'border border-1 border-light btn-' + cardType : 'border border-1 border-default bg-default'" disabled type="button" id="button-addon2" v-else>
                                    <i class="fas fa-paper-plane" :class="fontColor"></i>
                                </button>
                            </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>

    </div>
</template>
<script>

import firebase from "firebase/app";
import { db } from "@/firebase";

import store from "@/store.js";
import { mapGetters } from "vuex";

import _ from "lodash";

export default {
    name: 'social-engagement',
    props: ['id','fontColor','cardType'],

    mounted() {
       this.getSocialEngagement();
    },

    data () {
        return {
            socialData: null,
            displayComments: false,
            placeholder: true,
            modals: {
                comments: false
            },
            comment: null
        }
    },

    computed: {

        ...mapGetters({
            // map `this.user` to `this.$store.getters.user`
            // map `this.groups` to `this.$store.getters.groups`
            user: "user",
            groups: "groups",
        }),

        iReacted(){

            if(!this.socialData || this.socialData.length === 0){
                return false
            }

            if( this.socialData.reactions.find(element => element.uid === this.user.data.uid )){
                return true
            }else{
                return false
            }

        },

        iCommented(){

            if(!this.socialData || this.socialData.length === 0){
                return false
            }

            if( this.socialData.replies.find(element => element.uid === this.user.data.uid )){
                return true
            }else{
                return false
            }

        }

    },

    watch: {

    },

    methods: {

    getDate(datetime) {
      if (datetime._seconds) {
        return new Date(datetime._seconds * 1000);
      } else if (datetime.seconds) {
        return new Date(datetime.seconds * 1000);
      } else {
        let newdate = datetime.toString().substring(0, 10);
        return new Date(Number(newdate) * 1000);
      }
    },

    getAvatarClass() {
      return "avatar-sm rounded-circle";
    },

        reactionText(){

            let reactions =  this.socialData.reactions.length
            if(this.iReacted){

                if(reactions === 1){
                    return 'You like this';
                }else if(reactions === 2){
                    return 'You & one other person like this';
                }else{
                    return 'You & ' + ( parseInt(reactions) - 1 ) + ' other people like this';
                }

            }else{
                if(reactions === 1){
                    return 'One person likes this';
                }else{
                    return reactions + ' people like this';
                }
            }

        },

        commentText(){

            let comments =  this.socialData.replies.length

            if(comments === 0){
                return 'no comments';
            }else if(comments === 1){
                return '1 comment';
            }else{
                return parseInt(comments) + ' comments';
            }

        },

        emptySocial(){

            this.socialData = {
                replies: [],
                reactions: []
            }

        },

        getSocialEngagement(){

            const vm = this

            const docRef = db.collection("socialEngagements").doc(vm.id);
            docRef.onSnapshot( async function(doc) {

                vm.socialData = doc.data();

                // If we don't have a document,
                // create a placeholder
                if(!vm.socialData){
                    vm.emptySocial();
                    vm.placeholder = true;
                }else{
                    vm.placeholder = false;
                }

            });



        },

        async createSocial(){

            let socialDoc = _.cloneDeep(this.socialData)

            const socialRecord = db.collection('socialEngagements').doc(this.id);
            const res = await socialRecord.set(socialDoc);

            return true

        },

        like(){

            // If we're using a placeholder
            // then create our document
            if(this.placeholder){
                this.createSocial();
            }

            const socialRecord = db.collection('socialEngagements').doc(this.id);

            // Atomically add a new region to the "regions" array field.
            socialRecord.update({
                reactions: firebase.firestore.FieldValue.arrayUnion( {
                    "uid": this.user.data.uid,
                    "fullName" : this.user.data.fullName
                } )
            });


        },

        unlike(){

            const socialRecord = db.collection('socialEngagements').doc(this.id);

            // Atomically add a new region to the "regions" array field.
            socialRecord.update({
                reactions: firebase.firestore.FieldValue.arrayRemove ( {
                    "uid": this.user.data.uid,
                    "fullName" : this.user.data.fullName
                } )
            });


        },

        addComment(){

            // If we're using a placeholder
            // then create our document
            if(this.placeholder){
                this.createSocial();
            }

            let ourDate = new Date();
            let timestamp = new firebase.firestore.Timestamp.fromDate(ourDate);
            let comment = this.comment

            const socialRecord = db.collection('socialEngagements').doc(this.id);

            // Atomically add a new region to the "regions" array field.
            socialRecord.update({
                replies: firebase.firestore.FieldValue.arrayUnion( {
                    "uid": this.user.data.uid,
                    "fullName" : this.user.data.fullName,
                    "photoURL" : this.user.data.photoURL,
                    "creationTime": timestamp,
                    "creationSeconds": timestamp.seconds,
                    "comment": comment
                } )
            });

            this.comment = null
            this.displayComments = true

        },

        removeComment(comment){

            if(!comment){
                return
            }

            const socialRecord = db.collection('socialEngagements').doc(this.id);

            // Atomically add a new region to the "regions" array field.
            socialRecord.update({
                replies: firebase.firestore.FieldValue.arrayRemove ( comment )
            });


        },

    }
};
</script>

<style scoped>

    .comment-text:hover  {
        text-decoration: underline;
    }

    .comment-sep{
        opacity: 0.7;
    }

    .feed-title {
    font-size: 0.9em;
    line-height: 1em;
    }

    .feed-date {
    font-size: 0.8em;
    }

    .avatar-self {
    border: 3px solid #8d6c9f;
    }

    .avatar-else {
    border: 3px solid #999;
    }

    .media {
    display: flex;
    align-items: flex-start;
    }

    .media-body {
    flex: 1;
    }

    .media-reply-avatar {
    margin-top: -4px;
    margin-right: -2rem;
    position: relative;
    z-index: 1;
    width: 64px;
    height: 64px;
    transition: all 0.15s ease;
    }

    .media-reply-avatar-self {
    border: 3px solid #8d6c9f;
    }

    .media-reply-avatar-else {
    border: 3px solid #999;
    }

    @media (prefers-reduced-motion: reduce) {
    .media-reply-avatar {
        transition: none;
    }
    }

    .media-reply-text {
    border-radius: 0.3rem;
    border-top-left-radius: 0;
    position: relative;
    background-color: #f6f9fc !important;
    padding: 1rem 1.25rem 1rem 2.5rem;
    }

    .media-reply:hover .media-reply-avatar {
    transform: scale(1.1);
    }
</style>