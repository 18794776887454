<template>
  <div class="mb-3">
    <div class="card shadow p-0 bg-white rounded" v-if="selectedEntry">

      <!-- CARD HEADER -->
      <div class="mt-2 mb-0">
        <div class="float-right text-dark pt-2 pr-3">

          <base-dropdown position="right">
            <a
              role="button"
              class="pointer"
              slot="title"
              data-toggle="dropdown"
            >
              <icon
                name="fal fa-ellipsis-v"
                size="sm"
                iconOnly
                textColor="dark"
                type="white"
                outline
                rounded
                class="text-dark"
              ></icon>
            </a>

            <router-link class="dropdown-item pointer" :to="'/deep-dives/' + selectedEntry.id">
              <i class="fal fa-pencil"></i> Edit
            </router-link>
            <a class="dropdown-item pointer text-dark" role="button" @click="modals.deletedeepdive = true">
              <i class="fal fa-trash"></i> Delete
            </a>
          </base-dropdown>

          <!-- <img
            :alt="selectedEntry.member.fullName"
            :title="selectedEntry.member.fullName"
            :src="selectedEntry.member.photoURL"
            class="avatar-sm rounded-circle img-member ml-2"
            v-if="selectedEntry.member"
          /> -->

        </div>

        <div class="float-left ml-2 mr-1 p-2">
          <img
            :alt="selectedEntry.user.fullName"
            class="avatar avatar-sm bg-white border-avatar"
            :src="selectedEntry.user.photoURL"
          />
        </div>

        <p class="feed-title font-weight-bold text-default mt-2 mb-0">
          <router-link :to="'/deep-dives/' + selectedEntry.id">
            <span v-if="selectedEntry.label">{{ selectedEntry.label }}</span>
            <span v-else>Unnammed Deep Dive</span>
          </router-link>
        </p>
        <p class="feed-date text-muted">
          {{ $dayjs( getDeepDiveDate(selectedEntry.startTimestamp) ).format("Do MMM") }} - {{ $dayjs( getDeepDiveDate(selectedEntry.endTimestamp) ).format("Do MMM, YYYY") }} 
        </p>
        <hr class="mt-0 mb-0" />
      </div>

      <!-- ACTIVITY DETAILS -->
      <div class="container px-4 pt-4">

        <div class="row text-center justify-content-center mb-0">

          <!-- Base Stats -->
          <div class="col-xl-3 col-sm-6 col-6">

            <stats-card-clean
              icon="fad fa-head-side-brain text-primary"
              iconSize="fa-2x"
              title="Reflections"
              :subTitle="selectedEntry.baseStatistics.totalReflections.toFixed(0)"
            >
            </stats-card-clean>

          </div>
          <div class="col-xl-3 col-sm-6 col-6">

            <stats-card-clean
              icon="fad fa-fist-raised text-info"
              icon-size="fa-2x"
              title="Avg. Rating"
              :subTitle="splitNumbers(selectedEntry.baseStatistics.averageRating.toFixed(2))[0]"
              :decValue="splitNumbers(selectedEntry.baseStatistics.averageRating.toFixed(2))[1]"
            >
            </stats-card-clean>

          </div>
          <div class="col-xl-3 col-sm-6 col-6">

            <stats-card-clean
              icon="fad fa-crosshairs text-muted"
              iconSize="fa-2x"
              title="Activity Types"
              :subTitle="selectedEntry.reflectionsByType.length"
            >
            </stats-card-clean>

          </div>
          <div class="col-xl-3 col-sm-6 col-6">

            <stats-card-clean
              icon="fad fa-tags text-dark"
              iconSize="fa-2x"
              title="Tags"
              :subTitle="selectedEntry.reflectionsByTag.length"
            >
            </stats-card-clean>

          </div>

          <!-- EXTRA STATS -->
          <div class="col-xl-3 col-sm-6 col-6" v-if="viewStats">

            <stats-card-clean
              icon="fad fa-thumbs-up text-success"
              iconSize="fa-2x"
              title="Highlights"
              :subTitle="selectedEntry.baseStatistics.totalHighlights.toFixed(0)"
            >
            </stats-card-clean>

          </div>
          <div class="col-xl-3 col-sm-6 col-6" v-if="viewStats">

            <stats-card-clean
              icon="fad fa-thumbs-down text-danger"
              iconSize="fa-2x"
              title="Lowlights"
              :subTitle="selectedEntry.baseStatistics.totalLowlights.toFixed(0)"
            >
            </stats-card-clean>

          </div>
          <div class="col-xl-3 col-sm-6 col-6" v-if="viewStats">

            <stats-card-clean
              icon="fad fa-exchange-alt text-primary"
              iconSize="fa-2x"
              title="Adjustments"
              :subTitle="selectedEntry.baseStatistics.totalAdjustments.toFixed(0)"
            >
            </stats-card-clean>

          </div>
        </div>

<!-- 

        <div class="col-12 text-center mb-4 mt-4" v-if="!viewStats">
          <base-button
            type="white"
            size="sm"
            @click="viewStats = true"
          >
            <i class="fad fa-info-circle"></i> view more
          </base-button>
        </div>
        <div class="col-12 text-center mb-4 mt-4" v-else>
          <base-button
            type="secondary"
            size="sm"
            @click="viewStats = false"
          >
            <i class="fad fa-info-circle"></i> view less
          </base-button>
        </div>

-->


      </div>

      <div class="bg-gradient-default pointer" v-if="selectedEntry.analysis && viewAnalysis" @click="viewAnalysis = false">
        <hr class="mt-0 mb-0" />

        <div class="card-body mb-4 text-center">

          <div class="row justify-content-center mt-3 mb-3">

            <div class="col-12 mb-3"> 
              <span class="badge badge-default text-white">
                  <i class="fad fa-robot mr-2"></i> View Sentiment Analysis
              </span>
            </div>

            <div class="col-lg-2 col-4"> 
              <img :src="'/img/moods/' + selectedEntry.analysis.overall.score_tag + '.svg'" class="analysis-mood" /><br>
              <p class="text-lowercase badge badge-default">
                {{ selectedEntry.analysis.overall.score_label }}
              </p>
            </div>

          </div>

          <div class="row justify-content-center">

            <div class="col-lg-4 col-8">

                <sentiment-progress-bar :values="selectedEntry.analysis.overall.intensity"></sentiment-progress-bar>

            </div>

          </div>

        </div>

      </div>
      <div class="bg-gradient-default text-center pointer" v-else-if="selectedEntry.analysis && !viewAnalysis" @click="viewAnalysis = true">
        <hr class="mt-0 mb-0" />
        <div class="m-2 text-white">
              <span class="badge badge-default text-white">
                  <i class="fad fa-robot mr-2"></i> View Sentiment Analysis
              </span>
            <!-- <i class="fad fa-robot mr-2"></i> view sentiment analysis -->
        </div>
      </div>

      <!-- HIGHLIGHTS, LOWLIGHTS & ADJUSTMENTS-->
      <div v-if="selectedEntry.deepDiveRetrospective.highlights.length > 0 || selectedEntry.deepDiveRetrospective.lowlights.length > 0 || selectedEntry.deepDiveRetrospective.adjustments.length > 0">
        <hr class="mt-0 mb-0" />

        <div class="card-body text-center">
          <div>

            <div
              class="row justify-content-center mt-2"
            >

              <div class="col-lg-8 col-12 m-1 p-2 text-center" v-if="selectedEntry.deepDiveRetrospective.highlights.length > 0">
                <h6 class="text-muted small text-uppercase"><i class="fal fa-thumbs-up text-success"></i> highlights</h6>
                <div>
                  <ul class="list-group">
                    <li
                      class="list-group-item border-0 p-1"
                      v-for="(highlight, index) in selectedEntry.deepDiveRetrospective.highlights"
                      v-bind:key="index"
                    >
                      <span style="white-space: pre-wrap" v-html="highlight"></span>
                      <!-- <hr class="mt-1 mb-2 ml-5 mr-5" /> -->
                      <br><i class="fal fa-horizontal-rule text-success small"></i>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-lg-8 col-12 m-1 p-2 text-center" v-if="selectedEntry.deepDiveRetrospective.lowlights.length > 0">
                <h6 class="text-muted small text-uppercase"><i class="fal fa-thumbs-down text-danger"></i> lowlights</h6>
                <div >
                  <ul class="list-group">
                    <li
                      class="list-group-item border-0 p-1"
                      v-for="(lowlight, index) in selectedEntry.deepDiveRetrospective.lowlights"
                      v-bind:key="index"
                    >
                      <span style="white-space: pre-wrap" v-html="lowlight"></span>
                      <!-- <hr class="mt-1 mb-2 ml-5 mr-5" /> -->
                      <br><i class="fal fa-horizontal-rule text-danger small"></i>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-lg-8 col-12 m-1 p-2 text-center" v-if="selectedEntry.deepDiveRetrospective.adjustments.length > 0">
                <h6 class="text-muted small text-uppercase"><i class="fal fa-exchange-alt text-primary"></i> adjustments</h6>
                <div >
                  <ul class="list-group">
                    <li
                      class="list-group-item border-0 p-1"
                      v-for="(adjustment, index) in selectedEntry.deepDiveRetrospective.adjustments"
                      v-bind:key="index"
                    >
                      <span style="white-space: pre-wrap" v-html="adjustment"></span>
                      <!-- <hr class="mt-1 mb-2 ml-5 mr-5" /> -->
                      <br><i class="fal fa-horizontal-rule text-primary small"></i>
                    </li>
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <!-- NO HIGHLIGHTS -->
      <div
        v-if="
          selectedEntry.deepDiveRetrospective.highlights.length === 0 && selectedEntry.deepDiveRetrospective.lowlights.length === 0 && selectedEntry.deepDiveRetrospective.adjustments.length === 0
        "
      >
        <hr class="mt-0 mb-0" />
        <p class="text-muted m-2 small text-center">
          <i class="fal fa-exclamation-triangle"></i> No highlights, lowlights or adjustments
        </p>
      </div>

      <!-- SUPPORT SOCIAL ENGAGEMENTS -->
      <!-- <social :id="socialId()" fontColor="white" card-type="white" v-if="taggedGroup()"></social> -->

    </div>

    <modal
        :show.sync="modals.deletedeepdive"
        gradient="danger"
        modal-classes="modal-danger modal-dialog-centered"
    >

        <div class="py-3 text-center">
            <i class="fad fa-bell fa-4x"></i>
            <h2 class="display-2 text-white mt-4">Are you sure?</h2>
            <p>Deleted deep dives cannot be restored!</p>
        </div>

        <template slot="footer">
            <base-button type="white" @click="deleteDeepDive">Yes</base-button>
            <base-button type="link" text-color="white" class="ml-auto" @click="modals.deletedeepdive = false">
                Close
            </base-button>
        </template>
    </modal>

  </div>
</template>
<script>
import BaseDropdown from "@/components/BaseDropdown";
// import ReflectionModals from "@/components/reflections/Modals";
// import AdvancedAttachments from "@/components/reflections/AdvancedAttachments";
import SentimentProgressBar from "@/components/SentimentProgressBar";
import StatsCardClean from "@/components/StatsCardClean";

// import InlineRating from "@/components/reflections/InlineRating";

import Social from "@/components/social/Social";
import Modal from "@/components/Modal.vue";


import firebase from "firebase/app";
import { db } from "@/firebase";

import store from "@/store.js";
import { mapGetters } from "vuex";

export default {
  name: "deep-dive-details",
  components: {
    BaseDropdown,
    // ReflectionModals,
    // InlineRating,
    SentimentProgressBar,
    StatsCardClean,
    SentimentProgressBar,
    // Social
    Modal
  },
  props: ["selectedEntry", "reflectionTypes"],

  mounted() {
    // this.user = this.currentUser;
  },

  data() {
    return {
      // data here
      imagesArray: null,
      modals: {
        deletedeepdive:false
      },
      action: null,
      viewSentimentAnalysis: false,
      viewAnalysis: false,
      viewStats: false,
    };
  },
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      // map `this.groups` to `this.$store.getters.groups`
      user: "user",
      groups: "groups",
    }),

    sparkStroke() {
      if (this.$isDesktop()) {
        return 1;
      } else {
        return 2.5;
      }
    },

    sparkData() {
      if (!this.selectedEntry.reflections) {
        return [0];
      }

      let values = Array();

      var tmpReflections = this.selectedEntry.reflections;
      tmpReflections.forEach(function (r, i) {
        if(r.rating > 0){
          values.unshift(Number(r.rating));
        }
        
      });

      return values;
    },

  },
  methods: {

    splitNumbers(num){
      return num.toString().split('.');
    },

    socialId(){

      if(this.selectedEntry.groupDocumentId){
        return this.selectedEntry.groupDocumentId
      }else{
        return this.selectedEntry.id
      }

    },

    getDeepDiveDate(datetime) {
      if (datetime._seconds) {
        return new Date(datetime._seconds * 1000);
      } else if (datetime.seconds) {
        return new Date(datetime.seconds * 1000);
      } else if (datetime.value) {
        return new Date(datetime.value);
      } else {
        let newdate = datetime.toString().substring(0, 10);
        return new Date(Number(newdate) * 1000);
      }
    },

    checkGroupType(groupId){

      let group = this.groups.find(groups => groups.id === groupId);
      if(!group) { return false }
      if(group.groupType && group.groupType === 'open'){
        return true
      }else{
        return false
      }

    },

    taggedGroup(){

      if(!this.selectedEntry){
        return
      }

      if(this.selectedEntry.tagData && this.selectedEntry.tagData.scope === 'group' && this.checkGroupType(this.selectedEntry.tagData.groupId)){
        return this.selectedEntry.tagData.groupId
      }else{
        return false
      }

    },

    deleteDeepDive(){

        const vm = this;
        let docId = vm.selectedEntry.id;

        db.collection("deepDives")
          .doc(docId)
          .delete();

        this.modals.deletedeepdive = false;

        this.$toast.open({
            message: "Deep Dive Deleted!",
            type: "warning",
            // all of other options may go here
        });

    }

  },
};
</script>

<style scoped>

.trend {
  width: 100%;
  position: relative;
  top: 0;
  /* min-height: 120px; */
  vertical-align: middle;
}

.feed-title {
  font-size: 0.9em;
  line-height: 1em;
}

.feed-date {
  font-size: 0.8em;
}

.border-avatar {
  border: 2px solid #8d6c9f;
}

.card-ellipse {
  position: absolute;
  text-align: right;
  top: 0;
  right: 0;
  z-index: 50;
}

.img-rt {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 60px;
  height: 60px;
}

.img-rating-report {
  /* position: absolute; */
  /* top: 12px; */
  /* margin: 45px -45px; */
  width: 60px;
  height: 60px;
}

.img-rating-question {
  width: 60px;
  height: 60px;
}

.img-member {
  border: 3px solid #8d6c9f;
}

.analysis-mood {
  width: 60px;
}

.analysis-sentence-mood {
  width: 24px;
}

</style>
