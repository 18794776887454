<template>
    <div>

        <card shadow class="p-4" no-body v-if="user">

            <div class="row justify-content-center mb-2">
                <div class="col-12">
                    <div class="float-right">
                        <a class="btn btn-sm btn-default text-white" role="button" @click="modals.create = true">
                            <i class="fal fa-key mr-2"></i> New
                        </a>
                    </div>
                    <h4>
                        Webhooks
                    </h4>
                </div>
            <!-- </div> -->
            <div class="col-12" v-if="webhooks.length > 0">

                <ul class="list-group">
                    <li class="list-group-item" v-for="(hook,h) in webhooks" v-bind:key="h">
                        <div class="float-right">

                            <div class="btn-group btn-group-sm" role="group">
                                <base-button 
                                    type="default"
                                    id="webhook-key"
                                    v-clipboard:copy="hook.api_key"
                                    v-clipboard:success="onCopy"
                                    v-clipboard:error="onError"
                                >
                                    <i class="fal fa-copy"></i>
                                </base-button>
                                <base-button type="default" @click="viewToken(h)"><i class="fal fa-eye"></i></base-button>
                                <base-button type="default" @click="showDeleteModal(hook.hookId)"><i class="fal fa-trash"></i></base-button>
                            </div>

                        </div>

                         <div class="mt-2" v-if="viewing !== h">
                             <span class="text-primary font-weight-bold mr-2">{{ hook.label ? hook.label : 'Generic API Key' }}</span>
                            <i class="fad fa-key mr-1"></i> {{ shortenedKey(hook.api_key) }}&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;
                         </div>
                         <div class="mt-2" v-else>
                            <span class="text-primary font-weight-bold mr-2">{{ hook.label ? hook.label : 'Generic API Key' }}</span>
                            <small><i class="fas fa-info-circle"></i> Be careful who you share this API Key with</small>
                         </div>
                         <div class="mt-3" v-if="viewing === h">
                            <textarea class="form-control" readonly :value="hook.api_key"></textarea>
                         </div>

                    </li>
                </ul>

                <label class="mt-3" for="webhookURL">Personal Webhook URL/Target</label>
                <div class="input-group mb-3">
                    <input type="text" class="form-control border-default" id="webhookURL" readonly placeholder="Webhook URL" :value="webhookURL">
                    <div class="input-group-append">
                        <base-button 
                            type="default"
                            id="webhook-url"
                            v-clipboard:copy="webhookURL"
                            v-clipboard:success="onCopy"
                            v-clipboard:error="onError"
                        >
                            <i class="fal fa-copy"></i>
                        </base-button>
                    </div>
                    
                </div>

            </div>
            <div class="col-12" v-else>
                <div class="alert alert-default text-center" v-if="!loaded">
                    <i class="fad fa-spinner-third fa-4x fa-spin"></i>
                    <h5 class="text-white mt-2">Loading your webhooks!</h5>
                </div>
                <div class="alert alert-info text-center" v-if="loaded">
                    No API Keys Created Yet
                </div>
            </div>
            </div>

        </card>

        <modal
            :show.sync="modals.create"
            modal-classes="modal-dialog-centered"
        >
            <div class="row justify-content-center">

                <div class="col-lg-6 col-12 pt-1 pb-4">
                    <h3 class="modal-title text-center text-default mb-3" id="modal-title-default">
                        Create your API Key
                    </h3>
                </div>
            </div>

            <div class="row justify-content-center">

                <div class="col-12">
                    
                    <label>API Label</label>
                    <base-input
                        type="text"
                        placeholder="Use this to differntiate your API Keys"
                        v-model="apiLabel"
                    >
                    </base-input>
                    
                </div>

            </div>

            <template slot="footer">
                <base-button type="secondary" class="ml-auto" @click="closeModal">
                    Close
                </base-button>
                <base-button type="primary" v-if="apiLabel" @click="generateToken">Create</base-button>
                <base-button type="primary" v-else disabled>Create</base-button>
            </template>
        </modal>

        <modal
            :show.sync="modals.delete"
            v-if="deleteRef"
            gradient="danger"
            modal-classes="modal-danger modal-dialog-centered"
            >

            <div class="py-3 text-center">
                <i class="fad fa-bell fa-4x"></i>
                <h2 class="display-2 text-white mt-4">Are you sure?</h2>
                <p>Deleting this webhook will mean that any services using it will fail to work going forward.</p>
            </div>

            <template slot="footer">
                <base-button type="white" @click="deleteToken">Yes</base-button>
                <base-button type="link" text-color="white" class="ml-auto" @click="closeModal">
                Close
                </base-button>
            </template>
        </modal>

    </div>
</template>
<script>

import firebase from 'firebase/app'
import { db } from '@/firebase';
import store from '@/store.js'
import { mapGetters } from "vuex";
import { randomString } from "@/components/stringUtils"
import Modal from "@/components/Modal.vue";

export default {
    name: 'webhook-management',
    // props: [],
    components: { 
        Modal
    },

    computed: {

        ...mapGetters({
            // map `this.user` to `this.$store.getters.user`
            // map `this.groups` to `this.$store.getters.groups`
            user: "user",
            groups: "groups"
        }),

        webhookURL(){
            return 'https://myreflections.app/hooks/reflections/' + this.user.data.uid
        }

    },

    data () {
        return {
            loaded: false,
            showWebhookTip: false,
            apiLabel: '',
            webhooks: [],
            viewing: null,
            deleteRef: null,
            modals: {
                create: false,
                delete: false
            }
        }
    },

    mounted() {
        this.checkForToken();
    },

    methods: {

        closeModal(){
            this.modals.create = false;
            this.modals.delete = false;
        },

        shortenedKey(key){
            return key.substring(0,5)
        },

        onCopy(e) {

            if(e.trigger.id === 'webhook-key'){

                this.$toast.open({
                    message: 'Webhook copied to clipboard!',
                    type: 'success',
                    // all of other options may go here
                });

            }else{

                this.$toast.open({
                    message: 'Webhook Endpoint copied to clipboard!',
                    type: 'success',
                    // all of other options may go here
                });

            }
            
        },

        onError() {
            // console.log('error');
        },

        viewToken(index){

            if(this.viewing === index){
                this.viewing = null;
            }else{
                this.viewing= index
            }

        },

        checkForToken(){

            const vm = this

            var docRef = db.collection("userWebhooks");
            docRef = docRef.where("uid", "==", vm.user.data.uid);
            docRef.onSnapshot(async function (docs) {
                vm.loaded = true;
                docs.docChanges().forEach(function (change) {
                    let { newIndex, oldIndex, doc, type } = change;
                    if (type === "added") {
                        vm.webhooks.splice(newIndex, 0, doc.data());
                    } else if (type === "modified") {
                        vm.webhooks.splice(oldIndex, 1);
                        vm.webhooks.splice(newIndex, 0, doc.data());
                    } else if (type === "removed") {
                        vm.webhooks.splice(oldIndex, 1);
                    }
                });
            });

        },

        showDeleteModal(id){

            this.deleteRef = id;
            this.modals.delete = true;

        },

        deleteToken() {

            const vm = this
            let doc = this.deleteRef;

            db.collection("userWebhooks").doc(doc).delete().then(() => {

                vm.closeModal();
                vm.deleteRef = null;

                vm.$toast.open({
                    message: 'Webhook deleted!',
                    type: 'success',
                    // all of other options may go here
                });

            }).catch((error) => {
                // console.error("Error removing document: ", error);
            });

        },

        generateToken() {

            const vm = this

            var webhook = db.collection("userWebhooks").doc();
            let hookId = webhook.id
            let api_key = randomString(120)

            let ourDate = new Date();
            let timeStamp = new firebase.firestore.Timestamp.fromDate(ourDate) 

            const webhookMap = {
                label : this.apiLabel,
                api_key : api_key,
                uid: vm.user.data.uid,
                hookId: hookId,
                creationTime : timeStamp
            };

            webhook.set(webhookMap)
            .then(function(response) {

                vm.api_key = api_key
                vm.webhookDoc = webhookMap
                vm.modals.create = false

                vm.$toast.open({
                    message: 'Webhook created!',
                    type: 'success',
                    // all of other options may go here
                });

            })
            .catch(function(error) {

                // console.log(error)

                vm.$toast.open({
                    message: 'Something went wrong!',
                    type: 'error',
                    duration: 10000,
                    // all of other options may go here
                });

            });


        }

    }
};
</script>
<style scoped>

.textshadow .blurry-text {
   text-shadow: 2px 2px 5px green;
   color: transparent;
}

</style>