<template>
    <div>

        <div class="card  rounded shape-style-1 bg-gradient-default mt-4 mb-4">

            <div class="card-body">

                <div class="container">

                    <div class="row">

                        <div class="col-12">
                        

                            <h4 class="text-white mb-0">View my report</h4>
                            <p class="lead text-white mt-0">
                                "{{ shareLink.label }}" <small><i>- {{ shareLink.fullName }}</i></small>
                            </p>
                            <p class="text-white font-weight-bold">
                                {{ $dayjs(shareLink.startDate).format("Do MMM") }}
                                - 
                                {{ $dayjs(shareLink.endDate).format("Do MMM YYYY") }}
                            </p>
                            <p>
                                <a :href="shareLink.url" target="_blank" class="btn btn-lg btn-white">
                                    View Report
                                </a>
                            </p>

                        </div>


                    </div>

                </div>



            </div>

        </div>

    </div>
</template>
<script>

import firebase from 'firebase/app'
import { db } from '@/firebase';

import store from '@/store.js'
import { mapGetters } from "vuex";

export default {
    name: 'report-share',
    props: ['shareLink'],

    mounted() {
      // this.user = this.currentUser;
    },

    data () {
        return {
            // data here
        }
    },
    computed: {

        ...mapGetters({
            // map `this.user` to `this.$store.getters.user`
            // map `this.groups` to `this.$store.getters.groups`
            user: "user",
            groups: "groups"
        }),

    },
    methods: {



    }
};
</script>

<style scoped>

    .share-avatar{
        position:relative;
        z-index:1;
        width:90px;
        height:90px;
        transition:all .15s ease;
        border:3px solid #8d6c9f;
    }


</style>