<template>
  <div class="mb-3">
    <div class="card shadow p-0 bg-white rounded" v-if="selectedEntry">
      <div class="mt-2 mb-0">

        <div class="float-right text-dark pt-2 pr-3">

          <router-link :to="'/mygroups/' + selectedEntry.groupUid">
              <icon name="fad fa-users-class"
                size="sm"
                iconOnly
                textColor="dark"
                type="white"
                outline
                rounded
                shadow
                class="text-dark" ></icon>
          </router-link>

        </div>

        <div class="float-left ml-2 mr-1 p-2">
          <img
            alt="Image placeholder"
            class="avatar avatar-sm bg-white border-avatar"
            :src="selectedEntry.user.photoURL"
          />
        </div>

        <p class="feed-title font-weight-bold text-default mt-2 mb-0">
          {{ selectedEntry.user.fullName }}
          <router-link :to="'/mygroups/' + selectedEntry.groupUid">
            <small class="text-muted">@{{ getGroupName(selectedEntry.groupUid) }}</small>
          </router-link>
          
        </p>
        <p class="feed-date text-muted">
          {{ $dayjs(getReflectionDate(selectedEntry.creationTime)).fromNow() }}
        </p>

        <hr class="mt-0 mb-0" />

        <div class="m-3 text-default">
          <span
            style="white-space: pre-wrap"
            v-html="selectedEntry.text"
          ></span>
        </div>

        <hr class="border-group-inner mt-0 mb-0" v-if="selectedEntry.attachment && selectedEntry.attachment.type" />

        <div class="m-3" v-if="selectedEntry.attachment">

          <report-share :share-link="selectedEntry.attachment" v-if="selectedEntry.attachment.type === 'report'"></report-share>
          <video-embed class="rounded" :src="selectedEntry.attachment.url" v-if="selectedEntry.attachment.type === 'video'"></video-embed>
          <image-embed :attachment="selectedEntry.attachment" v-if="selectedEntry.attachment.type === 'image'"></image-embed>
          <website-embed :attachment="selectedEntry.attachment" v-if="selectedEntry.attachment.type === 'website'"></website-embed>
          <poll-embed :post="selectedEntry" v-if="selectedEntry.attachment.type === 'poll'"></poll-embed>

        </div>

        <hr class="border-group-inner mt-0 mb-0" />

        <div class="float-right justify-content-right text-right">
          <span class="badge badge-white pl-2 pr-2 text-right badge-count pointer" @click="toggleComments(selectedEntry.id)">
            <span class="text-default" v-if="selectedEntry.replies && selectedEntry.replies.length > 0">
              <span class="ml-2 mr-1 font-weight-bold">
                {{ selectedEntry.replies.length }}
              </span>
            </span>

            <span class="text-default" v-else> 0 </span>

            <span>
              <img src="/img/images/comments.svg" class="comments-count-image ml-1" />
            </span>
          </span>
        </div>

        <reactions :post="selectedEntry" class="m-1"></reactions>
        <!-- <replies :post="selectedEntry"></replies> -->

        <div v-if="activeComments(selectedEntry.id).length">

          <hr class="border-group-inner mt-0 mb-0" />
          <div class="m-3">
            <replies :post="selectedEntry"></replies>
          </div>

        </div>

      </div>

    </div>
  </div>
</template>
<script>
import BaseDropdown from "@/components/BaseDropdown";
import ReflectionModals from "@/components/reflections/Modals";
import firebase from "firebase/app";
import { db } from "@/firebase";

import store from "@/store.js";
import { mapGetters } from "vuex";

import ReportShare from "@/components/embeds/ReportShare";
import VideoEmbed from "@/components/embeds/Video";
import ImageEmbed from "@/components/embeds/Image";
import WebsiteEmbed from "@/components/embeds/Website";
import PollEmbed from "@/components/embeds/Poll";

import Reactions from "@/components/mygroups/Reactions";
import Replies from "@/components/mygroups/Replies";

export default {
  name: "group-post-details",
  components: {
    BaseDropdown,
    ReflectionModals,
    Reactions,
    Replies,
    ReportShare,
    VideoEmbed,
    PollEmbed,
    ImageEmbed,
    WebsiteEmbed,

  },
  props: ["selectedEntry", "reflectionTypes"],

  mounted() {
    // this.user = this.currentUser;
  },

  data() {
    return {
      // data here
      imagesArray: null,
      action: null,
      commentsEnabled: [],
    };
  },
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      // map `this.groups` to `this.$store.getters.groups`
      user: "user",
      groups: "groups",
    }),
  },
  methods: {

    stripGroupId(tag) {
      if (!this.groups) {
        return tag;
      }

      let newTag = tag;

      var tmpGroups = this.groups;
      tmpGroups.forEach(function (t, i) {
        newTag = newTag.replace(t.id + "#", "");
      });

      return newTag;
    },

    getGroupName(groupId){

      let groupName = ''
      var tmpGroups = this.groups;
      tmpGroups.forEach(function (g, i) {
        if(g.id === groupId){
          // console.log(g.groupName)
          groupName = g.groupName
        }
      });

      return groupName

    },

    getReflectionDate(datetime) {
      let myDate = new Date(datetime.seconds * 1000);
      return myDate;
    },

    getRatingImage(rating) {
      return "img/images/values/circled-" + rating + ".svg";
    },

    getReflectionTypeImage(type) {
      let rt = this.reflectionTypes.find(element => element.name === type)
      return rt.image
    },

    activeComments(postId) {
      return this.commentsEnabled.filter((post) => {
        return post.id === postId;
      });
    },

    toggleComments(postId) {
      let found = false;
      let index = -1;
      var tmpActive = this.commentsEnabled;
      tmpActive.forEach((active, i) => {
        if (active.id === postId) {
          found = true;
          index = i;
        }
      });

      if (!found) {
        this.commentsEnabled.push({
          id: postId,
        });
      } else {
        this.commentsEnabled.splice(index, 1);
      }
    },

    modalWasClosed() {
      this.action = null;
      this.$emit("reflectionDeleted");
    },
  },
};
</script>

<style scoped>
.feed-title {
  font-size: 0.9em;
  line-height: 1em;
}

.feed-date {
  font-size: 0.8em;
}

.border-group {

  border-color: #CCC;

}

.border-avatar {
  border: 2px solid #8d6c9f;
}

.card-ellipse {
  position: absolute;
  text-align: right;
  top: 0;
  right: 0;
  z-index: 50;
}

.img-rt {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 60px;
  height: 60px;
}

.img-rating-report {
  /* position: absolute; */
  /* top: 12px; */
  /* margin: 45px -45px; */
  width: 60px;
  height: 60px;
}

.img-rating-question {
  width: 60px;
  height: 60px;
}

/* span::first-letter {
  font-size: 250%;
  color: #8d6c9f;
} */


.badge-count {
  border-radius: 24px;
}

.comments-count-image {
  width: 24px;
  height: 24px;
}

.comment {
  display: flex;
  align-items: flex-start;
}

.comment-body {
  flex: 1;
}

.comment-footer {
  border-top: 1px solid rgba(141, 108, 159, 0);
}

.comment-grouppost-avatar {
  margin-top: -4px;
  margin-right: -1rem;
  position: relative;
  z-index: 1;
  width: 42px;
  height: 42px;
  transition: all 0.15s ease;
}

.comment-grouppost-avatar-self {
  border: 3px solid #8d6c9f;
}

.comment-grouppost-avatar-else {
  border: 3px solid #999;
}

@media (prefers-reduced-motion: reduce) {
  .comment-grouppost-avatar {
    transition: none;
  }
}

.comment-grouppost-text {
  border-radius: 0.3rem;
  border-top-left-radius: 0;
  position: relative;
  background-color: #fff !important;
  padding: 1rem 1.25rem 1rem 2.5rem;
}

.comment-grouppost:hover .comment-grouppost-avatar {
  transform: scale(1.1);
}

</style>
