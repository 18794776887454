<template>
    <div>

        <div class="row justify-content-center pl-3 pr-3 mb-2">

            <div class="col-12" v-show="attachment.title || attachment.summary">
                <p class="card-title mb-1 text-default font-weight-bold" v-show="attachment.title">{{ attachment.title }}<br /></p>
                <p v-show="attachment.summary">{{ attachment.summary }}</p>
            </div>

            <div class="col-lg-6 col-12">

                <div class="mt-4 mb-4 text-center">

                    <div v-for="(qn, index) in localData.data" v-bind:key="index">
                        <p class="card-title mt-4 text-primary font-weight-bold">
                            {{ qn.label }}
                        </p>
                        <div class="row justify-content-center" v-if="reqData">
                            <div class="col-12 pt-1 pb-2" v-if="qn.type === 'rating'">
                                <input
                                    v-model="localData.data[index].value"
                                    type="range"
                                    min="1"
                                    max="10"
                                    class="rating"
                                />
                                <small v-if="localData.data[index].value">rated {{ localData.data[index].value }} out of 10</small>
                                <small v-else>set your rating out of 10</small>
                            </div>
                            <div class="col-12 input-group mb-5" v-else>
                                <textarea-autosize
                                    placeholder="Type your answer..."
                                    :ref="localData.data[index].id"
                                    v-model="localData.data[index].value"
                                    :min-height="50"
                                    class="form-control"
                                />
                            </div>
                        </div>
                        <div class="row justify-content-center" v-else>

                            <div v-if="localData.data[index].type === 'rating'">
                                <p style="line-height: 12px;">
                                    <img
                                        :alt="localData.data[index].value"
                                        class="img-rating-report"
                                        :src="getRatingImage(localData.data[index].value)"
                                        v-if="localData.data[index].value"
                                    />
                                    <img
                                        alt="No Rating!"
                                        class="img-rating-report"
                                        src="/img/images/not-available.svg"
                                        v-else
                                    />
                                    <br>
                                    <small v-if="localData.data[index].value">
                                        answered with<br>{{ localData.data[index].value }} out of 10
                                    </small>
                                </p>
                                
                            </div>
                            <div v-else>
                                <p>{{ attachment.data[index].value ? attachment.data[index].value : 'Not answered' }}</p>
                            </div>

                            
                        </div>
                    </div>

                    <!-- <base-button type="danger" outline size="sm" @click="editEnabled = false">Cancel</base-button>
                    <base-button type="default" outline size="sm" @click="updateData">Save</base-button> -->

                </div>

                <p>{{ attachment.summary }}</p>

            </div>

        </div>
    </div>
</template>
<script>

import _ from "lodash";

export default {
    name: 'embed-survey',
    props: ['attachment','id','index','readOnly'],
    components: {
        // Pie,
        // PieChart
    },
    mounted() {
      this.reqData = this.requestDataChecker();
    },

    data () {
        return {
            localData: _.cloneDeep(this.attachment),
            newData: false,
            editEnabled: false,
            reqData: false
        }
    },
    computed: {

        showEdit() {

            if(this.readOnly){
                return false
            }

            if(this.attachment.allowDataEdits && this.editEnabled){
                return true
            }

            // if(this.reqData){
            //     return true
            // }

            return false;
        }

    },

    watch: {

        attachment: {
            handler: function () {
                setTimeout(this.resetNewData,200);
            },
            deep: true,
        }

    },

    methods: {

        requestDataChecker(){

            if(this.readOnly){
                return false
            }

            if(this.attachment.requestData && this.checkForValues()){
                return true
            }

            return false
        },

        checkForValues(){

            let data = this.localData.data
            let dataReq = false

            data.forEach((record,i) => {

                if(!record.value){
                    dataReq = true
                }

            });

            if(this.readOnly){
                dataReq = false
            }

            return dataReq

        },


        getRatingImage(rating) {
            return "/img/images/values/circled-" + rating + ".svg";
        },

        resetNewData(){
            this.newData = false;
        },

        updateData(){

            let docId = this.id;
            let attachmentMap = this.localData

            this.newData = true;

            this.sendUpdate(attachmentMap,this.index);
            this.editEnabled = false;

            this.$toast.open({
                message: "Data Updated!",
                type: "success",
                // all of other options may go here
            });

        },

        sendUpdate(attachment,index){
            this.$emit('updateAttachment', attachment, index)
        }

    }
};
</script>

<style scoped>

/* rating */

.img-rating-report {
  width: 60px;
  height: 60px;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  margin: 15.7px 0;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4.6px;
  cursor: pointer;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
  background: #e6dfea;
  border-radius: 2.4px;
  border: 0px solid rgba(1, 1, 1, 0);
}

input[type="range"]::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid rgba(0, 0, 0, 0);
  height: 36px;
  width: 36px;
  border-radius: 47px;
  background: #8d6c9f;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -15.7px;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #f2eef4;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 4.6px;
  cursor: pointer;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
  background: #e6dfea;
  border-radius: 2.4px;
  border: 0px solid rgba(1, 1, 1, 0);
}

input[type="range"]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid rgba(0, 0, 0, 0);
  height: 36px;
  width: 36px;
  border-radius: 47px;
  background: #8d6c9f;
  cursor: pointer;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 4.6px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type="range"]::-ms-fill-lower {
  background: #dad0e0;
  border: 0px solid rgba(1, 1, 1, 0);
  border-radius: 4.8px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
}

input[type="range"]::-ms-fill-upper {
  background: #e6dfea;
  border: 0px solid rgba(1, 1, 1, 0);
  border-radius: 4.8px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
}

input[type="range"]::-ms-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid rgba(0, 0, 0, 0);
  height: 36px;
  width: 36px;
  border-radius: 47px;
  background: #8d6c9f;
  cursor: pointer;
  height: 4.6px;
}

input[type="range"]:focus::-ms-fill-lower {
  background: #e6dfea;
}

input[type="range"]:focus::-ms-fill-upper {
  background: #f2eef4;
}
</style>