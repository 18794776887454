<template>
    <div>

        <h5 class="card-title text-danger font-weight-bold"><i class="fad fa-exclamation-triangle"></i> Currently not rated <i class="fad fa-exclamation-triangle"></i></h5>
        <div class="row justify-content-md-center">
          <div class="col-lg-6 col-12 pt-1 pb-2 mb-3">
            <input
              v-model="rating"
              type="range"
              min="1"
              max="10"
              class="rating"
            />
            <small v-if="rating">currently rated {{ rating }} out of 10</small>
            <small v-else>rate it out of 10</small>
          </div>

          <div class="w-100 d-block"></div>

          <div class="col-lg-6 col-12 pt-1 pb-2 mb-3" v-if="rating">
              <base-button size="sm" style="width: 100px;" type="success" outline @click="saveRating">
                  save
              </base-button>
          </div>

        </div>

    </div>
</template>
<script>


import firebase from "firebase/app";
import { db } from "@/firebase";

export default {

    name: 'inline-rating',
    props: ['selectedEntry'],
    data () {
        return {
          rating: null
        }
    },

    methods: {

      saveRating(){

        db.collection("reflections")
            .doc(this.selectedEntry.id)
            .update({
                "rating": this.rating
            });

      }

    }
};
</script>

<style scoped>

  /* rating */

  input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    margin: 15.7px 0;
  }

  input[type="range"]:focus {
    outline: none;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 4.6px;
    cursor: pointer;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
    background: #e6dfea;
    border-radius: 2.4px;
    border: 0px solid rgba(1, 1, 1, 0);
  }

  input[type="range"]::-webkit-slider-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid rgba(0, 0, 0, 0);
    height: 36px;
    width: 36px;
    border-radius: 47px;
    background: #8d6c9f;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -15.7px;
  }

  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #f2eef4;
  }

  input[type="range"]::-moz-range-track {
    width: 100%;
    height: 4.6px;
    cursor: pointer;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
    background: #e6dfea;
    border-radius: 2.4px;
    border: 0px solid rgba(1, 1, 1, 0);
  }

  input[type="range"]::-moz-range-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid rgba(0, 0, 0, 0);
    height: 36px;
    width: 36px;
    border-radius: 47px;
    background: #8d6c9f;
    cursor: pointer;
  }

  input[type="range"]::-ms-track {
    width: 100%;
    height: 4.6px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  input[type="range"]::-ms-fill-lower {
    background: #dad0e0;
    border: 0px solid rgba(1, 1, 1, 0);
    border-radius: 4.8px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
  }

  input[type="range"]::-ms-fill-upper {
    background: #e6dfea;
    border: 0px solid rgba(1, 1, 1, 0);
    border-radius: 4.8px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
  }

  input[type="range"]::-ms-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid rgba(0, 0, 0, 0);
    height: 36px;
    width: 36px;
    border-radius: 47px;
    background: #8d6c9f;
    cursor: pointer;
    height: 4.6px;
  }

  input[type="range"]:focus::-ms-fill-lower {
    background: #e6dfea;
  }

  input[type="range"]:focus::-ms-fill-upper {
    background: #f2eef4;
  }

</style>