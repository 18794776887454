<template>
    <div>

        <div class="row justify-content-center text-center pl-3 pr-3 mb-2">

            <div class="col-12" v-show="attachment.title || attachment.summary">
                <p class="card-title mb-1 text-default font-weight-bold" v-show="attachment.title">{{ attachment.title }}<br /></p>
                <p v-show="attachment.summary">{{ attachment.summary }}</p>
            </div>

            <div class="col-lg-9 col-12">

                <card class="m-4" :type="attachment.class ? attachment.class : 'default'">

                    <div class="row" v-if="!reqData">

                        <div class="col-lg-4 col-12 text-white">
                            <p class="m-0 text-white">{{ attachment.data[0].label}}</p>
                            <h2 class="text-white">{{ attachment.data[0].value ? attachment.data[0].value : 'N/A' }}</h2>
                        </div>

                        <div class="col-lg-4 col-12 pt-3 mb-3 text-primary">
                            <span v-if="localData.data[0].value > localData.data[1].value">defeated</span>
                            <span v-else-if="localData.data[0].value < localData.data[1].value">lost to</span>
                            <span v-else>drew with</span>
                        </div>

                        <div class="col-lg-4 col-12 text-white">
                            <p class="m-0 text-white">{{ attachment.data[1].label}}</p>
                            <h2 class="text-white">{{ attachment.data[1].value ? attachment.data[1].value : 'N/A' }}</h2>
                        </div>

                    </div>

                    <div class="row" v-else>

                        <div class="col-lg-4 col-12 text-white">
                            <p class="m-0 text-white">{{ attachment.data[0].label}}</p>
                            <input type="number" class="text-center form-control form-control-lg" placeholder="Enter score" v-model.number="localData.data[0].value">
                        </div>

                        <div class="col-lg-4 col-12 pt-3 mb-3 text-primary">
                            <span v-if="localData.data[0].value > localData.data[1].value">defeated</span>
                            <span v-else-if="localData.data[0].value < localData.data[1].value">lost to</span>
                            <span v-else>drew with</span>
                        </div>

                        <div class="col-lg-4 col-12 text-white">
                            <p class="m-0 text-white">{{ attachment.data[1].label}}</p>
                            <input type="number" class="text-center form-control form-control-lg" placeholder="Enter score" v-model.number="localData.data[1].value">
                        </div>

                    </div>

                </card>

                <div class="mt-4 mb-4" v-if="attachment.displayTable">

                    <table class="table table-sm small">
                        <thead>
                            <tr>
                                <th>
                                    Label
                                </th>
                                <th>
                                    Value
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(record, index) in attachment.data" v-bind:key="index">
                                <td>
                                    {{ record.label ? record.label : 'Team 1' }}
                                </td>
                                <td>
                                    {{ record.value ? record.value : 'Unknown' }}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>

                <p>{{ attachment.summary }}</p>

            </div>

        </div>
    </div>
</template>
<script>

import _ from "lodash";

export default {
    name: 'embed-sports-result',
    props: ['attachment','id','index','readOnly'],
    components: {
        // Pie,
        // PieChart
    },
    mounted() {
       this.reqData = this.requestDataChecker();
    },

    data () {
        return {
            localData: _.cloneDeep(this.attachment),
            newData: false,
            editEnabled: false,
            reqData: false
        }
    },
    computed: {

    },

    watch: {

        attachment: {
            handler: function () {
                setTimeout(this.resetNewData,200);
            },
            deep: true,
        }

    },

    methods: {


        requestDataChecker(){

            if(this.readOnly){
                return false
            }

            if(this.attachment.requestData && this.checkForValues()){
                return true
            }

            return false
        },

        checkForValues(){

            let data = this.localData.data
            let dataReq = false

            data.forEach((record,i) => {

                if(!record.value){
                    dataReq = true
                }

            });

            return dataReq

        },

        resetNewData(){
            this.newData = false;
        }

    }
};
</script>

<style scoped>

</style>