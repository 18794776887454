<template>
  <div class="text-center">
    <h6 class="card-title text-muted font-weight-bold mt-5">
        Build your attachment(s)
    </h6>

    <div class="container bg-secondary rounded">

        <div v-if="attachments.length > 0">

          <!-- ADVANCED ATTACHMENTS -->
          <advanced-attachments
            :attachment="attachments"
            :id="null"
            :allowEdit="false"
            v-if="attachments.length > 0"
            v-on:updateAttachment="attachmentUpdated"
          ></advanced-attachments>

        </div>

        <!-- SET ATTACHMENT MODE (if data capture is supported)-->
        <div class="row justify-content-center pt-1 pb-4" v-if="options.supportDataCapture && !attachmentMode">

            <div class="col-12 mt-1 mb-0">
                <p class="text-muted small" v-if="attachments.length === 0">What would you like to do?</p>
                <p class="text-muted small" v-else>Add another?</p>
            </div>
            <div class="col-12 mb-0 text-center">
                <base-button size="sm" style="width: 100px;" class="m-1" type="light" outline @click="attachmentMode = 'share'">
                    share
                </base-button>
                <base-button size="sm" style="width: 100px;" class="m-1" type="light" outline @click="attachmentMode = 'request'">
                    request
                </base-button>
                <!-- <base-button size="sm" style="width: 100px;" type="light" outline @click="attachmentMode = 'templates'">
                    templates
                </base-button> -->
            </div>

        </div>

        <!-- DISPLAY ATTACHMENT OPTIONS -->
        <div class="row justify-content-center pt-1 pb-4" v-else-if="attachmentMode && !attachment">

            <div class="col-12 mt-1 mb-0 p-2">
                <p class="text-muted small">What type of information would you like to {{ attachmentMode }}?</p>
            </div>
            <div
                class="col-lg-2 col-sm-4 col-4 pt-2 pb-2 m-0 text-center justify-content-center"
                v-for="(at, index) in filteredTypes"
                v-bind:key="index"
                @click="setAttachmentType(at)"
            >
                <div class="text-center">
                    <img
                        :alt="at.name"
                        class="img-rt img-rt-selection pointer"
                        :src="at.image"
                    />
                </div>
                <div class="label-rt text-center text-default font-weight-bold pointer">
                {{ at.name }}
                </div>
            </div>

        </div>

        <!-- ATTACHMENT SETTINGS -->
        <div class="row justify-content-center pt-1 pb-4" v-else-if="attachment && attachment.type">

            <div class="col-lg-6 col-12 mt-1 mb-0 p-2">

                    <base-input
                        v-model="attachment.title"
                        class="mt-3 mb-3 text-center"
                        :placeholder="'Enter a title for this ' + attachment.type"
                    >
                    </base-input>

                    <div class="input-group">
                        <textarea-autosize
                            :placeholder="'Provide a summary of the ' + attachment.type"
                            ref="summaryTextarea"
                            v-model="attachment.summary"
                            :min-height="50"
                            class="form-control"
                        />
                    </div>

            </div>

            <div class="w-100 d-block"></div>


            <!-- SURVEY -->
            <div class="col-lg-6 col-12 text-center mb-0 p-2" v-if="attachment.type === 'survey'">
                <hr>
                <div v-for="(record, index) in attachment.data" v-bind:key="index">
                    
                    <base-input
                        v-model="attachment.data[index].label"
                        class="mb-3 text-center"
                        placeholder="Question"
                    >
                    </base-input>

                    <p class="mt-3 small text-left mb-1">Response Type</p>
                    <div class="btn-group w-100 mb-5" role="group">
                        <button type="button" class="btn w-50" :class="[ !attachment.data[index].type ? 'btn-default' : 'btn-outline-default']" @click="attachment.data[index].type = null">Written</button>
                        <button type="button" class="btn w-50" :class="[ attachment.data[index].type === 'rating' ? 'btn-default' : 'btn-outline-default']" @click="attachment.data[index].type = 'rating'">Rating</button>
                    </div>

                </div>

                <base-button size="sm" type="default" @click="attachment.data.push({ label: null, value: null, type: null})">
                    <i class="fas fa-plus"></i> new question
                </base-button>

            </div>

            <!-- POLL -->
            <div class="col-lg-6 col-12 text-center mb-0 p-2" v-if="attachment.type === 'poll'">
                <hr>
                <div v-for="(record, index) in attachment.data" v-bind:key="index">
                    
                    <base-input
                        v-model="attachment.data[index].label"
                        class="mb-3 text-center"
                        placeholder="Enter your option"
                    >
                    </base-input>

                </div>

                <base-button size="sm" type="default" @click="attachment.data.push({ label: null, value: null, type: null})">
                    <i class="fas fa-plus"></i> new option
                </base-button>

            </div>

            <!-- PIE CHART -->
            <div class="col-lg-6 col-12 text-center mb-0 p-2" v-if="attachment.type === 'piechart'">

                <div v-for="(record, index) in attachment.data" v-bind:key="index">
                    <hr>
                    
                    <base-input
                        v-model="attachment.data[index].label"
                        class="mb-3 text-center"
                        placeholder="Label"
                    >
                    </base-input>

                    <input
                        type="number"
                        v-model="attachment.data[index].value"
                        class="mb-3 form-control"
                        placeholder="Value"
                        v-if="attachmentMode === 'share'"
                    >

                </div>

                <base-button size="sm" type="default" @click="attachment.data.push({ label: null, value: null})">
                    <i class="fas fa-plus"></i> new segment
                </base-button>

                <hr>

                <a role="button" class="small pointer" @click="attachment.showAdvanced = true">
                    advanced options
                </a>

                <div v-if="attachment.showAdvanced">
                    
                    <base-checkbox class="mb-3" v-model="attachment.displayTable">
                        Display Table
                    </base-checkbox>

                </div>

            </div>

            <!-- METRICS -->
            <div class="col-lg-6 col-12 text-center mb-0 p-2" v-if="attachment.type === 'metrics'">

                <div v-for="(record, index) in attachment.data" v-bind:key="index">
                    <hr>
                    
                    <base-input
                        v-model="attachment.data[index].label"
                        class="mb-3 text-center"
                        placeholder="Label"
                    >
                    </base-input>

                    <input
                        type="number"
                        v-model="attachment.data[index].value"
                        class="mb-3 form-control"
                        placeholder="Value"
                        v-if="attachmentMode === 'share'"
                    >

                    <a role="button" class="small pointer" @click="attachment.data[index].showAdvanced = true">
                        advanced options
                    </a>

                    <div v-if="attachment.data[index].showAdvanced">
                        <p class="small text-muted mt-3 mb-0">Choose style</p>
                        <button class="mb-1 btn btn-sm" @click="attachment.data[index].class = null">
                            None
                        </button>
                        <button class="mb-1 btn btn-sm" :class="[ attachment.data[index].class === 'default' ? 'btn-default' : 'btn-outline-default']" @click="attachment.data[index].class = 'default'">
                            Default
                        </button>
                        <button class="mb-1 btn btn-sm" :class="[ attachment.data[index].class === 'primary' ? 'btn-primary' : 'btn-outline-primary']" @click="attachment.data[index].class = 'primary'">
                            primary
                        </button>
                        <button class="mb-1 btn btn-sm" :class="[ attachment.data[index].class === 'success' ? 'btn-success' : 'btn-outline-success']" @click="attachment.data[index].class = 'success'">
                            success
                        </button>
                        <button class="mb-1 btn btn-sm" :class="[ attachment.data[index].class === 'warning' ? 'btn-warning' : 'btn-outline-warning']" @click="attachment.data[index].class = 'warning'">
                            warning
                        </button>
                        <button class="mb-1 btn btn-sm" :class="[ attachment.data[index].class === 'danger' ? 'btn-danger' : 'btn-outline-danger']" @click="attachment.data[index].class = 'danger'">
                            danger
                        </button>
                        <button class="mb-1 btn btn-sm" :class="[ attachment.data[index].class === 'light' ? 'btn-light' : 'btn-outline-light']" @click="attachment.data[index].class = 'light'">
                            light
                        </button>
                        <button class="mb-1 btn btn-sm" :class="[ attachment.data[index].class === 'dark' ? 'btn-dark' : 'btn-outline-dark']" @click="attachment.data[index].class = 'dark'">
                            dark
                        </button>
                    </div>

                    <div class="input-group mt-3 mb-3" v-if="attachment.data[index].showAdvanced">
                        <input type="text" v-model="attachment.data[index].icon" class="form-control" placeholder="Set Icon (use Font Awesome class e.g. stopwatch)" aria-label="Set Icon (use Font Awesome class e.g. stopwatch)">
                        <div class="input-group-append">
                            <a href="https://fontawesome.com/icons?d=gallery" target="_blank">
                                <button class="btn btn-outline-default" type="button"><i class="fal fa-search"></i></button>
                            </a>
                        </div>
                    </div>

                    <base-input
                        v-model="attachment.data[index].prefix"
                        class="mb-3 text-center"
                        placeholder="Set Prefix"
                        v-if="attachment.data[index].showAdvanced"
                    >
                    </base-input>

                    <select v-if="attachment.data[index].showAdvanced" v-model="attachment.data[index].format" class="form-control mb-3">
                        <option :value="null">No Formatting</option>
                        <option value="money">Money</option>
                    </select>

                </div>

                <base-button class="mt-3" size="sm" type="default" @click="attachment.data.push({ 
                    label: null,
                    value: null,
                    showAdvanced: false,
                    class: null,
                    prefix: null,
                    icon: null,
                    format: null
                })">
                    <i class="fas fa-plus"></i> new card
                </base-button>

            </div>

            <!-- SPORTS RESULT -->
            <div class="col-lg-6 col-12 text-center mb-0 p-2" v-if="attachment.type === 'sportsresult'">

                    <hr>
                    
                    <base-input
                        v-model="attachment.data[0].label"
                        class="mb-3 text-center"
                        placeholder="Team 1 Name"
                    >
                    </base-input>

                    <input
                        type="number"
                        v-model="attachment.data[0].value"
                        class="mb-3 form-control"
                        placeholder="Value"
                        v-if="attachmentMode === 'share'"
                    >

                    <hr>

                    <base-input
                        v-model="attachment.data[1].label"
                        class="mb-3 text-center"
                        placeholder="Team 2 Name"
                    >
                    </base-input>

                    <input
                        type="number"
                        v-model="attachment.data[1].value"
                        class="mb-3 form-control"
                        placeholder="Value"
                        v-if="attachmentMode === 'share'"
                    >

                    <a role="button" class="small pointer" @click="attachment.showAdvanced = true">
                        advanced options
                    </a>

                    <div v-if="attachment.showAdvanced">
                        <p class="small text-muted mt-3 mb-0">Choose style</p>
                        <button class="mb-1 btn btn-sm" :class="[ !attachment.class ? 'btn-default' : 'btn-outline-default']" @click="attachment.class = null">
                            default
                        </button>
                        <button class="mb-1 btn btn-sm" :class="[ attachment.class === 'primary' ? 'btn-primary' : 'btn-outline-primary']" @click="attachment.class = 'primary'">
                            primary
                        </button>
                        <button class="mb-1 btn btn-sm" :class="[ attachment.class === 'success' ? 'btn-success' : 'btn-outline-success']" @click="attachment.class = 'success'">
                            success
                        </button>
                        <button class="mb-1 btn btn-sm" :class="[ attachment.class === 'warning' ? 'btn-warning' : 'btn-outline-warning']" @click="attachment.class = 'warning'">
                            warning
                        </button>
                        <button class="mb-1 btn btn-sm" :class="[ attachment.class === 'danger' ? 'btn-danger' : 'btn-outline-danger']" @click="attachment.class = 'danger'">
                            danger
                        </button>
                        <button class="mb-1 btn btn-sm" :class="[ attachment.class === 'dark' ? 'btn-dark' : 'btn-outline-dark']" @click="attachment.class = 'dark'">
                            dark
                        </button>
                    </div>

            </div>

            <!-- VIDEO -->
            <div class="col-lg-6 col-12 text-center mb-0 p-2" v-if="attachment.type === 'video'">

                    <base-input
                        v-model="attachment.src"
                        class="mb-3 text-center"
                        placeholder="Paste your video URL"
                        v-if="attachmentMode === 'share'"
                    >
                    </base-input>

                    <div class="float-center text-uppercase text-muted" v-if="attachmentMode === 'share'">
                        <small class="mr-1">SUPPORTS</small>
                        <i class="fab fa-youtube mr-1" title="YouTube"></i>
                        <i class="fab fa-vimeo mr-1" title="Vimeo"></i>
                        <i class="icon-wistia mr-1" title="Wistia"></i>
                        <i class="fab fa-dailymotion mr-1" title="Daily Motion"></i>
                        <i class="icon-coub" title="Coub"></i>
                    </div>

            </div>

            <!-- PODCAST EPISODE -->
            <div class="col-lg-6 col-12 text-center mb-0 p-2" v-if="attachment.type === 'podcast-episode'">

                    <base-input
                        v-model="attachment.src"
                        class="mb-3 text-center"
                        placeholder="Paste your episode link"
                        v-if="attachmentMode === 'share'"
                    >
                    </base-input>

            </div>

            <!-- CTA -->
            <div class="col-lg-6 col-12 text-center mb-0 p-2" v-if="attachment.type === 'cta'">

                    <base-input
                        v-model="attachment.src"
                        class="mb-3 text-center"
                        placeholder="Enter your link"
                        v-if="attachmentMode === 'share'"
                    >
                    </base-input>


                    <a role="button" class="small pointer" @click="attachment.showAdvanced = true">
                        advanced options
                    </a>

                    <div v-if="attachment.showAdvanced">
                        <p class="small text-muted mt-3 mb-0">Choose style</p>
                        <button class="mb-1 btn btn-sm" :class="[ !attachment.class ? 'btn-default' : 'btn-outline-default']" @click="attachment.class = null">
                            default
                        </button>
                        <button class="mb-1 btn btn-sm" :class="[ attachment.class === 'primary' ? 'btn-primary' : 'btn-outline-primary']" @click="attachment.class = 'primary'">
                            primary
                        </button>
                        <button class="mb-1 btn btn-sm" :class="[ attachment.class === 'success' ? 'btn-success' : 'btn-outline-success']" @click="attachment.class = 'success'">
                            success
                        </button>
                        <button class="mb-1 btn btn-sm" :class="[ attachment.class === 'warning' ? 'btn-warning' : 'btn-outline-warning']" @click="attachment.class = 'warning'">
                            warning
                        </button>
                        <button class="mb-1 btn btn-sm" :class="[ attachment.class === 'danger' ? 'btn-danger' : 'btn-outline-danger']" @click="attachment.class = 'danger'">
                            danger
                        </button>
                        <button class="mb-1 btn btn-sm" :class="[ attachment.class === 'dark' ? 'btn-dark' : 'btn-outline-dark']" @click="attachment.class = 'dark'">
                            dark
                        </button>
                    </div>

            </div>

            <!-- IMAGE -->
            <div class="col-lg-6 col-12 text-center mb-0 p-2" v-if="attachment.type === 'image'">

                <div v-if="!attachment.src">

                    <p class="text-muted small">Upload your image</p>

                    <clipper-fixed
                        :src="tmpImage"
                        :border="2"
                        :rotate="tmpImageRotation"
                        :round="false"
                        ref="clipper"
                    >
                        <div slot="placeholder" class="text-center small mb-4">
                            Select!
                        </div>
                    </clipper-fixed>

                    <div
                        class="text-center text-default mt-2"
                        role="group"
                        aria-label="First group"
                        v-if="tmpImage && uploadingImage"
                    >
                        <i class="fad fa-spinner-third fa-spin"></i> Uploading your image
                    </div>

                    <div
                        class="text-center"
                        role="group"
                        aria-label="First group"
                        v-if="!tmpImage && !uploadingImage"
                    >
                        <clipper-upload v-model="tmpImage">
                            <base-button type="primary">Select Image</base-button>
                        </clipper-upload>
                    </div>

                    <div
                        class="btn-toolbar justify-content-between mt-2"
                        role="toolbar"
                        v-if="tmpImage && !uploadingImage"
                    >
                        <div class="btn-group">
                            <base-button type="danger" @click="cancelUpload">
                                <i class="fal fa-ban"></i>
                            </base-button>
                        </div>
                        <div class="btn-group">
                            <base-button type="secondary" @click="rotateCCW">
                                <i class="fal fa-redo fa-flip-horizontal"></i>
                            </base-button>
                            <base-button type="secondary" @click="rotateCW">
                                <i class="fal fa-redo"></i>
                            </base-button>
                        </div>
                        <div class="btn-group">
                            <base-button type="default" @click="getClipResult">
                                <i class="fal fa-check"></i>
                            </base-button>
                        </div>
                    </div>

                </div>
                <div v-else>

                    <img :src="attachment.src" class="img-fluid">

                </div>

            </div>

            <!-- PDF -->
            <div class="col-lg-6 col-12 text-center mb-0 p-2" v-if="attachment.type === 'pdf'">

                <div v-if="!attachment.src">

                    <p class="text-muted small">Upload your PDF</p>

                    <div class="alert alert-danger" v-if="errorMessage">
                        {{ errorMessage }}
                    </div>

                    <div class="custom-file" v-if="!tmpFile && !uploadingFile">
                        <input type="file" class="custom-file-input pointer" ref="fileUpload" v-on:change="handleFileUpload()">
                        <label class="custom-file-label" for="fileUpload">Choose PDF</label>
                    </div>
                    <div class="text-primary" v-else-if="tmpFile && uploadingFile">
                        <i class="fad fa-spinner-third fa-spin"></i> Uploading your PDF
                    </div>
                    <div v-else-if="tmpFile && !uploadingFile">
                        PDF uploaded!
                    </div>

                </div>
                <div v-else>

                    <pdf :src="attachment.src" :page="1"></pdf>

                </div>

            </div>


            <!-- TABLE -->
            <div class="col-lg-6 col-12 text-center mb-0 p-2" v-if="attachment.type === 'table'">

                <p class="mt-3 small text-left mb-1">Input Format</p>
                <div class="btn-group w-100 mb-2" role="group">
                    <button type="button" class="btn w-50" :class="[ attachment.inputFormat === 'json' ? 'btn-default' : 'btn-outline-default']" @click="attachment.inputFormat = 'json'">JSON</button>
                    <button type="button" class="btn w-50" :class="[ attachment.inputFormat === 'csv' ? 'btn-default' : 'btn-outline-default']" @click="attachment.inputFormat = 'csv'">CSV</button>
                </div>

                <div class="input-group">
                    <textarea-autosize
                        :placeholder="'Paste your ' + attachment.inputFormat"
                        ref="tableData"
                        v-model="attachment.inputData"
                        :min-height="50"
                        class="form-control"
                    />
                </div>

                <div class="alert alert-danger" v-if="error">
                    {{ error }}
                </div>
                <div class="alert alert-success" v-if="success">
                    {{ success }}
                </div>

                <base-button outline size="sm" block @click="verifyData(attachment.inputData, attachment.inputFormat)">
                    Verify
                </base-button>

            </div>

            <!-- WEBSITE -->
            <div class="col-lg-6 col-12 text-center mb-0 p-2" v-show="attachment.type === 'website'">

              <p class="mt-3 small text-left mb-1">Enter the website you would like to link:</p>

              <input type="input" placeholder="Add your website link" v-model="attachment.src" class="form-control" />

              <link-prevue ref="linkPreview" :url="attachment.src" v-show="attachment.src" cardWidth="100%" :showButton="false">
                <template slot="loading">
                  <!-- set your custom loading -->
                  <div class="text-center text-default">
                    <i class="fad fa-spinner-third fa-2x fa-spin"></i>
                  </div>
                </template>

                <template slot-scope="props">
                  <div class="card shadow" v-if="props.title">
                    <div class="d-flex flex-row align-items-center">
                      <div class="p-2 align-lg-left align-center">
                        <img class="card-img-top" :src="props.img" :alt="props.title" />
                      </div>
                      <div class="p-2 text-lg-left text-center">
                        <h5 class="text-default font-weight-bold">{{ props.title }}</h5>
                        <p class="card-text">{{ props.description }}<br /></p>
                        <a v-bind:href="props.url" class="btn btn-sm btn-primary">More</a>
                      </div>
                    </div>
                  </div>
                  <div class="card shadow p-2" v-else>
                    Preview is not available but you can still link to this website<br />
                    <a v-bind:href="props.url" class="btn btn-block btn-primary mt-2">Open website</a>
                  </div>
                </template>
              </link-prevue>
            </div>

        </div>

        <div class="row justify-content-center pt-1 pb-4" v-if="attachmentMode && attachment && attachment.type">

            <div class="col-12 mt-3">
                <base-button size="sm" style="width: 100px;" type="danger" outline @click="cancelAttachment">
                    cancel
                </base-button>
                <base-button size="sm" style="width: 100px;" type="success" outline @click="saveAttachment" v-if="attachment && (!attachment.reqVerification || (attachment.reqVerification && success))">
                    save
                </base-button>
                <base-button size="sm" style="width: 100px;" type="success" disabled outline @click="saveAttachment" v-else>
                    save
                </base-button>
            </div>

        </div>

    </div>


  </div>
</template>
<script>

import BaseDropdown from "@/components/BaseDropdown";
import firebase from "firebase/app";
import { db } from "@/firebase";
import store from "@/store.js";
import { mapGetters } from "vuex";
import _ from "lodash";

import AdvancedAttachments from "@/components/reflections/AdvancedAttachments";
import LinkPrevue from "link-prevue";
import { clipperFixed, clipperUpload } from "vuejs-clipper";
import pdf from 'vue-pdf'

import attachmentTypes from "@/models/attachmentTypes";

export default {
  name: "attachment-builder",
  components: {
    BaseDropdown,
    AdvancedAttachments,
    LinkPrevue,
    clipperFixed,
    clipperUpload,
    pdf
  },
  props: ["options"],

  mounted() {
      
    this.setAttachmentMode();
    this.setExistingAttachment();

  },

  watch: {

    options: {
      // This will let Vue know to look inside the array
      deep: true,

      // We have to move our method to a handler field
      handler(newVal, oldVal) {
        this.setAttachmentMode();
        this.setExistingAttachment();
      },
    },

  },

  data() {
    return {

        attachmentMode: '',
        attachmentTypes,

        // Attachment Details
        attachment: null,
        error: null,
        success: null,

        // Record of Attachments
        attachments: [],

        // handling images
        tmpImage: null,
        tmpImageRotation: 0,
        uploadingImage: false,

        // handling other files
        tmpFile: null,
        uploadingFile: false,

        errorMessage: null


    };
  },
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      // map `this.groups` to `this.$store.getters.groups`
      user: "user",
      groups: "groups",
    }),

    filteredTypes(){

        if(!this.attachmentMode){
            return
        }

        return this.attachmentTypes.filter((at) => {
            return at[this.attachmentMode];
        });
        
    }

  },
  methods: {

        rotateCCW() {
            if (this.tmpImageRotation === 0) {
                this.tmpImageRotation = 315;
            } else {
                this.tmpImageRotation -= 45;
            }
        },

        rotateCW() {
            if (this.tmpImageRotation >= 315) {
                this.tmpImageRotation = 0;
            } else {
                this.tmpImageRotation += 45;
            }
        },

        getClipResult: function () {
            const vm = this;

            const canvas = this.$refs.clipper.clip({ maxWPixel: 800 }); //call component's clip method
            canvas.toBlob(function (blob) {

                const reader = new FileReader();
                reader.addEventListener("load", function () {
                    vm.tmpImage = this.result;
                });
                vm.afterComplete(blob);
                // reader.readAsDataURL(blob);
            });
        },

        async afterComplete(upload) {
            const vm = this;
            vm.uploadingImage = true;
            var imageName = this.$uuid.v4();
            try {
                //save image
                let file = upload;
                var metadata = {
                    contentType: "image/png",
                };
                var storageRef = firebase.storage().ref("reflections");
                var imageRef = storageRef.child(`${imageName}.png`);
                await imageRef.put(file, metadata);
                var downloadURL = await imageRef.getDownloadURL();
                vm.attachment.src = downloadURL;
                // vm.updateProfile();
                vm.cancelUpload();
            } catch (error) {
                // console.log(error);
            }
        },

        cancelUpload() {
            this.uploadingFile = false;
            this.tmpFile = null;
            this.uploadingImage = false;
            this.tmpImage = null;
            this.tmpImageRotation = 0;
        },
      
        attachmentUpdated(res){

            let index = res.index
            let action = res.action
            let order = res.order
            let ourObject = this.attachments[index]
            let newPosition = index+order

            // Remove the attachment
            this.attachments.splice(index, 1);

            // If we need to reorder
            if(action === 'reorder'){
                this.attachments.splice(newPosition, 0, ourObject);
            }

        },

        handleFileUpload(){
            this.errorMessage = null;
            let thisFile = this.$refs.fileUpload.files[0];
            if((thisFile.size / 1000) > 2000){

                this.errorMessage = 'Sorry but that PDF file is too large, it should be less than 2MB';
                this.cancelUpload();

            }else{
                this.tmpFile = thisFile;
                this.afterUploadComplete(thisFile);
            }

        },

        async afterUploadComplete(upload) {
            const vm = this;
            vm.uploadingFile = true;
            var fileName = this.$uuid.v4();
            try {
                //save image
                let file = upload;
                var metadata = {
                    contentType: "application/pdf",
                };
                var storageRef = firebase.storage().ref("reflections");
                var fileRef = storageRef.child(`${fileName}.pdf`);
                await fileRef.put(file, metadata);
                var downloadURL = await fileRef.getDownloadURL();
                vm.attachment.src = downloadURL;
                // vm.updateProfile();
                vm.cancelUpload();
            } catch (error) {
                // console.log(error);
            }
        },

        isJsonString(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        },

        verifyData(inputData,inputFormat){

            let thisData = inputData
            let json = []

            this.error = null
            this.success = null
            this.attachment.data = []
            this.attachment.headings = []

            if(!inputData){
                this.error = 'You did not even provide any data'
                return
            }

            if(inputFormat === 'csv'){

                json = this.$papa.parse(inputData, { header: true } ).data

            }else{

                if(!this.isJsonString(thisData)){
                    this.error = 'Please check the format of your table'
                    return
                }
            }

            if(typeof json !== 'object') {
                this.error = 'Please check the format of your table'
                return
            }

            if(json.length === 0){

                if(!this.isJsonString(thisData)){
                    this.error = 'Please check the format of your table'
                    return
                }

                json = JSON.parse(thisData)
            }

            if(typeof json === 'object') {

                this.attachment.headings = Object.keys(json[0])
                this.attachment.data = json
                this.success = 'Looks good!'

            }


        },

        saveAttachment(){

            if(this.attachment.type === 'website' && this.$refs.linkPreview.response){
                let pageData = this.$refs.linkPreview.response

                if(pageData.title){
                    this.attachment.pageTitle = pageData.title
                }

                if(pageData.image){
                    this.attachment.pageImage = pageData.image
                }

                if(pageData.description){
                    this.attachment.pageDescription = pageData.description
                }

            }

            this.attachments.push(this.attachment);
            this.cancelAttachment();

        },

        cancelAttachment(){

            this.attachment = null
            this.attachmentMode = ''
            if(!this.options.supportDataCapture){
                this.attachmentMode = 'share';
            }
            this.cancelUpload()

        },

        toggleAdvanced(index){

            this.attachment[index].showAdvanced = true

        },

        setAttachmentMode(){
            if(!this.options.supportDataCapture){
                this.attachmentMode = 'share';
            }
        },

        setExistingAttachment(){
            if(this.options.attachments && this.options.attachments.length > 0){
                this.attachments = this.options.attachments;
            } 
        },

        setAttachmentType(attachmentType){

            this.attachment = _.cloneDeep(attachmentType.baseline);
            if(this.options.supportDataCapture && this.attachmentMode === 'request'){
                this.attachment.requestData = true;
            }else{
                this.attachment.requestData = false;
            }

        }
  },
};
</script>

<style scoped>

    .img-rt {
    /* position: relative; */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 60px;
    height: 60px;
    transition: transform 0.2s;
    }

    .img-rt-selection:hover {
    transform: scale(1.1);
    }

    .img-report {
    position: relative;
    top: 0;
    left: 0;
    width: 35px;
    height: 35px;
    }

    .label-rt {
    font-size: 0.7em;
    }

</style>
