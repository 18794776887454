<template>
    <div>

        <div v-if="reportdata">

            <!-- HERO INTRO -->
            <div class="position-relative mb-4">
                <!-- shape Hero -->
                <section class="section-shaped my-0">
                    <div class="shape shape-style-1 bg-gradient-default shape-skew">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <div class="container shape-container d-flex">
                        <div class="col px-0">
                            <div class="row">
                                <div class="col-lg-8">
                                    <h1 class="display-3 text-white">View the report for {{ reportdata.user.firstName }}</h1>
                                    <p class="lead text-white mt-0">
                                        for the week of {{ $dayjs(reportdata.startDate).format("Do MMM") }} - {{ $dayjs(reportdata.endDate).format("Do MMM YYYY") }}
                                    </p>
                                    <p class="lead text-white">
                                        I have been using <span class="text-primary font-weight-bold"><i>My Reflections</i></span> to reflect on my
                                        professional and personal reflection and wanted to share this report with you!
                                    </p>
                                </div>
                                <div class="col-lg-4 text-lg-right text-center">
                                    <div class="card-profile-image">
                                        <img :src="reportdata.user.photoURL" class="rounded-circle border border-white-50 border-2 bg-white">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <!-- 1st Hero Variation -->
            </div>

            <section class="section section-lg pt-lg-0 mt--300">
                <div class="container">

                    <card class="border-0" hover shadow body-classes="py-5">

                        <weekly-reflection-report-layout :new-report="reportdata" :expanded-details="true" :show-title="false"></weekly-reflection-report-layout>

                    </card>

                    <comments :report-uid="reportdata.id" :owner-uid="reportdata.uid"></comments>


                </div>
            </section>


            <!-- START A REFLECTION -->
            <section class="section section-shaped my-0 overflow-hidden" v-if="user && !user.loggedIn">
                <div class="shape shape-style-3 bg-gradient-default shape-skew">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div class="container pt-lg pb-300">
                    <div class="row text-center justify-content-center">
                        <div class="col-lg-10">
                            <h2 class="display-3 text-white">Start a reflection right now!</h2>
                            <p class="lead text-white">
                                It's easy to get starting with <span class="font-weight-bold"><i>My Reflections</i></span>,
                                in fact you can start right now!
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <!-- REFLECT PANEL -->
            <section class="section section-lg pt-lg-0 section-contact-us" id="get-started" v-if="user && !user.loggedIn">
                <div class="container">
                    <div class="row justify-content-center mt--300">
                        <div class="col-lg-10">

                                <reflection name="header"></reflection>

                        </div>
                    </div>
                </div>
            </section>

            <section class="section section-lg pt-lg-0" id="get-started" v-if="user && !user.loggedIn">
                <div class="container">
                    <div class="row justify-content-center mt-300">
                        <div class="col-lg-10 text-center">
                            
                            <p class="font-weight-bold text-muted">OR</p>
                            <router-link to="/landing" class="btn btn-default btn-lg">
                                Learn More
                            </router-link>

                        </div>
                    </div>
                </div>
            </section>
            
        </div>

        <div v-if="!reportdata">

            <section class="section section-shaped my-0 overflow-hidden" v-if="!reportuuid">
                <div class="shape shape-style-3 bg-gradient-default shape-skew">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div class="container pt-lg pb-300">
                    <div class="row text-center justify-content-center mt-4">
                        <div class="col-lg-10 mt-4">
                            <h2 class="display-3 text-white">Oh no... this is not a valid report</h2>
                        </div>
                    </div>
                </div>
            </section>
            <section class="section section-shaped my-0 overflow-hidden" v-if="reportuuid">
                <div class="shape shape-style-3 bg-gradient-default shape-skew">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div class="container pt-lg pb-300">
                    <div class="row text-center justify-content-center mt-4">
                        <div class="col-lg-10 mt-4">
                            <i class="fad fa-spinner-third fa-4x fa-spin text-white"></i>
                            <h2 class="display-3 text-white">Preparing report</h2>
                        </div>
                    </div>
                </div>
            </section>

        </div>
       
    </div>
</template>
<script>

// import WeeklyReflectionReport from "@/components/weekly/Report";
import firebase from 'firebase/app'
import { db } from '@/firebase';
import store from '@/store.js'
import { mapGetters } from "vuex";

import Reflection from "@/components/reflections/Reflection";
import Comments from "@/components/weekly/Comments";
import WeeklyReflectionReportLayout from "@/components/weekly/Layout";

// const currentUser = firebase.auth().currentUser;

export default {
    name: 'shared-report',

    components: {
        WeeklyReflectionReportLayout,
        Reflection,
        Comments
    },

    data() {
      return {
          reportdata: null,
          reportuuid: null,
          reportRequested: false,
          pageTitle: 'Report',
          error: null
      }
    },

    // metaInfo: {
    //   title: 'Report'
    // },

    mounted() {

      this.setReportUUID()

    },

    watch: {
        reportuuid: function(){
            this.loadReport()
        }
    },

    computed: {

        ...mapGetters({
            // map `this.user` to `this.$store.getters.user`
            // map `this.groups` to `this.$store.getters.groups`
            user: "user",
            groups: "groups"
        })

    },


    methods: {

        setReportUUID(){
            this.reportuuid = this.$route.params.uuid
        },

        loadReport(){

            if(!this.reportuuid){
                return
            }

            this.reportRequested = true

            const vm = this
            const axios = require('axios');

            // Send token to your backend via HTTPS
            var config = {};

            // let url = 'http://localhost:5001/my-reflection-22932/australia-southeast1/app/api/getpubliclink'
            let url = 'https://australia-southeast1-my-reflection-22932.cloudfunctions.net/app/reports/' + this.reportuuid

            // Make a request for a user with a given ID
            axios.get( url , config)
            .then(function (response) {

                // handle success
                vm.reportdata = response.data
                vm.reportRequested = false

                vm.$analytics.logEvent('report_viewed', {
                    reportId: vm.reportdata.id,
                    reportuuid: vm.reportuuid,
                    fullName: vm.reportdata.user.fullName
                });

            })
            .catch(function (error) {
                // handle error
                // console.log(error.response)
                vm.error = true
                vm.reportuuid = null
            })


        }

    }

};
</script>
<style scoped>

.img-inthought {
    width: 25%; 
    height: 25%;
}

.img-filter {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 35px; 
    height: 35px;
}

.unselected {
    filter: grayscale(100%);
    opacity: 0.3;
}

.row-striped:nth-of-type(odd){
  background-color: #efefef;
  /* border-left: 4px #000000 solid; */
}

.row-striped:nth-of-type(even){
  background-color: #ffffff;
  /* border-left: 4px #efefef solid; */
}

.row-striped {
    padding: 15px 0;
}

</style>
