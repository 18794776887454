<template>
    <div>

        <card shadow class="p-4" no-body v-if="reflectionTypes && user">
            <div class="row justify-content-center mb-2">
                <div class="col">
                    <h4>Goal History</h4>
                </div>
            </div>

            <div class="row justify-content-center" v-if="goals.length > 0">
                <div
                class="col-lg-6 col-12"
                v-for="(goal, t) in sortedGoals"
                v-bind:key="t"
                >
                    <card type="white" class="card card-body mb-4 p-0">

                        <div class="float-left mr-2">

                            <radial-progress-bar
                                :total-steps="goal.target"
                                :completed-steps="goal.reflections.length"
                                :diameter="circleOptions.size"
                                :startColor="circleOptions.startColor"
                                :stopColor="circleOptions.stopColor"
                                :strokeWidth="circleOptions.strokeWidth"
                                :innerStrokeWidth="circleOptions.innerStrokeWidth"
                                :innerStrokeColor="circleOptions.innerStrokeColor"
                            >
                                <p class="relative"> <!-- WIP: add check for status - completed / overdue -->
                                    <img
                                        :alt="goal.reflectionType"
                                        :class="circleOptions.iconClass"
                                        :src="goal.reflectionType.image"
                                    />

                                    <img
                                        alt="Unsuccessfull"
                                        :class="statusIconClass"
                                        src="/img/images/unsuccessful.svg"
                                        v-if="isSuccessful(goal) === 'unsuccessful'"
                                    />

                                    <img
                                        alt="Successfull"
                                        :class="statusIconClass"
                                        src="/img/images/successful.svg"
                                        v-if="isSuccessful(goal) === 'successful'"
                                    />

                                </p>
                            </radial-progress-bar>

                        </div>

                            <p class="small text-default text-left mb-0">
                                You created this goal and named it
                                <span class="font-weight-bold">{{ goal.name }}</span>,
                                committing yourself to completing
                                <span class="font-weight-bold">{{ goal.target }} {{ goal.reflectionType.name }} reflections</span>
                                <span v-if="goal.tag !== 'all' && goal.tag"> tagged as <b>{{ goal.tag }}</b></span>  
                                between <span class="font-weight-bold">{{ $dayjs.unix(goal.creationSeconds).format("MMM Do, YYYY") }}</span> and
                                 <span class="font-weight-bold">{{ $dayjs.unix(goal.targetDate.seconds).format("MMM Do, YYYY") }}</span>
                                and <span class="font-weight-bold">completed {{ goal.reflections.length }}</span>
                            </p>

                    </card>
                </div>
            </div>
            <div class="row justify-content-center" v-else>

                <div class="col-12 mt-3 mb-3 text-center text-primary">

                    <div class="alert alert-default text-center" v-if="!loaded">
                        <i class="fad fa-spinner-third fa-4x fa-spin"></i>
                        <h5 class="text-white mt-2">Loading your goals!</h5>
                    </div>

                    <div class="alert alert-defaultt text-center" v-if="loaded">
                        No Goals
                    </div>

                </div>

            </div>
        </card>

    </div>
</template>
<script>

import firebase from "firebase/app";
import { db } from "@/firebase";

import store from "@/store.js";
import { mapGetters } from "vuex";

import RadialProgressBar from 'vue-radial-progress'

export default {
    name: 'profile-goals',
    props: ['reflectionTypes'],
    components: { 
        RadialProgressBar
    },

    computed: {

        ...mapGetters({
            // map `this.user` to `this.$store.getters.user`
            // map `this.groups` to `this.$store.getters.groups`
            user: "user",
            groups: "groups"
        }),

        statusIconClass(){
            return 'status-icon-xs';
        },

        circleOptions() {
            return { 
                size: 70,
                strokeWidth: 5,
                innerStrokeWidth: 5,
                iconClass: 'goalIcon goalIcon-xs bg-white mt-3',
                startColor: '#AED768',
                stopColor: '#8D6C9F',
                innerStrokeColor: 'rgba(0,0,0,0.1)',
                innerStrokeColorAdd: 'rgba(0,0,0,0.05)'
            };
        },

        sortedGoals() {
            var content = this.goals;
            return content.sort((b, a) => (a.creationSeconds > b.creationSeconds ? 1 : -1));
        }

    },

    mounted(){

        this.getGoals();
        
    },

    data () {
        return {
            loaded: false,
            querying: false,
            goals: []

        }
    },
    methods: {

        isSuccessful(goal){

            if(goal.target === goal.reflections.length){
                return 'successful';
            }

            let currentDateTime = new firebase.firestore.Timestamp.fromDate(new Date());
            if(goal.targetDate.seconds < currentDateTime.seconds){
                return 'unsuccessful';
            }

            if(goal.status === 'cancelled'){
                return 'unsuccessful';
            }

        },

        getReflectionTypeImage(type) {

            let rt = this.reflectionTypes.find(element => element.name === type)
            return rt.image

        },

        getGoals() {

            const vm = this;

            if (vm.user.loggedIn && vm.user.data && vm.user.data.uid) {
                // console.log('Getting your reflections')

                var docRef = db.collection("users").doc(vm.user.data.uid).collection("goals");
                docRef = docRef.where("status", "!=", "active");
                docRef = docRef.orderBy("status", "asc");
                // docRef = docRef.limit(3);
                docRef.onSnapshot(async function(docs) {
                    vm.loaded = true;
                    docs.docChanges().forEach(function(change) {
                        let { newIndex, oldIndex, doc, type } = change;
                        let docData = doc.data();
                        if (type === "added") {
                            vm.goals.splice(newIndex, 0, docData);
                        } else if (type === "modified") {
                            vm.goals.splice(oldIndex, 1);
                            vm.goals.splice(newIndex, 0, docData);
                        } else if (type === "removed") {
                            vm.goals.splice(oldIndex, 1);
                        }
                    });
                });

            }
        }

    }
};
</script>
<style scoped>

    .border-avatar {
        border: 2px solid #8d6c9f;
    }

    .goal-details{
        display:block;
        width:100%;
        /* height:calc(2.75rem + 2px); */
        padding:.625rem .75rem;
        font-size:1rem;
        line-height:1.5;
        color:#8d6c9f;
        background-color:#fff;
        background-clip:padding-box;
        border:1px solid #e0e3e6;
        border-radius:.25rem;
        -webkit-box-shadow:none;
        box-shadow:none;
        -webkit-transition:all .2s cubic-bezier(.68,-.55,.265,1.55);
        transition:all .2s cubic-bezier(.68,-.55,.265,1.55)
    }

    .goalIcon {
        color: #ffffff;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        height: 48px;
        width: 48px; 
    } 

    .goalIcon img {
        width: 100%;
    }

    .goalIcon-xs {
        width: 32px;
        height: 32px;
    }

    .status-icon {
        position: absolute;
        right: 15%;
        bottom: 20%;
        width: 32px;
        height: 32px;
        z-index: 999;
    }

    .status-icon-xs {
        position: absolute;
        right: 10%;
        bottom: 10%;
        width: 24px;
        height: 24px;
        z-index: 999;
    }

    .img-rt {
        /* position: relative; */
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 60px;
        height: 60px;
        transition: transform 0.2s;
        z-index: 899;
    }

    .img-rt-selection:hover {
        transform: scale(1.1);
    }

    .label-rt {
        font-size: 0.7em;
    }

    .feed-title {
        font-size: 0.9em;
    }

    .feed-date {
        font-size: 0.8em;
    }

</style>