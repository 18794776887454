<template>
  <div class="profile-page"> <!-- v-touch:swipe.right="swipeRightHandler" -->
    <section class="section mt-0 bg-default" style="min-height: 450px"></section>

    <section class="section section-skew" style="margin-top: -450px">
      <div class="container">

        <div class="row">

          <!-- PROFILE HEADER & EDIT -->
          <div class="col-12">
              
              <card shadow class="card-profile" no-body>
                <div class="m-2 card-ellipse">
                  <base-dropdown position="right">
                    <a role="button" class="pointer" slot="title" data-toggle="dropdown">
                      <icon
                        name="fad fa-cog"
                        iconOnly
                        textColor="default"
                        type="light"
                        outline
                      ></icon>
                    </a>

                    <a class="dropdown-item pointer text-dark" role="button" @click="enableEdit">
                      <i class="fal fa-pencil"></i> Edit
                    </a>
                    <!-- <a class="dropdown-item pointer text-dark" role="button" @click="resetTips">
                      <i class="fal fa-bell"></i> Reset tips
                    </a> -->
                    <router-link to="/welcome" class="dropdown-item pointer">
                        <i class="fal fa-house-leave"></i>Revisit Welcome
                    </router-link>
                    <router-link to="/weekly" class="dropdown-item pointer" v-if="user.data.reflectionsWeekly.length > 0">
                        <i class="fal fa-calendar-week"></i> Weekly Reflections <span class="badge badge-info">Legacy</span>
                    </router-link>
                    <a
                      class="dropdown-item pointer text-dark"
                      role="button"
                      @click="modals.profileImage = true"
                    >
                      <i class="fal fa-camera"></i> Change Profile Image
                    </a>
                    <a class="dropdown-item pointer text-dark" role="button" @click="signout">
                      <i class="fal fa-sign-out"></i> Logout
                    </a>

                    <div class="dropdown-divider"></div>

                    <router-link to="/admin" class="dropdown-item pointer" v-if="user.administrativeUser">
                        <i class="fad fa-user-cowboy"></i> Administration
                    </router-link>

                    <router-link to="/privacy-policy" title="Privacy Policy" class="dropdown-item pointer">
                      <i class="fal fa-user-secret"></i> Privacy Policy
                    </router-link>

                    <router-link
                      to="/terms-and-conditions"
                      title="Terms and Conditions"
                      class="dropdown-item pointer"
                    >
                      <i class="fal fa-file-alt"></i> Terms &amp; Conditions
                    </router-link>
                    <!-- <router-link to="/faq" class="dropdown-item pointer">
                      <i class="fal fa-question-circle"></i> FAQ
                    </router-link> -->
                    <a
                      class="dropdown-item pointer"
                      href="javascript:void(Tawk_API.toggle())"
                    >
                      <i class="fal fa-user-headset"></i> Get Help
                    </a>
                  </base-dropdown>
                </div>

                <div class="px-4">
                  <div class="row justify-content-center">
                    <div class="col-lg-3 col-12 order-lg-2">
                      <div
                        class="card-profile-image pointer text-center"
                        @mouseover="showChangeButton = true"
                        @mouseleave="showChangeButton = false"
                      >
                        <img
                          :src="profile.photoURL"
                          class="rounded-circle bg-white"
                          @click="modals.profileImage = true"
                        />
                        <base-button
                          class="mt-4"
                          type="default"
                          size="sm"
                          v-if="showChangeButton"
                          @click="modals.profileImage = true"
                        >
                          <i class="fas fa-camera"></i> Change
                        </base-button>
                      </div>
                    </div>
                    <div class="col-lg-4 col-6 order-lg-1 mt-4">
                      <div
                        class="card-profile-stats d-flex justify-content-center mt-sm-400 mt-md-400 mt-lg-3"
                        style="margin-top: 120px"
                      >
                        <div>
                          <span class="heading">{{ totalReflections }}</span>
                          <span class="description">Reflections</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-6 order-lg-3 mt-4">
                      <div
                        class="card-profile-stats d-flex justify-content-center mt-sm-400 mt-md-400 mt-lg-3"
                        style="margin-top: 120px"
                      >
                        <div>
                          <span class="heading">
                            {{ overallAverage.toFixed(2) }}
                          </span>
                          <span class="description">Avg Rating</span>
                        </div>
                      </div>
                    </div>

                    <modal
                      :show.sync="modals.profileImage"
                      modal-classes="modal-white modal-dialog-centered"
                    >
                      <h5 slot="header" class="modal-title" id="modal-title-default">
                        Update Profile Image
                      </h5>

                      <clipper-fixed
                        :src="tmpProfileImage"
                        :border="2"
                        :rotate="tmpProfileImageRotation"
                        :round="true"
                        ref="clipper"
                      >
                        <div slot="placeholder" class="text-center small mb-4">
                          Select your new profile image!
                        </div>
                      </clipper-fixed>

                      <div
                        class="text-center text-default mt-2"
                        role="group"
                        aria-label="First group"
                        v-if="tmpProfileImage && uploadingImage"
                      >
                        <i class="fad fa-spinner-third fa-spin"></i> Uploading your image
                      </div>

                      <div
                        class="text-center"
                        role="group"
                        aria-label="First group"
                        v-if="!tmpProfileImage && !uploadingImage"
                      >
                        <clipper-upload v-model="tmpProfileImage">
                          <base-button type="primary">Select Image</base-button>
                        </clipper-upload>
                      </div>

                      <div
                        class="btn-toolbar justify-content-between mt-2"
                        role="toolbar"
                        v-if="tmpProfileImage && !uploadingImage"
                      >
                        <div class="btn-group">
                          <base-button type="danger" @click="closeUpload"
                            ><i class="fal fa-ban"></i
                          ></base-button>
                        </div>
                        <div class="btn-group">
                          <base-button type="secondary" @click="rotateCCW">
                            <i class="fal fa-redo fa-flip-horizontal"></i>
                          </base-button>
                          <base-button type="secondary" @click="rotateCW">
                            <i class="fal fa-redo"></i>
                          </base-button>
                        </div>
                        <div class="btn-group">
                          <base-button type="default" @click="getClipResult">
                            <i class="fal fa-check"></i>
                          </base-button>
                        </div>
                      </div>
                    </modal>
                  </div>
                  <div v-if="action !== 'edit'">
                    <div class="text-center mt-0">
                      <h4>{{ profile.fullName }}</h4>
                      <div class="h6">
                        <i class="fad fa-envelope mr-2"></i> {{ profile.email }}
                      </div>
                      <div class="h6 font-weight-300" v-if="profile.location">
                        <i class="fad fa-map-pin mr-2"></i> {{ profile.location }}
                      </div>
                      <!-- <div class="h6 mt-4" v-if="profile.statusText"><i class="fad fa-star mr-2"></i> {{ profile.statusText }}</div> -->
                    </div>
                    <div
                      class="mt-4 mb-0 py-5 border-top text-center"
                      v-if="profile.biography"
                    >
                      <div class="row justify-content-center">
                        <div class="col-lg-9">
                          <p>
                            {{ profile.biography }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text-left mt-0" v-else>
                    <ValidationObserver v-slot="{ passes }">
                      <form @submit.prevent="passes(updateProfile)">
                        <validation-provider
                          mode="lazy"
                          name="First Name"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <label class="text-muted">First name</label><br />
                          <base-input
                            alternative
                            v-model="profile.firstName"
                            required
                            class="mb-3"
                            placeholder="First Name"
                            :error="errors[0]"
                            addon-left-icon="fad fa-user"
                          >
                          </base-input>
                        </validation-provider>

                        <validation-provider
                          mode="lazy"
                          name="Last Name"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <label class="text-muted">Last name</label><br />
                          <base-input
                            alternative
                            v-model="profile.lastName"
                            class="mb-3"
                            placeholder="Last Name"
                            :error="errors[0]"
                            addon-left-icon="fas fa-user"
                          >
                          </base-input>
                        </validation-provider>

                        <validation-provider
                          mode="lazy"
                          name="Full Name"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <label class="text-muted">Full name</label><br />
                          <base-input
                            alternative
                            v-model="profile.fullName"
                            required
                            class="mb-3"
                            placeholder="Full Name"
                            :error="errors[0]"
                            addon-left-icon="fas fa-user"
                          >
                          </base-input>
                        </validation-provider>

                        <hr />

                        <!-- <base-input alternative
                                                      v-model="profile.statusText"
                                                      class="mb-3"
                                                      placeholder="Status Update"
                                                      addon-left-icon="fad fa-star">
                                          </base-input> -->

                        <label class="text-muted">Location</label><br />
                        <base-input
                          alternative
                          v-model="profile.location"
                          class="mb-3"
                          placeholder="Location"
                          addon-left-icon="fad fa-map-pin"
                        >
                        </base-input>

                        <label class="text-muted">Birthday</label><br />
                        <base-input
                          class="mb-3"
                          alternative
                          addon-left-icon="fad fa-birthday-cake"
                        >
                          <flat-picker
                            slot-scope="{ focus, blur }"
                            @on-open="focus"
                            @on-close="blur"
                            :config="{ allowInput: true }"
                            class="form-control datepicker"
                            placeholder="Date of Birth"
                            v-model="tempDateOfBirth"
                          >
                          </flat-picker>
                        </base-input>

                        <div class="form-group text-left">
                          <label class="text-muted">Biography</label><br />
                          <textarea
                            class="form-control mb-3"
                            v-model="profile.biography"
                            id="exampleFormControlTextarea1"
                            rows="3"
                            placeholder="Write a bit about yourself"
                          ></textarea>
                        </div>

                        <div
                          class="form-group text-left"
                          v-if="
                            profile.providerId === 'password' &&
                            !profile.profileImageUploaded
                          "
                        >
                          <label class="text-muted"
                            >Where does your profile image come from?</label
                          >
                          <div class="alert alert-warning">
                            <p>To keep things simple, we have integrated with Gravatar!</p>
                            <p>
                              Gravatar is used on millions of sites and services so if you
                              haven't already created an account you can do so now and and your
                              profile image there or you can just update the profile image by
                              clicking on your profile image above!
                            </p>
                            <a
                              href="https://en.gravatar.com/"
                              target="_blank"
                              class="btn btn-sm btn-secondary"
                              >Let's use Gravatar!</a
                            >
                          </div>
                        </div>
                        <div
                          class="form-group text-left"
                          v-else-if="profile.providerId === 'facebook.com'"
                        >
                          <label class="text-muted"
                            >Where does your profile image come from?</label
                          >
                          <div class="alert alert-warning">
                            <p>
                              To make your life that little bit easier, we have brought in your
                              profile image from Facebook, you can change it if you like
                            </p>
                          </div>
                        </div>
                        <div
                          class="form-group text-left"
                          v-else-if="profile.providerId === 'google.com'"
                        >
                          <label class="text-muted"
                            >Where does your profile image come from?</label
                          >
                          <div class="alert alert-warning">
                            <p>
                              To make your life that little bit easier, we have brought in your
                              profile image from Google, you can change it if you like
                            </p>
                          </div>
                        </div>

                        <div class="text-center">
                          <base-button native-type="submit" type="primary" class="my-4"
                            >Update profile</base-button
                          >
                          <base-button type="danger" class="my-4" @click="action = null"
                            >Cancel</base-button
                          >
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </card>

          </div>

          <!-- NEW TABS -->
          <div class="col-lg-3 col-xl-3 col-12 d-lg-block d-none mt-4">

              <ul class="nav nav-pills nav-fill flex-column">

                  <li class="nav-item mt-1 mb-1 p-0 text-left" v-for="(tab, t) in tabs" v-bind:key="t">
                      <a class="nav-link shadow pointer" :class="currentTab === tab.id ? 'active' : ''" role="button" @click="switchTab(tab)">
                        <i class="mr-2" :class="tab.icon"></i> {{ tab.name }}
                        <span class="badge badge-dark float-right text-white" v-show="tab.pro">PRO</span>
                      </a>
                  </li>

              </ul>

          </div>

          <div class="col-xl-9 col-lg-9 col-12 mt-4">
            
            <div class="d-lg-none d-block">
              <button class="btn btn-block mb-2 mt-2" :class="currentTab === tabs[0].id ? 'btn-default' : 'btn-outline-default'" @click="switchTab(tabs[0])">
                  <i class="mr-2" :class="tabs[0].icon"></i> {{ tabs[0].name }}
                  <span class="badge badge-dark text-white" v-show="tabs[0].pro">PRO</span>
              </button>
            </div>
            <div v-show="currentTab === 'statistics'">
                <statistics ref="reflectionStats" :reflection-types="reflectionTypes"></statistics>
            </div>


            <div class="d-lg-none d-block">
              <button class="btn btn-block mb-2 mt-2" :class="currentTab === tabs[1].id ? 'btn-default' : 'btn-outline-default'" @click="switchTab(tabs[1])">
                  <i class="mr-2" :class="tabs[1].icon"></i> {{ tabs[1].name }}
                  <span class="badge badge-dark text-white" v-show="tabs[1].pro">PRO</span>
              </button>
            </div>
            <div v-show="currentTab === 'goals'">
                <goals ref="reflectionGoals" :reflection-types="reflectionTypes" v-if="isGoalsVisible"></goals>
            </div>

            <div class="d-lg-none d-block">
              <button class="btn btn-block mb-2 mt-2" :class="currentTab === tabs[2].id ? 'btn-default' : 'btn-outline-default'" @click="switchTab(tabs[2])">
                  <i class="mr-2" :class="tabs[2].icon"></i> {{ tabs[2].name }}
                  <span class="badge badge-dark text-white" v-show="tabs[2].pro">PRO</span>
              </button>
            </div>
            <div v-show="currentTab === 'tasks'">
                <tasks ref="reflectionTasks" :reflection-types="reflectionTypes" v-if="isTasksVisible"></tasks>
            </div>

            <div class="d-lg-none d-block">
              <button class="btn btn-block mb-2 mt-2" :class="currentTab === tabs[3].id ? 'btn-default' : 'btn-outline-default'" @click="switchTab(tabs[3])">
                  <i class="mr-2" :class="tabs[3].icon"></i> {{ tabs[3].name }}
                  <span class="badge badge-dark text-white" v-show="tabs[3].pro">PRO</span>
              </button>
            </div>
            <div v-show="currentTab === 'tags'">
                <tags></tags>
            </div>

            <div class="d-lg-none d-block">
              <button class="btn btn-block mb-2 mt-2" :class="currentTab === tabs[4].id ? 'btn-default' : 'btn-outline-default'" @click="switchTab(tabs[4])">
                  <i class="mr-2" :class="tabs[4].icon"></i> {{ tabs[4].name }}
                  <span class="badge badge-dark text-white" v-show="tabs[4].pro">PRO</span>
              </button>
            </div>
            <div v-show="currentTab === 'groups'">
                <div v-if="user.subscription.tier > 0">
                    <groups v-if="isGroupsVisible"></groups>
                </div>
                <div v-else>
                  <card shadow class="p-4" no-body>
                    <h5 class="text-dark font-weight-bold">
                      Groups are a paid feature
                      <small><br />upgrade your account now to access this feature!</small>
                    </h5>
                  </card>
                </div>
            </div>

            <div class="d-lg-none d-block">
              <button class="btn btn-block mb-2 mt-2" :class="currentTab === tabs[5].id ? 'btn-default' : 'btn-outline-default'" @click="switchTab(tabs[5])">
                  <i class="mr-2" :class="tabs[5].icon"></i> {{ tabs[5].name }}
                  <span class="badge badge-dark text-white" v-show="tabs[5].pro">PRO</span>
              </button>
            </div>
            <div v-show="currentTab === 'schedule'">
                <div v-if="user.subscription.tier > 0">
                    <scheduled-reflections v-if="isScheduledVisible"></scheduled-reflections>
                </div>
                <div v-else>
                  <card shadow class="p-4" no-body>
                    <h5 class="text-dark font-weight-bold">
                      Scheduled reflections are a paid feature
                      <small><br />upgrade your account now to access this feature!</small>
                    </h5>
                  </card>
                </div>
            </div>


            <div class="d-lg-none d-block">
              <button class="btn btn-block mb-2 mt-2" :class="currentTab === tabs[6].id ? 'btn-default' : 'btn-outline-default'" @click="switchTab(tabs[6])">
                  <i class="mr-2" :class="tabs[6].icon"></i> {{ tabs[6].name }}
                  <span class="badge badge-dark text-white" v-show="tabs[6].pro">PRO</span>
              </button>
            </div>
            <div v-show="currentTab === 'webhooks'">
                <div v-if="user.subscription.tier > 0">
                    <webhooks v-if="isWebhookVisible"></webhooks>
                </div>
                <div v-else>
                  <card shadow class="p-4" no-body>
                    <h5 class="text-dark font-weight-bold">
                      Webhooks are a paid feature
                      <small><br />upgrade your account now to access this feature!</small>
                    </h5>
                  </card>
                </div>
            </div>


            <div class="d-lg-none d-block">
              <button class="btn btn-block mb-2 mt-2" :class="currentTab === tabs[7].id ? 'btn-default' : 'btn-outline-default'" @click="switchTab(tabs[7])">
                  <i class="mr-2" :class="tabs[7].icon"></i> {{ tabs[7].name }}
                  <span class="badge badge-dark text-white" v-show="tabs[7].pro">PRO</span>
              </button>
            </div>
            <div v-show="currentTab === 'subscription'">

              <card shadow type="default" class="p-4" no-body>
                <div class="row">
                  <div class="col-lg-7 col-12">
                    <div class="float-left mr-3">
                      <img
                        src="/img/brand/my-reflection-icon-spaced_transparent.png"
                        class="mr-logo rounded"
                      />
                    </div>
                    <h4
                      class="display-5 mt-lg-3 mt-2 text-white font-weight-bold"
                      v-if="user.subscription.lifetime"
                    >
                      My Reflections<br />LIFETIME
                    </h4>
                    <h4
                      class="display-5 mt-lg-3 mt-2 text-white font-weight-bold"
                      v-else-if="user.subscription.sponsored"
                    >
                      My Reflections<br />SPONSORED
                    </h4>
                    <h4
                      class="display-5 mt-lg-3 mt-2 text-white font-weight-bold"
                      v-else-if="user.subscription.tier > 0"
                    >
                      My Reflections<br />Professional
                    </h4>
                    <h4 class="display-5 mt-lg-4 mt-2 text-white font-weight-bold" v-else>
                      My Reflections FREE
                    </h4>
                  </div>
                  <div
                    class="col-lg-5 col-12 text-white text-lg-right text-center"
                    v-if="user.subscription.lifetime"
                  >
                    <div>
                      <b>Max users:</b> {{ user.subscription.maxUsers }} <b>Max groups:</b>
                      {{ user.subscription.maxGroups }}
                    </div>
                    <div>LIFETIME</div>
                  </div>
                  <div
                    class="col-lg-5 col-12 text-white text-lg-right text-center"
                    v-else-if="user.subscription.sponsored"
                  >
                    <div class="mt-4" v-if="user.subscription.expires">
                      <b>Sponsorship ends:</b>
                      {{ $dayjs(getDate(user.subscription.expires)).format("MMM Do YYYY") }}

                    </div>
                    <div>
                      <b>Max users:</b> {{ user.subscription.maxUsers }} <b>Max groups:</b>
                      {{ user.subscription.maxGroups }}
                    </div>
                    <div>FREE</div>
                  </div>
                  <div
                    class="col-lg-5 col-12 text-white text-lg-right text-center"
                    v-else-if="user.subscription.tier > 0"
                  >
                    <div class="mt-4">
                      <b>Next billing date:</b>
                      {{ $dayjs(getDate(user.subscription.expires)).format("MMM Do YYYY") }}
                    </div>
                    <div>
                      <b>Max users:</b> {{ user.subscription.maxUsers }} <b>Max groups:</b>
                      {{ user.subscription.maxGroups }}
                    </div>
                    <div>AUD${{ user.subscription.quantity * 4.99 }}</div>
                  </div>
                  <div class="col-lg-5 col-12 text-white text-lg-right text-center" v-else>
                    <div class="mt-4">This plan will be</div>
                    <div>FREE FOREVER</div>
                  </div>
                  <div v-if="!user.androidDetected">
                    <div class="col-12 mt-4 text-center" v-if="!user.subscription.sponsored">
                      <base-button
                        type="secondary"
                        block
                        @click="createPortalLink"
                        v-if="user.subscription.tier > 0"
                        >Manage Subscription</base-button
                      >
                      <router-link class="nav-link nav-link-icon" to="/subscription" v-else>
                        <base-button type="secondary" block>Subscribe</base-button>
                      </router-link>
                    </div>
                  </div>
                </div>
              </card>

              <modal
                :show.sync="modals.stripeLoading"
                gradient="default"
                modal-classes="modal-default modal-dialog-centered"
              >
                <div class="py-3 text-center">
                  <i class="fad fa-spinner-third fa-4x fa-spin"></i>
                  <h4 class="heading mt-4">We are redirecting you...</h4>
                  <p>
                    You are being redirected to your billing page where you can update or cancel
                    your subscription.. please wait
                  </p>
                </div>
              </modal>

            </div>

            <div class="d-lg-none d-block">
              <button class="btn btn-block mb-2 mt-2" :class="currentTab === tabs[8].id ? 'btn-default' : 'btn-outline-default'" @click="switchTab(tabs[8])">
                  <i class="mr-2" :class="tabs[8].icon"></i> {{ tabs[8].name }}
                  <span class="badge badge-dark text-white" v-show="tabs[8].pro">PRO</span>
              </button>
            </div>
            <div v-show="currentTab === 'settings'">

                  <card shadow class="p-4" no-body>
                    <h5 class="text-dark font-weight-bold">
                      Settings
                    </h5>

                    <p class="text-muted text-uppercase small">
                      Manage Features
                    </p>
                    <!-- Heartbeat Feature -->
                    <div class="row">

                      <div class="col-9 text-default">
                          Heartbeat
                      </div>
                      <div class="col-3 pr-2 text-right">
                          <base-switch :value="isHeartbeatEnabled" @input="setFeature('heartbeat', Boolean($event) )" />
                      </div>

                    </div>
                    <!-- Goals Feature -->
                    <div class="row">

                      <div class="col-9 text-default">
                          Goal Setting &amp; Management
                      </div>
                      <div class="col-3 pr-2 text-right">
                          <base-switch :value="areGoalsEnabled" @input="setFeature('goals', Boolean($event) )" />
                      </div>

                    </div>
                    <!-- Goals Feature -->
                    <div class="row">

                      <div class="col-9 text-default">
                          Task List &amp; Management
                      </div>
                      <div class="col-3 pr-2 text-right">
                          <base-switch :value="areTasksEnabled" @input="setFeature('tasks', Boolean($event) )" />
                      </div>

                    </div>

                    <hr class="mt-3 mb-4">
                    <p class="text-muted text-uppercase small">
                      Actions
                    </p>
                    <!-- Reset Tips -->
                    <div class="row">

                      <div class="col-8 text-default">
                          Reset Tips and Notifications
                      </div>
                      <div class="col-4 pr-2 text-right">
                          <button class="btn btn-primary btn-sm" @click="resetTips">
                            <i class="fal fa-bell"></i> Reset
                          </button>
                      </div>

                    </div>

                  </card>

            </div>
            

          </div>

        </div>

      </div>

      <div class="container" v-if="!profile">
        <div class="row justify-content-center mt-4">
          <div class="col-8 text-center">
            <div class="alert alert-default">
              <i class="fad fa-spinner-third fa-4x fa-spin"></i>
              <h5 class="text-white mt-2">Looking for your profile</h5>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import BaseDropdown from "@/components/BaseDropdown";
import firebase from "firebase/app";
import { db } from "@/firebase";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import _ from "lodash";

import store from "@/store.js";
import { mapGetters } from "vuex";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

import Modal from "@/components/Modal.vue";

// const Statistics = () => import('@/components/profile/Statistics.vue');
const Groups = () => import('@/components/managegroups/Groups.vue');
const Webhooks = () => import('@/components/profile/Webhooks.vue');
const ScheduledReflections = () => import('@/components/profile/ScheduledReflections.vue');
const Tags = () => import('@/components/profile/Tags.vue');
const Goals = () => import('@/components/profile/Goals.vue');
const Tasks = () => import('@/components/profile/Tasks.vue');
import Statistics from "@/components/profile/Statistics.vue";
import reflectionTypes from "@/models/reflectionTypes";

import { clipperFixed, clipperUpload } from "vuejs-clipper";

extend("email", email);
extend("required", required);

export default {
  name: "profile",
  metaInfo: {
    title: "Profile",
  },
  components: {
    flatPicker,
    ValidationProvider,
    ValidationObserver,
    BaseDropdown,
    Modal,
    clipperFixed,
    clipperUpload,
    Statistics,
    ScheduledReflections,
    Webhooks,
    Groups,
    Goals,
    Tasks,
    Tags
  },

  data() {
    return {
      // editProfile: false,
      tempDateOfBirth: null,
      imagesArray: null,
      reflectionTypes,
      action: null,

      modals: {
        stripeLoading: false,
        profileImage: false,
      },

      tabs: [
        {'id': 'statistics', 'name': 'Statistics', 'icon': 'fad fa-tally','pro': false},
        {'id': 'tags', 'name': 'Tags', 'icon': 'fas fa-tag','pro': false},
        {'id': 'goals', 'name': 'Goal History', 'icon': 'fad fa-bullseye-arrow','pro': false},
        {'id': 'tasks', 'name': 'Task History', 'icon': 'fad fa-check','pro': false},
        {'id': 'groups', 'name': 'Groups', 'icon': 'fad fa-users-medical','pro': true},
        {'id': 'schedule', 'name': 'Scheduled', 'icon': 'fad fa-clock','pro': true},
        {'id': 'webhooks', 'name': 'WebHooks', 'icon': 'fad fa-plug','pro': true},
        {'id': 'subscription', 'name': 'Subscription', 'icon': 'fad fa-credit-card-front','pro': false},
        {'id': 'settings', 'name': 'Settings', 'icon': 'fad fa-cog','pro': false}
      ],

      profile: [],

      // Profile Image Data
      showChangeButton: false,
      tmpProfileImage: null,
      tmpProfileImageRotation: 0,
      uploadingImage: false,

      currentTab: '',
      webhookLoaded: false,
      groupsLoaded: false,
      goalsLoaded: false,
      tasksLoaded: false,
      scheduledLoaded: false,

      defaultTab: null,

      totalReflections: 0,
      totalRating: 0,
      overallAverage: 0

    };
  },

  mounted() {

    if (this.user) {

      this.profile = _.cloneDeep(this.user.data);
      this.tempDateOfBirth = this.getDOB(this.profile.dateOfBirth);

    }

    this.setDefaultTab();

    this.$watch(
        "$refs.reflectionStats.totalReflections", (new_value, old_value) => {

            //execute your code here
            this.totalReflections = new_value
            this.totalRating = this.$refs.reflectionStats.totalRating
            this.overallAverage = this.$refs.reflectionStats.overallAverage

        }
    );

  },

  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      // map `this.groups` to `this.$store.getters.groups`
      user: "user",
      groups: "groups",
    }),

    isWebhookVisible(){
      if(this.webhookLoaded){
        return true
      }else{
        return false
      }
    },

    isScheduledVisible(){
      if(this.scheduledLoaded){
        return true
      }else{
        return false
      }
    },

    isGroupsVisible(){
      if(this.groupsLoaded){
        return true
      }else{
        return false
      }
    },

    isGoalsVisible(){
      if(this.goalsLoaded){
        return true
      }else{
        return false
      }
    },

    isTasksVisible(){
      if(this.tasksLoaded){
        return true
      }else{
        return false
      }
    },

    isHeartbeatEnabled(){

        if(!this.user.data.features.heartbeat){
            return true;
        }

        if(this.user.data.features && this.user.data.features.heartbeat === 'enabled'){
            return true
        }

        return false;
    },

    areGoalsEnabled(){

        if(this.user.data.features && this.user.data.features.goals === 'enabled'){
            return true
        }

        return false;
    },

    areTasksEnabled(){

        if(this.user.data.features && this.user.data.features.tasks === 'enabled'){
            return true
        }

        return false;
    }

  },

  watch: {
    user: {
      handler: function () {
        if (this.user) {
          this.tempDateOfBirth = this.getDOB(this.profile.dateOfBirth);
        }
      },
      deep: true,
    },
  },

  methods: {

    switchTab(tab){

      if(!this.$isDesktop() && this.currentTab === tab.id) {
        this.currentTab = ''
        return;
      }

      this.currentTab = tab.id
      if(this.currentTab === 'goals'){
        this.goalsLoaded = true;
      }
      if(this.currentTab === 'tasks'){
        this.tasksLoaded = true;
      }
      if(this.currentTab === 'groups'){
        this.groupsLoaded = true;
      }
      if(this.currentTab === 'schedule'){
        this.scheduledLoaded = true;
      }
      if(this.currentTab === 'webhooks'){
        this.webhookLoaded = true;
      }
    },

    enableEdit(){

      this.action = 'edit';
      this.tempDateOfBirth = this.getDOB(this.profile.dateOfBirth);

    },

    setDefaultTab(){

      if(this.$route.query && this.$route.query.tab){

        this.switchTab({id:this.$route.query.tab});

      }else{

        if (this.$isDesktop()) {
          this.switchTab({id:'statistics'});
        }
          
      }

    },

    setFeature(feature,status){

        let newValue = ''
        if(status === true){
            newValue = 'enabled'
        }else{
            newValue = 'disabled'
        }

        let newUser = _.cloneDeep(this.user.data);

        if(!newUser.features){
            newUser.features = {}
        }

        if(!newUser.features[feature]){
            newUser.features[feature] = ''
        }

        newUser.features[feature] = newValue;

        this.$store.dispatch("updateUser", newUser);

    },

    rotateCCW() {
      if (this.tmpProfileImageRotation === 0) {
        this.tmpProfileImageRotation = 315;
      } else {
        this.tmpProfileImageRotation -= 45;
      }
    },

    rotateCW() {
      if (this.tmpProfileImageRotation >= 315) {
        this.tmpProfileImageRotation = 0;
      } else {
        this.tmpProfileImageRotation += 45;
      }
    },

    getClipResult: function () {
      const vm = this;

      const canvas = this.$refs.clipper.clip({ maxWPixel: 250 }); //call component's clip method
      canvas.toBlob(function (blob) {
        const reader = new FileReader();
        reader.addEventListener("load", function () {
          vm.tmpProfileImage = this.result;
        });
        vm.afterComplete(blob);
        // reader.readAsDataURL(blob);
      });
    },

    async afterComplete(upload) {
      const vm = this;

      vm.uploadingImage = true;
      var imageName = this.profile.uid
      try {
        //save image
        let file = upload;
        var metadata = {
          contentType: "image/png",
        };
        var storageRef = firebase.storage().ref("profiles");
        var imageRef = storageRef.child(`${imageName}.png`);
        await imageRef.put(file, metadata);
        var downloadURL = await imageRef.getDownloadURL();

        vm.profile.photoURL = downloadURL;
        vm.updateProfile();
        vm.closeUpload();
      } catch (error) {
        // console.log(error);
      }
    },

    closeUpload() {
      this.uploadingImage = false;
      this.modals.profileImage = false;
      this.tmpProfileImage = null;
      this.tmpProfileImageRotation = 0;
    },

    signout: function () {
      const vm = this;
      firebase
        .auth()
        .signOut()
        .then(
          function () {
            vm.$store.commit("SET_LOGGED_IN", null);
            vm.$store.commit("SET_USER", null);
            vm.$router.replace("/landing");
          },
          function (error) {
            // console.error('Sign Out Error', error);
          }
        );
    },

    createPortalLink() {
      this.modals.stripeLoading = true;

      const functionLocation = "australia-southeast1";

      var functionRef = firebase
        .app()
        .functions(functionLocation)
        .httpsCallable("ext-firestore-stripe-subscriptions-createPortalLink");
      functionRef({
        returnUrl: window.location.origin,
      })
        .then(function (result) {
          // Read result of the Cloud Function.
          window.location.assign(result.data.url);
        })
        .catch(function (error) {
          // console.log(error)

          // Getting the Error details.
          var code = error.code;
          var message = error.message;
          var details = error.details;

          // ...
        });
    },

    getDate(datetime) {
      let myDate = new Date(datetime.seconds * 1000);
      return myDate;
    },

    getDOB(datetime) {
      if (!datetime) return "";
      return this.$dayjs.unix(datetime.seconds).format("YYYY-MM-DD");
    },

    cleanField(field) {
      if (field) {
        return field.trim();
      } else {
        return field;
      }
    },

    updateProfile() {

      let profileImageUploaded = this.profile.profileImageUploaded;
      if (!profileImageUploaded && this.tmpProfileImage) {
        profileImageUploaded = true;
      }

      let newUser = _.cloneDeep(this.profile);
      if(this.tempDateOfBirth){
        let ourDate = new Date(this.tempDateOfBirth);
        let timeStamp = new firebase.firestore.Timestamp.fromDate(ourDate);
        newUser.dateOfBirth = timeStamp
      }
      if(profileImageUploaded){
        newUser.profileImageUploaded = profileImageUploaded
      }

      this.$store.dispatch("updateUser", newUser);
      this.action = null;

      this.$toast.open({
        message: "Profile updated!",
        type: "success",
        // all of other options may go here
      });
    },

    resetTips() {
      let newUser = JSON.parse(JSON.stringify(this.profile));
      newUser.alerts = {};

      this.$store.dispatch("updateUser", newUser);
      this.action = null;

      this.$toast.open({
        message: "Tips reset!",
        type: "success",
        // all of other options may go here
      });
    },
  },
};
</script>

<style scoped>
  .card-ellipse {
    position: absolute;
    text-align: right;
    top: 0;
    right: 0;
    z-index: 100;
  }

  .mr-logo {
    width: 90px !important;
    height: 90px !important;
  }

  .nav-link {
    color: #553069 !important;
  }

  .nav-link:hover {
    color: #9a78ac !important;
  }

  .active {
    color: #fff !important;
  }

  .active:hover {
    color: #eddff7 !important;
  }

</style>
