<template>
<div v-if="user"> <!--  v-touch:swipe.left="swipeLeftHandler" v-touch:swipe.right="swipeRightHandler"  -->

    <div class="container" v-if="user.data && !currentWeek">

        <div class="row justify-content-center">

            <div class="col p-3 mb-5 mt-4">

                <div class=" " v-if="filteredWeeks.length > 0">

                    <div class="float-right" v-if="filteredWeeks.length > weeksPerPage">

                        <a role="button" class="btn btn-sm btn-default text-white" @click="pagePrev" v-if="pageStart > 0">
                            <i class="fas fa-chevron-left"></i>
                        </a>
                        <a role="button" disabled class="btn btn-sm btn-default disabled text-white" v-else>
                            <i class="fas fa-chevron-left"></i>
                        </a>

                        <a role="button" class="btn btn-sm btn-default text-white" @click="pageNext" v-if="weekPages.length === weeksPerPage">
                            <i class="fas fa-chevron-right"></i>
                        </a>
                        <a role="button" disabled class="btn btn-sm btn-default disabled text-white" v-else>
                            <i class="fas fa-chevron-right"></i>
                        </a>

                    </div>

                    <h5 class="card-title text-default font-weight-bold">Weekly reflections <span class="badge badge-info">Legacy</span></h5>
                    <div class="alert alert-info">
                        <span class="font-weight-bold">Weekly Reflections</span> has been replaced with
                        <span class="font-weight-bold">
                            <a href="/deep-dives"><i class="fas fa-lightbulb-on"></i> deep dives</a>
                        </span>!
                        For that reason you can only access completed weekly reflections.
                    </div>

                    <ul class="list-group">

                        <router-link :to="'/weekly/' + week.startDate" tag="li" v-for="(week,w) in weekPages" v-bind:key="w" class="list-group-item list-group-item-action shadow mb-2 font-weight-bold text-default pointer">

                            <div class="float-right text-default d-lg-block d-md-block d-none mt-1">
                                <i class="fad fa-calendar-week"></i>&nbsp;
                                <span class="badge badge-primary">
                                    {{ $dayjs(week.startDate).format("Do MMM") }}
                                     - 
                                    {{ $dayjs(week.endDate).format("Do MMM YYYY") }}
                                </span>
                            </div>

                            <div class="float-left mb-0">
                                <i class="fad fa-sad-tear fa-2x mr-2 text-muted" v-if="week.status === 'none'"></i>
                                <i class="fad fa-grin fa-2x mr-2 text-info" v-else-if="week.status === 'pending'"></i>
                                <i class="fad fa-grin-hearts fa-2x mr-2 text-default" v-else-if="week.status === 'completed'"></i>
                                <i class="fad fa-siren fa-2x mr-2 text-danger" v-else></i>
                            </div>

                            <div class="text-muted mt-1 mb-0">
                                <!-- checked week with no reflections -->
                                <span class="font-weight-bold" v-if="week.status === 'none'">
                                    <small> No reflections this week </small>
                                </span>
                                <!-- checked week with pending reflections -->
                                <span class="text-info font-weight-bold pointer" style="border-bottom: 1px dotted;" v-else-if="week.status === 'pending'">
                                    <small> {{ week.label }} ( Report In Progress ) </small>
                                </span>
                                <!-- checked week with completed reflections -->
                                <span class="text-default font-weight-bold pointer" v-else-if="week.status === 'completed'">
                                    {{ week.label }}
                                </span>
                                <!-- unchecked week -->
                                <span class="text-danger font-weight-bold pointer" style="border-bottom: 1px dotted;" v-else>
                                    <small> Start your reflection! </small>
                                </span>
                            </div>

                            <div class="float-right text-default d-lg-none d-md-none d-sm-block mt-0"> <!-- style="position: absolute; top: 32px; left: 55px;" -->
                                <small>
                                    <i class="fad fa-calendar-week"></i>&nbsp;
                                    <span class="badge badge-primary">
                                        {{ $dayjs(week.startDate).format("Do MMM") }}
                                        - 
                                        {{ $dayjs(week.endDate).format("Do MMM YYYY") }}
                                    </span>
                                </small>
                            </div>

                        </router-link>

                    </ul>

                </div>

            </div>

        </div>

    </div>

    <div class="container" v-if="user.data && currentWeek">

        <div>
            <weekly-reflection-report name="reflection report" :current-user="user.data" :current-week="currentWeek" v-on:reportClosed="currentWeek = null"></weekly-reflection-report>
        </div>

    </div>

    <div class="container" v-if="!user.data">

        <div class="row justify-content-center mt-4">
            <div class="col-8 text-center">

                <div class="alert alert-default">

                    <i class="fad fa-spinner-third fa-4x fa-spin"></i>
                    <h5 class="text-white mt-2">Verifying for your weekly reports</h5>

                </div>

            </div>

        </div>

    </div>

</div>
</template>

<script>
// import WeeklyReflectionWizard from "@/components/weekly/Wizard";
import WeeklyReflectionReport from "@/components/weekly/Report";
// import WeeklyReport from "@/reflection-components/WeeklyReport";
import firebase from 'firebase/app'
import {
    db
} from '@/firebase';
import store from '@/store.js'
import {
    mapGetters
} from "vuex";

export default {
    name: 'weekly',
    metaInfo: {
        title: 'Weekly Reflections',
    },
    components: {
        // WeeklyReflectionWizard,
        WeeklyReflectionReport
    },

    data() {
        return {
            currentUser: null,
            reflectionWeeks: [],
            currentWeek: null,
            previousWeek: null,
            filterIncNone: false,
            pageStart: 0,
            pageEnd: 10,
            weeksPerPage: 10
        }
    },

    mounted() {
        this.setCurrentWeek();
        // this.generateWeeks();
    },

    watch: {

        // currentUser() {
        //     if(this.currentUser){

        //     }
        // }

        $route(to, from) {

            this.setCurrentWeek()

        },

    },

    computed: {

        ...mapGetters({
            // map `this.user` to `this.$store.getters.user`
            // map `this.groups` to `this.$store.getters.groups`
            user: "user",
            groups: "groups"
        }),

        filteredReflectionTypes() {

            return this.reflectionTypes.filter(type => {
                return type.visible === true
            })

        },

        filteredReflection() {

            if (this.reflections || this.newReflections) {

                var tmpReflections = this.reflections
                var tmpNewReflections = this.newReflections
                var tmpTypes = this.filteredReflectionTypes
                var filtered = []

                tmpReflections.forEach(filter => {
                    tmpTypes.forEach(record => {

                        if (filter.reflectionType === record.name) {
                            filtered.push(filter)
                        }

                    });
                });

                tmpNewReflections.forEach(filter => {
                    tmpTypes.forEach(record => {

                        if (filter.reflectionType === record.name) {
                            filtered.unshift(filter)
                        }

                    });
                });

                return filtered

            } else {
                return []
            }

        },

        weekPages(){
            return this.filteredWeeks.slice(this.pageStart,this.pageEnd)
        },

        filteredWeeks() {

            if (this.user.data.reflectionsWeekly) {

                let filtered = this.user.data.reflectionsWeekly
                filtered.sort((b, a) => (a.startDate > b.startDate ? 1 : -1))

                if (!this.filterIncNone) {

                    return filtered.filter(week => {
                        return week.status === 'completed'
                    })

                }

                return filtered

            } else {

                return []

            }

        },

    },

    methods: {

        pagePrev(){
            this.pageStart -= this.weeksPerPage 
            this.pageEnd -= this.weeksPerPage 
        },

        pageNext(){
            this.pageStart += this.weeksPerPage 
            this.pageEnd += this.weeksPerPage 
        },

        setCurrentWeek() {

            this.currentWeek = null

            if (this.$route.params.startDate) {

                this.currentWeek = this.filteredWeeks.find(week => week.startDate === this.$route.params.startDate);
                // loadWeeklyReflection

            }

        },

        // swipeLeftHandler() {

        //     if (!this.currentWeek && !this.$isDesktop()) {
                
        //         if(this.groups && this.groups.length > 0){
        //             if(this.groups.length > 1){
        //                 this.$router.replace('/mygroups')
        //             }else{
        //                 this.$router.replace('/mygroups/' + this.groups[0].id)
        //             }
        //         }else{
        //             this.$router.replace('profile')
        //         }
                
        //     }

        // },

        // swipeRightHandler() {

        //     if (!this.currentWeek && !this.$isDesktop()) {
        //         this.$router.replace('reflectnow')
        //     }

        // },

        reportWasClosedSafely() {

            this.currentWeek = null

        },

        reportWasClosed() {

            const vm = this

            var tmpWeeks = vm.reflectionWeeks
            tmpWeeks.forEach((record, ri) => {

                if (record.startDate === this.currentWeek.startDate) {
                    vm.reflectionWeeks[ri].status = 'none'
                }

            });

            this.$toast.open({
                message: 'There were no reflections for that week!',
                type: 'warning',
                // all of other options may go here
            });

            this.currentWeek = null
            this.previousWeek = null

        },

        reportWasCompleted() {

            const vm = this

            this.currentWeek = null
            this.previousWeek = null

        },

        loadWeeklyReflection(week, w) {
            this.currentWeek = week
            if (this.filteredWeeks[w + 1]) {
                this.previousWeek = this.filteredWeeks[w + 1]
            }
        },

        getDataTime(datetime) {
            if (!datetime) return ''
            datetime = this.$dayjs.unix(datetime.seconds).format("YYYY-MM-DD");
            return datetime
        }

    }

};
</script>

<style>
.img-inthought {
    width: 25%;
    height: 25%;
}

.img-filter {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 35px;
    height: 35px;
}

.unselected {
    filter: grayscale(100%);
    opacity: 0.3;
}

.week-rounded {
    border-radius: 25px !important;
}
</style>
