<template>
    <div v-if="featureEnabled('goals') !== 'disabled'">

        <div class="card shadow p-0 bg-white rounded" v-if="featureEnabled('goals') === 'enabled'">

            <div class="mt-2 mb-0">
                <div class="float-left ml-2 mr-1 p-2">
                <img
                    alt="Image placeholder"
                    class="avatar avatar-sm bg-white border-avatar p-1"
                    src="/img/images/goals.svg"
                />
                </div>

                <p class="feed-title font-weight-bold text-default mt-2 mb-0">
                    Reflection Goals
                </p>
                <p class="feed-date text-muted" style="margin-top: -7px">
                    Latest progress:
                    <span v-if="lastUpdate()">{{ $dayjs( lastUpdate() ).fromNow() }}</span>
                    <span v-else>No reflections for current goals</span>
                </p>
                <hr class="mt-0 mb-0" />
            </div>

            <div class="container p-3">

                <div class="row justify-content-center">

                    <div class="col text-center" v-for="(record,index) in goals" v-bind:key="record.id">
                        <div @click="toggleViewGoal(index)" class="pointer text-center">
                            <center>
                                <radial-progress-bar
                                    :total-steps="record.target"
                                    :completed-steps="record.reflections.length"
                                    :diameter="circleOptions.size"
                                    :startColor="circleOptions.startColor"
                                    :stopColor="circleOptions.stopColor"
                                    :strokeWidth="circleOptions.strokeWidth"
                                    :innerStrokeWidth="circleOptions.innerStrokeWidth"
                                    :innerStrokeColor="circleOptions.innerStrokeColor"
                                >
                                    <p class="relative"> <!-- WIP: add check for status - completed / overdue -->
                                        <img
                                            :alt="record.reflectionType"
                                            :class="circleOptions.iconClass"
                                            :src="record.reflectionType.image"
                                        />

                                        <img
                                            alt="Unsuccessfull"
                                            :class="statusIconClass"
                                            src="/img/images/unsuccessful.svg"
                                            v-if="isSuccessful(record) === 'unsuccessful'"
                                        />

                                        <img
                                            alt="Successfull"
                                            :class="statusIconClass"
                                            src="/img/images/successful.svg"
                                            v-if="isSuccessful(record) === 'successful'"
                                        />

                                    </p>
                                </radial-progress-bar>
                            </center>
                        </div>

                    </div>

                    <div class="col text-center" v-for="n in (3-goals.length)" v-bind:key="n">
                        <div @click="createGoal()" class="pointer text-center">
                            <center>
                                <radial-progress-bar
                                    :total-steps="0"
                                    :completed-steps="0"
                                    :diameter="circleOptions.size"
                                    :startColor="circleOptions.startColor"
                                    :stopColor="circleOptions.stopColor"
                                    :strokeWidth="circleOptions.strokeWidth"
                                    :innerStrokeWidth="circleOptions.innerStrokeWidth"
                                    :innerStrokeColor="circleOptions.innerStrokeColorAdd"
                                >
                                    <p>
                                        <i class="text-muted fal fa-plus fa-2x mt-3"></i>
                                    </p>
                                </radial-progress-bar>
                            </center>
                        </div>

                    </div>

                </div>
                <div class="row justify-content-center" v-if="Number.isInteger(viewGoal)">

                    <div class="col text-center" style="margin-bottom: -19px;" v-for="n in 3" v-bind:key="n">
                        <div v-if="viewGoal === n-1" class="h4"><i class="fas fa-caret-up text-default"></i></div>
                    </div>

                    <div class="col-12 text-center mt-0" v-if="goals[viewGoal]">

                        <div class="card border-default mt-0">

                            <div class="card-body border-default">

                                <div class="row">

                                    <div class="col-12 mb-0 mt-1 text-default">

                                        <h4 class="text-default text-left pt-3 pb-2">
                                            <span class="font-weight-bold">{{ user.data.firstName}}</span>, you created this goal and named it
                                            <span class="font-weight-bold">{{ goals[viewGoal].name }}</span>,
                                            committing yourself to completing
                                            <span class="font-weight-bold">{{ goals[viewGoal].target }} {{ goals[viewGoal].reflectionType.name }} reflections</span>
                                            <span v-if="goals[viewGoal].tag !== 'all' && goals[viewGoal].tag"> tagged as <b>{{ goals[viewGoal].tag }}</b></span>
                                            by <span class="font-weight-bold">{{ $dayjs.unix( goals[viewGoal].targetDate.seconds ).format("MMMM Do, YYYY") }}</span>
                                            and so far you have <span class="font-weight-bold">completed {{ goals[viewGoal].reflections.length }}</span>
                                        </h4>

                                    </div>

                                </div>
                                
                            </div>
                            <div class="card-body p-0 pb-4">
                                <span class="text-muted small mb-0 pb-0 text-uppercase">Goal Actions</span><br>
                                <base-button class="mt-0" type="danger" size="sm" @click="cancelGoal()">
                                    <i class="fal fa-times"></i> Cancel
                                </base-button>
                                <base-button class="mt-0" type="success" size="sm" @click="completeGoal()">
                                    <i class="fal fa-flag-checkered"></i> Complete
                                </base-button>
                                <base-button class="mt-0" type="default" size="sm" @click="editGoal(goals[viewGoal])">
                                    <i class="fal fa-pencil"></i> Edit
                                </base-button>
                            </div>

                        </div>

                    </div>

                </div>


                <modal :show.sync="modals.goal" modal-classes="modal-dialog-centered modal-sm">

                    <div v-if="goalEntry">

                        <!-- CHOOSE A REFLECTION TYPE -->
                        <div class="card-body p-0" v-if="!goalEntry.reflectionType">

                            <h4 class="text-default mb-0 mt-0 text-left" v-if="user.data">
                                Hi {{ user.data.firstName}},
                            </h4>
                            <h4 class="text-default text-left font-weight-bold">
                                What type of activity would you like to set your goal for?
                            </h4>

                            <div class="row justify-content-center text-center">
                                <div
                                    class="col-4 pt-2 pb-2 m-0 text-center justify-content-center"
                                    v-for="(rt, index) in reflectionTypes"
                                    v-bind:key="index"
                                    @click="setReflectionType(rt)"
                                >
                                <div class="text-center">
                                    <img
                                        :alt="rt.name"
                                        class="img-rt img-rt-selection pointer"
                                        v-lazy="rt.image"
                                    />
                                </div>
                                    <div class="label-rt text-center text-default font-weight-bold pointer">
                                        {{ rt.name }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- CHOOSE A GOAL NAME-->
                        <div class="card-body p-0" v-else>

                            <h4 class="text-default text-left">
                                What do you want to call <span class="font-weight-bold">your goal?</span>
                            </h4>
                            <div class="row justify-content-center">
                                <div class="col-12 pt-1 pb-2">
                                    <base-input
                                        v-model="goalEntry.name"
                                        placeholder="Enter a name for this goal"
                                    >
                                    </base-input>
                                </div>
                            </div>


                            <h5 class="text-default text-left">
                                Define your <span class="font-weight-bold">targets</span>
                            </h5>
                            <div class="form-inline pb-2">
                                <span>
                                    I commit to myself to complete
                                </span>
                                <input
                                    type="number"
                                    class="form-control form-control-sm mx-sm-1 text-center"
                                    style="width: 70px;"
                                    v-model.number="goalEntry.target"
                                    placeholder="#"
                                >
                                <span>
                                    reflections in
                                </span>
                                <input
                                    type="number"
                                    class="form-control form-control-sm mx-sm-1 text-center"
                                    style="width: 70px;"
                                    v-model.number="goalEntry.targetDays"
                                    placeholder="#"
                                >
                                <span>
                                    days
                                </span>
                            </div>

                            <!-- <div class="row justify-content-center text-center">

                                <div class="col-6 pt-1 pb-2">
                                    <label># of Reflections</label>
                                    <base-input
                                        v-model.number="goalEntry.target"
                                        type="number"
                                        placeholder="#"
                                    >
                                    </base-input>
                                </div>

                                <div class="col-6 pt-1 pb-2">
                                    <label># of Days</label>
                                    <base-input
                                        v-model.number="goalEntry.targetDays"
                                        type="number"
                                        placeholder="How long?"
                                    >
                                    </base-input>
                                </div>

                            </div> -->


                            <!-- CHOOSE A TAG OR NOT -->
                            <h5 class="text-default text-left pt-3 pb-2">
                                Use your <span class="font-weight-bold">tags</span> to create more
                                <span class="font-weight-bold">specific goals</span>
                            </h5>
                            <div class="row justify-content-center text-center">

                                <div class="col-12 mt-1 mb-0 p-2 text-center">
                                    <span
                                        :class="getTagClass(tag)"
                                        v-for="(tag, index) in filteredTags"
                                        v-bind:key="index"
                                        @click="setTag(tag)"
                                        :title="tag.title"
                                    >
                                        <span v-if="tag.scope === 'group'">
                                            <i class="fal fa-users-class"></i> {{ tag.label }}
                                        </span>
                                        <span v-else> <i class="fal fa-tag"></i> {{ tag.name }} </span>
                                    </span>
                                </div>

                                <add-tag v-if="goalEntry.reflectionType" :reflection-type="goalEntry.reflectionType.name.trim()" />

                            </div>

                            <div
                                class="row justify-content-md-center"
                                v-if="goalEntry.name"
                            >
                                <div class="col-12 mt-5 pb-2 text-center">
                                    <base-button type="danger" size="lg" @click="modals.goal = false">
                                        Close
                                    </base-button>
                                    <base-button type="default" size="lg" @click="saveGoal()" v-if="!goalEntry.id">
                                        Create Goal
                                    </base-button>
                                    <base-button type="default" size="lg" @click="saveGoal()" v-else>
                                        Update Goal
                                    </base-button>
                                </div>
                            </div>
                            <div class="row justify-content-md-center" v-else>
                                <div class="col-12 mt-5 pb-2 text-center">
                                    <base-button type="danger" size="lg" @click="modals.goal = false">
                                        Cancel
                                    </base-button>
                                    <base-button type="default" size="lg" disabled>
                                        <span v-if="!goalEntry.id">Create</span>
                                        <span v-else>Update</span> Goal
                                    </base-button>
                                </div>
                            </div>

                        </div>

                    </div>


                </modal>

                <modal :show.sync="modals.congrats"
                    gradient="primary"
                    modal-classes="modal-primary modal-dialog-centered"
                    v-if="goalEntry && goalEntry.id"
                >
                    <h6 slot="header" class="modal-title" id="modal-title-notification">
                        {{ goalEntry.name}} Goal Completed!
                    </h6>

                    <div class="py-3 text-center" v-if="goalEntry">
                        <i class="fad fa-bullseye-arrow fa-4x"></i>
                        <h4 class="heading mt-4">You have successfully completed your goal!</h4>
                        <p>
                            It is amazing that you have committed to and accomplished
                            your goal. You set out to complete {{ goalEntry.target }} reflections
                            related to {{ goalEntry.reflectionType.name }}
                            <span v-if="goalEntry.tag !== 'all' && goalEntry.tag"> and tagged as <b>{{ goalEntry.tag }}</b></span> 
                            and absolutely crushed it!
                        </p>
                    </div>

                    <template slot="footer">
                        <base-button type="white" @click="completeGoalFromCongrats()"><i class="fal fa-flag-checkered"></i>  Mark as complete</base-button>
                        <base-button type="link"
                                    text-color="white"
                                    class="ml-auto"
                                    @click="closeCongrats()">
                            Close
                        </base-button>
                    </template>
                </modal>

            </div>

            <div>
                <hr class="mt-0 mb-0" />
                <p class="text-muted m-2 small text-center">
                    <i class="fal fa-info-circle"></i> These are your defined goals and their progress!
                </p>
                <!-- <div class="m-0 alert alert-secondary border-1 border-default">
                </div> -->
            </div>

        </div>

        <div class="card shadow p-0 bg-default rounded" v-if="featureEnabled('goals') === 'pending'">
            <div class="mt-2 mb-0">
                <div class="float-left ml-2 mr-1 p-2">
                <img
                    alt="Image placeholder"
                    class="avatar avatar-sm bg-white border-avatar p-1"
                    src="/img/images/goals.svg"
                />
                </div>

                <p class="feed-title font-weight-bold text-white mt-2 mb-0">
                    Reflection Goals
                </p>
                <p class="feed-date text-light" style="margin-top: -7px">
                    Get started today!
                </p>
                <hr class="mt-0 mb-0 border-light" />
            </div>

            <div class="container p-3">

                <div class="row justify-content-center">

                    <div class="col">

                        <h3 class="feature-title text-white">
                            Create &amp; track refletion goals &amp; keep focused
                        </h3>
                        <p class="text-white">
                            Reflection goals allow you to define and track your personal
                            objectives and through performing self-reflections not only achieve
                            better results but track your performance over the targeted
                            timeframe that you have set yourself.
                        </p>
                        <p class="text-white">
                            Would you like to enable this feature?
                        </p>

                        <button class="btn btn-light" @click="setFeature('enabled')">
                            Yes
                        </button>
                        <button class="btn btn-light" @click="setFeature('disabled')">
                            No
                        </button>

                        <p class="text-white">
                            <small>this choice can be changed in your profile at any time</small>
                        </p>



                    </div>

                </div>

            </div>

        </div>
        
        <hr style="margin-left: 35%; margin-right: 35%; " />

    </div>
</template>

<script>

import firebase from "firebase/app";
import { db } from "@/firebase";
import store from "@/store.js";
import { mapGetters } from "vuex";
import _ from "lodash";
import RadialProgressBar from 'vue-radial-progress'
import Modal from "@/components/Modal.vue";
import AddTag from "@/components/reflections/AddTag";

export default {
    name: "goals-progress",
    props: ["goals","reflections","reflectionTypes"],
    components: {
        RadialProgressBar,
        Modal,
        AddTag
    },
    data() {
        return {
            modals: {
                goal: false,
                congrats: false
            },
            currentProgress: [],
            goalEntry: null,
            viewGoal: null,
            consolidatedTags: []
        }
    },
    watch: {
        user: {
            deep: true,
            handler() {
                this.consolidatedTags = [];
                this.buildTags();
            },
        },
        reflections: {
            deep: true,
            handler() {
                if(this.reflections.length > 0){
                    this.checkGoals()
                }
                
            },
        },
        // WIP: CONVERT TO STORE
        reflectionActions: {
            deep: true,
            handler() {
                setTimeout(this.checkReflectionActions,1000);
            },
        }
    },
    computed: {
        ...mapGetters({
            // map `this.user` to `this.$store.getters.user`
            // map `this.groups` to `this.$store.getters.groups`
            user: "user",
            groups: "groups",
            reflectionActions: "reflectionActions"
        }),

        filteredTags() {

            if(!this.goalEntry || !this.goalEntry.reflectionType ){ return [] }
            if (this.consolidatedTags) {
                return this.consolidatedTags.filter((tag) => {
                    return (
                        tag.status === "active" && (!tag.type || tag.type === this.goalEntry.reflectionType.name || tag.type === "none")
                    );
                });
            }

            return [];
        },

        statusIconClass(){
            if (this.$isDesktop()) {
                return 'status-icon';
            } else {
                return 'status-icon-xs';
            }
        },

        circleOptions() {
            if (this.$isDesktop()) {
                return { 
                    size: 120,
                    strokeWidth: 10,
                    innerStrokeWidth: 10,
                    iconClass: 'goalIcon bg-white mt-3',
                    startColor: '#AED768',
                    stopColor: '#8D6C9F',
                    innerStrokeColor: 'rgba(0,0,0,.1)',
                    innerStrokeColorAdd: 'rgba(0,0,0,0.05)'
                };
            } else {
                return { 
                    size: 70,
                    strokeWidth: 5,
                    innerStrokeWidth: 5,
                    iconClass: 'goalIcon goalIcon-xs bg-white mt-3',
                    startColor: '#AED768',
                    stopColor: '#8D6C9F',
                    innerStrokeColor: 'rgba(0,0,0,0.1)',
                    innerStrokeColorAdd: 'rgba(0,0,0,0.05)'
                };
            }
        },

    },

    mounted() {
        this.buildTags()
        setTimeout(this.checkReflectionActions,1000);
    },

    methods: {

        setFeature(status){

            let newUser = _.cloneDeep(this.user.data);

            if(!newUser.features){
                newUser.features = {}
            }

            if(!newUser.features.goals){
                newUser.features.goals = ''
            }

            newUser.features.goals = status;

            this.$store.dispatch("updateUser", newUser);

        },     

        featureEnabled(feature){

            if(!this.user.data.features){
                return 'pending'
            }

            if(!(feature in this.user.data.features)){
                return 'pending'
            }

            if(this.user.data.features[feature] === 'disabled'){
                return 'disabled'
            }

            if(this.user.data.features[feature] === 'enabled'){
                return 'enabled'
            }

        },

        isSuccessful(record){

            if(record.target === record.reflections.length){
                return 'successful';
            }

            let currentDateTime = new firebase.firestore.Timestamp.fromDate(new Date());
            if(record.targetDate.seconds < currentDateTime.seconds){
                return 'unsuccessful';
            }

        },

        showCongrats(goal){

            this.goalEntry = goal;
            this.modals.congrats = true;

        },

        closeCongrats(){

            this.modals.congrats = false;
            this.goalEntry = null;

        },

        checkProgress(goal){

            if(goal.target === goal.reflections.length){

                this.showCongrats(goal)

            }

        },

        checkReflectionActions(){

            const vm = this

            let tmpDeletes = _.cloneDeep(this.reflectionActions.deleted);
            let tmpUpdates = _.cloneDeep(this.reflectionActions.updated);
            let tmpGoals = _.cloneDeep(this.goals);
            let shouldCheckGoals = false;

            if(tmpGoals.length === 0){
                
                // console.log('No Goals were found');

                tmpDeletes.forEach((d,di) => {

                    // console.log("REFLECTION_REMOVE_DELETED", d);
                    vm.$store.commit("REFLECTION_REMOVE_DELETED", d);

                });

                tmpUpdates.forEach((u,ui) => {

                    // console.log("REFLECTION_REMOVE_UPDATED", u);
                    vm.$store.commit("REFLECTION_REMOVE_UPDATED", u);

                });

                return

            }

            tmpGoals.forEach((g,gi) =>{

                let newGoal = _.cloneDeep(vm.goals[gi]);
                let newReflections = _.cloneDeep(vm.goals[gi].reflections);

                tmpDeletes.forEach((d,di) => {

                    var index = newReflections.indexOf(d);
                    if (index !== -1) {
                        newReflections.splice(index, 1);
                    }
                    // console.log("REFLECTION_REMOVE_DELETED", d);
                    vm.$store.commit("REFLECTION_REMOVE_DELETED", d);

                });

                tmpUpdates.forEach((u,ui) => {

                    var index = newReflections.indexOf(u);
                    if (index !== -1) {
                        newReflections.splice(index, 1);
                        shouldCheckGoals = true
                    }                        
                    // console.log("REFLECTION_REMOVE_UPDATED", u);
                    vm.$store.commit("REFLECTION_REMOVE_UPDATED", u);

                });

                newGoal.reflections = newReflections

                vm.addReflection(newGoal);

                if(shouldCheckGoals){
                    // console.log('We will check the goals')
                    setTimeout(this.checkGoals,3000);
                }
                

            });

        },

        checkGoals(){

            const vm = this

            let tmpReflections = _.cloneDeep(this.reflections);
            let tmpGoals = _.cloneDeep(this.goals);

            tmpGoals.forEach((g,gi) =>{

                // We will store our reflection id's in here temporarily
                let newReflections = []

                // Loop through the reflections
                tmpReflections.forEach((r) =>{


                    // Check if the reflection is part of a goal
                    // g.creationSeconds <= r.creationSeconds && g.targetDate.seconds >= r.creationSeconds && g.reflectionType.name === r.reflectionType // );
                    if( g.creationSeconds <= r.creationSeconds && g.targetDate.seconds >= r.creationSeconds && g.reflectionType.name === r.reflectionType && (!g.tag || g.tag === 'all' || g.tag === r.tag) ){

                        // Check if the reflection has been added previously!
                        let found = g.reflections.find(element => element === r.id)
                        if(!found){
                            newReflections.push(r.id)
                            tmpGoals[gi].reflections.push(r.id)
                        }

                    }


                })

                if(newReflections.length > 0){
                    let newGoal = _.cloneDeep(vm.goals[gi]);
                    let reflectionsArray = newGoal.reflections.concat(newReflections);
                    newGoal.reflections = reflectionsArray;
                    vm.addReflection(newGoal);
                }

            })

        },

        toggleViewGoal(index){

            if(this.viewGoal === index){
                this.viewGoal = null
                return
            }

            this.viewGoal = index;

        },

        lastUpdate() {
            if(this.goals.length === 0){
                return
            }
            if(!this.goals[0].lastUpdateTime){
                return
            }
            return new Date(this.goals[0].lastUpdateTime.seconds * 1000);
        },

        editGoal(record){

            this.goalEntry = record;
            this.modals.goal = true;

        },

        createGoal(){

            this.goalEntry = {
                
                creationTime: null,
                creationSeconds: null,
                lastUpdateTime: null,
                id: null,
                reflectionType: null,
                status: 'active',
                targetDays: 7,
                targetDate: null,
                name: null,
                tag: null,
                tagData: null,
                target: null,
                reflections: []

            };
            this.modals.goal = true;

        },

        setReflectionType(rt){
            this.goalEntry.reflectionType = rt;
        },

        buildTags() {

            const vm = this;

            if (!vm.user.data) {
                return;
            }

            let tmpPersonalTags = [];
            if (vm.user.data.tags) {
                tmpPersonalTags = _.cloneDeep(vm.user.data.tags);
            }
            tmpPersonalTags.forEach((tag, ti) => {
                tag.scope = "personal";
                tag.label = tag.name;
                vm.consolidatedTags.push(tag);
            });

            // Cycle through our groups
            let tmpGroups = [];
            if (vm.groups) {
                tmpGroups = _.cloneDeep(vm.groups);
            }

            tmpGroups.forEach((group, gi) => {
                // Add our tmpGroupTags
                var tmpGroupTags = _.cloneDeep(group.tags);
                tmpGroupTags.forEach((tag, t2i) => {
                    tag.groupId = group.id;
                    tag.title = group.groupName;
                    tag.label = tag.name;
                    tag.name = group.id + "#" + tag.name;
                    vm.consolidatedTags.push(tag);
                });
            });

        },

        setTempName(name){
            this.goalEntry.tempName = name;
        },

        setName(name){
            this.goalEntry.name = name;
        },

        setTag(tag){
            if (this.goalEntry.tag === tag.name) {
                this.goalEntry.tag = null;
                this.goalEntry.tagData = null;
            } else {
                this.goalEntry.tag = tag.name;
                this.goalEntry.tagData = tag;
            }
        },

        getTagClass(tag){
            if (tag.scope === "group") {
                if (tag.name === this.goalEntry.tag) {
                    return "pointer badge badge-pill badge-dark mb-2";
                } else {
                    return "pointer badge badge-pill badge-light text-dark mb-2";
                }
            } else {
                if (tag.name === this.goalEntry.tag) {
                    return "pointer badge badge-pill badge-default mb-2";
                } else {
                    return "pointer badge badge-pill badge-light text-default mb-2";
                }
            }
        },

        cancelGoal(){

            let index = this.viewGoal;
            this.goalEntry = this.goals[index];
            this.goalEntry.status = 'cancelled';
            this.viewGoal = null;
            this.saveGoal();

            this.goalEntry = null;

        },

        completeGoal(){
            
            let index = this.viewGoal;
            this.goalEntry = this.goals[index];
            this.goalEntry.status = 'completed';
            this.viewGoal = null;
            this.saveGoal();

            this.goalEntry = null;

        },

        completeGoalFromCongrats(){
            
            this.goalEntry.status = 'completed';
            this.modals.congrats = false;
            this.saveGoal();
            this.goalEntry = null;

        },

        saveGoal() {

            const vm = this;
            let tag = vm.goalEntry.tag;
            if (!tag) {
                tag = "all";
            }

            let tagData = vm.goalEntry.tagData;
            if (!tagData) {
                tagData = null;
            }

            if(!vm.goalEntry.id){

                var doc = db.collection("users").doc(vm.user.data.uid).collection("goals").doc();
                vm.goalEntry.id = doc.id

                // Get Creation Time
                let ourDate = new Date();
                vm.goalEntry.creationTime = new firebase.firestore.Timestamp.fromDate(ourDate);
                vm.goalEntry.creationSeconds = vm.goalEntry.creationTime.seconds;

            }

            // Get Target Time
            let targetMoment = this.$dayjs.unix( vm.goalEntry.creationSeconds ).add(vm.goalEntry.targetDays, "days").format();
            let ourTargetDate = new Date(targetMoment);
            vm.goalEntry.targetDate = new firebase.firestore.Timestamp.fromDate(ourTargetDate);


            db.collection("users").doc(vm.user.data.uid).collection("goals")
                .doc(vm.goalEntry.id)
                .set(vm.goalEntry)
                .then(function (docRef) {


                    // Send our Analytics event
                    vm.$analytics.logEvent("goal_saved", vm.goalEntry);

                    vm.$toast.open({
                        message: "Goal saved!",
                        type: "success",
                        // all of other options may go here
                    });

                    // Close Modal
                    vm.modals.goal = false;

                })
                .catch(function (error) {
                // console.log(error)

                    vm.$toast.open({
                        message: "Something went wrong!",
                        type: "error",
                        duration: 10000,
                        // all of other options may go here
                    });
                });
        },

        addReflection(goal) {

            const vm = this;

            // Get Creation Time
            let ourDate = new Date();
            goal.lastUpdateTime = new firebase.firestore.Timestamp.fromDate(ourDate);

            vm.checkProgress(goal);

            db.collection("users").doc(vm.user.data.uid).collection("goals")
                .doc(goal.id)
                .set(goal)
                .then(function (docRef) {

                    // Send our Analytics event
                    vm.$analytics.logEvent("goal_reflection_added", goal);

                })
                .catch(function (error) {

                    vm.$toast.open({
                        message: "Something went wrong!",
                        type: "error",
                        duration: 10000,
                        // all of other options may go here
                    });
                });
        },

    }
}
</script>
<style scoped>

    .feature-title {
        font-size: 4.5vh;
        font-weight: 500;
        line-height: 1em;
        font-family: 'Caveat';
    }

    .border-avatar {
        border: 2px solid #8d6c9f;
    }

    .goal-details{
        display:block;
        width:100%;
        /* height:calc(2.75rem + 2px); */
        padding:.625rem .75rem;
        font-size:1rem;
        line-height:1.5;
        color:#8d6c9f;
        background-color:#fff;
        background-clip:padding-box;
        border:1px solid #e0e3e6;
        border-radius:.25rem;
        -webkit-box-shadow:none;
        box-shadow:none;
        -webkit-transition:all .2s cubic-bezier(.68,-.55,.265,1.55);
        transition:all .2s cubic-bezier(.68,-.55,.265,1.55)
    }

    .goalIcon {
        color: #ffffff;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        height: 48px;
        width: 48px; 
    } 

    .goalIcon img {
        width: 100%;
    }

    .goalIcon-xs {
        width: 32px;
        height: 32px;
    }

    .status-icon {
        position: absolute;
        right: 15%;
        bottom: 20%;
        width: 32px;
        height: 32px;
        z-index: 999;
    }

    .status-icon-xs {
        position: absolute;
        right: 10%;
        bottom: 10%;
        width: 24px;
        height: 24px;
        z-index: 999;
    }

    .img-rt {
        /* position: relative; */
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 60px;
        height: 60px;
        transition: transform 0.2s;
        z-index: 899;
    }

    .img-rt-selection:hover {
        transform: scale(1.1);
    }

    .label-rt {
        font-size: 0.7em;
    }

    .feed-title {
        font-size: 0.9em;
    }

    .feed-date {
        font-size: 0.8em;
    }

    .trend {
        width: 100%;
        position: relative;
        top: 0;
        /* min-height: 120px; */
        vertical-align: middle;
    }

    .trend-icon {
        z-index: 9999;
        width: 90px;
        position: absolute;
        height: 100%;
        vertical-align: middle;
    }

    .img-hb {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        min-width: 60px;
        min-height: 60px;
    }
</style>