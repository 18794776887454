<template>
    <div v-if="featureEnabled('tasks') !== 'disabled'">

        <div class="card shadow p-0 bg-white rounded" v-if="featureEnabled('tasks') === 'enabled'">

            <div class="mt-2 mb-0">
                <div class="float-left ml-2 mr-1 p-2">
                <img
                    alt="Image placeholder"
                    class="avatar avatar-sm bg-white border-avatar p-1"
                    src="/img/images/successful.svg"
                />
                </div>

                <p class="feed-title font-weight-bold text-default mt-2 mb-0">
                    Task List
                </p>
                <p class="feed-date text-muted" style="margin-top: -7px">
                    Manage your tasks and reflect on their outcomes
                </p>
                <hr class="mt-0 mb-0" />
            </div>

            <div class="container px-3">

                <div class="row">

                    <div class="col-12 text-left task-col py-3" v-for="(record,index) in tasks" v-bind:key="record.id">

                        <div v-if="!taskEntry || taskEntry.id !== record.id">

                            <div class="float-right h-100 ml-3 align-middle text-right">
                                <button class="btn btn-sm btn-white" @click="deleteTask(index)">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </div>

                            <div
                                class="float-left h-100 mr-3 align-middle"
                                v-on:mouseover="taskMouseover(index)"
                                v-on:mouseout="taskMouseout"
                                @click="completeTask(index)"
                            >
                                <i class="fal fa-circle task-check text-success opacity-20 pointer" v-if="hoveredTask !== index"></i>
                                <i class="fad fa-check-circle task-check text-success pointer" v-else></i>
                            </div>


                            <div @click="editTask(index)">
                                {{ record.title }}
                            </div>
                        </div>
                        <div class="input-group" v-else>

                            <input
                                type="text"
                                v-model="taskEntry.title"
                                placeholder="What do you need to get done?"
                                class="form-control mb-0"
                                v-on:keydown="onHitEnterEdit"
                            >
                            <div class="input-group-append">

                                <button
                                    class="btn btn-default"
                                    type="button"
                                    id="button-addon2"
                                    @click="saveTask"
                                >
                                    <i class="fas fa-save"></i>
                                </button>

                                <button
                                    class="btn btn-danger"
                                    type="button"
                                    id="button-addon2"
                                    @click="taskEntry = {}"
                                >
                                    <i class="fas fa-times"></i>
                                </button>

                            </div>
                        </div>



                    </div>
                    
                    <div class="col-12 text-center text-light py-3" v-if="tasks.length === 0">
                        <i class="fas fa-grin-wink"></i> Well done! You're all caught up
                    </div>


                </div>


                <modal :show.sync="modals.task" modal-classes="modal-dialog-centered modal-sm">

                    <div v-if="taskEntry">

                        <!-- CHOOSE A REFLECTION TYPE -->
                        <div class="card-body p-0" v-if="!taskEntry.reflectionType">

                            <h4 class="text-default mb-0 mt-0 text-left" v-if="user.data">
                                Hi {{ user.data.firstName}},
                            </h4>
                            <h4 class="text-default text-left font-weight-bold">
                                What type of activity would you like to set your task for?
                            </h4>

                            <div class="row justify-content-center text-center">
                                <div
                                    class="col-4 pt-2 pb-2 m-0 text-center justify-content-center"
                                    v-for="(rt, index) in reflectionTypes"
                                    v-bind:key="index"
                                    @click="setReflectionType(rt)"
                                >
                                <div class="text-center">
                                    <img
                                        :alt="rt.title"
                                        class="img-rt img-rt-selection pointer"
                                        v-lazy="rt.image"
                                    />
                                </div>
                                    <div class="label-rt text-center text-default font-weight-bold pointer">
                                        {{ rt.title }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- CHOOSE A GOAL NAME-->
                        <div class="card-body p-0" v-else>

                            <h4 class="text-default text-left">
                                What do you want to call <span class="font-weight-bold">your task?</span>
                            </h4>
                            <div class="row justify-content-center">
                                <div class="col-12 pt-1 pb-2">
                                    <base-input
                                        v-model="taskEntry.title"
                                        placeholder="Enter a title for this task"
                                    >
                                    </base-input>
                                </div>
                            </div>


                            <h5 class="text-default text-left">
                                Define your <span class="font-weight-bold">targets</span>
                            </h5>
                            <div class="form-inline pb-2">
                                <span>
                                    I commit to myself to complete
                                </span>
                                <input
                                    type="number"
                                    class="form-control form-control-sm mx-sm-1 text-center"
                                    style="width: 70px;"
                                    v-model.number="taskEntry.target"
                                    placeholder="#"
                                >
                                <span>
                                    reflections in
                                </span>
                                <input
                                    type="number"
                                    class="form-control form-control-sm mx-sm-1 text-center"
                                    style="width: 70px;"
                                    v-model.number="taskEntry.targetDays"
                                    placeholder="#"
                                >
                                <span>
                                    days
                                </span>
                            </div>

                            <!-- <div class="row justify-content-center text-center">

                                <div class="col-6 pt-1 pb-2">
                                    <label># of Reflections</label>
                                    <base-input
                                        v-model.number="taskEntry.target"
                                        type="number"
                                        placeholder="#"
                                    >
                                    </base-input>
                                </div>

                                <div class="col-6 pt-1 pb-2">
                                    <label># of Days</label>
                                    <base-input
                                        v-model.number="taskEntry.targetDays"
                                        type="number"
                                        placeholder="How long?"
                                    >
                                    </base-input>
                                </div>

                            </div> -->


                            <!-- CHOOSE A TAG OR NOT -->
                            <h5 class="text-default text-left pt-3 pb-2">
                                Use your <span class="font-weight-bold">tags</span> to create more
                                <span class="font-weight-bold">specific tasks</span>
                            </h5>
                            <div class="row justify-content-center text-center">

                                <div class="col-12 mt-1 mb-0 p-2 text-center">
                                    <span
                                        :class="getTagClass(tag)"
                                        v-for="(tag, index) in filteredTags"
                                        v-bind:key="index"
                                        @click="setTag(tag)"
                                        :title="tag.title"
                                    >
                                        <span v-if="tag.scope === 'group'">
                                            <i class="fal fa-users-class"></i> {{ tag.label }}
                                        </span>
                                        <span v-else> <i class="fal fa-tag"></i> {{ tag.title }} </span>
                                    </span>
                                </div>

                                <add-tag v-if="taskEntry.reflectionType" :reflection-type="taskEntry.reflectionType.title.trim()" />

                            </div>

                            <div
                                class="row justify-content-md-center"
                                v-if="taskEntry.title"
                            >
                                <div class="col-12 mt-5 pb-2 text-center">
                                    <base-button type="danger" size="lg" @click="modals.task = false">
                                        Close
                                    </base-button>
                                    <base-button type="default" size="lg" @click="saveTask()" v-if="!taskEntry.id">
                                        Create Task
                                    </base-button>
                                    <base-button type="default" size="lg" @click="saveTask()" v-else>
                                        Update Task
                                    </base-button>
                                </div>
                            </div>
                            <div class="row justify-content-md-center" v-else>
                                <div class="col-12 mt-5 pb-2 text-center">
                                    <base-button type="danger" size="lg" @click="modals.task = false">
                                        Cancel
                                    </base-button>
                                    <base-button type="default" size="lg" disabled>
                                        <span v-if="!taskEntry.id">Create</span>
                                        <span v-else>Update</span> Task
                                    </base-button>
                                </div>
                            </div>

                        </div>

                    </div>


                </modal>

            </div>

            <div class=" p-0">
                <hr class="mt-0 mb-0 p-0" />

                <div class="p-3">

                  <div class="input-group">
                    <input
                        type="text"
                        v-model="tmpTitle"
                        placeholder="What do you need to get done?"
                        class="form-control mb-0"
                        v-on:keydown="onHitEnter"
                    >
                    <div class="input-group-append">
                      <button
                        class="btn btn-default"
                        type="button"
                        id="button-addon2"
                        @click="createTask"
                      >
                        <i class="fal fa-plus"></i>
                      </button>
                    </div>
                  </div>

                </div>



            </div>

        </div>

        <div class="card shadow p-0 bg-default rounded" v-if="featureEnabled('tasks') === 'pending'">
            <div class="mt-2 mb-0">
                <div class="float-left ml-2 mr-1 p-2">
                <img
                    alt="Image placeholder"
                    class="avatar avatar-sm bg-white border-avatar p-1"
                    src="/img/images/successful.svg"
                />
                </div>

                <p class="feed-title font-weight-bold text-white mt-2 mb-0">
                    Task List
                </p>
                <p class="feed-date text-light" style="margin-top: -7px">
                    Get started today!
                </p>
                <hr class="mt-0 mb-0 border-light" />
            </div>

            <div class="container p-3">

                <div class="row justify-content-center">

                    <div class="col">

                        <h3 class="feature-title text-white">
                            Create &amp; track refletion tasks &amp; keep focused
                        </h3>
                        <p class="text-white">
                            Reflection tasks allow you to define and track your personal
                            objectives and through performing self-reflections not only achieve
                            better results but track your performance over the targeted
                            timeframe that you have set yourself.
                        </p>
                        <p class="text-white">
                            Would you like to enable this feature?
                        </p>

                        <button class="btn btn-light" @click="setFeature('enabled')">
                            Yes
                        </button>
                        <button class="btn btn-light" @click="setFeature('disabled')">
                            No
                        </button>

                        <p class="text-white">
                            <small>this choice can be changed in your profile at any time</small>
                        </p>



                    </div>

                </div>

            </div>

        </div>
        
        <hr style="margin-left: 35%; margin-right: 35%; " />

    </div>
</template>

<script>

import firebase from "firebase/app";
import { db } from "@/firebase";
import store from "@/store.js";
import { mapGetters } from "vuex";
import _ from "lodash";
import Modal from "@/components/Modal.vue";
import AddTag from "@/components/reflections/AddTag";

export default {
    title: "task-list",
    props: ["tasks","reflectionTypes"],
    components: {
        Modal,
        AddTag
    },
    data() {
        return {
            modals: {
                task: false,
                congrats: false
            },
            currentProgress: [],
            hoveredTask: null,
            taskEntry: {},
            taskEntryIndex: null,
            viewTask: null,
            consolidatedTags: [],
            tmpTitle: ''
        }
    },
    watch: {
        user: {
            deep: true,
            handler() {
                this.consolidatedTags = [];
                this.buildTags();
            },
        }
    },
    computed: {
        ...mapGetters({
            // map `this.user` to `this.$store.getters.user`
            // map `this.groups` to `this.$store.getters.groups`
            user: "user",
            groups: "groups",
            reflectionActions: "reflectionActions",
            reflectionQueue: "reflectionQueue"
        }),

        filteredTags() {

            if(!this.taskEntry || !this.taskEntry.reflectionType ){ return [] }
            if (this.consolidatedTags) {
                return this.consolidatedTags.filter((tag) => {
                    return (
                        tag.status === "active" && (!tag.type || tag.type === this.taskEntry.reflectionType.title || tag.type === "none")
                    );
                });
            }

            return [];
        },

    },

    mounted() {
        this.buildTags();
    },

    methods: {

        onHitEnter: function (e) {

            if (e.keyCode === 27){

                this.taskEntry = {};
                this.tmpTitle = null;

            }

            if (e.keyCode === 13 || e.keyCode === 9) {
                if (this.tmpTitle.trim()) {
                    this.createTask();
                }
            }

        },

        onHitEnterEdit: function (e) {

            if (e.keyCode === 27){

                this.taskEntry = {};

            }

            if (e.keyCode === 13 || e.keyCode === 9) {
                if (this.taskEntry.title.trim()) {
                    this.saveTask();
                }
            }

        },

        taskMouseover(index){
            this.hoveredTask = index;
        },

        taskMouseout(){
            this.hoveredTask = null;
        },

        setFeature(status){

            let newUser = _.cloneDeep(this.user.data);

            if(!newUser.features){
                newUser.features = {}
            }

            if(!newUser.features.tasks){
                newUser.features.tasks = ''
            }

            newUser.features.tasks = status;

            this.$store.dispatch("updateUser", newUser);

        },     

        featureEnabled(feature){

            if(!this.user.data.features){
                return 'pending'
            }

            if(!(feature in this.user.data.features)){
                return 'pending'
            }

            if(this.user.data.features[feature] === 'disabled'){
                return 'disabled'
            }

            if(this.user.data.features[feature] === 'enabled'){
                return 'enabled'
            }

        },

        lastUpdate() {
            if(this.tasks.length === 0){
                return
            }
            if(!this.tasks[0].lastUpdateTime){
                return
            }
            return new Date(this.tasks[0].lastUpdateTime.seconds * 1000);
        },

        editTask(index){

            this.taskEntry = this.tasks[index];
            this.taskEntryIndex = index;

        },

        createTask(){

            this.taskEntry = {
                
                creationTime: null,
                creationSeconds: null,
                lastUpdateTime: null,
                id: null,
                reflectionType: null,
                status: 'active',
                dueDate: null,
                title: this.tmpTitle.trim(),
                tag: null,
                tagData: null

            };

            this.saveTask();

        },

        setReflectionType(rt){
            this.taskEntry.reflectionType = rt;
        },

        buildTags() {

            const vm = this;

            if (!vm.user.data) {
                return;
            }

            let tmpPersonalTags = [];
            if (vm.user.data.tags) {
                tmpPersonalTags = _.cloneDeep(vm.user.data.tags);
            }
            tmpPersonalTags.forEach((tag, ti) => {
                tag.scope = "personal";
                tag.label = tag.title;
                vm.consolidatedTags.push(tag);
            });

            // Cycle through our groups
            let tmpGroups = [];
            if (vm.groups) {
                tmpGroups = _.cloneDeep(vm.groups);
            }

            tmpGroups.forEach((group, gi) => {
                // Add our tmpGroupTags
                var tmpGroupTags = _.cloneDeep(group.tags);
                tmpGroupTags.forEach((tag, t2i) => {
                    tag.groupId = group.id;
                    tag.title = group.groupName;
                    tag.label = tag.title;
                    tag.title = group.id + "#" + tag.title;
                    vm.consolidatedTags.push(tag);
                });
            });

        },

        setTempName(title){
            this.taskEntry.tempTitle = title;
        },

        setName(title){
            this.taskEntry.title = title;
        },

        setTag(tag){
            if (this.taskEntry.tag === tag.title) {
                this.taskEntry.tag = null;
                this.taskEntry.tagData = null;
            } else {
                this.taskEntry.tag = tag.title;
                this.taskEntry.tagData = tag;
            }
        },

        getTagClass(tag){
            if (tag.scope === "group") {
                if (tag.title === this.taskEntry.tag) {
                    return "pointer badge badge-pill badge-dark mb-2";
                } else {
                    return "pointer badge badge-pill badge-light text-dark mb-2";
                }
            } else {
                if (tag.title === this.taskEntry.tag) {
                    return "pointer badge badge-pill badge-default mb-2";
                } else {
                    return "pointer badge badge-pill badge-light text-default mb-2";
                }
            }
        },

        deleteTask(index){

            this.taskEntry = this.tasks[index];
            this.taskEntry.status = 'deleted';
            this.saveTask();

            this.taskEntry = {};
            this.taskEntryIndex = null;
            this.hoveredTask = null;

        },

        completeTask(index){
            
            this.taskEntry = this.tasks[index];
            this.taskEntry.status = 'completed';
            this.saveTask();

            this.taskEntry = {};
            this.taskEntryIndex = null;
            this.hoveredTask = null;

        },

        saveTask() {

            const vm = this;
            let tag = vm.taskEntry.tag;
            if (!tag) {
                tag = "none";
            }

            let tagData = vm.taskEntry.tagData;
            if (!tagData) {
                tagData = null;
            }

            if(!vm.taskEntry.id){

                var doc = db.collection("users").doc(vm.user.data.uid).collection("tasks").doc();
                vm.taskEntry.id = doc.id

                // Get Creation Time
                let ourDate = new Date();
                vm.taskEntry.creationTime = new firebase.firestore.Timestamp.fromDate(ourDate);
                vm.taskEntry.creationSeconds = vm.taskEntry.creationTime.seconds;

            }

            // this.taskEntry.title = this.tmpTitle;

            // Get Target Time
            let dueMoment = this.$dayjs.unix( vm.taskEntry.creationSeconds ).add(1, "days").format();
            let ourDueDate = new Date(dueMoment);
            vm.taskEntry.dueDate = new firebase.firestore.Timestamp.fromDate(ourDueDate);

            let data = _.cloneDeep(vm.taskEntry);
            vm.tmpTitle = null;
            vm.taskEntry = {};
            vm.taskEntryIndex = null;

            if(data.status === 'completed'){
                this.$store.commit("REFLECTION_QUEUE_ADD", data);
            }

            db.collection("users").doc(vm.user.data.uid).collection("tasks")
                .doc(data.id)
                .set(data)
                .then(function (docRef) {


                    // Send our Analytics event
                    vm.$analytics.logEvent("task_saved", vm.taskEntry);

                    vm.taskEntry = {};
                    vm.taskEntryIndex = null;
                    vm.hoveredTask = null;

                    // vm.$toast.open({
                    //     message: "Task saved!",
                    //     type: "success",
                    //     // all of other options may go here
                    // });
                    

                })
                .catch(function (error) {
                // console.log(error)

                    vm.$toast.open({
                        message: "Something went wrong!",
                        type: "error",
                        duration: 10000,
                        // all of other options may go here
                    });
                });
        }

    }
}
</script>
<style scoped>

    .opacity-20{
        opacity: 0.2;
    }

    .task-col:hover {
        background-color: #f8f8f8;
    }

    .task-check {
        font-size: 24px;
    }

    .feature-title {
        font-size: 4.5vh;
        font-weight: 500;
        line-height: 1em;
        font-family: 'Caveat';
    }

    .border-avatar {
        border: 2px solid #8d6c9f;
    }

    .task-details{
        display:block;
        width:100%;
        /* height:calc(2.75rem + 2px); */
        padding:.625rem .75rem;
        font-size:1rem;
        line-height:1.5;
        color:#8d6c9f;
        background-color:#fff;
        background-clip:padding-box;
        border:1px solid #e0e3e6;
        border-radius:.25rem;
        -webkit-box-shadow:none;
        box-shadow:none;
        -webkit-transition:all .2s cubic-bezier(.68,-.55,.265,1.55);
        transition:all .2s cubic-bezier(.68,-.55,.265,1.55)
    }

    .taskIcon {
        color: #ffffff;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        height: 48px;
        width: 48px; 
    } 

    .taskIcon img {
        width: 100%;
    }

    .taskIcon-xs {
        width: 32px;
        height: 32px;
    }

    .status-icon {
        position: absolute;
        right: 15%;
        bottom: 20%;
        width: 32px;
        height: 32px;
        z-index: 999;
    }

    .status-icon-xs {
        position: absolute;
        right: 10%;
        bottom: 10%;
        width: 24px;
        height: 24px;
        z-index: 999;
    }

    .img-rt {
        /* position: relative; */
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 60px;
        height: 60px;
        transition: transform 0.2s;
        z-index: 899;
    }

    .img-rt-selection:hover {
        transform: scale(1.1);
    }

    .label-rt {
        font-size: 0.7em;
    }

    .feed-title {
        font-size: 0.9em;
    }

    .feed-date {
        font-size: 0.8em;
    }

    .trend {
        width: 100%;
        position: relative;
        top: 0;
        /* min-height: 120px; */
        vertical-align: middle;
    }

    .trend-icon {
        z-index: 9999;
        width: 90px;
        position: absolute;
        height: 100%;
        vertical-align: middle;
    }

    .img-hb {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        min-width: 60px;
        min-height: 60px;
    }
</style>