<template>
    <div>
        
        <card shadow class="p-4" no-body v-if="user">

            <div class="row justify-content-center mb-2">
                <div class="col-12 text-center">
                    <div class="float-right">
                        <a class="btn btn-sm btn-default text-white" role="button" @click="createNotice = true">
                            <i class="fad fa-bell"></i> New
                        </a>
                    </div>
                    <h4>
                        Notifications
                    </h4>
                </div>
            </div>

            <div v-show="createNotice">

                <div class="row justify-content-center">

                    <div class="col-12 pt-1">
                        <p class="text-default mb-3 font-weight-bold">
                            Create Notification
                        </p>
                    </div>

                </div>

                <div class="row justify-content-center" v-if="notification">

                    <div class="col-12">
                        
                        <label>Title</label>
                        <base-input
                            type="text"
                            placeholder="Add title"
                            v-model="notification.title"
                        >
                        </base-input>
                        
                    </div>

                    <div class="col-12">

                        <label>Notification Text</label>
                        <textarea-autosize
                        placeholder="Add your text"
                        v-model="notification.text"
                        :min-height="50"
                        class="form-control"
                        />

                    </div>

                     <div class="col-12" v-if="createNotice">

                        <attachment-builder ref="attachmentBuilder" :options="attachmentBuilderOptions"></attachment-builder>

                    </div>

                    <div class="col-lg-6 col-12 text-center">

                        <a role="button" class="small pointer btn btn-sm btn-white mt-3" @click="showAdvanced = !showAdvanced">
                            advanced options
                        </a>

                        <div class="text-left" v-if="showAdvanced">

                            <p class="small text-muted mt-3 mb-0">From Name</p>
                            <base-input
                                type="text"
                                placeholder="From"
                                v-model="notification.user.fullName"
                            >
                            </base-input>

                            <p class="small text-muted mt-3 mb-0">Photo URL <span class="small font-weight-bold">[ img/brand/basic-purple.png OR img/brand/basic-white.png OR full URL ]</span></p>
                            <base-input
                                type="text"
                                placeholder="From"
                                v-model="notification.user.photoURL"
                            >
                            </base-input>

                        </div>

                        <div class="text-left" v-if="showAdvanced">

                            <p class="small text-muted mt-3 mb-0">Choose style</p>
                            <button class="mb-1 btn btn-sm" :class="[ notification.type === 'default' ? 'btn-default' : 'btn-outline-default']" @click="notification.type = 'default'">
                                default
                            </button>
                            <button class="mb-1 btn btn-sm" :class="[ notification.type === 'primary' ? 'btn-primary' : 'btn-outline-primary']" @click="notification.type = 'primary'">
                                primary
                            </button>
                            <button class="mb-1 btn btn-sm" :class="[ notification.type === 'success' ? 'btn-success' : 'btn-outline-success']" @click="notification.type = 'success'">
                                success
                            </button>
                            <button class="mb-1 btn btn-sm" :class="[ notification.type === 'warning' ? 'btn-warning' : 'btn-outline-warning']" @click="notification.type = 'warning'">
                                warning
                            </button>
                            <button class="mb-1 btn btn-sm" :class="[ notification.type === 'danger' ? 'btn-danger' : 'btn-outline-danger']" @click="notification.type = 'danger'">
                                danger
                            </button>
                            <button class="mb-1 btn btn-sm" :class="[ notification.type === 'dark' ? 'btn-dark' : 'btn-outline-dark']" @click="notification.type = 'dark'">
                                dark
                            </button>

                        </div>

                    </div>

                    <div class="col-12 mt-5 pb-2 text-center" v-if="notification.title">

                        <base-button type="info" size="lg" @click="saveDraftNotification">
                            Draft
                        </base-button>
                        <base-button type="default" size="lg" @click="publishNotification">
                            Publish
                        </base-button>
                    </div>
                    <div class="col-12 mt-5 pb-2 text-center" v-else>
                        <base-button type="info" size="lg" disabled>
                            Draft
                        </base-button>
                        <base-button type="default" size="lg" disabled>
                            Publish
                        </base-button>
                    </div>

                </div>

                <div class="row justify-content-center text-center mt-5">

                    <a role="button" @click="clearNotification" class="pointer" title="Clear">
                        <icon name="fal fa-times" size="lg" type="danger" shadow rounded></icon>
                    </a>

                </div>

            </div>

            <div v-show="!createNotice">

                <div class="col-12 p-0" v-for="(record, index) in notifications" v-bind:key="index">

                    <notification-details
                        ref="notificationDetailsPanel"
                        name="notification cards"
                        :selected-entry="record"
                        v-if="record.audience"
                        v-on:editNotification="selectNotification"
                    ></notification-details>
                
                </div>

                <div class="col-12 p-0" v-if="notifications.length === 0">

                    <div class="alert alert-default text-center text-default p-5" v-if="!loading">

                        <!-- <i class="fad fa-analytics fa-3x"></i> -->
                        <span class="fa-stack fa-2x">
                            <i class="fas fa-square fa-stack-2x"></i>
                            <i class="fad fa-bell fa-stack-1x fa-inverse"></i>
                        </span>
                        <p class="text-white font-weight-bold mt-3 mb-0">
                            We could not find any notifications
                        </p>

                    </div>
                    <div class="alert alert-default text-center text-white p-5" v-else>

                        <i class="fad fa-spinner-third fa-4x fa-spin"></i>
                        <h5 class="text-white mt-2">Loading reflection statistics</h5>

                    </div>

                </div>

            </div>

        </card>

    </div>
</template>
<script>

import firebase from 'firebase/app'
import { db } from '@/firebase';
import store from '@/store.js'
import { mapGetters } from "vuex";
import reflectionTypes from "@/models/reflectionTypes";

import BaseDropdown from "@/components/BaseDropdown";
import AttachmentBuilder from "@/components/reflections/AttachmentBuilder.vue";
import NotificationDetails from "@/components/admin/NotificationDetails.vue";

export default {
    name: 'notification-management',
    // props: [],
    components: { 
        BaseDropdown,
        AttachmentBuilder,
        NotificationDetails
    },

    computed: {

        ...mapGetters({
            // map `this.user` to `this.$store.getters.user`
            // map `this.groups` to `this.$store.getters.groups`
            user: "user",
            groups: "groups"
        }),

    },

    data () {
        return {
            
            loading: true,
            notification: null,
            notifications: [],
            reflectionTypes,
            createNotice: false,
            showAdvanced: false,
            attachmentBuilderOptions: {
                supportDataCapture: false,
                templateType: 'group'
            }

        }
    },

    mounted() {
        this.clearNotification();
        this.getNotifications();
    },

    methods: {

        selectNotification(id){

            this.notification = this.notifications.find(element => element.id === id);
            this.attachmentBuilderOptions.id = this.notification.attachment.id;
            this.attachmentBuilderOptions.attachments = this.notification.attachment;
            this.createNotice = true;

        },

        clearNotification(){

            this.notification = {
                
                alertId: null,
                id: null,
                attachment: null,
                audience: 'a-myreflections-testing',
                creationSeconds: null,
                creationTime: null,
                title: null,
                text: null,
                published: false,
                type: 'default',
                user: {
                    fullName: 'A message from #myreflections',
                    photoURL: 'img/brand/basic-purple.png'
                }
            }

            this.attachmentBuilderOptions = {
                supportDataCapture: false,
                templateType: 'group'
            }

            this.createNotice = false

        },

        saveDraftNotification(){

            this.notification.published = false;
            this.createNotification();

        },

        publishNotification(){

            this.notification.published = true;
            this.createNotification();

        },

        createNotification() {

            const vm = this;

            var docid = this.notification.id
            if(!this.notification.id){
                var doc = db.collection("communityNotifications").doc();
                docid = doc.id
            }

            let attachment = []
            if(this.$refs.attachmentBuilder.attachments){
                attachment = this.$refs.attachmentBuilder.attachments
            }

            let ourDate = new Date();
            let notificationDate = new firebase.firestore.Timestamp.fromDate(ourDate);

            let notification = {
                alertId: docid,
                id: docid,
                attachment: attachment,
                audience: this.notification.audience,
                creationSeconds: notificationDate.seconds,
                creationTime: notificationDate,
                title: this.notification.title,
                text: this.notification.text,
                published: this.notification.published,
                type: this.notification.type,
                uid: this.user.data.uid,
                user: {
                    fullName: 'A message from #myreflections',
                    photoURL: 'img/brand/basic-white.png',
                    uid: this.user.data.uid
                }
            };

            db.collection("communityNotifications")
                .doc(docid)
                .set(notification)
                .then(function (docRef) {

                // Send our Analytics event
                vm.$analytics.logEvent("notification_created", notification);

                // console.log(notification)

                vm.$toast.open({
                    message: "Notification saved!",
                    type: "success",
                    // all of other options may go here
                });

                vm.clearNotification();

            })
            .catch(function (error) {
                // console.log(error)

                vm.$toast.open({
                    message: "Something went wrong!",
                    type: "error",
                    duration: 10000,
                    // all of other options may go here
                });
            });
        },

        getNotifications() {

            const vm = this;

            // Community Notifications
            var docRef = db.collection("communityNotifications");
            docRef = docRef.orderBy("creationSeconds", "desc");
            docRef = docRef.limit(20);
            docRef.onSnapshot(async function(docs) {
                docs.docChanges().forEach(function(change) {
                    let { newIndex, oldIndex, doc, type } = change;
                    if (type === "added") {
                        vm.notifications.splice(newIndex, 0, doc.data());
                    } else if (type === "modified") {
                        vm.notifications.splice(oldIndex, 1);
                        vm.notifications.splice(newIndex, 0, doc.data());
                    } else if (type === "removed") {
                        vm.notifications.splice(oldIndex, 1);
                    }
                });
            });

            vm.loading = false;

        },

    }
};
</script>
<style scoped>

  .card-ellipse {
    position: absolute;
    text-align: right;
    top: 0;
    right: 0;
    z-index: 100;
  }

  .mr-logo {
    width: 90px !important;
    height: 90px !important;
  }

</style>