<template>
  <div>
    <div class="container" v-if="currentUser && !dataBuilt">
      <div class="row justify-content-center mt-4">
        <div class="col-8 text-center">
          <div class="alert alert-default p-4">
            <i :class="warmUpIcon"></i>
            <h5 class="text-white m-2" v-html="warmUpMessage"></h5>
          </div>
        </div>
      </div>
    </div>

    <div class="container" v-else>
      <div class="row justify-content-center">
        <div class="col-12 card shadow p-4 bg-white rounded mt-4">
          <weekly-reflection-report-layout
            :new-report="newReport"
            :show-title="true"
          ></weekly-reflection-report-layout>
        </div>

        <!-- public link -->
        <div
          class="col-12 card shadow rounded p-4 bg-white text-center mt-4"
          v-if="!newReport.publicLink"
        >
          <h4 class="card-title text-default font-weight-bold mb-0">
            Would you like to share this report?
          </h4>
          <p>You can create a unique link to share this report</p>

          <div v-if="!publicLinkRequested">
            <base-button type="secondary" size="lg" @click="generatePublicLink">
              Create Public Link
            </base-button>
          </div>
          <div v-else>
            <i class="fad fa-spinner-third fa-4x fa-spin"></i>
            <h5 class="text-default mt-2">Creating your public link</h5>
          </div>
        </div>

        <div class="col-12 card shadow rounded p-4 bg-white text-center mt-4" v-else>
          <h4 class="card-title text-default font-weight-bold mb-0">
            You have created a public link for this report
          </h4>
          <p>
            <small>COPY &amp; SHARE THE BELOW</small> |
            <small class="pointer underline" @click="removePublicLink"
              ><i class="fad fa-times"></i> REMOVE</small
            >
          </p>

          <base-input
            placeholder="Public Link"
            class="text-center"
            inputClasses="form-control-lg text-center"
            disabled
            :value="publicLink"
          ></base-input>
        </div>

        <comments :report-uid="reportUid" :owner-uid="newReport.uid"></comments>
      </div>
    </div>

    <div class="container">
      <div class="row justify-content-center mt-4">
        <a role="button" @click="reportClosed" class="pointer" title="Clear">
          <icon name="fal fa-times" size="lg" type="danger" shadow rounded></icon>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import firebase from "firebase/app";
import { db } from "@/firebase";

// import baseReport from "@/models/baseReport";

import WeeklyReflectionReportLayout from "@/components/weekly/Layout";
import Comments from "@/components/weekly/Comments";
import reflectionTypes from "@/models/reflectionTypes";

// const currentUser = firebase.auth().currentUser;

export default {
  name: "weekly-reflections-report",
  components: {
    WeeklyReflectionReportLayout,
    Comments,
  },

  props: ["currentUser", "currentWeek"],

  data() {
    return {
      showReflectionsByType: false,
      showReflectionsByDay: false,
      showReflectionsDetails: false,

      expandedInput: false,

      warmUpMessage: "Downloading all of your reflections<br>the wizard will start soon!",
      warmUpIcon: "fad fa-spinner-third fa-4x fa-spin",

      wizardReady: false,
      dataReceived: false,
      dataBuilt: false,
      publicLinkRequested: false,

      reportUid: null,

      newReport: null,

      form: {
        comments: [],
      },

      showFilter: false,
      reflections: [],
      // reflections2: [],
      reflectionTypes: reflectionTypes,
    };
  },

  mounted() {
    this.getReflectionData();
  },

  watch: {
    currentWeek() {
      if (this.currentWeek) {
        this.getReflectionData();
      }
    },
  },

  computed: {
    publicLink: function () {
      return "https://myreflections.app/shared/" + this.newReport.publicLink;
    },
  },

  methods: {
    generatePublicLink() {
      this.publicLinkRequested = true;

      const vm = this;
      const axios = require("axios");

      // this.newReport.publicLink = this.$uuid.v4();
      // this.updateReportDocument()

      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then(function (idToken) {
          // Send token to your backend via HTTPS
          var config = {
            headers: { Authorization: "Bearer " + idToken },
          };

          // let url = 'http://localhost:5001/my-reflection-22932/australia-southeast1/app/api/getpubliclink'
          let url =
            "https://australia-southeast1-my-reflection-22932.cloudfunctions.net/app/api/getpubliclink";

          // Make a request for a user with a given ID
          axios
            .get(url, config)
            .then(function (response) {
              // handle success
              vm.newReport.publicLink = response.data;
              vm.updateReportDocument();
              vm.publicLinkRequested = false;
            })
            .catch(function (error) {
              // handle error
              // console.log(error.response)
            });

          // ...
        })
        .catch(function (error) {
          // Handle error
        });
    },

    removePublicLink() {
      this.newReport.publicLink = null;
      this.updateReportDocument();
    },

    updateReportDocument() {
      db.collection("weeklyReflections").doc(this.reportUid).update(this.newReport);

      this.$toast.open({
        message: "Report progress updated!",
        type: "success",
        // all of other options may go here
      });
    },

    returnPerc(previous, current) {
      return ((previous - current) / previous) * 100;
    },

    getReflectionData() {
      const vm = this;

      let week = this.currentWeek;
      let start = new Date(week.startDate);
      let end = new Date(week.endDate);
      let reportUid = week.reportUid;

      if (reportUid) {
        this.reportUid = reportUid;

        var docRef = db.collection("weeklyReflections").doc(reportUid);
        docRef
          .get()
          .then(function (doc) {
            if (doc.exists) {
              vm.newReport = doc.data();
              vm.dataBuilt = true;
            } else {
              vm.reportClosed();

              // doc.data() will be undefined in this case
              vm.$toast.open({
                message: "Something went wrong finding your report!",
                type: "error",
                duration: 10000,
                // all of other options may go here
              });
            }
          })
          .catch(function (error) {
            // console.log("Error getting document:", error);
          });
      }
    },

    reportClosed() {
      this.$emit("reportClosed");
      this.$router.replace("/weekly");
    },
  },
};
</script>
<style scoped>
.line-chart {
  /* max-width: 100%; */
  height: 350px;
  /* margin:  50px auto; */
}

.img-rt-statcard {
  /* position: relative; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 35px;
  height: 35px;
}

.img-rt {
  /* position: relative; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 60px;
  height: 60px;
}

.label-rt {
  font-size: 0.7em;
}

.img-filter {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 35px;
  height: 35px;
}

.unselected {
  filter: grayscale(100%);
  opacity: 0.3;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.media-comment-avatar {
  margin-top: -1rem;
  margin-right: -2rem;
  position: relative;
  z-index: 1;
  width: 64px;
  height: 64px;
  border: 4px solid #fff;
  transition: all 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .media-comment-avatar {
    transition: none;
  }
}

.media-comment-text {
  border-radius: 0.3rem;
  border-top-left-radius: 0;
  position: relative;
  background-color: white;
  padding: 1rem 1.25rem 1rem 2.5rem;
}

.media-comment:hover .media-comment-avatar {
  transform: scale(1.1);
}
</style>
