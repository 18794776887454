<template>
    <div>

        <div class="row justify-content-center pl-3 pr-3 mb-2">

            <div class="col-12" v-show="attachment.title || attachment.summary">
                <p class="card-title mb-1 text-default font-weight-bold" v-show="attachment.title">{{ attachment.title }}<br /></p>
                <p v-show="attachment.summary">{{ attachment.summary }}</p>
            </div>

            <div class="col-12 text-center">

                <audio ref="player" controls class="w-100"></audio>

            </div>

        </div>
    </div>
</template>
<script>

import _ from "lodash";

export default {
    name: 'embed-podcast-episode',
    props: ['attachment','id','index','readOnly'],

    mounted() {
        this.$refs.player.src = this.attachment.src
        // this.$refs.player.play()
    },

    data () {
        return {
            localData: _.cloneDeep(this.attachment),
            reqData: false
        }
    },
    computed: {

    },

    watch: {

    },

    methods: {

    }
};
</script>

<style scoped>

</style>