<template>
    <div class="container">

      <div class="row">

        <!-- Comments -->
        <div class="col-12 card shadow rounded p-4 bg-white mt-4">

            <h4 class="card-title text-default font-weight-bold mb-0 text-center">Comments and feedback</h4>
            <div class="card-body justify-content-center">

                <!-- ADD COMMENT -->
                <div class="media align-items-center mb-4" v-if="user.loggedIn">
                    <img alt="Image placeholder" :src="user.data.photoURL" class="avatar avatar-sm rounded-circle mt-3 mb-4 mr-2" v-if="user.data && user.data.photoURL">
                    <img alt="Image placeholder" src="/img/my-reflections-default-profile.png" class="avatar avatar-sm rounded-circle mt-3 mb-4 mr-2" v-else>
                    <div class="media-body">

                        <div class="input-group mt-2 mb-2">
                            <textarea-autosize
                                placeholder="Write your comment..."
                                ref="commentTextarea"
                                v-model="form.comment"
                                :min-height="10"
                                class="form-control"
                            />
                            <div class="input-group-append" v-if="form.comment">
                                <button class="btn btn-default" type="button" id="button-addon2" @click="commitComment"><i class="fas fa-reply"></i></button>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="text-center rounded" v-else>

                    <p><i class="fad fa-exclamation-circle"></i> Only registered users can view and comment on this report, click here to</p>
                    <base-button type="default" @click="displayRego = true" v-if="!displayRego" class="mt-2">
                        Register Now
                    </base-button>
                    <div class="mt-2 text-default" v-show="displayRego">
                        <registration-form ref="regForm" redirect-to="reflectnow" register-label="Sign up to start commenting" form-type="white"></registration-form>
                    </div>
                  

                </div>

                <div class="mt-4 mb-4 text-center" v-if="comments.length === 0 && user.loggedIn">

                  Be the first to comment!

                </div>

                <div class="media media-comment mt-4" v-for="(comment,index) in comments" v-bind:key="index">
                    <img alt="Image placeholder"
                         :class="getAvatarClass(comment.user.uid)"
                         :src="comment.user.photoURL">
                    <div class="media-body">
                        <div class="media-comment-text">

                            <h6 class="h6 mt-0 mb-0 font-weight-bold text-default">{{ comment.user.fullName }}</h6>
                            <p class="text-muted mt-0 mb-3">
                              <small>{{ $dayjs(getDate(comment.creationTime)).fromNow() }}</small>
                            </p>
                            <p class="text-sm">
                                {{ comment.text }}
                            </p>

                        </div>
                    </div>
                </div>

            </div>

        </div>

      </div>

    </div>
</template>
<script>

import firebase from 'firebase/app'
import { db } from '@/firebase';
import store from '@/store.js'
import { mapGetters } from "vuex";
import RegistrationForm from "@/components/auth/Form";

export default {
    name: 'comments',
    props: ['reportUid','ownerUid'],
    components: { 
        RegistrationForm
    },

    mounted() {
      this.$watch(
        "$refs.regForm.regStatus", (new_value, old_value) => {
          //execute your code here
          if(new_value === 'success'){
              // this.$router.replace('reflectnow')
             // console.log('we registered in!')
          }
        }
      );
    },

    computed: {

        ...mapGetters({
            // map `this.user` to `this.$store.getters.user`
            // map `this.groups` to `this.$store.getters.groups`
            user: "user",
            groups: "groups"
        })

    },

    data () {
        return {
            comments : [],
            form: {
              comment: ''
            },
            displayRego: false
        }
    },
    methods: {

        getDate(datetime){

          if(datetime._seconds){
            return new Date(datetime._seconds * 1000)
          }else if(datetime.seconds){
            return new Date(datetime.seconds * 1000)
          }else{
            let newdate = datetime.toString().substring(0,10)
            return new Date(Number(newdate) * 1000)
          }

        },

        getAvatarClass(uid){

            if(!this.user.data){
                return 'media-comment-avatar media-comment-avatar-else rounded-circle'
            }

            if(uid === this.user.data.uid){
                return 'media-comment-avatar media-comment-avatar-self rounded-circle'
            }else{
                return 'media-comment-avatar media-comment-avatar-else rounded-circle'
            }
            

        },

        commitComment(){

            const vm = this;

            let ourDate = new Date();
            let timeStamp = new firebase.firestore.Timestamp.fromDate(ourDate) 

            let comment = {

                user : {
                    fullName : this.user.data.fullName,
                    photoURL : this.user.data.photoURL,
                    uid : this.user.data.uid
                },
                ownerUid: this.ownerUid,
                text : this.form.comment,
                reportUid : this.reportUid,
                creationTime : timeStamp,
                status: 'submitted'

            }

            db.collection("comments").add(comment)
            .then(function(docRef) {

                vm.$analytics.logEvent('comment', {
                    commentId: docRef.id,
                    ownerUid: vm.ownerUid,
                    reportUid: vm.reportUid,
                    commentorUid: vm.user.data.uid,
                    fullName : vm.user.data.fullName,
                    creationTime : timeStamp,
                    text : vm.form.comment
                });

                // console.log(docRef.id);
                vm.form.comment = ''
                vm.$toast.open({
                      message: 'Comment added',
                      type: 'success',
                      // all of other options may go here
                  });

            })
            .catch(function(error) {

                vm.$toast.open({
                    message: 'Something went wrong!',
                    type: 'error',
                    duration: 10000,
                    // all of other options may go here
                });

            });

        }

    },
    firestore () {
      return {
        comments: db.collection('comments').where('reportUid', '==', this.reportUid).orderBy('creationTime', "desc")
      }
    }
};
</script>
<style scoped>

    .media {
        display:flex;
        align-items:flex-start
    }

    .media-body {
        flex:1
    }

    .media-comment-avatar{
        margin-top: -4px;
        margin-right: -2rem;
        position:relative;
        z-index:1;
        width:64px;
        height:64px;
        transition:all .15s ease
    }

    .media-comment-avatar-self {
      border:3px solid #8d6c9f;
    }

    .media-comment-avatar-else {
      border:3px solid #999;
    }
    
    @media (prefers-reduced-motion:reduce){
        .media-comment-avatar{
            transition:none
        }
    }
    
    .media-comment-text {
        border-radius:.3rem;
        border-top-left-radius:0;
        position:relative;
        background-color: #f6f9fc !important;
        padding:1rem 1.25rem 1rem 2.5rem
    }
    
    .media-comment:hover .media-comment-avatar{
        transform:scale(1.1)
    }

</style>