<template>
  <div>
    <modal
      :show.sync="modals.delete"
      v-if="entry"
      gradient="danger"
      modal-classes="modal-danger modal-dialog-centered"
    >
      <!-- <h6 slot="header" class="modal-title" id="modal-title-notification">Your attention is required</h6> -->

      <div class="py-3 text-center">
        <i class="fad fa-bell fa-4x"></i>
        <h2 class="display-2 text-white mt-4">Are you sure?</h2>
        <p>Deleted reflections cannot be restored!</p>
      </div>

      <template slot="footer">
        <base-button type="white" @click="deleteReflection">Yes</base-button>
        <base-button type="link" text-color="white" class="ml-auto" @click="closeModal">
          Close
        </base-button>
      </template>
    </modal>

    <modal
      :show.sync="modals.nohighlow"
      v-if="entry"
      gradient="default"
      modal-classes="modal-default modal-dialog-centered"
    >
      <!-- <h6 slot="header" class="modal-title" id="modal-title-notification">Your attention is required</h6> -->

      <div class="py-3 text-center">
        <i class="fad fa-robot fa-4x"></i>
        <h2 class="display-2 text-white mt-4">Not much point!</h2>
        <p>You have not provided any highlights or lowlight, so sentiment analysis is not going to achieve much</p>
      </div>

      <template slot="footer">
        <base-button type="white" @click="loadReflection">Do it now!</base-button>
        <base-button type="link" text-color="white" class="ml-auto" @click="closeModal">
          Close
        </base-button>
      </template>
    </modal>

  </div>
</template>
<script>
import Modal from "@/components/Modal.vue";
import reflectionTypes from "@/models/reflectionTypes";
import firebase from "firebase/app";
import { db } from "@/firebase";
import { mapGetters } from "vuex";

export default {
  name: "reflection-modals",
  components: {
    Modal,
  },
  props: ["entry", "action"],

  mounted() {
    this.buildTags();
    this.setModal();
    //this.setDate(0);
  },

  data() {
    return {
      // data here
      reflectionTypes: reflectionTypes,
      reflectionDate: null,
      reflectionAdjustment: 0,
      imagesArray: null,
      form: {
        activityName: "",
        activityDetails: "",
        rating: 5,
        highlights: [],
        lowlights: [],
        tempHighlight: "",
        tempLowlight: "",
        reflectionType: "",
        tag: "",
        tagData: null,
        dailyCheckinQuestions: null,
      },
      modals: {
        delete: false,
        nohighlow: false,
        edit: false,
      },
      consolidatedTags: [],
    };
  },
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      // map `this.groups` to `this.$store.getters.groups`
      user: "user",
      groups: "groups",
      reflectionActions: "reflectionActions"
    }),

    filteredTags() {
      if (this.consolidatedTags) {
        return this.consolidatedTags.filter((tag) => {
          return (
            tag.status === "active" &&
            (!tag.type || tag.type === this.form.reflectionType || tag.type === "none")
          );
        });
      }

      return [];
    },
  },
  methods: {

    loadReflection(){

        this.$router.replace('/reflectnow/' + this.entry.id);
        this.closeModal();

    },

    buildTags() {
      const vm = this;

      if (!vm.user.data) {
        return;
      }

      let tmpPersonalTags = [];
      if (vm.user.data.tags) {
        tmpPersonalTags = JSON.parse(JSON.stringify(vm.user.data.tags));
      }
      tmpPersonalTags.forEach((tag, ti) => {
        tag.scope = "personal";
        tag.label = tag.name;
        vm.consolidatedTags.push(tag);
      });

      // Cycle through our groups
      let tmpGroups = [];
      if (vm.groups) {
        tmpGroups = JSON.parse(JSON.stringify(vm.groups));
      }

      tmpGroups.forEach((group, gi) => {
        // Add our tmpGroupTags
        var tmpGroupTags = JSON.parse(JSON.stringify(group.tags));
        tmpGroupTags.forEach((tag, t2i) => {
          tag.groupId = group.id;
          tag.title = group.groupName;
          tag.label = tag.name;
          tag.name = group.id + "#" + tag.name;
          vm.consolidatedTags.push(tag);
        });
      });
    },

    setTag(tag) {
      if (this.form.tag === tag.name) {
        this.form.tag = null;
        this.form.tagData = null;
      } else {
        this.form.tag = tag.name;
        this.form.tagData = tag;
      }
    },

    getTagClass(tag) {
      if (tag.scope === "group") {
        if (tag.name === this.form.tag) {
          return "pointer badge badge-pill badge-dark mb-2";
        } else {
          return "pointer badge badge-pill badge-light text-dark mb-2";
        }
      } else {
        if (tag.name === this.form.tag) {
          return "pointer badge badge-pill badge-default mb-2";
        } else {
          return "pointer badge badge-pill badge-light text-default mb-2";
        }
      }
    },

    setModal() {
      this.modals[this.action] = true;
    },


    closeModal() {
      this.modals[this.action] = false;
      this.form = {
        activityName: "",
        activityDetails: "",
        rating: 5,
        highlights: [],
        lowlights: [],
        tempHighlight: "",
        tempLowlight: "",
        tag: "",
        tagData: null,
        dailyCheckinQuestions: null,
      };
      setTimeout(this.sendClose, 500);
    },

    sendClose() {
      this.$emit("modalClosed");
    },

    deleteReflection() {

      const vm = this

      let docId = vm.entry.id;

      db.collection("reflections")
        .doc(docId)
        .delete()
        .then(function () {
          // console.log("Document successfully deleted!");

          vm.$store.commit("REFLECTION_DELETED", docId);

        })
        .catch(function (error) {
          // console.error("Error removing document: ", error);
        });

      // WIP: ADD REFLECTION ID TO STORE

      this.closeModal();

      this.$toast.open({
        message: "Reflection deleted!",
        type: "warning",
        // all of other options may go here
      });
    }
  },
};
</script>

<style scoped>
.card-ellipse {
  position: absolute;
  text-align: right;
  top: 0;
  right: 0;
}

.img-rt {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 60px;
  height: 60px;
}

.img-rating-report {
  position: absolute;
  /* top: 12px; */
  margin: 45px -45px;
  width: 30px;
  height: 30px;
}

/* rating */

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  margin: 15.7px 0;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4.6px;
  cursor: pointer;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
  background: #e6dfea;
  border-radius: 2.4px;
  border: 0px solid rgba(1, 1, 1, 0);
}
input[type="range"]::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid rgba(0, 0, 0, 0);
  height: 36px;
  width: 36px;
  border-radius: 47px;
  background: #8d6c9f;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -15.7px;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #f2eef4;
}
input[type="range"]::-moz-range-track {
  width: 100%;
  height: 4.6px;
  cursor: pointer;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
  background: #e6dfea;
  border-radius: 2.4px;
  border: 0px solid rgba(1, 1, 1, 0);
}
input[type="range"]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid rgba(0, 0, 0, 0);
  height: 36px;
  width: 36px;
  border-radius: 47px;
  background: #8d6c9f;
  cursor: pointer;
}
input[type="range"]::-ms-track {
  width: 100%;
  height: 4.6px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type="range"]::-ms-fill-lower {
  background: #dad0e0;
  border: 0px solid rgba(1, 1, 1, 0);
  border-radius: 4.8px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
}
input[type="range"]::-ms-fill-upper {
  background: #e6dfea;
  border: 0px solid rgba(1, 1, 1, 0);
  border-radius: 4.8px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
}
input[type="range"]::-ms-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid rgba(0, 0, 0, 0);
  height: 36px;
  width: 36px;
  border-radius: 47px;
  background: #8d6c9f;
  cursor: pointer;
  height: 4.6px;
}
input[type="range"]:focus::-ms-fill-lower {
  background: #e6dfea;
}
input[type="range"]:focus::-ms-fill-upper {
  background: #f2eef4;
}
</style>
