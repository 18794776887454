// lineChart.js
import { Line } from 'vue-chartjs'

export default {
  extends: Line,
  props: ['chartData','chartLabels'],
  data() {
    return {
        memberData: [],
        colors: [
            "#8D6C9F","#C0B5D1","#7A909B","#38867F","#866058","#9CA5B9","#7A909B","#AD9163","#8A6B55","#624B41"
        ]
    }
  },
  mounted () {

    // Get our member pie chart data


    var tmpMember = this.chartData
    tmpMember.forEach((member,ti) => {

        let membersData = Array()

        var tmpData = member.data
        tmpData.forEach((data,mi) => {
            membersData.push(data.totalReflections)
        });

        let dataSet = {
            label: member.name,
            borderColor: this.colors[ti],
            data: membersData,
            fill: false,
            borderWidth: 3
        }

        this.memberData.push(dataSet)

    });



    // Overwriting base render method with actual data.
    this.renderChart(
      // Data
      {
          labels: this.chartLabels,
          datasets: this.memberData
      },
      // Options
      {
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              boxWidth: 15,
            }
          },
          maintainAspectRatio: false
      }
    )
  },
  methods: {

        // outputPops(){
        //     // console.log(this.chartData)
        // }

  }
}
