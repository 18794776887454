<template>
    <div>

        <div class="row justify-content-center pl-3 pr-3 mb-2">

            <div class="col-12" v-show="attachment.title || attachment.summary">
                <p class="card-title mb-1 text-default font-weight-bold" v-show="attachment.title">{{ attachment.title }}<br /></p>
                <p v-show="attachment.summary">{{ attachment.summary }}</p>
            </div>

            <div class="col-12">

                <div class="mt-4 mb-4" v-if="!reqData">

                    <div v-if="attachment.src">
                        <video-embed class="rounded" :src="attachment.src"></video-embed>
                    </div>
                    <div v-else>
                        No video provided
                    </div>
                    

                </div>
                <div class="mt-4 mb-4" v-else>

                    <input type="text" class="text-center form-control" placeholder="Enter Video URL" v-model="localData.src">
                    <div class="float-right text-uppercase text-muted">
                        <small class="mr-1">SUPPORTS</small>
                        <i class="fab fa-youtube mr-1" title="YouTube"></i>
                        <i class="fab fa-vimeo mr-1" title="Vimeo"></i>
                        <i class="icon-wistia mr-1" title="Wistia"></i>
                        <i class="fab fa-dailymotion mr-1" title="Daily Motion"></i>
                        <i class="icon-coub" title="Coub"></i>
                    </div>

                    

                </div>


                <p>{{ attachment.summary }}</p>

            </div>

        </div>
    </div>
</template>
<script>

import firebase from "firebase/app";
import { db } from "@/firebase";
import VideoEmbed from "@/components/embeds/Video";
import _ from "lodash";

export default {
    name: 'embed-video',
    props: ['attachment','id','index','readOnly'],
    components: {
        VideoEmbed
    },
    mounted() {
       this.reqData = this.requestDataChecker();
    },

    data () {
        return {
            localData: _.cloneDeep(this.attachment),
            reqData: false
        }
    },
    computed: {

    },

    watch: {

    },

    methods: {

        requestDataChecker(){

            if(this.readOnly){
                return false
            }

            if(this.attachment.requestData && this.checkForValues()){
                return true
            }

            return false
        },

        checkForValues(){

            let data = this.localData.src
            let dataReq = false

            if(!data){
                dataReq = true
            }

            return dataReq

        },

    }
};
</script>

<style scoped>

</style>